import React, {useContext, useState, useEffect, Fragment} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {IBeamPricing} from '../../../../models/beams/beamPricing'
import {IBeamFinish} from '../../../../models/beams/beamFinish'
import {IBeamType} from '../../../../models/beams/beamType'
import {IBeam} from '../../../../models/beams/beams'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { BeamUnitPriceFor } from '../../../../common/constant'

function NewBeamFasciaHangingItem(props: any) {
  const {
    isBeamFasciaHangingChecked,
    setIsBeamFasciaHangingChecked,
    fasciaHangingUnitPrice,
    setFasciaHangingUnitPrice,
    fasciaHangingQuantity,
    setFasciaHangingQuantity,
    fasicaHangingSize,
    setFasicaHangingSize,
    setFasciaHangingSizeName,
    fasciaHangingColorId,
    setFasciaHangingColorId,
    setFasciaHangingColorName,
    resetFasciaHangingState,
    beamType,
    colorId,
    isJoinerAndFasciaHidden,
    length,
    isLengthValid,
    disabledFasciaCheckBox
  } = props

  const {
    beamFasciaHangingSizes,
    beamFasciaHangingColours,
    beamFasciaHangingUnitPrice,
    getBeamFasciaHangingUnitPriceAsync,
    setBeamFasciaHangingUnitPrice,
  } = useContext(QuickOrderContext)

  useEffect(() => {
    if (!isBeamFasciaHangingChecked) {
      resetFasciaHangingState()
    }
  }, [isBeamFasciaHangingChecked])

  /**
   * TYPE
   */
  const handleFasicaHangingSizeOnChange = (e: any) => {
    setFasicaHangingSize(e.target.value)
  }

  useEffect(() => {
    if (beamFasciaHangingSizes && beamFasciaHangingSizes.length > 0) {
      let selected = beamFasciaHangingSizes.find((b: IBeamType) => b.id.toString() == fasicaHangingSize)
      if (selected) {
        setFasciaHangingSizeName(selected.name)
      }
    }
  }, [fasicaHangingSize])

  /**
   * COLOR
   */
  const handleFasciaHangingColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : beamFasciaHangingColours[selectedIndex - 1].name
    const parsedValue = parseFloat(e.target.value)
    if (!isNaN(parsedValue)) {
      setFasciaHangingColorId(parsedValue)
    } else {
      setFasciaHangingColorId(0)
    }
    setFasciaHangingColorName(name)
  }

  useEffect(() => {
    setFasciaHangingUnitPrice(beamFasciaHangingUnitPrice ? beamFasciaHangingUnitPrice : 0)
  }, [beamFasciaHangingUnitPrice])

  /**
   * QUANTITY
   */
  const handleFasciaHangingQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim()
    let inputNumber
    if (inputValue === '') {
      setFasciaHangingQuantity(null)
    } else {
      inputNumber = parseInt(inputValue, 10)
      if (!isNaN(inputNumber) && inputNumber >= 1 && inputNumber <= 99) {
        setFasciaHangingQuantity(inputNumber)
      }
    }
  }

  const getBeamUnitPrice = async () => {
    if (beamType && colorId) {
      await getBeamFasciaHangingUnitPriceAsync(beamType, colorId)
    }
    else {
      setBeamFasciaHangingUnitPrice(0)
    }
  }

  useEffect(() => {
    getBeamUnitPrice()
  }, [beamType, colorId])

  const handleBeamFasciaHangingCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsBeamFasciaHangingChecked(true)
    } else {
      setIsBeamFasciaHangingChecked(false)
    }
  }

  const getCheckboxMessage = () => {
    if (isJoinerAndFasciaHidden) {
      return "Only applicable for steel beam"
    }

    return ""
  }

  return (
    <Fragment>
      {/*  Fascia Hanging  */}
      {!isJoinerAndFasciaHidden ? (
        <>
          <div className='row mb-4'>
            <div className='col-md-4 d-flex align-items-center justify-content-between' title={getCheckboxMessage()}>
              <div>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='beamFasciaHanging'
                  name='beamFasciaHanging'
                  checked={isBeamFasciaHangingChecked}
                  onChange={handleBeamFasciaHangingCheckboxChange}
                  style={{marginRight: 10}}
                  disabled={isJoinerAndFasciaHidden || disabledFasciaCheckBox}
                />
                <label className='fw-bolder text-dark' style={{fontSize: 12}}>Fascia Hanging</label>
              </div>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>
                ({moneyFormatter.format(
                  fasciaHangingUnitPrice
                )})
              </span>
            </div>
            {/* COLOR */}
            {/* <div className='col-md-3'>
              <label className='fw-bolder text-dark' htmlFor='colorId'>
                Colour
              </label>
              <select
                style={{fontSize: '1rem'}}
                id='fasciaHangingcolorId'
                className='form-select'
                aria-label='fasciaHangingColour'
                value={fasciaHangingColorId}
                onChange={handleFasciaHangingColorOnChange}
                disabled={!isBeamFasciaHangingChecked}
              >
                <option value='Select Colour'>Select</option>
                {beamFasciaHangingColours?.map((beamColour: any) => (
                  <option value={beamColour.id} key={beamColour.id}>
                    {beamColour.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-3'>
              <label className='fw-bolder text-dark' htmlFor='sub-type'>
                Size
              </label>
              <select
                style={{fontSize: '1rem'}}
                className='form-select'
                value={fasicaHangingSize}
                onChange={handleFasicaHangingSizeOnChange}
                id='size'
                disabled={!isBeamFasciaHangingChecked}
              >
                <option value=''>Select</option>
                {beamFasciaHangingSizes?.map((beamType: any) => (
                  <option value={beamType.id} key={beamType.id}>
                    {beamType.name}
                  </option>
                ))}
              </select>
            </div> */}
            {/* Quantity */}
            <div className='col-md-3'>
              <label className='fw-bolder text-dark'>Quantity</label>
              <input
                style={{fontSize: '1rem'}}
                type='number'
                min='1'
                step='1'
                max='99'
                className='form-control'
                id='beamFasciaHangingQuantity'
                aria-describedby='beamFasciaHangingQuantity'
                value={fasciaHangingQuantity === null ? '' : fasciaHangingQuantity}
                onChange={handleFasciaHangingQuantityChange}
                disabled={!isBeamFasciaHangingChecked}
                placeholder='Enter Quantity'
              />
            </div>
          </div>
        </>
      ) : null}
    </Fragment>
  )
}

export default NewBeamFasciaHangingItem
