import axios from 'axios'
import React, {useRef, useEffect, useState, useContext} from 'react'
import FixingsProduct from './FixingsProduct'
import {
  deleteFixing,
  getFixingPricingPaginated,
  getFixingUnitTypes,
  getFixingMasterlist,
  createFixingProduct,
  updateFixingProduct,
  deleteFixingProduct
} from '../../../../../api/fixings-api'
import {useAsyncFn, useEffectOnce} from 'react-use'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {getUnitTypes} from '../../../../../api/unittype-api'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import { useHistory } from 'react-router-dom'
import {getColours} from '../../../../../api/index'

type Props = {
  product: string
  productId: number
}

const Fixings = (props: Props) => {
  const {productId} = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const {getFixingAsync, fixings, colours, fetchFixings, setFixingUnitTypesProducts, fixingUnitTypesProducts} =
    useContext(AdminProductContext)

  /**
   * REF
   */
  const nameRef = useRef<any>()
  const screwRef = useRef<any>()
  const unitRef = useRef<any>()
  const priceRef = useRef<any>()
  const regex = /^[a-zA-Z0-9\s./-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/

  /**
   * Initial Load
   */
  useEffectOnce(() => {
    if (!fixings) {
      getFixingAsync()
    }
    if (!fetchUnitTypes && !fixingUnitTypesProducts) {
      fetchUnitTypesAsync()
    }

  })
  const [{loading: fetchUnitTypes}, fetchUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFixingUnitTypes()
    if (result != null) {
      const mappedData: any[] = result.data.map((item: {
        id: number
        unitTypeId: number
        unitTypeName: string
      }) => ({
        id: item.unitTypeId,
        name: item.unitTypeName
      }))
      setFixingUnitTypesProducts(mappedData)
    }
  }, [setFixingUnitTypesProducts])

  /**
   * Pricebook Event
   */
  useEffect(() => {
    setSeqTotalPages(1)
    setSeqCurrentPage(1)
    // Load to page 1
    // fetchFixingsProduct(1)
  }, [productId])

  /**
   * SEQ
   */
  const [seqData, setSeqData] = useState<any>({})
  const [newSeqProduct, setNewSeqProduct] = useState<any>({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')

  const history = useHistory();

  const fetchFixingsProduct = async (currentPage: number, filter1?: any, filter2?: any, filter3?: any) => {
    // const response = await getFixingProductPaginated(currentPage, seqDataPerPage, filter1, filter2)
    // setSeqData(response.data)
    // setSeqTotalPages(Math.ceil(response.data.totalCount / seqDataPerPage))

    await getFixingMasterlist(currentPage, seqDataPerPage, filter1, filter2, filter3).then((response: any) => {
      setSeqData(response.data)
      setSeqTotalPages(Math.ceil(response.data?.totalCount / 10))
    })
  }
  
  /**
   * PAGINATION
   */
  const handleFilter = (filter1: any, filter2: any, filter3: any) => {
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setSeqCurrentPage(1)
  }

  // useEffect(() => {
  //   // fetchFixingsProduct(seqCurrentPage, Filter1, Filter2)
  //   setSeqCurrentPage(1)
  // }, [Filter1, Filter2])

  useEffect(() => {
    fetchFixingsProduct(seqCurrentPage, Filter1, Filter2, Filter3)
  }, [seqCurrentPage, Filter1, Filter2, Filter3])

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setIsSeqAdding(false)
  }
  const handleCancel = () => {
    setEditingSeqId(null)
    setEditedSeqData({})
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }
    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({
        ...prevData,
        [name]: name === 'panelThickness' ? {...name, name: value} : value,
      }))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deleteFixingProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchFixingsProduct(seqCurrentPage)
        handleSuccessMsg("Product deleted successfully.")
      } else {
        const errorMessage = result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Fixing:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }
  
    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
  }
  /**
   * UPDATE
   */
  const handleView = (iProductId: any) => {
    history.push(`/admin/product-details/Fixing/${iProductId}`)
  }
  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg("Product updated sucessfully.")
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewSeqProduct({})
    setIsSeqAdding(false)
  }

  const handleSave = () => {
    const newData = {
      ...seqData,
      items: seqData.items?.map((item: any) => (item.id === editingSeqId ? editedSeqData : item)),
    }

    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  /**
   * ADD
   */
  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData({...seqData, items: [newSeqProduct, ...seqData.items]})
    setIsSeqAdding(false)
  }
  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(nameRef.current.value)) {
      nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(screwRef.current.value)) {
      screwRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(unitRef.current.value)) {
      unitRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    setNewSeqProduct({...newSeqProduct, [name]: value})
  }

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const productFields = [
    { label: 'Item Number', key: 'Code', type: 'text' },
    { label: 'Screw Name', key: 'FixingName', type: 'text' },
    { label: 'Screw Type', key: 'ScrewType', type: 'text', optional: true },
    { label: 'Colour', key: 'ColourId', type: 'dropdown', options: colours},
  ]

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    console.log('newProduct', newProduct)
    createFixingProduct(newProduct)
    .then((result: any) => {
      if (result.data) {
        handleSuccessMsg("Product added successfully.")
        fetchFixingsProduct(seqCurrentPage)
      } else {
        handleErrorMessage(result?.response?.data)
      }
    })
    .catch(() => {
      handleErrorMessage('Error Encountered While Saving. Please try again later.')
    })

    setIsSeqAdding(false)
  }

  return (
    <>
      <div className='card-body' style={{height: '100vh', backgroundColor: '#fff'}}>
        <AddProductButton onClick={handleAddProduct} />
        <div style={{overflowY: 'auto', height: '95%'}}>
          {errorMsg && (
            <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
              <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className='alert alert-success w-50 m-auto text-center' role='alert'>
              <i className='fa fa-check-circle text-success'></i> {successMsg}
            </div>
          )}

          {!fetchFixings && (
            <FixingsProduct
              data={seqData}
              currentPage={seqCurrentPage}
              totalPages={seqTotalPages}
              handleView={handleView}
              handleSave={handleSave}
              editingId={editingSeqId}
              editedData={editedSeqData}
              handleSaveClick={handleSaveClick}
              handleInputChange={handleInputChange}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              handleCancelClick={handleCancelClick}
              handleCancel={handleCancel}
              handleDeleteProduct={handleDeleteProduct}
              handleChange={handleChange}
              handleUpdateSuccess={handleUpdateSuccess}
              newProduct={newSeqProduct}
              isAdding={isSeqAdding}
              nameRef={nameRef}
              screwRef={screwRef}
              unitRef={unitRef}
              priceRef={priceRef}
              fixings={fixings}
              handleErrorMessage={handleErrorMessage}
              handleFilter={handleFilter}
            />
          )}
        </div>
      </div>
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
        isDeleting={isDeleting}
      />
      <NewProductModal
        isOpen={isAddingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleSaveNewProduct}
        fields={productFields}
      />
    </>
  )
}

export default Fixings
