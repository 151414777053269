/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, lazy, useState} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {UserLayout} from '../../_metronic/layout/UserLayout'
import {Login} from '../pages/admin-portal/Login'
import {AdminPortalLayout} from '../pages/admin-portal/AdminPortalLayout'
import {AdminRoutes} from '../pages/admin-portal/AdminRoutes'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import HomePage from '../pages/home/HomePage'
import QuickOrderPage from '../pages/quick-order/OuickOrderPage'
import PatioBuilderPage from '../pages/patio-builder/PatioBuilderPage'
import CheckoutPage from '../pages/checkout/CheckoutPage'
import OrderSummaryPage from '../pages/order-summary/OrderSummaryPage'
import {QuickOrderContextProvider} from '../context/QuickOrderContext'
import ConfirmOrderPage from './../pages/confirm-order/ConfirmOrderPage'

const Routes: FC = () => {
  // const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [isAuthorized, setIsAuthorized] = useState(
    JSON.parse(localStorage.getItem('isAuthorized') as any)
  )
  // const [isAuthorized, setIsAuthorized] = useState(false)

  return (
    <QuickOrderContextProvider>
      <Switch>
        <Route exact path='/error' component={ErrorsPage} />
        <Route exact path='/logout' component={Logout} />

        <Route exact path='/' render={() => <HomePage isAuthorized={isAuthorized} />} />
        <Route
          exact
          path='/quick-order'
          render={() => <QuickOrderPage isAuthorized={isAuthorized} />}
        />
        {/* <Route
          exact
          path='/patio-builder'
          render={() => <PatioBuilderPage isAuthorized={isAuthorized} />}
        /> */}

        <Route exact path='/admin' render={() => <Login setIsAuthorized={setIsAuthorized} />} />
        <Route exact path='/checkout' render={() => <CheckoutPage isAuthorized={isAuthorized} />} />
        <Route
          exact
          path='/order-summary'
          render={() => <OrderSummaryPage isAuthorized={isAuthorized} />}
        />
        <Route
          exact
          path='/confirm-order'
          render={() => <ConfirmOrderPage isAuthorized={isAuthorized} />}
        />
        {isAuthorized === true ? <AdminRoutes /> : <PrivateRoutes />}
      </Switch>
      <MasterInit />
    </QuickOrderContextProvider>
  )
}

export {Routes}
