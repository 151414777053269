import React, {useContext, useEffect, useState} from 'react'
import FlashingItem from './FlashingItem'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {useEffectOnce} from 'react-use'
import NewFlashingItem from './NewFlashingItem'

const Flashings = (props: any) => {
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const {flashingProducts} = useContext(QuickOrderContext)

  const [flashingItemList, setFlashingItemList] = useState<[]>([])

  useEffect(() => {
    if (flashingProducts) {
      // get all flashing type in pricing

      const flashingSelection: any = Object.values(
        flashingProducts.reduce((map: any, obj: any) => {
          const key = `${obj.flashingTypeId}-${obj.flashingTypeName}`
          if (!map[key]) {
            map[key] = obj
          }
          return map
        }, {} as Record<string, any>)
      )
      const options = (flashingSelection || [])?.map((availPricing: any) => ({
        id: availPricing.flashingTypeId,
        name: availPricing.flashingTypeName,
      }))

      setFlashingItemList(options)
    }
  }, [flashingProducts])

  return (
    <div className='tab-items flashing-tab-items'>
      <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
        Flashing Types
      </h3>
      {/* TOAST */}
      {error ? (
        <div
          className={`alert alert-danger ${
            errorMsg.length > 100 ? 'w-50' : 'w-25'
          } m-auto text-center`}
          role='alert'
        >
          {errorMsg}
        </div>
      ) : null}
      <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden', height: '90vh'}}>
        {/* {flashingItemList?.map((flashing: any) => (
          <FlashingItem
            flashing={flashing}
            key={flashing.id}
            flashingName={flashing.name}
            unitPrice={flashing.unitPrice}
            setError={setError}
            setErrorMsg={setErrorMsg}
          />
        ))} */}
        {/* UI REVISIONS */}
        <NewFlashingItem
          setError={setError}
          setErrorMsg={setErrorMsg}
          flashingItemList={flashingItemList}
        />
      </div>
    </div>
  )
}

export default Flashings
