import React from 'react'
import { moneyFormatter } from '../../../../../utils'

function RainwaterTable(props: any) {
    const { cartItems } = props
    const hasColor = cartItems.some((item: { color: undefined; }) => item.color !== undefined);
    const hasLength = cartItems.some((item: { length: undefined; }) => item.length !== undefined && item.length !== 0);
    const whole = 1.00000000000000000

    return (
        <div>
            <table className='table table-hover summary-table' style={{ backgroundColor: '#fff' }}>
                <thead className='thead-dark'>
                    <tr>
                        <th scope='col' style={{ width: '20%' }}>
                            Description
                        </th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'>
                            Colour
                        </th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'></th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Length
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Quantity
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Unit Price
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            GST
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems?.map((item: any, index: number) => {
                        if (
                            item.type === 'gutter' ||
                            item.type === 'stop-end-bracket'
                        ) {

                            let computedPrice = 0.00
                            let totalAmount = 0.00
                            let gst = 0.00

                            const roundToTwo = (num: number) => Math.round(num * 100) / 100

                            if (item.length > 1) {
                                computedPrice = (item.unitPrice * (item?.length / 1000) * item.quantity)
                            }
                            else {
                                computedPrice = (item.unitPrice * item?.quantity)
                            }
                            gst = roundToTwo(computedPrice * .10)
                            totalAmount = roundToTwo(computedPrice) + gst

                            return (
                                <tr key={index}>
                                    <td className='align-middle'>
                                        <p className='fw-bolder text-dark mb-0'>
                                            {item?.goodsType ? item?.goodsType : item?.gutterName}
                                        </p>
                                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                            <p className='text-dark mb-0'>Type:</p>
                                            <p className='text-dark mb-0 text-nowrap'>{item?.rainwaterPartName}</p>
                                        </div>
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {item?.colorName ? item?.colorName : '-' }
                                    </td>
                                    <td />
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {/* {(item.type) === 'accessory' || (item.type) === 'stop-end-bracket' ? '-' : (item?.length)} */}
                                        {item.length > 1 ? `${item.length}mm` :  '-' }
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {/* {(item.type) === 'accessory' || (item.type) === 'stop-end-bracket' ? (item?.quantity) : '-'} */}
                                        {item.quantity}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(
                                            item?.type === 'accessory' ? item?.unitPrice : item?.price
                                        )}
                                    </td>
                                    {/* Assumption */}
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(gst)}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(totalAmount)}
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default RainwaterTable
