import React, {useContext, useEffect} from 'react'
import FixingItem from './FixingItem'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {useEffectOnce} from 'react-use'
import {IFixingPricing} from '../../../../models/fixings/fixingPricing'
import NewFixingItem from './NewFixingItem'

const Fixings = () => {
  const {fixingPricings} = useContext(QuickOrderContext)

  return (
    <div className='tab-items fixing-tab-items'>
      <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
        Screw Types
      </h3>
      <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden', height: '90vh'}}>
        <NewFixingItem fixingPricings={fixingPricings} />
      </div>
    </div>
  )
}

export default Fixings
