import React, { useState } from "react"
import { useAsyncFn } from "react-use"
import { IPanelBottomColour } from "../models/panel-bottom-color"
import { IPanelThickness } from "../models/panel-thickness"
import { getPanelBottomColours, getPanelCores, getPanelProfiles, getPanelThicknesses } from "../api/panels-api"
import { IPanelProfiles } from "../models/panel-profile"
import { IPanelCores } from "../models/panel-core"
import { getColours, getProductIdByName } from "../api"
import { IColour } from "../models/colour"
import { getFixingUnitTypes } from "../api/fixings-api"
import { IFixingUnitTypeProducts } from "../models/fixings/fixingUnitTypeProducts"
import { getAllProduct as getAllLightProducts, getLightProductMaster } from "../api/light-api"
import {
    getProductFlashingColours,
    getProductFlashingSubTypes,
    getProductFlashingThicknesses,
    getProductFlashingTypes,
    getFlashingUnitPrice
} from '../api/flashings-api'
import { 
    getProductFixings,
    getProductFixingScrewTypes,
    getFixingUnitPrice
} from "../api/fixings-api"
import { 
    getBeamsPM,
    getBeamTypesProdMasterlist,
    getBeamColours,
    getBeamProductDetails
} from "../api/beams-api"
import { 
    getPostTypesPM,
    getBeamSizesPM,
    getAllPostColoursPM,
    getPostProductDetails
} from "../api/posts-api"
import { 
    getPanelProductOptions,
    getPanelProductDetails
} from "../api/panels-api"
import { IFlashingType } from "../models/flashings/flashingType"
import { IFlashingSubtype } from "../models/flashings/flashingSubtype"
import { IFlashingThickness } from "../models/flashings/flashingThickness"
import { IFixing } from "../models/fixings/fixing"
import { IBeamsProducts } from "../models/beams/beamProducts"
import { IBeamSize } from "../models/beams/beamSizes"
import { IPostType } from "../models/posts/postType"
import { getAllProduct as getAllRainwaterProduct } from "../api/rainwater-api"
import { getAllProduct as getAllAccessoryProduct } from "../api/accessory-api"
import { IPanelCutback } from "../models/panel-cutback"
import { IPanelDirectionLay } from "../models/panel-direction-lay"
import { PRODUCT_IMPORT_TYPE_POST, ORDER_TAB_POSTS } from "../common/constant"

export const AdminOrderSummaryContext = React.createContext<any>({})

export const AdminOrderSummaryContextProvider: React.FC = ({children}) => {

    const fetchPanelOptions = async () => {
        if (!fetchPanelBottomColours && !panelBottomColour) {
            await fetchPanelBottomColoursAsync()
        }
        if (!fetchPanelThicknesses && !panelThickness) {
            await fetchPanelThicknessesAsync()
        }
        if (!fetchPanelProfiles && !panelProfiles) {
            await fetchPanelProfilesAsync()
        }
        if (!fetchPanelCores && !panelCores) {
            await fetchPanelCoresAsync()
        }
        if (!fetchColours && !colours) {
            await fetchColoursAsync()
        }
    }
    
    //** Panel Thickness **//
    const [panelThickness, setPanelThickness] = useState<IPanelThickness[] | undefined>()
    const [{loading: fetchPanelThicknesses}, fetchPanelThicknessesAsync] = useAsyncFn(async () => {
    try {
        const result: any = await getPanelThicknesses()
        if (result != null) {
        setPanelThickness(result.data)
        }
    } catch (error) {
        console.error('Error in fetchPanelThicknessesAsync:', error)
    }
    }, [setPanelThickness])
    
    //** Panel Profiles **//
    const [panelProfiles, setPanelProfiles] = useState<IPanelProfiles[] | undefined>()
    const [{loading: fetchPanelProfiles}, fetchPanelProfilesAsync] = useAsyncFn(async () => {
    const result: any = await getPanelProfiles()
    if (result != null) {
        setPanelProfiles(result.data)
    }
    }, [setPanelProfiles])
    //** Panel Cores **//
    const [panelCores, setPanelCores] = useState<IPanelCores[] | undefined>()
    const [{loading: fetchPanelCores}, fetchPanelCoresAsync] = useAsyncFn(async () => {
    const result: any = await getPanelCores()
    if (result != null) {
        setPanelCores(result.data)
    }
    }, [setPanelCores])
    //** Bottom Colour **//
    const [panelBottomColour, setPanelBottomColours] = useState<IPanelBottomColour[] | undefined>()
    const [{loading: fetchPanelBottomColours}, fetchPanelBottomColoursAsync] =
    useAsyncFn(async () => {
        const result: any = await getPanelBottomColours()
        if (result != null) {
        setPanelBottomColours(result.data)
        }
    }, [setPanelBottomColours])
    //** Panel Colour **//
    const [colours, setColours] = useState<IColour[] | undefined>()
    const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
        if (result != null) {
            setColours(result.data)
        }
    }, [setColours])


    const fetchFixingOptions = async () => {
        // if (!fixings) {
        //     getFixingAsync()
        // }
        if (!fetchUnitTypes && !fixingUnitTypesProducts) {
            fetchUnitTypesAsync()
        }
    }
    const [fixingUnitTypesProducts, setFixingUnitTypesProducts] = useState<IFixingUnitTypeProducts[] | undefined>()
    const [{loading: fetchUnitTypes}, fetchUnitTypesAsync] = useAsyncFn(async () => {
        const result: any = await getFixingUnitTypes()
        if (result != null) {
            const mappedData: any[] = result.data.map((item: {
                id: number
                unitTypeId: number
                unitTypeName: string
                }) => ({
                id: item.unitTypeId,
                name: item.unitTypeName
            }))
            setFixingUnitTypesProducts(mappedData)
        }
    }, [setFixingUnitTypesProducts])


    //** Lights **//
    const [lightProductMaster, setLightProductMaster] = useState<[] | undefined>()
    const [{loading: fetchLightProductMaster}, fetchLightProductMasterAsync] = useAsyncFn(async (pricebookId) => {
    const result: any = await getLightProductMaster(pricebookId)
        if (result != null) {
            setLightProductMaster(result.data)
        }
    }, [setLightProductMaster])


    //Flashings
    const [flashingTypes, setFlashingTypes] = useState<IFlashingType[] | undefined>()
    const [flashingSubTypes, setFlashingSubTypes] = useState<IFlashingSubtype[] | undefined>()
    const [flashingThicknesses, setFlashingThicknesses] = useState<IFlashingThickness[] | undefined>()
    const [flashingColours, setFlashingColours] = useState<IColour[] | undefined>()
    const [flashingUnitPrice, setFlashingUnitPrice] = useState<number>(0)
    const [flashingProductDetails, setFlashingProductDetails] = useState<any>(null)

    const fetchFlashingProductOptions = async () => {
        try {
            const flashingColoursPM: any = await getProductFlashingColours()
            const flashingSubTypesPM: any = await getProductFlashingSubTypes()
            const flashingThicknessesPM: any = await getProductFlashingThicknesses()
            const flashingTypesPM: any = await getProductFlashingTypes()

            setFlashingTypes(flashingTypesPM.data)
            setFlashingSubTypes(flashingSubTypesPM.data)
            setFlashingThicknesses(flashingThicknessesPM.data)
            setFlashingColours(flashingColoursPM.data)
        } catch (error) {
            console.error("Error fetching flashing product options:", error)
        }
    }

    const [{loading: fetchingFlashingUnitPrice}, getFlashingUnitPriceAsync] = useAsyncFn(
        async (flashingTypeId: number, flashingSubTypeId: number, flashingThicknessId: number, colourId: number) => {
        const prodDetails: any = await getFlashingUnitPrice(flashingTypeId, flashingSubTypeId, flashingThicknessId, colourId)
        if (prodDetails != null) {
            setFlashingUnitPrice(prodDetails.priceLevel1 ? prodDetails.priceLevel1 : 0)
            setFlashingProductDetails(prodDetails)
        }
      }, [setFlashingUnitPrice])


    //Fixings
    const [productFixings, setProductFixings] = useState<IFixing[] | undefined>()
    const [fixingScrewTypes, setFixingScrewTypes] = useState<{ id: number; name: string }[] | undefined>()
    const [fixingUnitPrice, setFixingUnitPrice] = useState<number>(0)
    const [fixingProductDetails, setFixingProductDetails] = useState<any>(null)

    const fetchFixingProductOptions = async (fixingId?: number) => {
        try {
            const fixingsPM: any = await getProductFixings()
            const fixingScrewTypesPM: any = await getProductFixingScrewTypes(fixingId)

            setProductFixings(fixingsPM.data)
            setFixingScrewTypes(fixingScrewTypesPM)
        } catch (error) {
            console.error("Error fetching fixing product options:", error)
        }
    }

    const [{loading: fetchingFixingUnitPrice}, getFixingUnitPriceAsync] = useAsyncFn(
        async (fixingId: number, screwType: string) => {
        
        const prodDetails: any = await getFixingUnitPrice(fixingId, screwType)
        if (prodDetails != null) {
            setFixingUnitPrice(prodDetails.priceLevel1 ? prodDetails.priceLevel1 : 0)
            setFixingProductDetails(prodDetails)
        }
      }, [setFixingUnitPrice])

    //Beams
    const [beamProducts, setBeamProducts] = useState<IBeamsProducts[] | undefined>()
    const [beamSizes, setBeamSizes] = useState<IBeamSize[] | undefined>()
    const [beamColours, setBeamColours] = useState<IColour[] | undefined>()
    const [beamUnitPrice, setBeamUnitPrice] = useState<number>(0)
    const [beamProductDetails, setBeamProductDetails] = useState<any>(null)

    const fetchBeamProductOptions = async () => {
        try {
            const beamProductsPM: any = await getBeamsPM()
            const beamSizesPM: any = await getBeamTypesProdMasterlist()
            const beamColoursPM: any = await getBeamColours()

            setBeamProducts(beamProductsPM.data)
            setBeamSizes(beamSizesPM.data)
            setBeamColours(beamColoursPM.data)
        } catch (error) {
            console.error("Error fetching beam product options:", error)
        }
    }

    const [{loading: fetchingBeamUnitPrice}, getBeamUnitPriceAsync] = useAsyncFn(
        async (beamProductId: number, beamSizeId: number, beamColourId: number) => {
        
        const prodDetails: any = await getBeamProductDetails(beamProductId, beamSizeId, beamColourId)
        if (prodDetails != null) {
            setBeamUnitPrice(prodDetails.priceLevel1 ? prodDetails.priceLevel1 : 0)
            setBeamProductDetails(prodDetails)
        }
      }, [setBeamUnitPrice])

    //Posts
    const [postTypes, setPostTypes] = useState<IPostType[] | undefined>()
    const [postBeamSizes, setPostBeamSizes] = useState<IBeamSize[] | undefined>()
    const [postColours, setPostColours] = useState<IColour[] | undefined>()
    const [postUnitPrice, setPostUnitPrice] = useState<number>(0)
    const [postProductDetails, setPostProductDetails] = useState<any>(null)
    const [postProductId, setPostProductId] = useState<number>(0)
    const [postKitProductId, setPostKitProductId] = useState<number>(0)

    const [{loading: fetchingPostProductIdByName}, getPostProductIdByNameAsync] = useAsyncFn(async (
        postProductName: string) => {
        const productId: any = await getProductIdByName(postProductName, PRODUCT_IMPORT_TYPE_POST)
        if (productId != null) {
            if (postProductName === ORDER_TAB_POSTS) {
                setPostProductId(productId.productId)
            }
            else {
                setPostKitProductId(productId.productId)
            }
        }
      }, [setPostProductId, setPostKitProductId])

    const fetchPostProductOptions = async (postProductId?: number) => {
        try {
            const postTypesPM: any = await getPostTypesPM(postProductId ? postProductId : 0)
            const postBeamSizesPM: any = await getBeamSizesPM(postProductId ? postProductId : 0)
            const postColoursPM: any = await getAllPostColoursPM(postProductId ? postProductId : 0)

            setPostTypes(postTypesPM.data)
            setPostBeamSizes(postBeamSizesPM.data)
            setPostColours(postColoursPM.data)
        } catch (error) {
            console.error("Error fetching post product options:", error)
        }
    }

    const [{loading: fetchingPostUnitPrice}, getPostUnitPriceAsync] = useAsyncFn(
        async (postTypeId: number, postBeamSizeId: number, postColourId: number) => {
        
        const prodDetails: any = await getPostProductDetails(postTypeId, postBeamSizeId, postColourId)
        if (prodDetails != null) {
            setPostUnitPrice(prodDetails.priceLevel1 ? prodDetails.priceLevel1 : 0)
            setPostProductDetails(prodDetails)
        }
      }, [setPostUnitPrice])

    //Panels
    const [productPanelBottomColours, setProductPanelBottomColours] = useState<IPanelBottomColour[] | undefined>()
    const [productPanelCores, setProductPanelCores] = useState<IPanelCores[] | undefined>()
    const [productPanelProfiles, setProductPanelProfiles] = useState<IPanelProfiles | undefined>()
    const [productPanelRoofColours, setProductPanelRoofColours] = useState<IColour[] | undefined>()
    const [productPanelThicknesses, setProductPanelThicknesses] = useState<IPanelThickness[] | undefined>()
    const [productPanelCutbacks, setProductPanelCutbacks] = useState<IPanelCutback[] | undefined>()
    const [productPanelDirectionalLays, setProductPanelDirectionalLays] = useState<IPanelDirectionLay[] | undefined>()
    const [panelUnitPrice, setPanelUnitPrice] = useState<number>(0)
    const [panelProductDetails, setPanelProductDetails] = useState<any>(null)

    const fetchPanelProductOptions = async (pricebookId?: number) => {
        try {
            const panelOptions: any = await getPanelProductOptions(pricebookId ? pricebookId : 0)

            setProductPanelBottomColours(panelOptions.panelBottomColours)
            setProductPanelCores(panelOptions.panelCores)
            setProductPanelProfiles(panelOptions.panelProfiles)
            setProductPanelRoofColours(panelOptions.panelRoofColours)
            setProductPanelThicknesses(panelOptions.panelThicknesses)
            setProductPanelCutbacks(panelOptions.panelCutbacks)
            setProductPanelDirectionalLays(panelOptions.panelDirectionalLays)
        } catch (error) {
            console.error("Error fetching panel product options:", error)
        }
    }

    const [{loading: fetchingPanelUnitPrice}, getPanelUnitPriceAsync] = useAsyncFn(
        async (panelProfileId: number, 
            panelCoreId: number, 
            panelThicknessId: number,
            panelRoofColourId: number, 
            panelBottomColourId: number, 
            pricebookId: number) => {
        
        const prodDetails: any = await getPanelProductDetails(panelProfileId, panelCoreId, panelThicknessId, 
            panelRoofColourId, panelBottomColourId, pricebookId)

        if (prodDetails != null) {
            setPanelUnitPrice(prodDetails.priceLevel1 ? prodDetails.priceLevel1 : 0)
            setPanelProductDetails(prodDetails)
        }
      }, [setPanelUnitPrice])

    // Rainwater
    const [rainwaterProductMaster, setRainwaterProductMaster] = useState<[] | undefined>()
    const [{loading: fetchRainwaterProductMaster}, fetchRainwaterProductMasterAsync] = useAsyncFn(async (pricebookId: number) => {
    const result: any = await getAllRainwaterProduct(pricebookId)
        if (result != null) {
            setRainwaterProductMaster(result)
        }
    }, [setRainwaterProductMaster])

    // Accessories
    const [accessoryProductMaster, setAccessoryProductMaster] = useState<[] | undefined>()
    const [{loading: fetchAccessoryProductMaster}, fetchAccessoryProductMasterAsync] = useAsyncFn(async (pricebookId: number) => {
    const result: any = await getAllAccessoryProduct(pricebookId)
        if (result != null) {
            setAccessoryProductMaster(result)
        }
    }, [setAccessoryProductMaster])

    return(
        <AdminOrderSummaryContext.Provider value={{
            fetchPanelOptions,
            panelThickness,
            panelProfiles,
            panelCores,
            panelBottomColour,
            colours,
            fetchLightProductMasterAsync,
            lightProductMaster,
            flashingTypes,
            flashingSubTypes,
            flashingThicknesses,
            flashingColours,
            fetchFlashingProductOptions,
            getFlashingUnitPriceAsync,
            flashingProductDetails,
            flashingUnitPrice,
            productFixings,
            fixingScrewTypes,
            fixingUnitPrice,
            fixingProductDetails,
            fetchFixingProductOptions,
            getFixingUnitPriceAsync,
            beamProducts,
            beamSizes,
            beamColours,
            beamUnitPrice,
            beamProductDetails,
            fetchBeamProductOptions,
            getBeamUnitPriceAsync,
            postTypes,
            postBeamSizes,
            postColours,
            postUnitPrice,
            postProductDetails,
            getPostProductIdByNameAsync,
            postProductId,
            postKitProductId,
            fetchPostProductOptions,
            getPostUnitPriceAsync,
            productPanelBottomColours,
            productPanelCores,
            productPanelProfiles,
            productPanelRoofColours,
            productPanelThicknesses,
            productPanelCutbacks,
            productPanelDirectionalLays,
            panelUnitPrice,
            panelProductDetails,
            fetchPanelProductOptions,
            getPanelUnitPriceAsync,            
            fetchRainwaterProductMasterAsync,
            rainwaterProductMaster,
            fetchAccessoryProductMasterAsync,
            accessoryProductMaster
        }}>
             {children}
        </AdminOrderSummaryContext.Provider>
    )
}