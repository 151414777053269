import React, {useContext, useEffect, useState} from 'react'
import {moneyFormatter, validLimitDecimal} from '../../../../../../utils'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {NumericInput} from '../../../../../shared/components/NumericInput'

type Product = {
  data?: any
  currentPage?: number
  totalPages?: number
  handleView: (id: any) => void
  handleSave: () => void
  editingId?: any
  editedData?: any
  handleSaveClick: () => void
  handleInputChange: (e: any) => void
  handlePrevPage: () => void
  handleNextPage: () => void
  handleCancelClick: () => void
  handleCancel: () => void
  handleDeleteProduct: (id: any) => void
  handleChange: (e: any) => void
  handleUpdateSuccess: (e: any) => void
  handleErrorMessage: (msg: string) => void
  isAdding: boolean
  nameRef: any
  typeRef: any
  finishRef: any
  priceRef: any
  newProduct: any
  handleFilter: (filter1: any, filter2: any, filter3: any) => void
}

function BeamsProduct(props: Product) {
  const {
    data,
    currentPage,
    totalPages,
    handleView,
    handleSave,
    editingId,
    editedData,
    handleSaveClick,
    handleInputChange,
    handlePrevPage,
    handleNextPage,
    handleCancelClick,
    handleCancel,
    handleDeleteProduct,
    handleChange,
    isAdding,
    nameRef,
    typeRef,
    finishRef,
    priceRef,
    newProduct,
    handleUpdateSuccess,
    handleErrorMessage,
    handleFilter,
  } = props

  const {beamFinishes, beams, beamTypes, beamUnitTypes} = useContext(AdminProductContext)

  const [saveData, setSaveData] = useState(isAdding ? newProduct : editedData)
  const [beamsTypesOption, setBeamsTypesOption] = useState(beamTypes)

  const [beamProductFilter, setbeamProductFilter] = useState('')
  const [typeFilter, settypeFilter] = useState('')
  const [colourFilter, setcolourFilter] = useState('')

  useEffect(() => {
    handleFilter(beamProductFilter, typeFilter, colourFilter)
  }, [beamProductFilter, typeFilter, colourFilter])

  useEffect(() => {
    setSaveData(editedData)
  }, [editedData])

  useEffect(() => {
    setSaveData(newProduct)
  }, [newProduct])

  useEffect(() => {
    if (saveData.beamId > 0) {
      const options = beamTypes.filter((x: any) => x.beamId === +saveData.beamId)
      setBeamsTypesOption(options)
    } else {
      setBeamsTypesOption([])
    }
  }, [saveData.beamId])

  const invalidRecord =
    (saveData?.beamProduct || '') === '' ||
    (saveData?.type || '') === '' ||
    (saveData?.colour || '') === ''

  return (
    <div style={{width: '85%'}} className='m-auto mt-4'>
      <table className='table table-hover product-table'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col' className='align-middle text-center'>
              Item Number
            </th>
            <th scope='col' className='align-middle text-center'>
              Beam Product
            </th>
            <th scope='col' className='align-middle text-center'>
              Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Colour
            </th>
            <th scope='col' className='align-middle text-center'></th>
          </tr>
          <tr>
            <td className='align-middle text-center'></td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={beamProductFilter}
                onChange={(e) => setbeamProductFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={typeFilter}
                onChange={(e) => settypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={colourFilter}
                onChange={(e) => setcolourFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'></td>
          </tr>
        </thead>
        <tbody>
          {data?.items?.length === 0 ? (
            <tr>
              <td colSpan={5} className='align-middle text-center'>
                There are no products to display!
              </td>
            </tr>
          ) : (
            data?.items?.map((data: any, index: number) => (
              <tr key={index}>
                <td className='align-middle text-center'>{data?.code}</td>
                <td className='align-middle text-center'>{data?.beamProductName}</td>
                <td className='align-middle text-center'>{data?.beamSizeName}</td>
                <td className='align-middle text-center'>{data?.colourName}</td>
                <td className='align-middle text-center' style={{width: '250px'}}>
                  <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => handleView(data?.id)}
                      style={{marginRight: 10}}
                    >
                      View
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleDeleteProduct(data?.id)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className='pagination d-flex align-items-center pb-4'>
        <button className='page-link' onClick={handlePrevPage} disabled={currentPage === 1}>
          <i className='bi bi-chevron-left'></i>
        </button>
        <div className='px-4'>{`Page ${currentPage} of ${totalPages}`}</div>
        <button
          className='page-link'
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <i className='bi bi-chevron-right'></i>
        </button>
      </div>
    </div>
  )
}

export default BeamsProduct
