import React, { useEffect } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { toAbsoluteUrl } from '../helpers'

type UserLayout = {
    isAuthorized?: boolean
    children: any
    isBanner: boolean
}

const UserLayout = (props: UserLayout) => {
    const { children, isAuthorized } = props
    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])
    return (
        <PageDataProvider>
            <div className='page user-page d-flex flex-row flex-column-fluid'>
                {/* <AsideDefault /> */}
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapper isAuthorized={isAuthorized} />

                    {props.isBanner && (
                        <>
                            <div
                                className='home-banner'
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                        '/media/slide1-insulated-roofing-panel-system.jpg'
                                    )})`,
                                }}
                            >
                                <h3>Delta Panels Order Systems</h3>
                                <p>Choose how you want to place your order.</p>
                            </div>
                        </>
                    )}

                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        {/* <Toolbar /> */}
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <Content>{children}</Content>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            {/* begin:: Modals */}
            <Main />
            <InviteUsers />
            <UpgradePlan />
            {/* end:: Modals */}
            <ScrollTop />
        </PageDataProvider>
    )
}

export { UserLayout }
