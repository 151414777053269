import React, {Suspense} from 'react'
import {useHistory, BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import { Auth0Provider } from "@auth0/auth0-react";
import { AdminProductContextProvider } from './context/AdminProductContext';
type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";
  const scope = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <Auth0Provider
                domain={domain}
                clientId={clientId}
                redirectUri={window.location.origin}
                audience={audience}
                scope={scope}
              >
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <AdminProductContextProvider>
                <Routes />
                </AdminProductContextProvider>
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </Auth0Provider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
