import React, { useContext, useEffect, useState } from 'react'
import { moneyFormatter } from '../../../../utils'
import { useEffectOnce } from 'react-use'
import { QuickOrderContext } from '../../../context/QuickOrderContext'

function TotalPriceInfo(props: any) {
    const { shippingPreference } = props
    const [totalPrice] = useState(JSON.parse(localStorage.getItem('totalPrice') as any))
    const [summaryTotalPrice, setSummaryTotalPrice] = useState<any>(null)
    const {getComputeSummaryTotal} = useContext(QuickOrderContext)
    
    const tenth = 0.10000000000000000
    const totalGST = totalPrice * tenth;

    useEffectOnce(() => {
        setSummaryTotalPrice(getComputeSummaryTotal())
    })

    // const computeSummaryTotal = () => {
    //     const miscFee = shippingPreference?.deliveryFee && parseFloat(shippingPreference.deliveryFee) > 0 ? 
    //                              shippingPreference?.shipping === 'delivery'
    //                                 ? moneyFormatter.format(parseFloat(shippingPreference.deliveryFee))
    //                                 : moneyFormatter.format(0) : 0

    //     const total = shippingPreference?.deliveryFee !== 'TBC'
    //     ? (shippingPreference?.shipping === 'delivery'
    //             ? shippingPreference?.deliveryFee
    //                 ? shippingPreference?.deliveryFee
    //                 : 0
    //             : 0) +
    //         (totalPrice + totalGST)
    //     : totalPrice + totalGST

    //     const creditcardFee =  (totalPrice + totalGST) * 1.75
    //     const creditcardmiscFee = shippingPreference?.shipping === 'delivery'
    //     ? shippingPreference?.deliveryFee !== 'TBC'
    //         ? shippingPreference?.deliveryFee ? shippingPreference?.deliveryFee : 0
    //         : 0
    //     : 0

    //     const summaryTotalPrice: any = {
    //         productTotalFee: totalPrice,
    //         totalGST: totalGST,
    //         miscFee: miscFee,
    //         totalProductAmount: total,
    //         creditcardFee: creditcardFee,
    //         creditcardmiscFee: creditcardmiscFee,
    //         totalAmount: (creditcardmiscFee + totalPrice + totalGST) * 1.75
    //     }

    //     localStorage.setItem('summaryTotalPrice', JSON.stringify(summaryTotalPrice))
    // }

    return (
        <div className='col-md-4'>
            <div className='d-flex flex-column align-items-center' style={{ height: '100%' }}>
            {
                    summaryTotalPrice && (
                        <React.Fragment>
                                <div
                                className='w-100 p-4'
                                style={{
                                    backgroundColor: 'rgb(204, 204, 204)',
                                    height: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                            
                                <div>
                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                        <p className='fw-bolder text-dark'>Product Total Fee (ex. GST)</p>
                                        <p className='fw-bolder text-dark'> {moneyFormatter.format(summaryTotalPrice?.productTotalFee)}</p>
                                    </div>
                                    {
                                        shippingPreference?.deliveryFee && (
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <p className='fw-bolder text-dark'>Delivery Fee: </p>
                                                <p className='fw-bolder text-dark'>
                                                    {moneyFormatter.format(summaryTotalPrice?.miscFee)}
                                                </p>
                                            </div>
                                        )
                                    }
                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                        <p className='fw-bolder text-dark'>GST</p>
                                        <p className='fw-bolder text-dark'>{moneyFormatter.format(summaryTotalPrice?.totalGST)}</p>
                                    </div>
                                </div>

                                <div>
                                    <hr />
                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                        <p className='fw-bolder  mb-0' style={{ fontSize: '1.75rem' }}>
                                            TOTAL
                                        </p>

                                        <p className='fw-bolder mb-0' style={{ fontSize: '1.75rem' }}>
                                            {moneyFormatter.format(summaryTotalPrice?.totalProductAmount)}
                                            {/* {shippingPreference?.deliveryFee !== 'TBC'
                                                ? moneyFormatter.format(
                                                    (shippingPreference?.shipping === 'delivery'
                                                        ? shippingPreference?.deliveryFee
                                                            ? shippingPreference?.deliveryFee
                                                            : 0
                                                        : 0) +
                                                    (totalPrice + totalGST)
                                                )
                                                : moneyFormatter.format(totalPrice + totalGST)} */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className='fw-bolder text-center my-4 ' style={{ fontSize: '1.05rem', color: '#f00' }}>
                                ** Below ONLY applies if paying by Credit Card **
                            </p>
                            <div
                                className='w-100 p-4'
                                style={{
                                    backgroundColor: 'rgb(204, 204, 204)',
                                    height: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <p className='fw-bolder text-dark'>Credit Card Payment Fee (0.75%)</p>
                                    <p className='fw-bolder text-dark'>
                                        {moneyFormatter.format(summaryTotalPrice?.creditcardFee)}
                                        {/* {moneyFormatter.format((totalPrice + totalGST) * 1.75)} */}
                                    </p>
                                </div>
                                {/* {
                                    <div className='d-flex flex-row justify-content-between align-items-center'>
                                        <p className='fw-bolder text-dark'>Miscellaneous: </p>
                                        <p className='fw-bolder text-dark'>
                                            {shippingPreference?.shipping === 'delivery'
                                                ? shippingPreference?.deliveryFee !== 'TBC'
                                                    ? moneyFormatter.format(summaryTotalPrice?.creditcardmiscFee)
                                                    : 'TBC'
                                                : moneyFormatter.format(0)}
                                        </p>
                                    </div>
                                } */}
                                <hr />
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <div>
                                        <p className='fw-bolder  mb-0' style={{ fontSize: '1.75rem' }}>
                                            TOTAL
                                        </p>
                                        <p className='mb-0 fw-bolder text-dark'>(With Credit Card Fee)</p>
                                    </div>
                                    <p className='fw-bolder mb-0' style={{ fontSize: '1.75rem' }}>
                                        {moneyFormatter.format(summaryTotalPrice?.totalAmount)}
                                        {/* {shippingPreference?.deliveryFee !== 'TBC'
                                            ? moneyFormatter.format(
                                                (shippingPreference?.shipping === 'delivery'
                                                    ? shippingPreference?.deliveryFee
                                                        ? shippingPreference?.deliveryFee
                                                        : 0
                                                    : 0) +
                                                (totalPrice + totalGST) * 1.75
                                            )
                                            : moneyFormatter.format((totalPrice + totalGST) * 1.75)} */}
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
               
            </div>
        </div>
    )
}

export default TotalPriceInfo
