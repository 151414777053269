import React, {FC} from 'react'
import {Switch, Route} from 'react-router-dom'
import {UserLayout} from '../../../_metronic/layout/UserLayout'
import OrderSummaryContent from './components/OrderSummaryContent'

type OrderSummaryPage = {
  isAuthorized?: boolean
  isBanner?: boolean
}

const OrderSummaryPage = (props: OrderSummaryPage) => {
  const {isAuthorized} = props
  return (
    <UserLayout isAuthorized={isAuthorized} isBanner={false}>
      <Switch>
        <Route exact path='/order-summary'>
          <OrderSummaryContent />
        </Route>
      </Switch>
    </UserLayout>
  )
}

export default OrderSummaryPage
