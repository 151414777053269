import React, {useContext, useEffect, useState} from 'react'
import {moneyFormatter, validLimitDecimal} from '../../../../../../utils'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {NumericInput} from '../../../../../shared/components/NumericInput'

type Product = {
  data?: any
  currentPage?: number
  totalPages?: number
  handleUpdateProduct: (id: any) => void
  handleSave: () => void
  editingId?: any
  editedData?: any
  handleSaveClick: () => void
  handleInputChange: (e: any) => void
  handlePrevPage: () => void
  handleNextPage: () => void
  handleCancelClick: () => void
  handleCancel: () => void
  handleDeleteProduct: (id: any) => void
  handleChange: (e: any) => void
  handleUpdateSuccess: (e: any) => void
  handleErrorMessage: (msg: string) => void
  isAdding: boolean
  nameRef: any
  typeRef: any
  unitRef: any
  priceRef: any
  panelRef: any
  newProduct: any
  handleFilter: (filter1: any, filter2: any, filter3: any, filter4: any, filter5: any) => void
}

function LightsProduct(props: Product) {
  const {
    data,
    currentPage,
    totalPages,
    handleUpdateProduct,
    handleSave,
    editingId,
    editedData,
    handleSaveClick,
    handleInputChange,
    handlePrevPage,
    handleNextPage,
    handleCancelClick,
    handleCancel,
    handleDeleteProduct,
    handleChange,
    handleUpdateSuccess,
    handleErrorMessage,
    isAdding,
    nameRef,
    typeRef,
    unitRef,
    priceRef,
    panelRef,
    newProduct,
    handleFilter,
  } = props
  const {lightPanelTypes, lightProducts, lightProductTypes, lightUnitTypes} =
    useContext(AdminProductContext)

  const [productNameFilter, setproductNameFilter] = useState('')
  const [panelTypeFilter, setpanelTypeFilter] = useState('')
  const [productTypeFilter, setproductTypeFilter] = useState('')
  const [unitTypeFilter, setUnitTypeFilter] = useState('')
  const [thicknessFilter, setthicknessFilter] = useState('')

  useEffect(() => {
    handleFilter(productNameFilter, panelTypeFilter, productTypeFilter, unitTypeFilter, thicknessFilter)
  }, [productNameFilter, panelTypeFilter, productTypeFilter, unitTypeFilter, thicknessFilter])

  const numRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/
  const [saveData, setSaveData] = useState(isAdding ? newProduct : editedData)

  useEffect(() => {
    setSaveData(editedData)
  }, [editedData])

  useEffect(() => {
    setSaveData(newProduct)
  }, [newProduct])

  const invalidRecord =
    (saveData?.productName || '') === '' ||
    (saveData?.panelType || '') === '' ||
    (saveData?.productType || '') === ''

  return (
    <div style={{width: '85%'}} className='m-auto mt-4'>
      <table className='table table-hover product-table '>
        <thead className='thead-dark'>
          <tr>
            <th scope='col' className='align-middle text-center'>
              Item number
            </th>
            <th scope='col' className='align-middle text-center'>
              Product Name
            </th>
            <th scope='col' className='align-middle text-center'>
              Panel Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Thickness
            </th>
            <th scope='col' className='align-middle text-center'>
              Product Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Unit Type
            </th>
            <th scope='col' className='align-middle text-center'></th>
          </tr>
          <tr>
            <td className='align-middle text-center'></td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={productNameFilter}
                onChange={(e) => setproductNameFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={panelTypeFilter}
                onChange={(e) => setpanelTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={thicknessFilter}
                onChange={(e) => setthicknessFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={productTypeFilter}
                onChange={(e) => setproductTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={unitTypeFilter}
                onChange={(e) => setUnitTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'></td>
          </tr>
        </thead>
        <tbody>
          {data?.items?.length === 0 ? (
            <tr>
              <td colSpan={5} className='align-middle text-center'>
                There are no products to display!
              </td>
            </tr>
          ) : (
            data?.items?.map((data: any, index: number) => (
              <tr key={index}>
                <td className='align-middle text-center'>{data?.code}</td>
                <td className='align-middle text-center'>{data?.lightProductName}</td>
                <td className='align-middle text-center'>{data?.lightProfileName}</td>
                <td className='align-middle text-center'>{data?.lightThicknessName}</td>
                <td className='align-middle text-center'>{data?.detail}</td>
                <td className='align-middle text-center'>{data?.lightUnitTypeName}</td>
                <td className='align-middle text-center' style={{width: '250px'}}>
                  <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => handleUpdateProduct(data?.id)}
                      style={{marginRight: 10}}
                    >
                      View
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleDeleteProduct(data?.id)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className='pagination d-flex align-items-center pb-4'>
        <button className='page-link' onClick={handlePrevPage} disabled={currentPage === 1}>
          <i className='bi bi-chevron-left'></i>
        </button>
        <div className='px-4'>{`Page ${currentPage} of ${totalPages}`}</div>
        <button
          className='page-link'
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <i className='bi bi-chevron-right'></i>
        </button>
      </div>
    </div>
  )
}

export default LightsProduct
