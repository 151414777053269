import React, {useState} from 'react'
import NewLightsItem from './NewLightsItem'

const Lights = () => {
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  return (
    <div className='tab-items lights-tab-items'>
      <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
        Lights
      </h3>
      {/* TOAST */}
      {error ? (
        <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
          {errorMsg}
        </div>
      ) : null}
      <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden', height: '90vh'}}>
        <NewLightsItem setError={setError} setErrorMsg={setErrorMsg} />
      </div>
    </div>
  )
}

export default Lights
