/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import TableConfig from './interface/TableConfig'

type Props = {
  className: string,
  headerText?: string,
  subHeaderText?: string,
  tableConfig: TableConfig,
  data?:any[]
}

const TablesWidget: React.FC<Props> = ({className, headerText, subHeaderText, tableConfig, data}) => {
  
  const getDescendantProp = (desc:string, obj:any) => {
    obj = obj || window;
    var arr:string[] = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()!]));
    return obj;
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{headerText || "Members Statistics"}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{subHeaderText || ""}</span>
        </h3>
        { 
          tableConfig.showHeaderButton || false ? 
              (
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a user'
                >
                  <a
                    href='#'
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target={tableConfig.headerButtonTargetModalId || ""}
                  >                    
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    {tableConfig.headerButtonText || "New"}
                  </a>
                </div>
              ):null
          }

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            
            
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                { 
                tableConfig.showSelector || false ? 
                    (
                      <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                        />
                      </div>
                    </th>
                    ) :null
                }
               
                {tableConfig.columns?.map((col, idx) => {
                    return (<th key={idx} className='min-w-150px'>{col.header}</th>)
                })}
                {tableConfig.showActions || false ? (<th className='min-w-100px text-end'>Actions</th>) : null}
                              
              </tr>
            </thead>
            {/* end::Table head */}


            {/* begin::Table body */}
            <tbody>            
              {
                data?.map((dd, idx) => {
                  return (

                  <tr key={idx}>
                      { tableConfig.showSelector || false ? 
                          (
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                              </div>
                            </td>
                          ) :null
                      }
   
                      {tableConfig.columns?.map((col, idx2) => {
                        return (
                          <td key={idx2} >
                            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                              { getDescendantProp(col.accessor, dd)}
                            </a>
                          </td>
                        )
                      })}
      
                      {
                        tableConfig.showActions || false ? 
                          (<td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                            </div>
                          </td>
                          ) 
                          : null
                      }                    
                  </tr>
                                    
                  )      
              })
              
              }
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget}
