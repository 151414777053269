import React, {useContext, useEffect, useState} from 'react'
import PanelItem from './PanelItem'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import NewPanelItem from './NewPanelItem'

const Panels = () => {
  const {panelSetup, panelMasterList} = useContext(QuickOrderContext)
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [headerText, setHeaderText] = useState('Roof Panels')
  const [panelProfiles, setPanelProfiles] = useState<any>([])

  const updateHeader = (newText: string) => {
    setHeaderText(newText)
  }

  useEffect(() => {
    if (panelMasterList) {
      const availableProfile = panelMasterList
        ?.filter((x: any) => x.panelProfileName !== null)
        .map((x: any) => ({
          id: x.panelProfileId,
          name: x.panelProfileName,
        }))
      // Avoid duplicate profile
      const distinctProfile: any = Object.values(
        availableProfile.reduce((map: any, obj: any) => {
          const key = `${obj.id}-${obj.name}`
          if (!map[key]) {
            map[key] = obj
          }
          return map
        }, {} as Record<string, any>)
      )
      setPanelProfiles(distinctProfile)
    }
  }, [panelMasterList])

  return (
    <div className='tab-items panels-tab-items' style={{overflow: 'hidden'}}>
      <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
        {headerText}
      </h3>
      {error ? (
        <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
          {errorMsg}
        </div>
      ) : null}
      <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden', height: '90vh'}}>
        <NewPanelItem
          error={error}
          setError={setError}
          setErrorMsg={setErrorMsg}
          panels={panelProfiles}
          updateHeader={updateHeader}
        />
      </div>
    </div>
  )
}

export default Panels
