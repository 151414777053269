import axios from 'axios'
export const FLASHING_PRICING_URL = `${process.env.REACT_APP_API_URL}/flashingpricings`
export const FLASHINGTYPE_URL = `${process.env.REACT_APP_API_URL}/FlashingTypes`
export const FLASHINGTHICKNESS_URL = `${process.env.REACT_APP_API_URL}/FlashingThicknesses`
export const FLASHINGSUBTYPE_URL = `${process.env.REACT_APP_API_URL}/FlashingSubTypes`

export const FLASHINGMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FlashingMasterlist`

export const FLASHINGADDPROD_URL = `${process.env.REACT_APP_API_URL}/OrderDetails`

interface FlashingsProducts {
  id: number
  flashingName: string
  panelType: string
  thickness: string
  colour: string
}

export const getProductFlashingTypes = async () => {
  try {
    return await axios.get(`${FLASHINGADDPROD_URL}/getAllFlashingTypes`)
  } catch (err) {
    return []
  }
}

export const getProductFlashingSubTypes = async () => {
  try {
    return await axios.get(`${FLASHINGADDPROD_URL}/getAllFlashingSubTypes`)
  } catch (err) {
    return []
  }
}

export const getProductFlashingThicknesses = async () => {
  try {
    return await axios.get(`${FLASHINGADDPROD_URL}/getAllFlashingThickness`)
  } catch (err) {
    return []
  }
}

export const getProductFlashingColours = async () => {
  try {
    return await axios.get(`${FLASHINGADDPROD_URL}/getAllFlashingColours`)
  } catch (err) {
    return []
  }
}

export const getFlashingUnitPrice = async (
  flashingTypeId: number, 
  flashingSubTypeId: number, 
  flashingThicknessId: number, 
  colourId?: number) => {
  try {
      const response = await axios.get(`${FLASHINGADDPROD_URL}/getFlashingUnitPrice`, {
        params: {
          flashingTypeId,
          flashingSubTypeId,
          flashingThicknessId,
          colourId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllProduct = async (priceBookid?: number) => {
  try {
    const response = await axios.get(`${FLASHINGMASTERLIST_URL}/getAllFlashingProduct`, {
      params: {
        priceBookid,
      },
    })
    return response.data
  } catch (ex) {
    return [null, ex]
  }
}

export const getFlashingMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  FlashingTypeName?: string,
  FlashingSubTypeName?: string,
  FlashingThicknessName?: string,
  ColourName?: string
) => {
  try {
    return await axios.get(`${FLASHINGMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        FlashingTypeName,
        FlashingSubTypeName,
        FlashingThicknessName,
        ColourName,
      },
    })
  } catch (err) {
    return err
  }
}

export const createFlashingProduct = async (payload: any) => {
  try {
    return await axios.post(`${FLASHINGMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateFlashingProduct = async (payload: any) => {
  try {
    return await axios.patch(`${FLASHINGMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteFlashingProduct = async (id: any) => {
  try {
    return await axios.delete(`${FLASHINGMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getFlashingPricings = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${FLASHING_PRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getFlashingSetup = async (priceBookid?: number) => {
  try {
    return await axios.get(`${FLASHING_PRICING_URL}/getflashingpricings`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const createFlashingPricing = async (payload: any) => {
  try {
    return await axios.post(`${FLASHING_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateFlashingPricing = async (payload: any) => {
  try {
    return await axios.patch(`${FLASHING_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteFlashingPricing = async (id: number) => {
  try {
    return await axios.delete(`${FLASHING_PRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getFlashingTypes = async () => {
  try {
    return await axios.get(`${FLASHINGTYPE_URL}`)
  } catch (err) {
    return err
  }
}

export const getFlashingThicknesses = async () => {
  try {
    return await axios.get(`${FLASHINGTHICKNESS_URL}`)
  } catch (err) {
    return err
  }
}

export const getFlashingSubTypes = async () => {
  try {
    return await axios.get(`${FLASHINGSUBTYPE_URL}`)
  } catch (err) {
    return err
  }
}
