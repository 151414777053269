import React, {lazy, useState, useEffect, useContext} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Panels from '../tabs/Panels/Panels'
import {PageTitle} from '../../../../_metronic/layout/core'
import ShippingPreference from '../tabs/ShippingPreference/ShippingPreference'
import clsx from 'clsx'
import Fixings from '../tabs/Fixings/Fixings'
import Flashings from '../tabs/Flashings/Flashings'
import RainwaterGoods from '../tabs/RainwaterGoods/RainwaterGoods'
import Beams from '../tabs/Beams/Beams'
import Posts from '../tabs/Posts/Posts'
import Lights from '../tabs/Lights/Lights'
import PanelOrderItem from '../tabs/Panels/PanelOrderItem'
import {moneyFormatter} from '../../../../utils'
import {QuickOrderContext} from '../../../context/QuickOrderContext'
import FixingOrderItem from '../tabs/Fixings/FixingOrderItem'
import FlashingOrderItem from '../tabs/Flashings/FlashingOrderItem'
import AccessoriesOrderItem from '../tabs/Accessories/AccessoriesItem/AccessoriesOrderItem'
import GutterOrderItem from './../tabs/RainwaterGoods/GutterItem/GutterOrderItem'
import StopEndBracketOrderItem from '../tabs/RainwaterGoods/GutterItem/StopEndBracketOrderItem'
import {useEffectOnce} from 'react-use'
import BeamOrderItem from './../tabs/Beams/BeamOrderItem'
import PostKitOrderItem from './../tabs/Posts/PostKitOrderItem'
import PostBracketOrderItem from '../tabs/Posts/PostBracketOrderItem'
import ExtendaBracketOrderItem from '../tabs/Posts/ExtendaBracketItem/ExtendaBracketOrderItem'
import PostOrderItem from '../tabs/Posts/PostOrderItem'
import LightsOrderItem from '../tabs/Lights/LightsOrderItem'
import ProductOrderItem from './ProductOrderItem/ProductOrderItem'
import LoadingQuickOrderData from './LoadingQuickOrderData/LoadingQuickOrderData'
import Accessories from '../tabs/Accessories/Accessories'
import { 
  ORDER_TAB_TYPE_SHIPPINGPREFERENCE,
  ORDER_TAB_TYPE_PANELS
  ,ORDER_TAB_TYPE_FIXINGS,
  ORDER_TAB_TYPE_FLASHINGS,
  ORDER_TAB_TYPE_RAINWATERGOODS,
  ORDER_TAB_TYPE_BEAMS,
  ORDER_TAB_TYPE_POSTS,
  ORDER_TAB_TYPE_LIGHTS,
  ORDER_TAB_TYPE_ACCESSORIES,
  ORDER_TAB_NAME_SHIPPINGPREFERENCE,
  ORDER_TAB_NAME_PANELS,
  ORDER_TAB_NAME_FIXINGS,
  ORDER_TAB_NAME_FLASHINGS,
  ORDER_TAB_NAME_RAINWATERGOODS,
  ORDER_TAB_NAME_BEAMS,
  ORDER_TAB_NAME_POSTS,
  ORDER_TAB_NAME_LIGHTS,
  ORDER_TAB_NAME_ACCESSORIES,
  ORDER_LIST_PANELS,
  ORDER_LIST_FIXINGS,
  ORDER_LIST_FLASHINGS,
  ORDER_LIST_RAINWATERGOODS,
  ORDER_LIST_BEAMS,
  ORDER_LIST_POSTS,
  ORDER_LIST_ACCESSORIES,
  ORDER_LIST_LIGHTS,
  ORDER_TAB_POSTS,
  ORDER_TAB_POSTS_KIT,
  ORDER_TAB_POST_EXTENDABRACKET,
  ORDER_TAB_RAINWATER_STOPEND,
  ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT,
  ORDER_TAB_LIGHTS_DELTASKYLIGHT
} from '../../../common/constant'

const QuickOrderContent: React.FC = () => {
  const {
    cartItems,
    totalPrice,
    getPanelSetupAsync,
    panelSetup,
    fixings,
    getFixingsAsync,
    fixingPricings,
    getFixingPricingsAsync,
    beamJoinerId,
    getBeamProductIdsAsync,    
    beams,
    getBeamsAsync,
    beamTypes,
    getBeamTypesAsync,
    beamFinishes,
    getBeamFinishesAsync,
    beamPricings,
    getBeamPricingsAsync,
    getBeamEndCapUnitPriceAsync,
    getBeamSpacerUnitPriceAsync,
    getBeamJoinerUnitPriceAsync,
    beamEndCapUnitPrice,
    beamSpacerUnitPrice,
    beamJoinerUnitPrice,
    getBeamFasciaHangingUnitPriceAsync,
    getBeamFasciaHangingColoursAsync,
    getBeamFasciaHangingSizesAsync,
    beamFasciaHangingSizes,
    beamFasciaHangingColours,
    getPostProductsAsync,
    getPostTypesAsync,
    getPostBeamSizesAsync,
    getPostFinishesAsync,
    getPostCategoriesAsync,
    getPostPricingsAsync,
    postProducts,
    postTypes,
    postFinishes,
    postCategories,
    postPricings,
    postExtendaBracketProducts,
    postExtendaBracketColours,
    getPostColoursAsync,
    postExtenderLengths,
    getPostExtenderLengthsAsync,
    getPostProductIdByNameAsync,
    postProductId,
    postKitProductId,
    postExtendaBracketId,
    getLightProductsAsync,
    getLightProductTypesAsync,
    getLightPanelTypesAsync,
    getLightPricingsAsync,
    getLightThicknessesAsync,
    lightThicknesses,
    lightProducts,
    lightProductTypes,
    lightPanelTypes,
    lightPricings,
    deltaLowProfileLightId,
    deltaSkylightId,
    getLightProductIdByNameAsync,
    flashings,
    flashingSetup,
    getFlashingSetupAsync,
    getAllFlashingProductAsync,
    flashingProducts,
    colours,
    getColoursAsync,
    getRainWaterSetupAsync,
    rainwaterCategories,
    beamColours,
    getBeamColoursAsync,
    beamEndCapColours,
    getBeamEndCapColoursAsync,
    beamSizes,
    panelMasterList,
    getPanelMasterListAsync,
    getAllFixingProductAsync,
    fixingMasterList,
    lightPanelTypesPM,
    lightProductTypesPM,
    rainwaterProductMasters,
    getRainwaterProductAsync,
    stopEndId,
    getRainwaterProductIdByNameAsync,
    accessoryProductMasters,
    getAccessoryProductAsync
  } = useContext(QuickOrderContext)

  const [message, setMessage] = useState<any>(null)
  const [fade, setFade] = useState(false)
  const dataShipping = JSON.parse(localStorage.getItem('shipping-preference') as any) || {}

  const getInitialTab = (): string => {
    try {
      const savedTab = localStorage.getItem('tab')
      return savedTab && dataShipping.pricebookId ? JSON.parse(savedTab) : ORDER_TAB_TYPE_SHIPPINGPREFERENCE
    } catch (ex) {
      return ORDER_TAB_TYPE_SHIPPINGPREFERENCE
    }
  }

  const [tab, setTab] = useState<string>(getInitialTab())

  const [postCode, setPostCode] = useState<any>(
    JSON.parse(localStorage.getItem('shipping-preference') as any)?.postalCode
  )
  const [pricebookId] = useState<any>(
    JSON.parse(localStorage.getItem('shipping-preference') as any)?.pricebookId
  )
  const [selectedShipping, setSelectedShipping] = useState(
    JSON.parse(localStorage.getItem('shipping-preference') as any)?.shipping
  )

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramTab = queryParams.get('tab')
  useEffect(() => {
    if (paramTab && pricebookId) {
      setTab(paramTab)
    }
  }, [paramTab])

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setFade(true)
      }, 4000)

      const fadeTimer = setTimeout(() => {
        setMessage(null)
        setFade(false)
      }, 5000)

      return () => {
        clearTimeout(timer)
        clearTimeout(fadeTimer)
      }
    }
  }, [message])

  const [isLoading, setIsLoading] = useState(true)

  useEffectOnce(() => {
    const fetchQuickOrderData = async () => {
      setIsLoading(true)
      try {
        if (!panelSetup) await getPanelSetupAsync()
        if (!panelMasterList) await getPanelMasterListAsync()
        if (!flashingSetup) await getFlashingSetupAsync()
        if (!flashingProducts) await getAllFlashingProductAsync()
        if (!fixings) await getFixingsAsync()
        if (!fixingMasterList) await getAllFixingProductAsync()
        if (!beamJoinerId) await getBeamProductIdsAsync()
        if (!beams) await getBeamsAsync()
        if (!beamTypes) await getBeamTypesAsync()
        if (!beamColours) await getBeamColoursAsync()
        if (!beamEndCapColours) await getBeamEndCapColoursAsync()
        if (!beamFinishes) await getBeamFinishesAsync()
        if (!beamPricings) await getBeamPricingsAsync()
        if (!beamFasciaHangingSizes) await getBeamFasciaHangingSizesAsync()
        if (!beamFasciaHangingColours) await getBeamFasciaHangingColoursAsync()
        if (!postProductId) await getPostProductIdByNameAsync(ORDER_TAB_POSTS)
        if (!postKitProductId) await getPostProductIdByNameAsync(ORDER_TAB_POSTS_KIT)
        if (!postExtendaBracketId) await getPostProductIdByNameAsync(ORDER_TAB_POST_EXTENDABRACKET)
        if (!postCategories) await getPostCategoriesAsync()
        if (!postExtenderLengths) await getPostExtenderLengthsAsync()
        if (!postPricings) await getPostPricingsAsync()
        if (!lightThicknesses) await getLightThicknessesAsync()
        if (!lightProducts) await getLightProductsAsync()
        if (!lightProductTypesPM) await getLightProductTypesAsync()
        if (!lightPanelTypesPM) await getLightPanelTypesAsync()
        if (!lightPricings) await getLightPricingsAsync()
        if (!deltaLowProfileLightId) await getLightProductIdByNameAsync(ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT)
        if (!deltaSkylightId) await getLightProductIdByNameAsync(ORDER_TAB_LIGHTS_DELTASKYLIGHT)
        if (!colours) await getColoursAsync()
        if (!rainwaterProductMasters) await getRainwaterProductAsync()
        if (!stopEndId) await getRainwaterProductIdByNameAsync(ORDER_TAB_RAINWATER_STOPEND)
        if (!fixingPricings) await getFixingPricingsAsync()
        if (!accessoryProductMasters) await getAccessoryProductAsync()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  
    fetchQuickOrderData()
  })

  if (isLoading) {
    return <LoadingQuickOrderData />
  }

  let content

  switch (tab) {
    case ORDER_TAB_TYPE_SHIPPINGPREFERENCE:
      content = <ShippingPreference setPostCode={setPostCode} />
      break
    case ORDER_TAB_TYPE_PANELS:
      content = <Panels />
      break
    case ORDER_TAB_TYPE_FIXINGS:
      content = <Fixings />
      break
    case ORDER_TAB_TYPE_FLASHINGS:
      content = <Flashings />
      break
    case ORDER_TAB_TYPE_RAINWATERGOODS:
      content = <RainwaterGoods />
      break
    case ORDER_TAB_TYPE_BEAMS:
      content = <Beams />
      break
    case ORDER_TAB_TYPE_POSTS:
      content = <Posts />
      break
    case ORDER_TAB_TYPE_LIGHTS:
      content = <Lights />
      break
      case ORDER_TAB_TYPE_ACCESSORIES:
        content = <Accessories />
        break
    default:
      content = <ShippingPreference setPostCode={setPostCode} />
      break
  }

  const setTabData = (newTab: string): void => {
    if (dataShipping.pricebookId || newTab === ORDER_TAB_TYPE_SHIPPINGPREFERENCE) {
      localStorage.setItem('tab', JSON.stringify(newTab))
      setTab(newTab)
    }
    else {
      newTab = ORDER_TAB_TYPE_SHIPPINGPREFERENCE
      localStorage.setItem('tab', JSON.stringify(newTab))
      setMessage('Please confirm your shipping preference and click \"NEXT\" to continue.')
      setTab(newTab)
    }
  }

  const itemOrder = [
    ORDER_LIST_PANELS,
    ORDER_LIST_FIXINGS,
    ORDER_LIST_FLASHINGS,
    ORDER_LIST_RAINWATERGOODS,
    ORDER_LIST_BEAMS,
    ORDER_LIST_POSTS,
    ORDER_LIST_ACCESSORIES,
    ORDER_LIST_LIGHTS
  ]
  
  const groupedItems = cartItems.reduce((acc: any, item: any) => {
    const productType = item.productType
    if (!acc[productType]) {
      acc[productType] = []
    }
    acc[productType].push(item)
    return acc
  }, {})

  const sortedGroupKeys = Object.keys(groupedItems).sort((a, b) => {
    return itemOrder.indexOf(a) - itemOrder.indexOf(b)
  })

  return (
    <React.Fragment>
    <div className="d-flex justify-content-between align-items-center">
      <h1 className="page-title">Quick Order</h1>
      <div className="flex-grow-1 text-center">
        {message && (
          <div
            className="alert alert-primary d-inline-block"
            role="alert"
            style={{
              opacity: fade ? 0 : 1,
              transition: 'opacity 1s ease-in-out'
            }}
          >
            {message}
          </div>
        )}
      </div>
      <div style={{ width: "150px" }}>
      </div>
    </div>

      <div className='row'>
        <div className='col-md-8'>
          <div className='header-menu header-menu-tabs align-items-stretch'>
            <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_SHIPPINGPREFERENCE)}
                  className={clsx(`menu-link py-3 tab-wrap`, {
                    active: tab === ORDER_TAB_TYPE_SHIPPINGPREFERENCE,
                  })}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-cart-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_SHIPPINGPREFERENCE}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_PANELS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_PANELS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-panels-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_PANELS}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_FIXINGS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_FIXINGS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-fixing-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_FIXINGS}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_FLASHINGS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_FLASHINGS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-flashing-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_FLASHINGS}</span>
                </div>
              </div>

              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_RAINWATERGOODS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_RAINWATERGOODS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-rainwater-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_RAINWATERGOODS}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_BEAMS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_BEAMS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-beam-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_BEAMS}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_POSTS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_POSTS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-post-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_POSTS}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_ACCESSORIES)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_ACCESSORIES,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-accessories-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_ACCESSORIES}</span>
                </div>
              </div>
              <div className='menu-item me-lg-1'>
                <div
                  role='tab'
                  onClick={() => setTabData(ORDER_TAB_TYPE_LIGHTS)}
                  className={clsx(
                    `menu-link py-3 tab-wrap ${
                      selectedShipping === undefined ||
                      (selectedShipping === 'delivery' && postCode === '')
                        ? 'disabled'
                        : ''
                    }`,
                    {
                      active: tab === ORDER_TAB_TYPE_LIGHTS,
                    }
                  )}
                >
                  <div
                    className='tab-image w-100px h-100px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icon_mages/thumb-light-s.png'
                      )})`,
                    }}
                  ></div>

                  <span className='menu-title'>{ORDER_TAB_NAME_LIGHTS}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='kt_content' className='content d-flex flex-column flex-column-fluid pt-0'>
        <div className='tab-pane'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-8'>
                <div
                  className='tab-items-wrap'
                  style={{backgroundColor: '#cccccc', height: '100vh'}}
                >
                  {content}
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='order-list'
                  style={{
                    backgroundColor: '#cccccc',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <h3>Order List</h3>
                  <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden'}}>
                    {/* {cartItems?.map((item: any, index: number) => {
                      if (item.type === 'panel') {
                        return <PanelOrderItem key={index} item={item} />
                      } else if (item.type === 'fixing') {
                        return <FixingOrderItem key={index} item={item} />
                      } else if (item.type === 'flashing') {
                        return <FlashingOrderItem key={index} item={item} />
                      } else if (item.type === 'accessory') {
                        return <AccessoriesOrderItem key={index} item={item} />
                      } else if (item.type === 'beam') {
                        return <BeamOrderItem key={index} item={item} />
                      } else if (item.type === 'post-kit') {
                        return <PostKitOrderItem key={index} item={item} />
                      } else if (item.type === 'post-bracket') {
                        return <PostBracketOrderItem key={index} item={item} />
                      } else if (item.type === 'post-item') {
                        return <PostOrderItem key={index} item={item} />
                      } else if (item.type === 'post-extendabracket') {
                        return <ExtendaBracketOrderItem key={index} item={item} />
                      }else if (item.type === 'lights') {
                        return <LightsOrderItem key={index} item={item} />
                      }
                    })} */}

                    {sortedGroupKeys.map((productType) => {
                      const items = groupedItems[productType]
                      if (!items || items.length === 0) return null

                      const firstItemType = items[0].productType 

                      switch (firstItemType) {
                        case ORDER_LIST_PANELS:
                        case ORDER_LIST_FIXINGS:
                        case ORDER_LIST_FLASHINGS:
                        case ORDER_LIST_RAINWATERGOODS:
                        case ORDER_LIST_BEAMS:
                        case ORDER_LIST_POSTS:
                        case ORDER_LIST_ACCESSORIES:
                        case ORDER_LIST_LIGHTS:
                          return <ProductOrderItem key={productType} productType={productType} items={items} />
                        default:
                          return null
                      }
                    })}
                  </div>

                  <div className='d-flex flex-row justify-content-between align-items-center mt-auto'>
                    <div>
                      <p className='fw-bolder mb-2 text-dark'>SUBTOTAL</p>
                      <h3>{moneyFormatter.format(totalPrice)}</h3>
                    </div>
                    <button
                      disabled={cartItems.length === 0}
                      className='btn btn-primary'
                      onClick={() => {
                        history.push('/checkout')
                      }}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {QuickOrderContent}
