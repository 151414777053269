import React, { useState, useRef, useContext, useEffect } from 'react'
import './ShippingPreference.css'
import { useHistory } from 'react-router-dom'
import { getPostcodeMatriceByPostcode } from '../../../../api'
import { useEffectOnce } from 'react-use'
import { QuickOrderContext } from '../../../../context/QuickOrderContext'
import { SHIPPING_TYPES } from '../../../../../utils'

const ShippingPreference = (props: any) => {
    const { setPostCode } = props
    const { getPanelSetupAsync } = useContext(QuickOrderContext)
    const history = useHistory()

    const [customerInfo, setCustomerInfo] = useState(() => {
        const customerInfoValue = localStorage.getItem('customer-info');
        return customerInfoValue ? JSON.parse(customerInfoValue) : {};
    });

    /**
     * RADIO BUTTONS
     */
    const [selectedShipping, setSelectedShipping] = useState(
        localStorage.getItem('selectedShipping') || SHIPPING_TYPES.BNE
    )
    const handleShippingChange = (event: any) => {
        setSelectedShipping(event.target.value)
        sessionStorage.removeItem('cartItems')
    }
    /**
     *POSTAL CODE
     */
    const postCodeRef = useRef<any>()
    const [errorMsg, setErrorMsg] = useState<string | undefined>()

    useEffectOnce(() => {
        const localShippingStorage = localStorage.getItem('shipping-preference')
        if (localShippingStorage) {
            const localShipping = JSON.parse(localShippingStorage)
            if (localShipping.postalCode && postCodeRef.current) {
                postCodeRef.current.value = localShipping.postalCode
            }
        }
    })

    const handleSavePreference = async () => {
        setErrorMsg(undefined)
        const postCodeValue = postCodeRef.current && postCodeRef.current.value
        let shippingPreference: any = {
            postalCode: postCodeValue,
            shipping: selectedShipping,
        }
        if (postCodeValue) {
            var postCode: any = await getPostcodeMatriceByPostcode(postCodeValue)
            if (postCode.data && postCodeValue.length === 4) {
                shippingPreference = {
                    ...shippingPreference,
                    pricebookId: postCode.data.pricebookId,
                    deliveryFee: postCode.data.deliveryFee,
                    state: postCode.data.state,
                    country: 'Australia'
                }
            } else {
                shippingPreference = {
                    ...shippingPreference,
                    pricebookId: 1,
                    deliveryFee: '0',
                }
                setTimeout(() => {
                    setErrorMsg(undefined)
                }, 2000)
            }
        } else {
            shippingPreference = {
                ...shippingPreference,
                pricebookId: selectedShipping == SHIPPING_TYPES.BNE ? 1 : 2,
                deliveryFee: 'TBC',
            }
        }

        localStorage.setItem('shipping-preference', JSON.stringify(shippingPreference))

        setPostCode(postCode?.data?.postCode)

        customerInfo.postCode = postCode?.data?.postCode
        customerInfo.state = postCode?.data?.state
        customerInfo.country = postCode?.data?.country
        localStorage.setItem('customer-info', JSON.stringify(customerInfo))
        setCustomerInfo(customerInfo)

        history.push('/quick-order?tab=Panels')
        window.location.reload()
    }

    useEffect(() => {
        localStorage.setItem('selectedShipping', selectedShipping)
    }, [selectedShipping])

    return (
        <div className='tab-items'>
            {/* TOAST */}
            {errorMsg ? (
                <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
                    {errorMsg}
                </div>
            ) : null}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSavePreference()
                }}
            >
                <div className='form-group'>
                    <div className='d-flex justify-content-between'>
                        <p>How would you want to get your Order?</p>
                        <button type='submit' className='btn btn-primary'>
                            NEXT
                        </button>
                    </div>

                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='form-check'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='shipping'
                                    id={SHIPPING_TYPES.BNE}
                                    value={SHIPPING_TYPES.BNE}
                                    checked={selectedShipping === SHIPPING_TYPES.BNE}
                                    onChange={handleShippingChange}
                                />
                                <label className='form-check-label' htmlFor={SHIPPING_TYPES.BNE}>
                                    Customer Collect (BNE)
                                </label>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-check'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='shipping'
                                    id={SHIPPING_TYPES.SYD}
                                    value={SHIPPING_TYPES.SYD}
                                    checked={selectedShipping === SHIPPING_TYPES.SYD}
                                    onChange={handleShippingChange}
                                />
                                <label className='form-check-label' htmlFor={SHIPPING_TYPES.SYD}>
                                    Customer Collect (SYD)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='shipping'
                                id={SHIPPING_TYPES.DELIVERY}
                                value={SHIPPING_TYPES.DELIVERY}
                                checked={selectedShipping === SHIPPING_TYPES.DELIVERY}
                                onChange={handleShippingChange}
                            />
                            <label className='form-check-label' htmlFor={SHIPPING_TYPES.DELIVERY}>
                                Delivery
                            </label>
                        </div>
                        {selectedShipping === SHIPPING_TYPES.DELIVERY ? (
                            <div style={{ width: '25%', marginBottom: 30 }}>
                                <label htmlFor='postCode' className='fw-bolder mb-2 text-dark'>
                                    POST CODE
                                </label>
                                <input
                                    style={{ fontSize: '1rem' }}
                                    ref={postCodeRef}
                                    type='text'
                                    pattern="\d*"
                                    className='form-control'
                                    id='postCode'
                                    aria-describedby='postCode'
                                    placeholder='1234'
                                    maxLength={4}
                                    required
                                    onChange={() => {
                                        sessionStorage.removeItem('cartItems')
                                    }}
                                    onInput={(e) => {
                                        const input = e.currentTarget as HTMLInputElement;
                                        input.value = input.value.replace(/\D/g,'');
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ShippingPreference
