import React from 'react'
import {UserLayout} from '../../../_metronic/layout/UserLayout'
import {Route, Switch} from 'react-router-dom'
import {QuickOrderContent} from './components/QuickOrderContent'

type QuickOrderPage = {
  isAuthorized?: boolean
  isBanner?: boolean
}

const QuickOrderPage = (props: QuickOrderPage) => {
  const {isAuthorized} = props
  return (
    <UserLayout isAuthorized={isAuthorized} isBanner={false}>
      <Switch>
        <Route exact path='/quick-order'>
          <QuickOrderContent />
        </Route>
      </Switch>
    </UserLayout>
  )
}

export default QuickOrderPage
