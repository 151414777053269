import React, {Fragment, useState, useEffect, useContext} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {IFixingPricing} from '../../../../models/fixings/fixingPricing'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { 
  ORDER_LIST_FIXINGS,
  FIXINGS_CYCLONE_PLATES,
  FIXINGS_RIVETS,
  FIXINGS_TEK_SCREWS
 } from '../../../../common/constant'

function NewFixingItem(props: any) {
  const {fixings} = props
  const {addToCart, fixingMasterList, colours} = useContext(QuickOrderContext)
  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [fixingPricing, setFixingPricings] = useState<IFixingPricing>()
  const maxQuantity = 1000000

  useEffect(() => {
    if (fixingMasterList) {
      fixingsOption()
    }
  }, [fixingMasterList])
  /**
   * Fixings
   */
  const [fixingOption, setFixingOption] = useState<any[]>([])
  const fixingsOption = () => {
    const availFixing = fixingMasterList.map((x: any) => ({
      id: x.fixingId,
      name: x.fixingName,
    }))

    // Avoid duplicate Fixings
    const distinctFixings: any = Object.values(
      availFixing.reduce((map: any, obj: any) => {
        const key = `${obj.id}-${obj.name}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )
    setFixingOption(distinctFixings)
  }

  /**
   * TYPE
   */
  const [fixing, setFixing] = useState(0)
  const [fixingName, setFixingName] = useState<string>('')
  const handleFixingOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : fixingOption[selectedIndex - 1].name
    setFixingName(name)
    setFixing(e.target.value)
    screwTypeOption(+e.target.value, name)
    filterColoursBasedOnFixingId(+e.target.value)
    setScrewType('')
    setQuantity(0)
    setUnitPrice(0)
    setColor(0)
  }

  const [pricingId, setPricingId] = useState<number>(0)

  /**
   * SCREW TYPE
   */
  const [screwType, setScrewType] = useState('')
  const handleScrewTypeOnChange = (e: any) => {
    let pricingId = e.target.value
    setScrewType(pricingId)
  }

  useEffect(() => {
    if (!hasColourDropDown && screwType) {
      let fixingPricing
      if (fixingName !== FIXINGS_CYCLONE_PLATES) {
        fixingPricing = fixingMasterList.find(
          (f: IFixingPricing) => f.fixingId == fixing && f.screwType == screwType
        )

        setFixingPricings(fixingPricing)
        setUnitPrice(fixingPricing.priceLevel1)
        setPricingId(fixingPricing.id)
        setUnitTypeDisplay(fixingPricing.unitTypeName == 'Each' ? 'each' : '')
      }
    }
    else {
      setUnitPrice(0)
      setColor(0)
    }
  }, [screwType])

  const [isScewTypeHidden, setIsScewTypeHidden] = useState<boolean>(false)
  const [screwTypeoption, setScrewTypeoption] = useState([])
  const screwTypeOption = (fixingId: number, fixingName: string) => {
    // ScrewType
    const availScrewTypes = fixingMasterList
      ?.filter((x: any) => x.screwType !== null)
      .filter((x: any) => x.fixingId == fixingId)

      .map((x: any) => ({
        id: x.screwType,
        name: x.screwType,
      }))

    if (fixingName === FIXINGS_CYCLONE_PLATES) {
      setIsScewTypeHidden(true)
      setScrewType(FIXINGS_CYCLONE_PLATES)
    }
    else{
      setIsScewTypeHidden(false)
      setScrewType('')
    }

    // Avoid duplicate Roof colour
    const distinctavailScrewTypes: any = Object.values(
      availScrewTypes.reduce((map: any, obj: any) => {
        const key = `${obj.id}-${obj.name}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )
    setScrewTypeoption(distinctavailScrewTypes)
  }

  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<any>()
  const handleQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > maxQuantity || inputNumber < 0) {
      return
    }
    setQuantity(inputNumber)
  }

  const [color, setColor] = useState<number>(0)
  const [colorName, setColorName] = useState<string>('')
  const [colourOption, setColourOption] = useState<any[]>([])
  const [hasColourDropDown, setHasColourDropDown] = useState<boolean>(false)
  const handleColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
    setColor(+e.target.value)
    setColorName(name)
  }  

  useEffect(() => {
    if (hasColourDropDown && color) {
      let fixingPricing
      if (fixingName !== FIXINGS_CYCLONE_PLATES) {
        fixingPricing = fixingMasterList.find(
          (f: IFixingPricing) => f.fixingId == fixing && f.screwType == screwType && f.colourId == color
        )
      }
      else {
        fixingPricing = fixingMasterList.find(
          (f: IFixingPricing) => f.fixingId == fixing && f.colourId == color
        )
      }

      setFixingPricings(fixingPricing)
      setUnitPrice(fixingPricing.priceLevel1)
      setPricingId(fixingPricing.id)
      setUnitTypeDisplay(fixingPricing.unitTypeName == 'Each' ? 'each' : '')
    }
  }, [color])

  const filterColoursBasedOnFixingId = (fixingId: number) => {
    const availableColourIds = fixingMasterList
      ?.filter((x: any) => x.colourId !== null && x.fixingId === fixingId)
      .map((x: any) => x.colourId)

    const filteredColours = colours.filter((colour: any) => availableColourIds.includes(colour.id))
    
    setColourOption(filteredColours)
  }

  useEffect(() => {
    if (fixingName === FIXINGS_CYCLONE_PLATES || 
          fixingName === FIXINGS_RIVETS ||
          fixingName === FIXINGS_TEK_SCREWS) {
      setHasColourDropDown(true)
    }
    else {
      setHasColourDropDown(false)
      setColor(0)
      setColorName('')
    }
  }, [fixingName])

  /**
   * DISABLED
   */
  const disabled =
    screwType === 'Select One' ||
    isNaN(quantity) ||
    quantity === 0 ||
    quantity > maxQuantity ||
    quantity < 0 ||
    fixing < 0 ||
    unitPrice === 0

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        {/* SCREW NAME */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Screw Name</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={fixing}
            onChange={handleFixingOnChange}
          >
            <option value=''>Select Screw Name</option>
            {fixingOption?.map((fixing: any) => (
              <option value={fixing.id} key={fixing.id}>
                {fixing.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' />
        <div className='col-md-3' />
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 0,
                marginRight: 24,
              }}
            >
              {moneyFormatter.format(fixing !== 0 ? unitPrice : 0)}
            </p>
            <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
              {unitPrice ? unitTypeDisplay : ''}
            </p>
          </div>
          <button
            disabled={disabled}
            className='btn btn-primary w-100'
            onClick={() => {
              addToCart({
                ...fixingPricing,
                price: unitPrice,
                unitPrice,
                pricingId,
                quantity,
                customId: Math.random(),
                type: 'fixing',
                uniqueID: `fixing-${screwType}-${quantity}-${getUniqueIdWithPrefix(
                  'fixing'
                )}`.replace(/\s/g, ''),
                productType: ORDER_LIST_FIXINGS,
                productName: fixingPricing?.fixingName,
                description:  `${fixingPricing?.screwType ? fixingPricing?.screwType + ',' : ''} ${colorName ? colorName : '-'}`,
                colorName
              })
              // SET DEFAULT
              setScrewType('')
              setQuantity('')
              setFixing(0)
              setUnitPrice(0)
              setColor(0)
              setHasColourDropDown(false)
              setFixingName('')
              setIsScewTypeHidden(false)
            }}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>
      {/* SCREW TYPE */}
      <div className='row'>
        {!isScewTypeHidden ? (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark'>Screw Type</label>
            <select
              style={{fontSize: '1rem'}}
              className='form-select  my-4'
              value={screwType}
              onChange={handleScrewTypeOnChange}
              disabled={fixing <= 0}
            >
              <option value=''>Select One</option>
              {screwTypeoption?.map((fixing: any) => (
                <option value={fixing.id} key={fixing.id}>
                  {fixing.name}
                </option>
              ))}
              {/* {fixingMasterList
                ?.filter((item: any) => item?.fixingId === +fixing)
                ?.map((fixingPricing: any) => (
                  <option value={fixingPricing.id} key={fixingPricing.id}>
                    {fixingPricing.screwType}
                  </option>
                ))} */}
            </select>
          </div>
        ) : null}
        {/* QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            className='form-control  my-4'
            placeholder='Enter'
            min='1'
            max={maxQuantity}
            value={quantity || ''}
            // value={fixing === '' ? '' :quantity}
            onChange={handleQuantityChange}
            disabled={fixing <= 0}
          />
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
      </div>
      <div className='row'>
        {hasColourDropDown ? (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark' htmlFor='color'>
              Colour
            </label>
            <select
              style={{fontSize: '1rem'}}
              id='color'
              className='form-select  my-4'
              aria-label='Colour'
              value={color}
              onChange={handleColorOnChange}
              disabled={fixing <= 0}
            >
              <option value={0}>Select</option>
              {colourOption?.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
      </div>
    </Fragment>
  )
}

export default NewFixingItem
