import React from 'react'
import { Modal, Button } from 'react-bootstrap-v5'

interface DeleteModalProps {
  isModalOpen: boolean
  handleConfirmDelete: () => void
  handleCancelDelete: () => void
  isDeleting?: boolean
  title?: string
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isModalOpen,
  handleConfirmDelete,
  handleCancelDelete,
  isDeleting,
  title
}) => {
  return (
    <Modal show={isModalOpen} onHide={handleCancelDelete} centered size="sm">
      <Modal.Header>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center text-dark" style={{ fontSize: 14, marginBottom: 0 }}>
          {title ? 
            (<span>{title}</span>): 
            (<span>This will delete the Product on the system, Continue?</span>)
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleConfirmDelete} disabled={isDeleting}>
          {isDeleting ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            'Confirm'
          )}
        </Button>
        <Button variant="secondary" onClick={handleCancelDelete}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal
