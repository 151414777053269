import React, {useContext, useState, useEffect, Fragment} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {IPostType} from '../../../../models/posts/postType'
import {IPostPricing} from '../../../../models/posts/postPricing'
import {IPostFinish} from '../../../../models/posts/postFinish'
import {IColour} from '../../../../models/colour'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { 
  ORDER_LIST_POSTS,
  ORDER_TAB_POSTS_KIT_TYPE
 } from '../../../../common/constant'

function NewPostKitItem(props: any) {
  const {postKit, postKitName, posts, extendaBracketUnitPrice, disabledExtendaBracket, isExtendaBracketChecked, addNewExtendaItem} = props
  const {addToCart, colours, postFinishes, postPricings, 
    postProducts, getPostProductsAsync, postBeamSizes, getPostBeamSizesAsync, postColours, getPostColoursAsync, 
    postUnitPrice, getPostUnitPriceAsync, postProductMastersId, postKitProductId} = useContext(QuickOrderContext)

  useEffect(() => {
    getPostProductsAsync(postKitProductId)
    getPostBeamSizesAsync(postKitProductId)
    getPostColoursAsync(postKitProductId)
  }, [])

  const [postTypes, setPostTypes] = useState<IPostType[]>([])
  const getPostTypesByProduct = (pricings: IPostPricing[], product: string): IPostType[] => {
    return pricings
      .filter((p) => p.postProductName === product)
      .map((p) => ({id: p.postTypeId, name: p.postTypeName}))
      .filter(
        (value, index, self) =>
          self.findIndex((item) => item.id === value.id && item.name === value.name) === index
      )
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  /**
   * POST PRODUCT
   */
  const [postProduct, setPostProduct] = useState('')
  const handlePostProductOnChange = (e: any) => {
    setPostProduct(e.target.value)
    const selectedName = e.target.options[e.target.selectedIndex].innerHTML
    // setPostTypes(getPostTypesByProduct(postPricings, selectedName))
  }

  const [productName, setProductName] = useState('')
  useEffect(() => {
    let selected = postProducts?.find((p: any) => p.id.toString() == postProduct)
    if (selected) {
      setFinish('Select Finish')
      setColor('Select Colour')
      setQuantity('')
      setLength('')
      setLengthSelection('Select Length')
      setUnitPrice(0)
      setProductName(selected.name)
    } else {
      setFinish('Select Finish')
      setColor('Select Colour')
      setQuantity('')
      setLengthSelection('Select Length')
      setUnitPrice(0)
      setLength('')
    }
  }, [postProduct])

  /**
   * FINISH
   */
  const [finish, setFinish] = useState('Select Finish')
  const handleFinishOnChange = (e: any) => {
    setFinish(e.target.value)
  }

  const [finishName, setFinishName] = useState<string>()
  useEffect(() => {
    let selected = postFinishes?.find((b: IPostFinish) => b.id.toString() == finish)
    if (selected) {
      setFinishName(selected.name)
    }
  }, [finish])
  /**
   * LENGTH
   */
  const [lengthSelection, setLengthSelection] = useState('Select Length')
  const handleLengthOnChange = (e: any) => {
    setLengthSelection(e.target.value)
  }

  const [lengthName, setLengthName] = useState<string>()
  useEffect(() => {
    let selected = postBeamSizes?.find((b: IPostType) => b.id.toString() == lengthSelection)
    if (selected) {
      setLengthName(selected.name)
    }
  }, [lengthSelection])
  /**
   * COLOR
   */
  const [color, setColor] = useState('Select Colour')
  const handleColorOnChange = (e: any) => {
    setColor(e.target.value)
  }

  const [colorName, setColorName] = useState()
  useEffect(() => {
    let selected = postColours?.find((b: IColour) => b.id.toString() == color)
    if (selected) {
      setColorName(selected.name)
    }
    if (color === 'Select Colour') {
      setLengthSelection('Select Length')
    }
  }, [color])

  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<any>()
  const handleQuantityChange = (e: any) => {
    // if (e.target.value === '') {
    //   e.target.value = '1'
    // }
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > 99 || inputNumber < 0) {
      return
    }
    setQuantity(inputNumber)
  }

  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [unitPriceToDisplay, setUnitPriceToDisplay] = useState<number>(0)
  // const [price, setPrice] = useState<number>(0)
  const [pricingId, setPricingId] = useState(0)
  const [postPricing, setPostPricing] = useState<any>()
  useEffect(() => {
    setUnitPrice(postUnitPrice ? postUnitPrice : 0)
    let postPricing = postPricings?.find(
      (f: IPostPricing) =>
        f.postProductId.toString() == postProduct &&
        f.postTypeId.toString() == lengthSelection &&
        f.postFinishId.toString() == finish &&
        f.postCategoryName == 'Post Kit'
    )

    if (postPricing) {
      setUnitPrice(postUnitPrice ? postUnitPrice : 0)
      setPricingId(postPricing.id)
      setPostPricing(postPricing)
      setUnitTypeDisplay(postPricing.unitTypeName == 'Each' ? 'each' : '')
    } else {
      setUnitPrice(postUnitPrice ? postUnitPrice : 0)
      setPricingId(0)
      setPostPricing(undefined)
    }
  }, [lengthSelection, finish, postProduct, postUnitPrice])

  useEffect(() => {
    const getPostUnitPrice = async () => {
      if (postProduct && color && lengthSelection !== 'Select Length') {
        await getPostUnitPriceAsync(postKitProductId, postProduct, color, lengthSelection)
      }
    }

    getPostUnitPrice()
  }, [postProduct, color, lengthSelection])

  useEffect(() => {
    setUnitPrice(0)
  }, [postProduct])

  useEffect(() => {
    if (lengthSelection === 'Select Length') {
      setUnitPrice(0)
    }
  }, [lengthSelection])

  /**
   * ERROR
   */
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [errorLength, setErrorLength] = useState<boolean>(false)
  const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')

  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])

  /**
   * LENGTH
   */
  const [length, setLength] = useState<any>('')
  const handleLengthFocusChange = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue === 0 || inputValue < 0) {
      setErrorLengthMsg('Value must not be less than 100mm.')
      setErrorLength(true)
      return
    }
    if (inputValue < 100) {
      setErrorLengthMsg('Value must not be less than 100mm.')
      setErrorLength(true)
    } else if (inputValue > 23000) {
      setErrorLengthMsg('Value must not be greater than 23000mm.')
      setErrorLength(true)
    } else {
      setErrorLengthMsg('')
      setErrorLength(false)
    }
    setLength(inputValue)
  }

  const handleLengthBlur = (e: any) => {
    setErrorLengthMsg('')
    setErrorLength(false)
  }

  useEffect(() => {
    const combinedPrice = unitPrice + extendaBracketUnitPrice
    setUnitPriceToDisplay(combinedPrice)
  }, [unitPrice, extendaBracketUnitPrice])

  // useEffect(() => {
  //   setPrice(unitPrice * quantity)
  // }, [unitPrice, quantity])

  const disabledField = postProduct === ''
  const isLengthValid = length !== null && !isNaN(length) && length >= 100 && length <= 23000

  const disabledPostKit =
    color === 'Select Colour' ||
    lengthSelection === 'Select Length' ||
    isNaN(quantity) ||
    quantity === 0 ||
    quantity > 99 ||
    quantity < 0 ||
    unitPrice === 0 ||
    quantity === '' ||
    length === null ||
    !isLengthValid

  const disabled =
    (!isExtendaBracketChecked && disabledField) ||
    (isExtendaBracketChecked && disabledExtendaBracket) ||
    (!disabledField && disabledPostKit)

  const addPostKitItem = () => {
    addToCart({
      ...postKit,
      ...postPricing,
      postProductMastersId,
      productName,
      length,
      postKitName,
      finish,
      finishName,
      colorId: parseInt(color),
      colorName,
      lengthSelection,
      lengthName,
      pricingId,
      unitPrice,
      quantity,
      customId: Math.random(),
      type: ORDER_TAB_POSTS_KIT_TYPE,
      uniqueID: `${ORDER_TAB_POSTS_KIT_TYPE}-${unitPrice}-${lengthName}-${length}-${color}-${productName}-${quantity}-${Math.random().toString(36).substring(2, 15)}`,
      price: unitPrice,
      productType: ORDER_LIST_POSTS,
      description: `${productName}, ${lengthName}, ${colorName}`,
    })
    // SET DEFAULT
    setPostProduct('')
    setFinish('Select Finish')
    setColor('Select Colour')
    setLengthSelection('Select Length')
    setQuantity('')
    setLength('')
  }

  const handlePostKitAddToCart = () => {
    if (!disabledField && !disabledPostKit) {
      addPostKitItem()
    }
    
    if (isExtendaBracketChecked && !disabledExtendaBracket) {
      addNewExtendaItem()
    }
  }

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Product Name</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={postProduct}
            onChange={handlePostProductOnChange}
          >
            <option value=''>Select Product</option>
            {postProducts?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' />
        <div className='col-md-3' />
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 0,
                marginRight: 24,
              }}
            >
              {moneyFormatter.format(unitPriceToDisplay || 0)}
            </p>
            <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
              {unitPriceToDisplay ? unitTypeDisplay : ''}
            </p>
          </div>
          <button
            className='btn btn-primary w-100'
            onClick={handlePostKitAddToCart}
            disabled={disabled}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>

      <div className='row'>
        {/* COLOUR */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='color'>
            Colour
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='color'
            className='form-select my-4'
            aria-label='Colour'
            value={color}
            onChange={handleColorOnChange}
            disabled={disabledField}
          >
            <option value='Select Colour'>Select Colour</option>
            {postColours?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
        {/* LENGTH */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='length'>
            Beam Size
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='Beam Size'
            className='form-select my-4'
            aria-label='Beam Size'
            value={lengthSelection}
            onChange={handleLengthOnChange}
            disabled={disabledField}
          >
            <option value='Select Beam Size'>Select Beam Size</option>
            {postBeamSizes?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row'>
        {/*  FINISH */}
        {/* <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='finish'>
            Finish
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='finish'
            className='form-select my-4'
            aria-label='Finish'
            value={finish}
            onChange={handleFinishOnChange}
            disabled={disabledField}
          >
            <option value='Select'>Select Finish</option>
            {postFinishes?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div> */}
        {/*  QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            className='form-control my-4'
            min='1'
            max='99'
            step='1'
            pattern='\d+'
            value={quantity || ''}
            onChange={handleQuantityChange}
            disabled={disabledField}
            placeholder='Enter Quantity'
            // onKeyDown={(event) => {
            //   const allowedKeys = [
            //     'Backspace',
            //     'Delete',
            //     'ArrowUp',
            //     'ArrowDown',
            //     'ArrowLeft',
            //     'ArrowRight',
            //     'Tab',
            //   ]
            //   if ((!Number(event.key) && !allowedKeys.includes(event.key)) || event.key === ' ') {
            //     event.preventDefault()
            //   }
            // }}
          />
        </div>
        {/* LENGTH */}
        <div className='col-md-3'>
          <label
            className='fw-bolder text-dark'
            htmlFor='length'
            // hidden={disabledField || !isLengthAvailable}
          >
            Length (mm)
          </label>
          <input
            style={{
              fontSize: '1rem',
              borderColor: length < 100 && length !== '' ? 'red' : '',
            }}
            placeholder='Enter Length'
            min='1'
            step='1'
            max='23000'
            type='number'
            className='form-control my-4'
            id='length'
            aria-describedby='length'
            value={length || ''}
            onFocus={handleLengthFocusChange}
            onBlur={handleLengthBlur}
            onChange={handleLengthFocusChange}
            // disabled={disabledField || !isLengthAvailable}
            // hidden={disabledField || !isLengthAvailable}
          />
          {errorLength ? (
            <div className='text-danger m-auto text-left' role='alert'>
              {errorLengthMsg}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  )
}

export default NewPostKitItem
