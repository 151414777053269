import axios from 'axios'
export const LIGHTPRICING_URL = `${process.env.REACT_APP_API_URL}/lightpricings`
export const LIGHTTHICKNESS_URL = `${process.env.REACT_APP_API_URL}/lightthicknesses`
export const LIGHTPRODUCTTYPE_URL = `${process.env.REACT_APP_API_URL}/lightproducttypes`
export const LIGHTPRODUCTS_URL = `${process.env.REACT_APP_API_URL}/lightproducts`
export const LIGHTPANELTYPES_URL = `${process.env.REACT_APP_API_URL}/lightpaneltypes`
export const LIGHTUNITTYPES_URL = `${process.env.REACT_APP_API_URL}/lightunittypes`

export const LIGHTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/LightMasterlist`
export const LIGHTPROFILES_URL = `${process.env.REACT_APP_API_URL}/LightProfiles`

export const LIGHTQUICKORDER_URL = `${process.env.REACT_APP_API_URL}/LightQuickOrder`

interface LightProduct {
  id: number
  productName: string
  panelType: string
  productType: string
}

export const getLightProductsPM = async () => {
  try {
    return await axios.get(`${LIGHTQUICKORDER_URL}/getAllLightProducts`)
  } catch (err) {
    return []
  }
}

export const getLightPanelTypesPM = async () => {
  try {
    return await axios.get(`${LIGHTQUICKORDER_URL}/getAllLightPanelTypes`)
  } catch (err) {
    return []
  }
}

export const getLightProductTypesPM = async () => {
  try {
    return await axios.get(`${LIGHTQUICKORDER_URL}/getAllLightProductTypes`)
  } catch (err) {
    return []
  }
}

export const getLightThicknesses = async () => {
  try {
    return await axios.get(`${LIGHTQUICKORDER_URL}/getAllLightThicknesses`)
  } catch (err) {
    return []
  }
}

export const getLightUnitPrice = async (lightProductId: number, lightsDetail: string, lightPanelTypeId?: number, lightThicknessId?: number) => {
  try {
      const response = await axios.get(`${LIGHTQUICKORDER_URL}/getUnitPrice`, {
        params: {
          lightProductId,
          lightPanelTypeId,
          lightsDetail,
          lightThicknessId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getLightProfiles = async () => {
  try {
    return await axios.get(`${LIGHTPROFILES_URL}`)
  } catch (err) {
    return []
  }
}

export const getAllProduct = async () => {
  try {
      const response = await axios.get(`${LIGHTMASTERLIST_URL}/getAllLightProduct`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getLightProductMaster = async (priceBookId: number) => {
  try {
    return await axios.get(`${LIGHTMASTERLIST_URL}/getAllLightProduct`, {
      params: {
        priceBookId
      }
    })
  } catch (err) {
    return []
  }
}

export const getLightMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  LightProductName?: string,
  LightProfileName?: string,
  LightProductTypeName?: string,
  LightUnitTypeName?: string,
  LightThicknessName?: string
) => {
  try {
    return await axios.get(`${LIGHTMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        LightProductName,
        LightProfileName,
        LightProductTypeName,
        LightUnitTypeName,
        LightThicknessName
      },
    })
  } catch (err) {
    return err
  }
}

export const createLightProduct = async (payload: any) => {
  try {
    return await axios.post(`${LIGHTMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateLightProduct = async (payload: any) => {
  try {
    return await axios.patch(`${LIGHTMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteLightProduct = async (id: any) => {
  try {
    return await axios.delete(`${LIGHTMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getLightPricingPagination = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${LIGHTPRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getLightPricings = async (priceBookid?: number) => {
  try {
    return await axios.get(`${LIGHTPRICING_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const createLightPricings = async (payload: any) => {
  try {
    return await axios.post(`${LIGHTPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateLightPricings = async (payload: any) => {
  try {
    return await axios.patch(`${LIGHTPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteLightPricings = async (id: any) => {
  try {
    return await axios.delete(`${LIGHTPRICING_URL}?id=${id}`)
  } catch (err) {
    return err
  }
}

export const getLightProductTypes = async () => {
  try {
    return await axios.get(`${LIGHTPRODUCTTYPE_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightProducts = async () => {
  try {
    return await axios.get(`${LIGHTPRODUCTS_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightPanelTypes = async () => {
  try {
    return await axios.get(`${LIGHTPANELTYPES_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightUnitTypes = async () => {
  try {
    return await axios.get(`${LIGHTUNITTYPES_URL}`)
  } catch (err) {
    return []
  }
}

export const getAllLightThicknesses = async () => {
  try {
    return await axios.get(`${LIGHTTHICKNESS_URL}`)
  } catch (err) {
    return []
  }
}
