import React from 'react'
import {moneyFormatter} from '../../../../../utils'

function MiscTable(props: any) {
  const {shippingPreference} = props
  return (
    <div>
      <table className='table table-hover summary-table' style={{backgroundColor: '#fff'}}>
        <thead className='thead-dark'>
          <tr>
            <th scope='col' style={{width: '20%'}}>
              Delivery Fee
            </th>
            <th scope='col' style={{width: '15%'}} className='text-center'></th>
            <th scope='col' style={{width: '15%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='align-middle fw-bolder text-dark '>
              {shippingPreference?.deliveryFee !== 'TBC'
                ? moneyFormatter.format(
                    shippingPreference?.deliveryFee ? shippingPreference?.deliveryFee : 0
                  )
                : 'TBC'}
            </td>
            <td className='fw-bolder text-dark align-middle'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
            <td className='fw-bolder text-dark align-middle text-center'></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MiscTable
