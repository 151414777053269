import React, {useContext, useState} from 'react'
import {moneyFormatter, roundToTwo} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import './ProductOrderItem.css'
import { 
  ORDER_TAB_POSTS_TYPE,
  ORDER_TAB_POSTS_KIT_TYPE,
  ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE,
  ORDER_TAB_POSTS,
  ORDER_TAB_POSTS_KIT,
  ORDER_TAB_POSTS_EXTENDA_BRACKET
 } from '../../../../common/constant'

function ProductOrderItem({ items, productType }: { items: any[], productType: string }) {

  const { handleDeleteOrderItem } = useContext(QuickOrderContext)

  const productName = (item: any) => {
    const itemType = item.type
    switch (itemType) {
      case ORDER_TAB_POSTS_TYPE:
        return ORDER_TAB_POSTS
      case ORDER_TAB_POSTS_KIT_TYPE:
        return ORDER_TAB_POSTS_KIT
      case ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE:
        return ORDER_TAB_POSTS_EXTENDA_BRACKET
      default:
        return item.productName
    }
  }

  const calculateAmount = (item: any) => {
    return item.length > 1
      ? roundToTwo(item.price * (item.length / 1000) * item.quantity)
      : roundToTwo(item.price * item.quantity)
  }

  const subtotal = items.reduce((total, item) => {
    return roundToTwo(total + calculateAmount(item))
  }, 0)

  return (
    <div className='card small-text mt-4 mb-4'>
      <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <h5 className='card-title'>{productType}</h5>
            <h5 className='card-title'>{moneyFormatter.format(subtotal)}</h5>
          </div>
        <div className='row text-center header-bold' style={{ backgroundColor: '#f3e550' }}>
          <div className='col-1'></div>
          <div className='col-2'>Qty</div>
          <div className='col-2'>Name</div>
          <div className='col-3'>Description</div>
          <div className='col-2'>Length</div>
          <div className='col-2'>Amount</div>
        </div>
        {items.map((item, index) => (
          <div className='row align-items-center' key={item.customId} style={{ borderBottom: '1px solid #ddd' }}>
            <div className='col-1 text-center'>
              <button className='btn btn-link p-0' onClick={() => handleDeleteOrderItem(item.customId)}>
                <i className="bi bi-x-lg" style={{ color: 'red' }}></i>
              </button>
            </div>
            <div className='col-2 text-center'>{item.quantity}</div>
            <div className='col-2 text-center'>{productName(item)}</div>
            <div className='col-3 text-center'>{item.description}</div>
            <div className='col-2 text-center'>
              {item.length > 1 ? `${item.length}` :  '-' }
            </div>
            <div className='col-2 text-center'>
              {moneyFormatter.format(calculateAmount(item))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductOrderItem
