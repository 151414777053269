import axios from 'axios'
export const PANELPRICING_URL = `${process.env.REACT_APP_API_URL}/PanelPricings`
export const PANELTHICKNESS_URL = `${process.env.REACT_APP_API_URL}/PanelThicknesses`
export const PANELPROFILES_URL = `${process.env.REACT_APP_API_URL}/PanelProfiles`
export const PANELCORES_URL = `${process.env.REACT_APP_API_URL}/PanelCores`
export const PANELBOTTOMCOLOURS_URL = `${process.env.REACT_APP_API_URL}/PanelBottomColours`
export const PANELORDERS_URL = `${process.env.REACT_APP_API_URL}/PanelPricings`

export const PANELMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PanelMasterlist`
export const PANELADDPROD_URL = `${process.env.REACT_APP_API_URL}/OrderDetails`

interface PanelProduct {
  id: number
  panelProfile: string
  panelCore: string
  thickness: string
  topSheetColour: string
  bottomSheetColour: string
}

export const getPanelProductOptions = async (
  pricebookId?: number) => {
  try {
      const response = await axios.get(`${PANELADDPROD_URL}/getProductPanelData`, {
        params: {
          pricebookId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getPanelProductDetails = async (
  panelProfileId: number, 
  panelCoreId: number, 
  panelThicknessId: number, 
  panelRoofColourId: number, 
  panelBottomColourId: number, 
  pricebookId?: number) => {
  try {
      const response = await axios.get(`${PANELADDPROD_URL}/getProductPanelUnitPrice`, {
        params: {
          panelProfileId,
          panelCoreId,
          panelThicknessId,
          panelRoofColourId,
          panelBottomColourId,
          pricebookId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllProduct = async (priceBookid?: number) => {
  try {
    const response = await axios.get(`${PANELMASTERLIST_URL}/getAllPanelProduct`, {
      params: { priceBookid },  
    })
    return response.data
  } catch (ex) {
    return [null, ex]
  }
}

export const getPanelMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  PanelProfileName?: string,
  PanelCoreName?: string,
  PanelThicknessName?: string,
  PanelRoofColourName?: string,
  PanelBottomColourName?: string
) => {
  try {
    return await axios.get(`${PANELMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        PanelProfileName,
        PanelCoreName,
        PanelThicknessName,
        PanelRoofColourName,
        PanelBottomColourName,
      },
    })
  } catch (err) {
    return err
  }
}

export const createPanelProduct = async (payload: any) => {
  try {
    return await axios.post(`${PANELMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updatePanelProduct = async (payload: any) => {
  try {
    return await axios.patch(`${PANELMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deletePanelProduct = async (id: any) => {
  try {
    return await axios.delete(`${PANELMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getPanelPricings = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${PANELPRICING_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const createPanelPricing = async (payload: any) => {
  try {
    return await axios.post(`${PANELPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updatePanelPricing = async (payload: any) => {
  try {
    return await axios.patch(`${PANELPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deletePanelPricing = async (id: any) => {
  try {
    return await axios.delete(`${PANELPRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getPanelThicknesses = async () => {
  try {
    return await axios.get(`${PANELTHICKNESS_URL}`)
  } catch (err) {
    return []
  }
}

export const getPanelProfiles = async () => {
  try {
    return await axios.get(`${PANELPROFILES_URL}/getpanelprofiles`)
  } catch (err) {
    return []
  }
}

export const getPanelCores = async () => {
  try {
    return await axios.get(`${PANELCORES_URL}/getpanelcores`)
  } catch (err) {
    return []
  }
}

export const getPanelBottomColours = async () => {
  try {
    return await axios.get(`${PANELBOTTOMCOLOURS_URL}`)
  } catch (err) {
    return []
  }
}


export const deletePanelOrder = async (id: any) => {
  try {
    return await axios.delete(`${PANELORDERS_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}