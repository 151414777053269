import React, {useState, useEffect, useContext, useRef} from 'react'
import axios from 'axios'
import '../Products.css'
import PanelsProduct from './PanelsProduct'
import {
  getPanelBottomColours,
  getPanelCores,
  getPanelProfiles,
  getPanelThicknesses,
  getPanelMasterlist,
  createPanelProduct,
  updatePanelProduct,
  deletePanelProduct,
} from '../../../../../api/panels-api'
import {getColours} from '../../../../../api/index'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {useAsyncFn, useEffectOnce} from 'react-use'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import {useHistory} from 'react-router-dom'

type Props = {
  product: string
  productId: number
}

const Panels = (props: Props) => {
  const {productId} = props
  const {
    panelBottomColour,
    setPanelBottomColours,
    panelCores,
    setPanelCores,
    panelProfiles,
    setPanelProfiles,
    panelThickness,
    setPanelThickness,
    colours,
    setColours,
  } = useContext(AdminProductContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const history = useHistory()

  /**REFS */
  const panelTypeRef = useRef<any>()
  const thicknessRef = useRef<any>()
  const colorRef = useRef<any>()
  const coreRef = useRef<any>()
  const priceRef = useRef<any>()
  const regex = /^[a-zA-Z0-9\s./-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/

  /**
   * SYD: 2
   */
  const [seqData, setSeqData] = useState<any>({})
  const [newProduct, setNewProduct] = useState({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')
  const [Filter4, setFilter4] = useState('')
  const [Filter5, setFilter5] = useState('')

  /**
   * INITIAL LOAD
   */
  useEffectOnce(() => {
    if (!fetchPanelBottomColours && !panelBottomColour) {
      fetchPanelBottomColoursAsync()
    }
    if (!fetchPanelThicknesses && !panelThickness) {
      fetchPanelThicknessesAsync()
    }
    if (!fetchPanelProfiles && !panelProfiles) {
      fetchPanelProfilesAsync()
    }
    if (!fetchPanelCores && !panelCores) {
      fetchPanelCoresAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  })

  /**
   * FETCH RECORD
   */
  const [{loading: fetchPanelThicknesses}, fetchPanelThicknessesAsync] = useAsyncFn(async () => {
    const result: any = await getPanelThicknesses()
    if (result != null) {
      setPanelThickness(result.data)
    }
  }, [setPanelThickness])

  const [{loading: fetchPanelProfiles}, fetchPanelProfilesAsync] = useAsyncFn(async () => {
    const result: any = await getPanelProfiles()
    if (result != null) {
      setPanelProfiles(result.data)
    }
  }, [setPanelProfiles])

  const [{loading: fetchPanelCores}, fetchPanelCoresAsync] = useAsyncFn(async () => {
    const result: any = await getPanelCores()
    if (result != null) {
      setPanelCores(result.data)
    }
  }, [setPanelCores])

  const [{loading: fetchPanelBottomColours}, fetchPanelBottomColoursAsync] =
    useAsyncFn(async () => {
      const result: any = await getPanelBottomColours()
      if (result != null) {
        setPanelBottomColours(result.data)
      }
    }, [setPanelBottomColours])

  const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])

  useEffect(() => {
    setSeqTotalPages(1)
    setSeqCurrentPage(1)
    // Load to page 1
    // fetchPanelProducts(1)
  }, [productId])

  const fetchPanelProducts = async (
    currentPage: number,
    filter1?: any,
    filter2?: any,
    filter3?: any,
    filter4?: any,
    filter5?: any
  ) => {
    await getPanelMasterlist(
      currentPage,
      seqDataPerPage,
      filter1,
      filter2,
      filter3,
      filter4,
      filter5
    ).then((response: any) => {
      setSeqData(response.data)
      setSeqTotalPages(Math.ceil(response.data?.totalCount / 10))
    })
  }

  /**
   * PAGINATION
   */
  const handleFilter = (filter1: any, filter2: any, filter3: any, filter4?: any, filter5?: any) => {
    // fetchPanelProducts(seqCurrentPage, filter1, filter2, filter3, filter4, filter5)
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setFilter4(filter4)
    setFilter5(filter5)
    setSeqCurrentPage(1)
  }

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setIsSeqAdding(false)
  }
  const handleCancel = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }
    // if (product === 'SEQ Pricebook') {
    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({
        ...prevData,
        [name]: name === 'panelThickness' ? {...name, name: value} : value,
      }))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deletePanelProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchPanelProducts(seqCurrentPage)
        handleSuccessMsg('Product deleted successfully.')
      } else {
        const errorMessage =
          result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Panel:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }

    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
    setErrorMsg(undefined)
  }
  /**
   * UPDATE
   */

  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg('Product updated sucessfully.')
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewProduct({})
    setIsSeqAdding(false)
  }

  const handleUpdateProduct = (iProductId: any) => {
    history.push(`/admin/product-details/Panel/${iProductId}`)
  }
  const handleSave = () => {
    const newData = {
      ...seqData,
      items: seqData.items?.map((item: any) => (item.id === editingSeqId ? editedSeqData : item)),
    }
    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
    setErrorMsg(undefined)
  }
  /**
   * ADD
   */
  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData({...seqData, items: [newProduct, ...seqData.items]})
    setIsSeqAdding(false)
  }

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const productFields = [
    {label: 'Item Number', key: 'Code', type: 'text'},
    {label: 'Panel Profile', key: 'PanelProfileName', type: 'text'},
    {label: 'Panel Core', key: 'PanelCoreId', type: 'dropdown', options: panelCores},
    {label: 'Thickness', key: 'PanelThicknessId', type: 'dropdown', options: panelThickness},
    {label: 'Top Sheet Colour', key: 'PanelRoofColourId', type: 'dropdown', options: colours},
    {
      label: 'Bottom Sheet Colour',
      key: 'PanelBottomColourId',
      type: 'dropdown',
      options: panelBottomColour,
    },
  ]

  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(panelTypeRef.current.value)) {
      panelTypeRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(thicknessRef.current.value)) {
      thicknessRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(colorRef.current.value)) {
      colorRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(coreRef.current.value)) {
      coreRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    setNewProduct({
      ...newProduct,
      [name]: name === 'panelThickness' ? {id: Math.random(), name: value} : value,
    })
  }
  useEffect(() => {
    fetchPanelProducts(seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5)
  }, [seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5])

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    createPanelProduct(newProduct)
      .then((result: any) => {
        if (result.data) {
          handleSuccessMsg('Product added successfully.')
          fetchPanelProducts(seqCurrentPage)
        } else {
          handleErrorMessage(result?.response?.data)
        }
      })
      .catch(() => {
        handleErrorMessage('Error Encountered While Saving. Please try again later.')
      })

    setIsSeqAdding(false)
  }

  return (
    <>
      <div className='card-body' style={{height: '100vh', backgroundColor: '#fff'}}>
        <AddProductButton onClick={handleAddProduct} />
        <div style={{overflowY: 'auto', height: '95%'}}>
          {errorMsg && (
            <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
              <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className='alert alert-success w-50 m-auto text-center' role='alert'>
              <i className='fa fa-check-circle text-success'></i> {successMsg}
            </div>
          )}
          <PanelsProduct
            data={seqData}
            currentPage={seqCurrentPage}
            totalPages={seqTotalPages}
            handleUpdateProduct={handleUpdateProduct}
            handleSave={handleSave}
            editingId={editingSeqId}
            editedData={editedSeqData}
            handleSaveClick={handleSaveClick}
            handleInputChange={handleInputChange}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            handleCancelClick={handleCancelClick}
            handleCancel={handleCancel}
            handleDeleteProduct={handleDeleteProduct}
            handleChange={handleChange}
            isAdding={isSeqAdding}
            panelTypeRef={panelTypeRef}
            thicknessRef={thicknessRef}
            colorRef={colorRef}
            coreRef={coreRef}
            priceRef={priceRef}
            newProduct={newProduct}
            handleUpdateSuccess={handleUpdateSuccess}
            handleErrorMessage={handleErrorMessage}
            handleFilter={handleFilter}
          />
        </div>
      </div>
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
        isDeleting={isDeleting}
      />
      <NewProductModal
        isOpen={isAddingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleSaveNewProduct}
        fields={productFields}
      />
    </>
  )
}

export default Panels
