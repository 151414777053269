import React, { FC, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { HomeContent } from './components/HomeContent'
import { HomeContextProvider } from './context/HomeContext'
import { UserLayout } from '../../../_metronic/layout/UserLayout'

type HomePage = {
    isAuthorized?: boolean
    isBanner?: boolean
}

const HomePage = (props: HomePage) => {
    const { isAuthorized } = props

    useEffect(() => {
        localStorage.removeItem('orderNumber');
    }, []);

    return (
        <UserLayout isAuthorized={isAuthorized} isBanner={true}>
            <Switch>
                <Redirect from='/home' to='/' />
                <HomeContextProvider>
                    <Route exact path='/'>
                        <HomeContent />
                    </Route>
                </HomeContextProvider>
            </Switch>
        </UserLayout>
    )
}

export default HomePage
