import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const HomeContent: React.FC = () => {
  return (
    <React.Fragment>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-4'>
          <div className='home-action'>
            <img className='w-100' src='/media/action-img.jpg' />

            <div className='content-action p-10 bg-white'>
              <h2 className='mb-5'>Quick Order</h2>
              <p style={{height: 25}}>
                Place bulk orders for their desired products with just a few clicks
              </p>
              <div className='text-center mt-10'>
                {/* <a href='#' className='btn btn-primary'>
                  Place Order
                </a> */}
                <Link to='/quick-order' className='btn btn-primary'>
                  Place Order
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4 offset-1'>
          <div className='home-action' style={{ position: 'relative' }}>
            <div style={{ filter: 'grayscale(30%)', opacity: '0.5' }}>
              <img className='w-100' src='/media/action-img.jpg' />
              <div className='content-action p-10 bg-white'>
                <h2 className='mb-5'>Patio Builder</h2>
                <p style={{ height: 25 }}>Place Orders based on desired Patio Structure</p>
                <div className='text-center mt-10'>
                  <button className='btn btn-primary' disabled>
                    Go to Wizard
                  </button>
                </div>
              </div>
            </div>
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'blue',
              fontSize: '33px',
              zIndex: '2'
            }}>
              Coming Soon
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {HomeContent}
