import React from 'react'

interface IProps {}

export const CheckoutContext = React.createContext<IProps>({})

export const CheckoutContextProvider: React.FC = ({children}) => {
  const value = ''

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}
