import React, {Fragment, useState, useEffect, useContext} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { ORDER_LIST_PANELS } from '../../../../common/constant'

function NewPanelItem(props: any) {
  const {addToCart, panelMasterList, panelSetup} = useContext(QuickOrderContext)
  const {panelType, panels, updateHeader} = props

  const [panelItem, setPanelItem] = useState<any>({})
  const [panel, setPanel] = useState<any>('Select One')
  const [insulated, setInsulated] = useState<Boolean>(true)

  const handlePanelOnChange = (e: any) => {
    let panelId = e.target.value
    setPanel(+panelId)
    setPanelThicknessAndColourOption(+panelId, 0)
    // setPanelItem(panelSetup?.panelPricings.find((item: any) => item?.panelProfileId === +panelId))
    setPanelItem(panelMasterList.find((item: any) => item?.panelProfileId === +panelId))

    setPanelThickness(0)
    setTopSheetColor(0)
    setTopSheetColourName('Select Colour')
    setDirectionalLay('Select Directional Lay')
    setDirectionalLayId('Select Directional Lay')
    setCutback(0)
    setBottomSheetColor(0)
    setBottomSheetColorName('Select Colour')
    setQuantity('')
    setLength('')
    setUnitPrice(0)
    setPanelCore(0)
  }

  /**
   * GET PANEL CORE OPTIONS
   */
  const getPanelCoreOptions = (panelProfile: number) => {
    // const availPanelPricing = (panelSetup?.panelPricings || []).filter(
    //   (x: any) => x?.panelProfileId == panelProfile && x.panelCoreId !== 0
    // )
    const availPanelPricing = (panelMasterList || []).filter(
      (x: any) => x?.panelProfileId == panelProfile && x.panelCoreId !== 0
    )
    const pricingsSelection: any = Object.values(
      availPanelPricing?.reduce((map: any, obj: any) => {
        const key = `${obj?.panelCoreId}-${obj?.panelCoreName || ''}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )
    const result = (pricingsSelection || [])?.map((availPricing: any) => ({
      id: availPricing?.panelCoreId,
      name: availPricing?.panelCoreName || '',
    }))
    return result
  }

  /**
   * PANEL CORE
   */
  const [panelCore, setPanelCore] = useState(0)
  const [panelCoreName, setPanelCoreName] = useState('')
  const handlePanelCoreOnChange = (e: any) => {
    // Get Panel Details
    const {options, value} = e.target
    const selectedIndex = options.selectedIndex <= 0 ? 0 : options.selectedIndex - 1
    const name = getPanelCoreOptions(panel)[selectedIndex].name

    setPanelCore(+value)
    setPanelCoreName(name)
    setPanelThicknessAndColourOption(+panel, +value)

    // Reset Non-Pricing Fields
    setTopSheetColor(0)
    setDirectionalLay('Select Directional Lay')
    setCutback(0)
  }

  const setPanelThicknessAndColourOption = (panelProfileId: number, panelCore: number) => {
    // filter Thickness based on panel profile and selected panel core
    // const availPanelPricing = panelSetup?.panelPricings
    //   .filter((x: any) => x.panelProfileId == panelProfileId)
    //   .filter((item: any) => (panelCore > 0 ? item.panelCoreId === panelCore : 1 == 1))
    const availPanelPricing = panelMasterList
      .filter((x: any) => x.panelProfileId == panelProfileId)
      .filter((item: any) => (panelCore > 0 ? item.panelCoreId === panelCore : 1 == 1))

    if (!availPanelPricing) return

    const availableThickness = availPanelPricing
      ?.filter((x: any) => x.panelThicknessName !== null)
      .map((x: any) => ({
        id: x.panelThicknessId,
        name: x.panelThicknessName,
      }))
    // Avoid duplicate Thickness
    const distinctThickness: any = Object.values(
      availableThickness.reduce((map: any, obj: any) => {
        const key = `${obj.id}-${obj.name}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )

    const sortedThickness = distinctThickness.sort((a: any, b: any) => {
      // Extract the numeric part of the strings and convert them to numbers
      const numericA = parseFloat(a.name.replace('mm', ''))
      const numericB = parseFloat(b.name.replace('mm', ''))

      // Compare the numeric values
      return numericA - numericB
    })

    setPanelThicknessOptions(sortedThickness)
    setPanelThickness(0)

    // filter Panel Bottom based on panel profile and selected panel core
    const availColour = availPanelPricing
      ?.filter((x: any) => x.panelBottomColourName !== null)
      .map((x: any) => ({
        id: x.panelBottomColourId,
        name: x.panelBottomColourName,
      }))
    // Avoid duplicate Thickness
    const distinctBottomColour: any = Object.values(
      availColour.reduce((map: any, obj: any) => {
        const key = `${obj.id}-${obj.name}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )
    // const availColour = panelSetup?.panelBottomColours.filter((pb: any) =>
    //   availPanelPricing.some((ap: any) => ap.panelBottomColourId === pb.id)
    // )
    setPanelBottomOption(distinctBottomColour)
    setBottomSheetColor(0)

    roofColourOption(availPanelPricing)
  }

  /**
   * PANEl THICKNESS
   */
  const [panelThickness, setPanelThickness] = useState(0)
  const [panelThicknessName, setPanelThicknessName] = useState(0)
  const handlePanelThicknessOnChange = (e: any) => {
    // Get Panel Details
    const thickNessData: any = panelThicknessOption
    if (thickNessData) {
      const name = thickNessData[e.target.options.selectedIndex - 1].name
      setPanelThickness(+e.target.value)
      setPanelThicknessName(name)
    }
  }
  const [panelThicknessOption, setPanelThicknessOptions] = useState<[]>([])

  /**
   * TOP SHEET COLOR
   */

  const [topSheetColor, setTopSheetColor] = useState<number>(0)
  const [topSheetColourName, setTopSheetColourName] = useState<string>('Select Colour')
  const [colourOption, setColourOption] = useState<any[]>([])
  const handleColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
    setTopSheetColor(+e.target.value)
    setTopSheetColourName(name)
  }
  const roofColourOption = (availPanelPricing: any) => {
    // Roof colour (TOP SHEET COLOR)
    const availRoofColour = availPanelPricing
      ?.filter((x: any) => x.panelRoofColourName !== null)
      .map((x: any) => ({
        id: x.panelRoofColourId,
        name: x.panelRoofColourName,
      }))
    // Avoid duplicate Roof colour
    const distinctRoofColour: any = Object.values(
      availRoofColour.reduce((map: any, obj: any) => {
        const key = `${obj.id}-${obj.name}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )
    setColourOption(distinctRoofColour)
  }
  /**
   * DIRECTIONAL LAY
   */
  const [directionalLayId, setDirectionalLayId] = useState('Select Directional Lay')
  const [directionalLay, setDirectionalLay] = useState('Select Directional Lay')
  const handleDirectionalLayOnChange = (e: any) => {
    setDirectionalLayId(e.target.value)
    const selectedName = e.target.options[e.target.selectedIndex].innerHTML
    setDirectionalLay(selectedName)
  }
  /**
   * CUTBACK
   */
  const [cutback, setCutback] = useState(0)
  const [cutbackName, setCutbackName] = useState('')
  const handleCutbackOnChange = (e: any) => {
    const panelCutbackData: any = panelSetup?.panelCutbacks
    const name = panelCutbackData[e.target.options.selectedIndex - 1].name
    setCutback(e.target.value)
    setCutbackName(name)
  }
  /**
   * BOTTOM SHEET COLOR
   */
  const [bottomSheetColor, setBottomSheetColor] = useState(0)
  const [bottomSheetColorName, setBottomSheetColorName] = useState('Select Colour')
  const handleBottomSheetColorOnChange = (e: any) => {
    const name =
      e.target.options.selectedIndex == 0
        ? ''
        : panelBottomOption[e.target.options.selectedIndex - 1].name
    // const name = panelCutbackData[e.target.options.selectedIndex - 1].name
    setBottomSheetColor(+e.target.value)
    setBottomSheetColorName(name)
  }
  const [panelBottomOption, setPanelBottomOption] = useState<any[]>([])
  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<any>()
  const handleQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > 99 || inputNumber < 0) {
      return
    }
    setQuantity(inputNumber)
  }
  /**
   * LENGTH
   */
  const [length, setLength] = useState<any>('')
  /**
   * ERROR
   */
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [errorLength, setErrorLength] = useState<boolean>(false)
  const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])

  const [pricingId, setPricingId] = useState<number>(0)

  /**
   * UNIT PRICE
   */
  const [unitPrice, setUnitPrice] = useState<number>(0)

  useEffect(() => {
    if (
      bottomSheetColor > 0 &&
      panelCore > 0 &&
      panel > 0 &&
      panelThickness > 0 &&
      topSheetColor > 0
    ) {
      // const pricingResult = panelSetup?.panelPricings.find(
      //   (x: any) =>
      //     (x.panelCoreId || 0) == panelCore &&
      //     (x.panelProfileId || 0) == panel &&
      //     (x.panelBottomColourId || 0) == bottomSheetColor &&
      //     (x.panelThickness?.id || 0) == panelThickness
      // )
      const pricingResult = panelMasterList.find(
        (x: any) =>
          (x.panelCoreId || 0) == panelCore &&
          (x.panelProfileId || 0) == panel &&
          (x.panelBottomColourId || 0) == bottomSheetColor &&
          (x.panelThicknessId || 0) == panelThickness &&
          (x.panelRoofColourId || 0) == topSheetColor
      )

      if (pricingResult) {
        setUnitPrice(pricingResult.priceLevel1)
        setPricingId(pricingResult.id)
        setUnitTypeDisplay('per metre')
        setInsulated(pricingResult.insulated)
        if (pricingResult.insulated === true) {
          updateHeader('Insulated Roof Panels')
        } else {
          updateHeader('Non-Insulated Roof Panels')
        }
      }
    } else {
      setUnitPrice(0)
      setPricingId(0)
    }
  }, [panelCore, panel, panelThickness, bottomSheetColor, topSheetColor])

  const handleLengthFocusChange = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue === 0 || inputValue < 0) {
      setErrorLengthMsg('Value must not be less than 1800mm.')
      setErrorLength(true)
      return
    }
    if (inputValue < 1800) {
      setErrorLengthMsg('Value must not be less than 1800mm.')
      setErrorLength(true)
    } else if (inputValue > 23000) {
      setErrorLengthMsg('Value must not be greater than 23000mm.')
      setErrorLength(true)
      return
    } else {
      setErrorLengthMsg('')
      setErrorLength(false)
    }
    setLength(inputValue)
  }

  const handleLengthBlur = (e: any) => {
    setErrorLengthMsg('')
    setErrorLength(false)
  }

  const disabled =
    (panelCore === 0 && getPanelCoreOptions(panel).length > 0) ||
    (panelThickness === 0 && panelThicknessOption.length > 0) ||
    topSheetColor === 0 ||
    directionalLayId === 'Select Directional Lay' ||
    cutback === 0 ||
    bottomSheetColor === 0 ||
    length === '' ||
    error ||
    quantity === '' ||
    isNaN(quantity) ||
    quantity === 0 ||
    quantity > 99 ||
    quantity < 0 ||
    isNaN(length) ||
    length === 0 ||
    length < 0 ||
    panel === '' ||
    unitPrice === 0

  const isNotAppcble =
    (panelCore === 0 && getPanelCoreOptions(panel).length > 0) ||
    (panelThickness === 0 && panelThicknessOption.length > 0) ||
    topSheetColor === 0 ||
    directionalLayId === 'Select Directional Lay' ||
    cutback === 0 ||
    bottomSheetColor === 0 ||
    length === '' ||
    error ||
    quantity === '' ||
    isNaN(quantity)

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Panel Profile</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={panel}
            onChange={handlePanelOnChange}
          >
            <option value=''>Select One</option>
            {panels?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' />
        <div className='col-md-3' />
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 0,
                marginRight: 24,
              }}
            >
              <span>{moneyFormatter.format(panel !== '' ? unitPrice : 0)}</span>
            </p>
            <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
              {unitPrice ? unitTypeDisplay : ''}
            </p>
          </div>
          <button
            className='btn btn-primary w-100'
            onClick={() => {
              addToCart({
                panelType: panelItem?.panelProfileName,
                ...panelItem,
                pricingId,
                panelCore,
                panelCoreName,
                panelThickness,
                panelThicknessName,
                topSheetColor,
                topSheetColourName,
                directionalLayId,
                directionalLay,
                cutback,
                cutbackName,
                quantity,
                bottomSheetColor,
                bottomSheetColorName,
                length,
                customId: Math.random(),
                type: 'panel',
                unitPrice,
                uniqueID:
                  `panel-${panelType}-${panelCoreName}-${panelThicknessName}-${topSheetColor}-${directionalLay}-${cutbackName}-${bottomSheetColorName}-${length}-${quantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
                    'panel'
                  )}`.replace(/\s/g, ''),
                price: unitPrice,
                productType: ORDER_LIST_PANELS,
                productName: panelItem?.panelProfileName,
                description:  `${panelCoreName}, ${panelThicknessName}, ${directionalLay}, ${cutbackName}, ${topSheetColourName}, ${bottomSheetColorName}`,
              })
              setDirectionalLay('Select Directional Lay')
              setCutback(0)
              setDirectionalLayId('Select Directional Lay')
              setLength('')
              setQuantity('')
            }}
            disabled={disabled}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>
      {/* PANEL CORE */}
      <div className='row'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Panel Core</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={panelCore}
            onChange={handlePanelCoreOnChange}
            disabled={
              panel === 'Select One' || panel === '' || getPanelCoreOptions(panel).length === 0
            }
          >
            <option value={0}>Select Panel Core</option>
            {getPanelCoreOptions(panel)?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {/* TOP SHEET COLOR */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Top Sheet Colour</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select  my-4'
            aria-label='Colour'
            value={topSheetColor}
            onChange={handleColorOnChange}
            disabled={panel === 'Select One' || panel === ''}
          >
            <option value={0}>Select Top Sheet Colour</option>
            {colourOption?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {/* BOTTOM SHEET COLOR */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Bottom Sheet Colour</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            aria-label='Bottom Sheet Colour'
            value={bottomSheetColor}
            onChange={handleBottomSheetColorOnChange}
            disabled={panel === 'Select One' || panel === ''}
          >
            <option value={0}>Select Colour</option>
            {panelBottomOption?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-3'></div>
      </div>
      {/* PANEl THICKNESS */}
      <div className='row'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Panel Thickness</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={panelThickness}
            disabled={panel === 'Select One' || panel === '' || panelThicknessOption.length === 0}
            onChange={handlePanelThicknessOnChange}
          >
            <option value={0}>Select Panel Thickness</option>
            {panelThicknessOption?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {/* LENGTH */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Length (mm)</label>
          <input
            style={{
              fontSize: '1rem',
              borderColor: length < 1800 && length !== '' ? 'red' : '',
            }}
            placeholder='Enter'
            min='1800'
            step='1'
            max='23000'
            type='number'
            className='form-control  my-4'
            id='length'
            aria-describedby='length'
            value={length || ''}
            onFocus={handleLengthFocusChange}
            onBlur={handleLengthBlur}
            onChange={handleLengthFocusChange}
            disabled={panel === 'Select One' || panel === ''}
          />
          {errorLength ? (
            <div className='text-danger m-auto text-left' role='alert'>
              {errorLengthMsg}
            </div>
          ) : null}
        </div>
        {/* QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            max='99'
            step='1'
            className='form-control my-4'
            id='quantity'
            aria-describedby='quantity'
            value={quantity || ''}
            onChange={handleQuantityChange}
            placeholder='Enter'
            disabled={panel === 'Select One' || panel === ''}
          />
        </div>
        <div className='col-md-3'></div>
      </div>
      {/* DIRECTIONAL LAY */}
      <div className='row'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Directional Lay</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={directionalLayId}
            onChange={handleDirectionalLayOnChange}
            disabled={panel === 'Select One' || panel === ''}
          >
            <option value='Select Directional Lay'>Select Directional Lay</option>
            {panelSetup &&
              panelSetup.panelDirectionalLays?.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
      </div>
      {/* CUTBACK */}
      <div className='row'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Cutback</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={cutback}
            onChange={handleCutbackOnChange}
            disabled={panel === 'Select One' || panel === ''}
          >
            <option value='0'>Select Cutback</option>
            {panelSetup &&
              panelSetup.panelCutbacks?.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
        <div className='col-md-3'></div>
      </div>
    </Fragment>
  )
}

export default NewPanelItem
