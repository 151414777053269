import React, {useContext, useState, useEffect, Fragment} from 'react'
import {QuickOrderContext} from '../../../../../context/QuickOrderContext'
import {IColour} from '../../../../../models/colour'
import { 
  ORDER_TAB_POST_EXTENDABRACKET,
  HIDE_COLOUR_EXTENDEDBRACKET
} from '../../../../../common/constant'

function NewExtendaBracketItem(props: any) {
  const {isExtendaBracketChecked, setIsExtendaBracketChecked, 
    extendaBracketPostProduct, setExtendaBracketPostProduct, extendaBracketProductName, setExtendaBracketProductName,
    extendaBracketColor, setExtendaBracketColor, extendaBracketColorName, setExtendaBracketColorName,
    extendaBracketQuantity, setExtendaBracketQuantity, extendaBracketUnitPrice, setExtendaBracketUnitPrice,
    extenderLength, setExtenderLength, extenderLengthName, setExtenderLengthName, isExtenderLengthsHidden, setExtenderLengthsHidden
  } = props
  const {addToCart, postPricings, 
    postExtendaBracketProducts, getPostProductsAsync, postExtendaBracketColours, getPostColoursAsync, 
    postUnitPrice, getPostUnitPriceAsync, postExtendaBracketUnitPrice, setPostExtendaBracketUnitPrice,
    postExtendaBracketId, postExtenderLengths} = useContext(QuickOrderContext)

  useEffect(() => {
    if (!isExtendaBracketChecked) {
      setExtendaBracketColor('Select Colour')
      setExtendaBracketQuantity('')
      setExtendaBracketUnitPrice(0)
      setExtendaBracketPostProduct('')
      setExtenderLength('')
    }
  }, [isExtendaBracketChecked])

  const [isExtendedBracketColourHidden, setExtendedBracketColourHidden] = useState<boolean>(false)
  
  /**
   * POST TYPE
   */
  const handlePostProductOnChange = (e: any) => {
    setExtendaBracketPostProduct(e.target.value)
    const selectedName = e.target.options[e.target.selectedIndex].innerHTML
    setExtendedBracketColourHidden(false)
  }

  
  useEffect(() => {
    let selected = postExtendaBracketProducts?.find((p: any) => p.id.toString() == extendaBracketPostProduct)
    if (selected) {
      setExtendaBracketColor('Select Colour')
      setExtendaBracketColorName('')
      setExtendaBracketQuantity('')
      setExtendaBracketUnitPrice(0)
      setExtendaBracketProductName(selected.name)

      if(HIDE_COLOUR_EXTENDEDBRACKET.includes(selected.name)){
        setExtendaBracketColor('-')
        setExtendedBracketColourHidden(true)
      }
      else{
        setExtendedBracketColourHidden(false)
      }

      const strName = selected.name as string

      if (strName.toLowerCase().includes(ORDER_TAB_POST_EXTENDABRACKET.replace('s', '').toLowerCase())) {
        setExtenderLengthsHidden(false)
        setExtenderLength('')
      }
      else {
        setExtenderLengthsHidden(true)
      }
    } else {
      setExtendaBracketColor('Select Colour')
      setExtendaBracketQuantity('')
      setExtendaBracketUnitPrice(0)
      setExtendedBracketColourHidden(false)
      setExtenderLength('')
    }
  }, [extendaBracketPostProduct])

  /**
   * COLOR
   */

  const handleColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    setExtendaBracketColor(e.target.value)
    if (selectedIndex === 0 && !isExtendedBracketColourHidden) {
      setExtendaBracketUnitPrice(0)
    }
  }

  useEffect(() => {
    let selected = postExtendaBracketColours?.find((b: IColour) => b.id.toString() == extendaBracketColor)
    if (selected) {
      setExtendaBracketColorName(extendaBracketColor === 'Select Colour' ? '' : selected.name)
    }
  }, [extendaBracketColor])

  /**
   * QUANTITY
   */
  const handleQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > 99 || inputNumber < 0) {
      return
    }
    setExtendaBracketQuantity(inputNumber)
  }

  const handleExtenderLengthOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    setExtenderLength(e.target.value)
    if (selectedIndex === 0 && !isExtenderLengthsHidden) {
      setExtendaBracketUnitPrice(0)
    }
  }

  useEffect(() => {
    let selected = postExtenderLengths?.find((b: IColour) => b.id.toString() == extenderLength)
    if (selected) {
      setExtenderLengthName(extenderLength === '' ? '' : selected.name)
    }
  }, [extenderLength])

  useEffect(() => {
    setExtendaBracketUnitPrice(postExtendaBracketUnitPrice ? postExtendaBracketUnitPrice : 0)
  }, [postExtendaBracketUnitPrice])

  useEffect(() => {
    
    const getPostUnitPrice = async () => {
      if (extendaBracketPostProduct && isExtendedBracketColourHidden){
        await getPostUnitPriceAsync(postExtendaBracketId, extendaBracketPostProduct, extendaBracketColor, 0, extenderLength)
      }
      else if (extendaBracketPostProduct && !isExtendedBracketColourHidden && Number(extendaBracketColor) > 0) {
        await getPostUnitPriceAsync(postExtendaBracketId, extendaBracketPostProduct, extendaBracketColor, 0, extenderLength)
      }
      else {
        setPostExtendaBracketUnitPrice(0)
      }
    }
    getPostUnitPrice()
  }, [isExtendedBracketColourHidden, extendaBracketPostProduct, extendaBracketColor, extenderLength ])

  /**
   * ERROR
   */
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])

  const handleExtendaBracketCheckboxChange = (event: any) => {
    if (event.target.checked) {
        setIsExtendaBracketChecked(true)
    } else {
        setIsExtendaBracketChecked(false)
    }
  }

  return (
    <Fragment>
      <div className='row mb-4 mt-10'>
        <div className='col-md-3'>
            <input
            className='form-check-input'
            type='checkbox'
            id='checkExtendaBracket'
            name='checkExtendaBracket'
            onChange={handleExtendaBracketCheckboxChange}
            checked={isExtendaBracketChecked}
            style={{marginRight: 10}}
            />
            <label className='fw-bolder text-dark'>{ORDER_TAB_POST_EXTENDABRACKET}</label>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Type</label>
          <select
            style={{ fontSize: '1rem' }}
            className='form-select my-4'
            value={extendaBracketPostProduct}
            onChange={handlePostProductOnChange}
            disabled={!isExtendaBracketChecked}
          >
            <option value=''>Select</option>
            {postExtendaBracketProducts?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
        {/*  QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            className='form-control my-4'
            min='1'
            max='99'
            step='1'
            pattern='\d+'
            value={extendaBracketQuantity || ''}
            onChange={handleQuantityChange}
            disabled={!isExtendaBracketChecked}
            placeholder='Enter Quantity'
          />
        </div>
      </div>
      <div className='row'>
        { !isExtendedBracketColourHidden && (
          <>
            {/* COLOUR */}
            <div className='col-md-3'>
              <label className='fw-bolder text-dark'>Colour</label>
              <select
                style={{fontSize: '1rem'}}
                className='form-select my-4'
                value={extendaBracketColor}
                onChange={handleColorOnChange}
                disabled={!isExtendaBracketChecked}
              >
                <option value=''>Select</option>
                {postExtendaBracketColours?.map((post: any) => (
                  <option key={post.id} value={post.id}>
                    {post.name}
                  </option>
                ))}
              </select>
            </div>
          </>
          )
        }
        {!isExtenderLengthsHidden && (
          <>
            {/* Extender Length */}
            <div className='col-md-3'>
              <label className='fw-bolder text-dark'>Extender Length</label>
              <select
                style={{fontSize: '1rem'}}
                className='form-select my-4'
                value={extenderLength}
                onChange={handleExtenderLengthOnChange}
                disabled={!isExtendaBracketChecked}
              >
                <option value=''>Select</option>
                {postExtenderLengths?.map((post: any) => (
                  <option key={post.id} value={post.id}>
                    {post.name}
                  </option>
                ))}
              </select>
            </div>
          </>
          )
        }
      </div> 
    </Fragment>
  )
}

export default NewExtendaBracketItem
