import React from 'react'
import { Modal, Button } from 'react-bootstrap-v5'
import Dropdown from './Dropdown'

interface Field {
  label: string
  key: string
  type: string //'text' / 'dropdown'
  options?: { id: number; name: string }[]
  optional?: boolean
}

interface NewProduct {
  [key: string]: string | null
}

interface NewProductModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (newProduct: NewProduct) => void
  fields: Field[]
  initialValues?: NewProduct
}

const NewProductModal: React.FC<NewProductModalProps> = ({ isOpen, onClose, onSave, fields, initialValues  }) => {
  const [newProduct, setNewProduct] = React.useState<NewProduct>(
    fields?.reduce((acc, field) => {
      acc[field.key] = field.type === 'dropdown' ? '' : null
      return acc
    }, {} as NewProduct) || {}
  )

  const [validationErrors, setValidationErrors] = React.useState<{ [key: string]: string }>({})

  const isUpdate = initialValues !== null && initialValues !== undefined

  React.useEffect(() => {
    if (isOpen && initialValues) {
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        ...fields.reduce((acc, field) => {
          if (field.type === 'dropdown' && initialValues[`${field.key}Id`]) {
            // Use the existing id value for dropdown fields
            acc[field.key] = initialValues[`${field.key}Id`]
          } else {
            acc[field.key] = initialValues[field.key] || null
          }
          return acc
        }, {} as NewProduct),
      }))
    }
  }, [isOpen, initialValues, fields])

  const validateFields = () => {
    const errors: { [key: string]: string } = {}
    fields.forEach((field) => {
      if (field.type === 'text') {
        const value = newProduct[field.key] || ''
        if (value.trim() === '' && !field.optional) {
          errors[field.key] = 'This field cannot be blank'
        } else if (value.length > 99) {
          errors[field.key] = 'Maximum length is 99 characters'
        } else if (!/^[a-zA-Z0-9 .&'\/\\-]*$/.test(value)) {
          errors[field.key] = "Only alphanumeric characters, '.', '-', '&', '/', and '\\' are allowed"
        }
      }
    })
    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }
  
  const handleSave = () => {
    if (validateFields()) {
      onSave(newProduct)
      onClose()
    }
  }

  const handleFieldChange = (key: string, value: string) => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [key]: value,
    }))
  }

  React.useEffect(() => {
    if (!isOpen) {
      setNewProduct(
        fields.reduce((acc, field) => {
          acc[field.key] = field.type === 'dropdown' && field.options ? '' : null
          return acc
        }, {} as NewProduct)
      )
    }
  }, [isOpen, fields])

  return (
    <Modal show={isOpen} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>{isUpdate ? 'Update Product' : 'Add Product'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        {fields.map((field, index) => (
          <div
            key={field.key}
            className="col-12"
            style={{
              marginBottom: index === 0 ? '0' : '10px',
            }}
          >
            <label style={{ display: 'block', textTransform: 'uppercase', fontWeight: 'bold' }}>
              {field.label}
            </label>
            {field.type === 'dropdown' && field.options ? (
              <Dropdown
                options={field.options}
                value={newProduct[field.key] as string}
                onChange={(value) => handleFieldChange(field.key, value)}
              />
            ) : (
              <>
                <input
                  type="text"
                  className='form-control'
                  value={newProduct[field.key] || ''}
                  onChange={(e) => handleFieldChange(field.key, e.target.value)}
                  style={{ width: '100%', border: '1px solid #bbb' }}
                />
                {validationErrors[field.key] && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {validationErrors[field.key]}
                  </div>
                )}
              </>
            )}
            {index === 0 && <hr />}
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer style={{ justifyContent: 'center' }}>
      <Button
        variant="primary"
        onClick={handleSave}
        disabled={fields.some((field) => {
          const value = newProduct[field.key] || ''
          return !field.optional && field.type === 'text' && value.trim() === ''
        })}
      >
        <i className='fa fa-save text-dark'></i> SAVE
      </Button>

      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default NewProductModal
