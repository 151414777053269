import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {AdminPortalLayout} from './AdminPortalLayout'
import PostCodeMatrix from './pages/LookUpTables/PostCodeMatrix/PostCodeMatrix'
import Product from './pages/Products/Products'
import ProductDetailsPage from './pages/Products/ProductDetailsPage'
import {useHistory} from 'react-router-dom'
import {AdminContextProvider} from '../../context/AdminContext'
import { AdminOrderSummaryContextProvider } from '../../context/AdminOrderSummaryContext'

export function AdminRoutes() {
  const PriceBook = lazy<React.FC>(() => import('./pages/PriceBooks/PriceBooks'))
  const OrderNumber = lazy<React.FC>(() => import('./pages/OrderNumberManager/OrderNumberManager'))
  const LookUpTable = lazy<React.FC>(() => import('./pages/LookUpTables/LookUpTables'))
  const Regions = lazy<React.FC>(() => import('./pages/LookUpTables/Regions/Regions'))
  const Orders = lazy<React.FC>(() => import('./pages/Orders/Orders'))
  const Colours = lazy<React.FC>(() => import('./pages/Colours/Colours'))
  const OrderDetails = lazy<React.FC>(
    () => import('./pages/Orders/OrderDetailsPage/OrderDetailsPage')
  )
  const TopographicalClassificationMatrix = lazy<React.FC>(
    () =>
      import(
        './pages/LookUpTables/TopographicalClassificationMatrix/TopographicalClassificationMatrix'
      )
  )
  const WindClassification = lazy<React.FC>(
    () => import('./pages/LookUpTables/WindClassification/WindClassification')
  )
  const SpanMatrix = lazy<React.FC>(() => import('./pages/LookUpTables/SpanMatrix/SpanMatrix'))
  const LoadCapacityMatrix = lazy<React.FC>(
    () => import('./pages/LookUpTables/LoadCapacityMatrix/LoadCapacityMatrix')
  )
  const BeamUpliftLoadsMatrix = lazy<React.FC>(
    () => import('./pages/LookUpTables/BeamUpliftLoadsMatrix/BeamUpliftLoadsMatrix')
  )

  const history = useHistory()

  return (
    <AdminPortalLayout>
      <Suspense fallback={<FallbackView />}>
        <AdminContextProvider>
          <Switch>
            <Route exact path='/admin/orders' render={() => <Orders />} />
            <Route exact path='/admin/orders/:id' render={() => 
              <AdminOrderSummaryContextProvider>
                <OrderDetails />
              </AdminOrderSummaryContextProvider>
            } />
            {/* <Route exact path='/admin/price-books' render={() => <PriceBook />} /> */}
            <Route exact path='/admin/order-number-manager' render={() => <OrderNumber />} />
            <Route exact path='/admin/lookup-tables' render={() => <LookUpTable />} />
            <Route exact path='/admin/products' render={() => <Product />} />
            <Route
              exact
              path='/admin/product-details/:productType/:productId'
              render={(props) => (
                <ProductDetailsPage
                  productType={props.match.params.productType}
                  productId={props.match.params.productId}
                  onClose={() => history.push('/admin/products')}
                />
              )}
            />
            <Route
              exact
              path='/admin/lookup-tables/post-code-matrix'
              render={() => <PostCodeMatrix />}
            />
            <Route exact path='/admin/lookup-tables/regions' render={() => <Regions />} />
            <Route
              exact
              path='/admin/lookup-tables/topographical-classification-matrix'
              render={() => <TopographicalClassificationMatrix />}
            />
            <Route
              exact
              path='/admin/lookup-tables/wind-classification'
              render={() => <WindClassification />}
            />
            <Route exact path='/admin/lookup-tables/span-matrix' render={() => <SpanMatrix />} />
            <Route
              exact
              path='/admin/lookup-tables/load-capacity-matrix'
              render={() => <LoadCapacityMatrix />}
            />
            <Route
              exact
              path='/admin/lookup-tables/beam-uplift-loads-matrix'
              render={() => <BeamUpliftLoadsMatrix />}
            />
            <Route
              exact
              path='/admin/colours'
              render={() => <Colours />}
            />
            <Redirect to='/admin' />
          </Switch>
        </AdminContextProvider>
      </Suspense>
    </AdminPortalLayout>
  )
}
