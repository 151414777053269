import axios from "axios"

export const PRICEBOOK_URL = `${process.env.REACT_APP_API_URL}/priceBooks`

export const getPriceBooks = async () => {
    try {
      return await axios.get(`${PRICEBOOK_URL}`)
    } catch (err) {
      return err
    }
  }
  
  