export const PRODUCT_TYPE_PANEL = 'Panel'
export const PRODUCT_TYPE_BEAM = 'Beam'
export const PRODUCT_TYPE_FIXING = 'Fixing'
export const PRODUCT_TYPE_FLASHING = 'Flashing'
export const PRODUCT_TYPE_LIGHT = 'Light'
export const PRODUCT_TYPE_POST = 'Post'
export const PRODUCT_TYPE_RAINWATER = 'Rainwater'
export const PRODUCT_TYPE_ACCESSORY = 'Accessory'

export const PRODUCT_IMPORT_TYPE_PANEL = 'panel'
export const PRODUCT_IMPORT_TYPE_BEAM = 'beams'
export const PRODUCT_IMPORT_TYPE_FIXING = 'fixings'
export const PRODUCT_IMPORT_TYPE_FLASHING = 'flashings'
export const PRODUCT_IMPORT_TYPE_LIGHT = 'lights'
export const PRODUCT_IMPORT_TYPE_POST = 'posts'
export const PRODUCT_IMPORT_TYPE_RAINWATER = 'rainwaters'
export const PRODUCT_IMPORT_TYPE_ACCESSORY = 'accessories'

export const ORDER_TAB_TYPE_SHIPPINGPREFERENCE = 'ShippingPreference';
export const ORDER_TAB_TYPE_PANELS = 'Panels';
export const ORDER_TAB_TYPE_FIXINGS = 'Fixings';
export const ORDER_TAB_TYPE_FLASHINGS = 'Flashings';
export const ORDER_TAB_TYPE_RAINWATERGOODS = 'RainwaterGoods';
export const ORDER_TAB_TYPE_BEAMS = 'Beams';
export const ORDER_TAB_TYPE_POSTS = 'Posts';
export const ORDER_TAB_TYPE_LIGHTS = 'Lights';
export const ORDER_TAB_TYPE_ACCESSORIES = 'Accessories';

export const ORDER_TAB_NAME_SHIPPINGPREFERENCE = 'Shipping Preference';
export const ORDER_TAB_NAME_PANELS = 'Panels';
export const ORDER_TAB_NAME_FIXINGS = 'Fixings';
export const ORDER_TAB_NAME_FLASHINGS = 'Flashings';
export const ORDER_TAB_NAME_RAINWATERGOODS = 'Rainwater Goods';
export const ORDER_TAB_NAME_BEAMS = 'Beams';
export const ORDER_TAB_NAME_POSTS = 'Posts';
export const ORDER_TAB_NAME_LIGHTS = 'Lights';
export const ORDER_TAB_NAME_ACCESSORIES = 'Accessories';

export const ORDER_TAB_RAINWATER_STOPEND = 'Gutter Stop End'
export const ORDER_TAB_RAINWATER_STOPENDBRACKET_DROPPER = 'Gutter Dropper'
export const ORDER_TAB_RAINWATER_STOPENDBRACKET_OVERSTRAP = 'Gutter Overstrap'

export const ORDER_TAB_STEEL_BEAM = 'Steel Beam';
export const ORDER_TAB_BEAM_END_CAP = 'Beam End Cap';
export const ORDER_TAB_STEEL_BEAM_END_CAP = 'Steel Beam End Caps';
export const ORDER_TAB_ALUMINIUM_BEAM_END_CAP = 'Aluminium Beam End Caps';
export const ORDER_TAB_BEAM_JOINER = 'Beam Joiner';
export const ORDER_TAB_BEAM_SPACER = 'Beam Spacer';
export const ORDER_TAB_BEAM_FASCIA_HANGING = 'Fascia Hanging';

export const ORDER_TAB_POSTS = 'Post';
export const ORDER_TAB_POSTS_KIT = 'Post Kit';
export const ORDER_TAB_POSTS_EXTENDA_BRACKET = 'Extender Bracket';

export const ORDER_TAB_POSTS_TYPE = 'post-item';
export const ORDER_TAB_POSTS_KIT_TYPE = 'post-kit';
export const ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE = 'post-extendabracket';

export const FIXINGS_CYCLONE_PLATES = 'Cyclone Plates';
export const FIXINGS_RIVETS = 'Rivets';
export const FIXINGS_TEK_SCREWS = 'Tek Screws';

export const ORDER_TAB_LIGHTS_DELTASKYLIGHT = 'DeltaSkyLight';
export const ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT = 'DeltaLowProfile Light';
export const ORDER_TAB_LIGHTS_170MM_X_1_LIGHT_PER_BOX = '170mm x 1 light per box';

export const ORDER_SUMMARY_SECTION_PANELS = 'Panels';
export const ORDER_SUMMARY_SECTION_FIXINGS = 'Fixings';
export const ORDER_SUMMARY_SECTION_FLASHINGS = 'Flashings';
export const ORDER_SUMMARY_SECTION_RAINWATERGOODS = 'Rainwater Goods';
export const ORDER_SUMMARY_SECTION_BEAMS = 'Beams';
export const ORDER_SUMMARY_SECTION_POSTS = 'Posts';
export const ORDER_SUMMARY_SECTION_LIGHTS = 'Lights';
export const ORDER_SUMMARY_SECTION_ACCESSORIES = 'Accessories';

export const ACCESSORY_PART_ACCESSORIES = 'Accessories';

export const ORDER_TAB_POST_EXTENDABRACKET = 'Extenda Brackets'

export const ORDER_LIST_PANELS = 'PANELS';
export const ORDER_LIST_FIXINGS = 'FIXINGS';
export const ORDER_LIST_FLASHINGS = 'FLASHINGS';
export const ORDER_LIST_RAINWATERGOODS = 'RAINWATER GOODS';
export const ORDER_LIST_BEAMS = 'BEAMS';
export const ORDER_LIST_POSTS = 'POSTS';
export const ORDER_LIST_LIGHTS = 'LIGHTS';
export const ORDER_LIST_ACCESSORIES = 'ACCESSORIES';



export enum ProductType {
  Flashings = 3,
  Beams = 5,
}

export enum BeamProducts {
  SteelBeamEndCap = 'Steel Beam End Cap',
  AluminiumBeamEndCap = 'Aluminium Beam End Cap',
  BeamSpacer = 'Beam Spacer',
  BeamJoiner = 'Beam Joiner',
}

export enum BeamUnitPriceFor {
  BeamFasciaHanging = 0
}

export const HIDE_COLOUR_EXTENDEDBRACKET = [] as string[];
HIDE_COLOUR_EXTENDEDBRACKET.push('Roof Extenda S300', 'Roof Extenda S400', 'Roof Extenda S500')