import axios from 'axios'
export const POSTS_URL = `${process.env.REACT_APP_API_URL}/PostPricings`
export const POSTTYPES_URL = `${process.env.REACT_APP_API_URL}/PostTypes`
export const POSTPRODUCTS_URL = `${process.env.REACT_APP_API_URL}/PostProducts`
export const POSTFINISHES_URL = `${process.env.REACT_APP_API_URL}/PostFinishes`
export const POSTCATEGORY_URL = `${process.env.REACT_APP_API_URL}/postcategories`
export const POSTUNITTYPE_URL = `${process.env.REACT_APP_API_URL}/postunittypes`

export const POSTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PostMasterlist`

export const POSTQUICKORDER_URL = `${process.env.REACT_APP_API_URL}/PostQuickOrder`
export const POSTADDPROD_URL = `${process.env.REACT_APP_API_URL}/OrderDetails`

export const getPostProductDetails = async (
  postTypeId: number, 
  beamSizeId: number, 
  colourId?: number) => {
  try {
      const response = await axios.get(`${POSTADDPROD_URL}/getProductPostUnitPrice`, {
        params: {
          postTypeId,
          beamSizeId,
          colourId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllProduct = async () => {
  try {
      const response = await axios.get(`${POSTMASTERLIST_URL}/getAllPostProduct`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getPostMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  PostProductName?: string,
  PostTypeName?: string,
  BeamSizeName?: string,
  extenderLengthName?: string,
  ColourName?: string
) => {
  try {
    return await axios.get(`${POSTMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        PostProductName,
        PostTypeName,
        BeamSizeName,
        extenderLengthName,
        ColourName
      },
    })
  } catch (err) {
    return err
  }
}

export const createPostProduct = async (payload: any) => {
  try {
    return await axios.post(`${POSTMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updatePostProduct = async (payload: any) => {
  try {
    return await axios.patch(`${POSTMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deletePostProduct = async (id: any) => {
  try {
    return await axios.delete(`${POSTMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

interface PostsProducts {
  id: number
  postName: string
  panelType: string
  thickness: string
  colour: string
}

export const getPostPricingPaginated = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${POSTS_URL}/Posts/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getPostKitPricingPaginated = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${POSTS_URL}/PostKits/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getPostPricingList = async (priceBookid?: number) => {
  try {
    return await axios.get(`${POSTS_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const createPostPricing = async (payload: any) => {
  try {
    return await axios.post(`${POSTS_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updatePostPricing = async (payload: any) => {
  try {
    return await axios.patch(`${POSTS_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deletePostPricing = async (id: any) => {
  try {
    return await axios.delete(`${POSTS_URL}?id=${id}`)
  } catch (err) {
    return err
  }
}

export const getPostTypes = async () => {
  try {
    return await axios.get(`${POSTTYPES_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostTypesPM = async (postProductId: any) => {
  try {
    return await axios.get(`${POSTQUICKORDER_URL}/getAllPostTypes?PostProductId=${postProductId}`)
  } catch (err) {
    return []
  }
}

export const getBeamSizesPM = async (postProductId: any) => {
  try {
    return await axios.get(`${POSTQUICKORDER_URL}/getAllBeamSizes?PostProductId=${postProductId}`)
  } catch (err) {
    return []
  }
}

export const getAllPostColoursPM = async (postProductId: any) => {
  try {
    return await axios.get(`${POSTQUICKORDER_URL}/getAllColours?PostProductId=${postProductId}`)
  } catch (err) {
    return []
  }
}

export const getPostExtenderLengths = async () => {
  try {
    return await axios.get(`${POSTQUICKORDER_URL}/getAllExtenderLengths`)
  } catch (err) {
    return []
  }
}

export const getPostUnitPrice = async (
  postProductId: number,
  postTypeId: number,
  colourId: number,
  beamSizeId: number,
  extenderLengthId: number = 0
) => {
  try {
    console.log('extenderLengthId', extenderLengthId)
      const response = await axios.get(`${POSTQUICKORDER_URL}/getUnitPrice`, {
        params: {
          postProductId,
          postTypeId,
          colourId,
          beamSizeId,
          extenderLengthId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getPostProducts = async () => {
  try {
    return await axios.get(`${POSTPRODUCTS_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostFinishes = async () => {
  try {
    return await axios.get(`${POSTFINISHES_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostCategories = async () => {
  try {
    return await axios.get(`${POSTCATEGORY_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostUnitTypes = async () => {
  try {
    return await axios.get(`${POSTUNITTYPE_URL}`)
  } catch (err) {
    return []
  }
}
