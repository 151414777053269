/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.ADMINISTRATION'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/user'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      />
      <AsideMenuItem
        to='/role'
        title={intl.formatMessage({id: 'MENU.ROLES'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      />
      <AsideMenuItem
        to='/settings'
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
