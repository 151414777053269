import axios from 'axios'
import { ACCESSORY_PART_ACCESSORIES } from '../common/constant'
export const ACCESSORY_PRICING_URL = `${process.env.REACT_APP_API_URL}/accessorypricings`
export const ACCESSORY_CATEGORIES_URL = `${process.env.REACT_APP_API_URL}/accessorycategories`
export const ACCESSORY_PARTS_URL = `${process.env.REACT_APP_API_URL}/accessoryparts`
export const ACCESSORY_STYLES_URL = `${process.env.REACT_APP_API_URL}/accessorystyles`
export const ACCESSORY_UNIT_TYPES_URL = `${process.env.REACT_APP_API_URL}/accessoryunittypes`

export const ACCESSORYMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/AccessoryMasterlist`

interface AccessoryProducts {
  id: number
  productName: string
  gutterType: string
  colour: string
}

export const getAllProduct = async (priceBookid?: number) => {
  try {
      const response = await axios.get(`${ACCESSORYMASTERLIST_URL}/getAllAccessoryProduct`, {
        params: {
          priceBookid
        }
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAccessoryMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  AccessoryPartName?: string,
  AccessoryStyleName?: string,
  AccessoryColourName?: string
) => {
  try {
    return await axios.get(`${ACCESSORYMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        AccessoryPartName,
        AccessoryStyleName,
        AccessoryColourName
      },
    })
  } catch (err) {
    return err
  }
}

export const createAccessoryProduct = async (payload: any) => {
  try {
    return await axios.post(`${ACCESSORYMASTERLIST_URL}`, {...payload, accessoryPartName: ACCESSORY_PART_ACCESSORIES})
  } catch (err) {
    return err
  }
}

export const updateAccessoryProduct = async (payload: any) => {
  try {
    return await axios.patch(`${ACCESSORYMASTERLIST_URL}`, {...payload, accessoryPartName: ACCESSORY_PART_ACCESSORIES})
  } catch (err) {
    return err
  }
}

export const deleteAccessoryProduct = async (id: any) => {
  try {
    return await axios.delete(`${ACCESSORYMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getAccessoryPricings = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${ACCESSORY_PRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const createAccessoryPricing = async (payload: any) => {
  try {
    return await axios.post(`${ACCESSORY_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateAccessoryPricing = async (payload: any) => {
  try {
    return await axios.patch(`${ACCESSORY_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteAccessoryPricing = async (id: any) => {
  try {
    return await axios.delete(`${ACCESSORY_PRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getAccessoryCategories = async () => {
  try {
    return await axios.get(`${ACCESSORY_CATEGORIES_URL}`)
  } catch (err) {
    return []
  }
}

export const getAccessoryParts = async () => {
  try {
    return await axios.get(`${ACCESSORY_PARTS_URL}`)
  } catch (err) {
    return []
  }
}

export const getAccessoryStyles = async () => {
  try {
    return await axios.get(`${ACCESSORY_STYLES_URL}`)
  } catch (err) {
    return []
  }
}

export const getAccessoryUnitTypes = async () => {
    try {
        return await axios.get(`${ACCESSORY_UNIT_TYPES_URL}`)
    } catch (err) {
        return []
    }
}

