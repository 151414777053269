import React, { Fragment, useContext, useState, useEffect } from 'react'
import { moneyFormatter } from '../../../../../../utils'
import { QuickOrderContext } from '../../../../../context/QuickOrderContext'

function NewGutterItem(props: any) {
    const { items, categoryName, setSelectedGutterItem, setSelectedGutterItemForStopEndBracket, setColourId,
        gutterItem, setGutterItem, newGutterPricingId, setNewGutterPricingId, colorName, setColorName, color, setColor,
        gutter, setGutter, quantity, setQuantity, length, setLength, price, setPrice, 
        isGutterChecked, setIsGutterChecked, disabled, handleGutterStopendBracketAddToCart } = props
    const { addToCart, cartItems } = useContext(QuickOrderContext)
    const maxQuantity = 1000000

    const storedShippingPreference = JSON.parse(localStorage.getItem('shipping-preference') || '{}')
    const priceBookId: number | undefined = parseInt(storedShippingPreference.pricebookId)

    
    const pricings = props.pricings?.filter((item: any) => item?.pricebookId === priceBookId)
    const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

    const handleGutterOnChange = (e: any) => {
        let gutterId = +e.target.value
        setGutter(gutterId)
        loadColourOption(gutterId)
        setGutterItem({})

        // setLength('')
        // props.setGutterBracketQuantity('')
        // props.setStopEndQuantity('')
        // props.setIsGutterBracketChecked(false)
        // props.setIsStopEndChecked(false)

        // let pricing = pricings.find((item: any) => item?.id === +gutterId)
        // if (pricing !== undefined) {
        //     // setUnitTypeDisplay(pricing?.unitTypeName)
        //     setUnitTypeDisplay('Per meter')
        // }
        // else {
        //     setUnitTypeDisplay('')
        // }
    }

    const [gutterOption, setGutterOption] = useState<[]>([])
    useEffect(() => {
        if (items) {
            const rainWaterSelection: any = Object.values(
                items.reduce((map: any, obj: any) => {
                    const key = `${obj.rainwaterStyleId}-${obj.rainwaterStyleName}`
                    if (!map[key] && obj.rainwaterStyleId) {
                    map[key] = obj
                    }
                    return map
                }, {} as Record<string, any>)
            )
            const result = (rainWaterSelection || [])?.map((availPricing: any) => ({
                id: availPricing.rainwaterStyleId,
                name: availPricing.rainwaterStyleName,
            }))
    
            setGutterOption(result || [])
        }
    },[items])

    // useEffect(() => {
    //     setSelectedGutterItem(gutterItem)
    // }, [gutterItem])

    // useEffect(() => {
    //     if (Object.keys(gutterItem || {}).length !== 0) {
    //         setPrice(gutterItem?.price)
    //         setPricingId(gutterItem?.id)
    //     }
    // }, [gutter])

    /**
     * COLOR
     */
    // useEffect(() => {
    //     if (colours) {
    //         setColourOption(colours)
    //     }
    // }, [colours])

    const [colourOption, setColourOption] = useState<any[]>([])
    const handleColorOnChange = (e: any) => {
        const selectedIndex = e.target.options.selectedIndex
        const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
        setColourId(+e.target.value)
        setColor(+e.target.value)
        setColorName(name)
    }

   const loadColourOption = (rainwaterStyleId: number) => {
        const availableColour = items.filter((item: any) => 
            item.rainwaterStyleId == rainwaterStyleId
        )
        
        const colourSelection: any = Object.values(
            availableColour.reduce((map: any, obj: any) => {
                const key = `${obj.rainwaterColourId}-${obj.rainwaterColourName}`
                if (!map[key]) {
                map[key] = obj
                }
                return map
            }, {} as Record<string, any>)
        )

        const result = (colourSelection || [])?.map((availPricing: any) => ({
            id: availPricing.rainwaterColourId,
            name: availPricing.rainwaterColourName,
        }))

            // default selection for colour
        const existsDataColour = result.filter((item1: any) =>
            cartItems.some(
                (x: any) =>
                    x.type === 'panel' && item1.name != null &&
                    x.topSheetColourName.toLowerCase().includes(item1.name.toLowerCase())
            )
        )
        if (existsDataColour) {
            if (existsDataColour && existsDataColour.length === 1) {
                const defaultColour = existsDataColour[0]
                setColourId(defaultColour.id)
                setColor(defaultColour.id)
                setColorName(defaultColour.name)
            } else {
                setColor(0)
                setColourId(0)
            }
        } else {
            setColor(0)
            setColourId(0)
        }


        setColourOption(result)
   }

   
    /**
     * UNITPRICE
     */

    useEffect(() => {
        if (color > 0 && gutter > 0) {
            const pricing = items.find((item: any) => 
                item.rainwaterPartName.toUpperCase() === categoryName.toUpperCase() &&
                item.rainwaterStyleId == gutter &&
                item.rainwaterColourId == color
            )
            if (pricing) {
                setNewGutterPricingId(pricing.id)
                setPrice(pricing.priceLevel1)

                setUnitTypeDisplay('Per meter')
                setLength('')
                setQuantity(0)
                props.setGutterBracketQuantity('')
                props.setStopEndQuantity('')
                props.setIsGutterBracketChecked(false)
                props.setIsStopEndChecked(false)

                setGutterItem(pricing)
                setSelectedGutterItem(pricing)

            } else {
                setNewGutterPricingId(0)
                setPrice(0)
                setUnitTypeDisplay('')
                setGutterItem({})
                setSelectedGutterItem({})
            }
        } else {
            setNewGutterPricingId(0)
            setPrice(0)
            setUnitTypeDisplay('')
            setSelectedGutterItem({})
        }
    }, [color, gutter])

    useEffect(() => {
        if (gutter > 0) {
            const pricing = items.find((item: any) => 
                item.rainwaterPartName.toUpperCase().includes(categoryName.toUpperCase()) &&
                item.rainwaterStyleId == gutter
            )
            if (pricing) {
                
                setNewGutterPricingId(pricing.id)
                setPrice(pricing.priceLevel1)

                setUnitTypeDisplay('Per meter')
                setLength('')
                setQuantity(0)
                props.setGutterBracketQuantity('')
                props.setStopEndQuantity('')
                props.setIsGutterBracketChecked(false)
                props.setIsStopEndChecked(false)

                setSelectedGutterItemForStopEndBracket(pricing)

            } else {
                setNewGutterPricingId(0)
                setPrice(0)
                setUnitTypeDisplay('')
                setGutterItem({})
                setSelectedGutterItemForStopEndBracket({})
            }
        } else {
            setNewGutterPricingId(0)
            setPrice(0)
            setUnitTypeDisplay('')
            setSelectedGutterItemForStopEndBracket({})
        }
    }, [gutter])

    /**
     * ERROR
     */
    const [error, setError] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [errorLength, setErrorLength] = useState<boolean>(false)
    const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
    useEffect(() => {
        props.setErrorMsg(errorMsg)
        props.setError(error)
    }, [errorMsg, error])

    /**
     * LENGTH
     */
    

    const handleLengthFocusChange = (e: any) => {
        const inputValue = parseInt(e.target.value)
        if (inputValue === 0 || inputValue < 0) {
            setErrorLengthMsg('Value must not be less than 100mm.')
            setErrorLength(true)
            return
        }
        if (inputValue < 100) {
            setErrorLengthMsg('Value must not be less than 100mm.')
            setErrorLength(true)
        } else if (inputValue > 23000) {
            setErrorLengthMsg('Value must not be greater than 23000mm.')
            setErrorLength(true)
        } else {
            setErrorLengthMsg('')
            setErrorLength(false)
        }
        setLength(inputValue)
    }

    const handleLengthBlur = (e: any) => {
        setErrorLengthMsg('')
        setErrorLength(false)
    }

    /**
     * QUANTITY
     */
    const handleQuantityChange = (e: any) => {
        const inputNumber = parseInt(e.target.value, 10)
        if (inputNumber === 0 || inputNumber > maxQuantity || inputNumber < 0) {
        return
        }
        setQuantity(inputNumber)
    }

    const handleGutterCheckboxChange = (event: any) => {
        if (event.target.checked) {
            setIsGutterChecked(true)
        } else {
            setIsGutterChecked(false)
        }
      }

    // const disabled =
    //     color === 0 ||
    //     isNaN(quantity) ||
    //     quantity === 0 ||
    //     quantity > maxQuantity ||
    //     quantity < 0 ||
    //     length === '' ||
    //     isNaN(length) ||
    //     length === 0 ||
    //     length < 0 ||
    //     length < 100 ||
    //     length > 23000

    return (
        <Fragment>
            <div className='row' style={{ position: 'relative' }}>
                {/* GUTTER NAME */}
                <div className='col-md-3'>
                    <input
                    className='form-check-input'
                    type='checkbox'
                    id='checkGutter'
                    name='checkGutter'
                    onChange={handleGutterCheckboxChange}
                    checked={isGutterChecked}
                    style={{marginRight: 10}}
                    />
                    <label className='fw-bolder text-dark'>Gutter Name</label>
                    <select
                        style={{ fontSize: '1rem' }}
                        className='form-select my-4'
                        value={gutter}
                        onChange={handleGutterOnChange}
                    >
                        <option value=''>Select Gutter Name</option>
                        {gutterOption?.filter((option: any) => !option.name.toLowerCase().includes('overstrap'))
                        .map((option: any, index: number) => (
                            <option key={index} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='col-md-3' />
                <div className='col-md-3' />
                <div className='col-md-3' style={{ position: 'absolute', right: 0, top: 10 }}>
                    <div
                        className='d-flex flex-column justify-content-center align-items-end'
                        style={{
                            backgroundColor: '#B5B5C3',
                            paddingTop: 6,
                            paddingBottom: 6,
                            marginBottom: 10,
                            borderRadius: '6.175px',
                        }}
                    >
                        <p
                            style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: 0,
                                marginRight: 24,
                            }}
                        >
                            {moneyFormatter.format(gutter !== '' ? price : 0)}
                        </p>
                        <p style={{ fontWeight: '700', marginBottom: 0, marginRight: 24 }}>
                            {unitTypeDisplay.toLowerCase()}
                        </p>
                    </div>
                    <button
                        className='btn btn-primary w-100'
                        disabled={disabled}
                        onClick={handleGutterStopendBracketAddToCart}
                    >
                        <i className='fa fa-plus'></i>ADD
                    </button>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-md-9'>
                    <div style={{ borderBottom: '1px solid #B5B5C3' }} />
                </div>
                <div className='col-md-3' />
            </div>
            {/* COLOUR */}
            <div className='row  mb-4'>
                <div className='col-md-3'>
                    <label className='fw-bolder text-dark'>Colour</label>
                    <select
                        style={{ fontSize: '1rem' }}
                        className='form-select my-4'
                        value={color}
                        onChange={handleColorOnChange}
                        disabled={gutter === 0 || gutter === '' || gutter == 'Select Gutter Name'}
                    >
                        <option value={0}>Select</option>
                        {colourOption?.map((option: any, index: number) => (
                            <option key={index} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* LENGTH */}
                <div className='col-md-3'>
                    <label className='fw-bolder text-dark'>Length (mm)</label>
                    <input
                        style={{
                            fontSize: '1rem',
                            borderColor: length < 100 && length !== '' ? 'red' : '',
                        }}
                        min='1'
                        step='1'
                        max='23000'
                        type='number'
                        className='form-control my-4'
                        value={length || ''}
                        onFocus={handleLengthFocusChange}
                        onBlur={handleLengthBlur}
                        onChange={handleLengthFocusChange}
                        placeholder='Enter Length'
                        disabled={gutter === 0 || gutter === '' || gutter == 'Select Gutter Name'}
                    />
                    {errorLength ? (
                        <div className='text-danger m-auto text-left' role='alert'>
                            {errorLengthMsg}
                        </div>
                    ) : null}
                </div>
                {/* QUANTITY */}
                <div className='col-md-3'>
                <label className='fw-bolder text-dark'>Quantity</label>
                <input
                    style={{fontSize: '1rem'}}
                    type='number'
                    className='form-control  my-4'
                    placeholder='Enter Quantity'
                    min='1'
                    max={maxQuantity}
                    value={quantity || ''}
                    // value={fixing === '' ? '' :quantity}
                    onChange={handleQuantityChange}
                    disabled={gutter === 0 || gutter === '' || gutter == 'Select Gutter Name'}
                />
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-3'></div>
            </div>
        </Fragment>
    )
}

export default NewGutterItem
