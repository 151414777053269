// ProductDetailsPage.tsx
import React, { useEffect, useState, useContext } from 'react'
import {AdminProductContext} from '../../../../context/AdminProductContext'
import NewProductModal from '../../../../../_metronic/partials/modals/NewProductModal'
import { useAsyncFn } from 'react-use'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import { 
  PRODUCT_TYPE_ACCESSORY,
  PRODUCT_TYPE_BEAM,
  PRODUCT_TYPE_FIXING,
  PRODUCT_TYPE_FLASHING,
  PRODUCT_TYPE_LIGHT,
  PRODUCT_TYPE_PANEL,
  PRODUCT_TYPE_POST,
  PRODUCT_TYPE_RAINWATER 
} from '../../../../common/constant'
import {
  getPanelBottomColours,
  getPanelCores,
  getPanelProfiles,
  getPanelThicknesses,
  updatePanelProduct
} from '../../../../api/panels-api'
import {
  getFixingUnitTypes,
  updateFixingProduct
} from '../../../../api/fixings-api'
import {
  getFlashingSubTypes,
  getFlashingThicknesses,
  getFlashingTypes,
  updateFlashingProduct
} from '../../../../api/flashings-api'
import {
  getRainwaterCategories,
  getRainwaterParts,
  getRainwaterStyles,
  getRainwaterUnitTypes,
  updateRainwaterProduct
} from '../../../../api/rainwater-api'
import {
  getAccessoryCategories,
  getAccessoryParts,
  getAccessoryStyles,
  getAccessoryUnitTypes,
  updateAccessoryProduct
} from '../../../../api/accessory-api'
import {
  getBeamFinishes,
  getBeamTypes,
  getBeamUnitTypes,
  getBeams,
  getBeamSizes,
  updateBeamProduct,
  getBeamProducts
} from '../../../../api/beams-api'
import {
  getPostCategories,
  getPostFinishes,
  getPostProducts,
  getPostTypes,
  getPostUnitTypes,
  updatePostProduct
} from '../../../../api/posts-api'
import {
  getLightPanelTypes,
  getLightProductTypes,
  getLightProducts,
  getLightUnitTypes,
  getLightProfiles,
  getAllLightThicknesses,
  updateLightProduct
} from '../../../../api/light-api'
import { getColours, getProductByIdAndPricebookId } from '../../../../api/index'

interface Field {
  label: string
  key: string
}

type ProductDetailsPageProps = {
  productType: string
  productId: any
  onClose: () => void
}

const ProductDetailsPage: React.FC<ProductDetailsPageProps> = ({ productType, productId, onClose }) => {
  const [fields, setFields] = useState<Field[]>([])
  const [prodData, setProdData] = useState<any>({})
  const [isUpdatingProduct, setIsUpdatingProduct] = useState(false)

  const [priceBook, setPriceBook] = useState('SEQ Pricebook')
  const [priceBookId, setPriceBookId] = useState(1)

  const intl = useIntl()

  const {
    //panel
    panelBottomColour, setPanelBottomColours, panelCores, setPanelCores, panelProfiles, setPanelProfiles, panelThickness, setPanelThickness, colours, setColours,
    //fixing
    getFixingAsync, fixings, setFixingUnitTypesProducts, fixingUnitTypesProducts,
    //flashing
    setFlashingTypes, flashingTypes, setFlashingThickenesses, flashingThickenesses, setFlashingSubTypes, flashingSubTypes,
    //rainwater
    rainwaterCategories, setRainwaterCategories, rainwaterParts, setRainwaterParts, rainwaterStyles, setRainwaterStyles, rainwaterUnitTypes, setRainwaterUnitTypes,
    //accessory
    accessoryCategories, setAccessoryCategories, accessoryParts, setAccessoryParts, accessoryStyles, setAccessoryStyles, accessoryUnitTypes, setAccessoryUnitTypes,
    //beam
    beamFinishes, setBeamFinishes, beamSizes, setBeamSizes, beamProducts, setBeamProducts, beams, setBeams, beamTypes, setBeamTypes, setBeamUnitTypes, beamUnitTypes,
    //post
    postTypes, setPostTypes, postFinishes, setPostFinishes, postCategories, setPostCategories, postUnitTypes, setPostUnitTypes, postProduct, setPostProduct,
    //light
    lightPanelTypes, setLightPanelTypes, remappedLightUnitTypes, setRemappedLightUnitTypes, lightProfiles, setLightProfiles, lightProductTypes, setLightProductTypes, lightUnitTypes, setLightUnitTypes, lightProduct, setLightProduct, lightThickness, setLightThickness
  } = useContext(AdminProductContext)

  // #region Panels Fetching and Product Fields
  const fetchPanelOptions = async () => {
    if (!fetchPanelBottomColours && !panelBottomColour) {
      await fetchPanelBottomColoursAsync()
    }
    if (!fetchPanelThicknesses && !panelThickness) {
      await fetchPanelThicknessesAsync()
    }
    if (!fetchPanelProfiles && !panelProfiles) {
      await fetchPanelProfilesAsync()
    }
    if (!fetchPanelCores && !panelCores) {
      await fetchPanelCoresAsync()
    }
    if (!fetchColours && !colours) {
      await fetchColoursAsync()
    }
  }

  const [{loading: fetchPanelThicknesses}, fetchPanelThicknessesAsync] = useAsyncFn(async () => {
    try {
      const result: any = await getPanelThicknesses()
      if (result != null) {
        setPanelThickness(result.data)
      }
    } catch (error) {
      console.error('Error in fetchPanelThicknessesAsync:', error)
    }
  }, [setPanelThickness])
  
  
  const [{loading: fetchPanelProfiles}, fetchPanelProfilesAsync] = useAsyncFn(async () => {
    const result: any = await getPanelProfiles()
    if (result != null) {
      setPanelProfiles(result.data)
    }
  }, [setPanelProfiles])
  
  const [{loading: fetchPanelCores}, fetchPanelCoresAsync] = useAsyncFn(async () => {
    const result: any = await getPanelCores()
    if (result != null) {
      setPanelCores(result.data)
    }
  }, [setPanelCores])
  
  const [{loading: fetchPanelBottomColours}, fetchPanelBottomColoursAsync] =
    useAsyncFn(async () => {
      const result: any = await getPanelBottomColours()
      if (result != null) {
        setPanelBottomColours(result.data)
      }
    }, [setPanelBottomColours])
  
  const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])

  const panelProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Panel Profile', key: 'panelProfileName', type: 'text' },
    { label: 'Panel Core', key: 'panelCoreId', type: 'dropdown', options: panelCores },
    { label: 'Thickness', key: 'panelThicknessId', type: 'dropdown', options: panelThickness },
    { label: 'Top Sheet Colour', key: 'panelRoofColourId', type: 'dropdown', options: colours },
    { label: 'Bottom Sheet Colour', key: 'panelBottomColourId', type: 'dropdown', options: panelBottomColour },
  ]
  // #endregion

  // #region Fixings Fetching and Product Fields
  const fetchFixingOptions = async () => {
    if (!fixings) {
      getFixingAsync()
    }
    if (!fetchUnitTypes && !fixingUnitTypesProducts) {
      fetchUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  }

  const [{loading: fetchUnitTypes}, fetchUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFixingUnitTypes()
    if (result != null) {
      const mappedData: any[] = result.data.map((item: {
        id: number
        unitTypeId: number
        unitTypeName: string
      }) => ({
        id: item.unitTypeId,
        name: item.unitTypeName
      }))
      setFixingUnitTypesProducts(mappedData)
    }
  }, [setFixingUnitTypesProducts])

  const fixingProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Screw Name', key: 'fixingName', type: 'text' },
    { label: 'Screw Type', key: 'screwType', type: 'text', optional: true },
    { label: 'Colour', key: 'colourId', type: 'dropdown', options: colours},
  ]
  // #endregion

  // #region Flashings Fetching and Product Fields
  const fetchFlashingOptions = async () => {
    if (!flashingTypes && !fetchFlashingType) {
      getFlashingTypesAsync()
    }
    if (!flashingThickenesses && !fetchFlashingThicknesses) {
      getFlashingThicknessesAsync()
    }
    if (!flashingSubTypes && !fetchfixingSubTypes) {
      getFlashingSubTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  }

  const [{loading: fetchFlashingType}, getFlashingTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFlashingTypes()
    if (result != null) {
      setFlashingTypes(result.data)
    }
  }, [setFlashingTypes])

  const [{loading: fetchFlashingThicknesses}, getFlashingThicknessesAsync] =
    useAsyncFn(async () => {
      const result: any = await getFlashingThicknesses()
      if (result != null) {
        setFlashingThickenesses(result.data)
      }
    }, [setFlashingThickenesses])

  const [{loading: fetchfixingSubTypes}, getFlashingSubTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFlashingSubTypes()
    if (result != null) {
      setFlashingSubTypes(result.data)
    }
  }, [setFlashingSubTypes])

  const flashingProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Flashing Type', key: 'flashingTypeName', type: 'text' },
    { label: 'Panel Type', key: 'flashingSubTypeId', type: 'dropdown', options: flashingSubTypes },
    { label: 'Size', key: 'flashingThicknessId', type: 'dropdown', options: flashingThickenesses },
    { label: 'Colour', key: 'colourId', type: 'dropdown', options: colours },
  ]
  // #endregion
  
  // #region Rainwater Fetching and Product Fields
  const fetchRainwaterOptions = async () => {
    if (!rainwaterCategories && !fetchRainwaterCategories) {
      getRainwaterCategoriesAsync()
    }
    if (!rainwaterParts && !fetchRainwaterParts) {
      getRainwaterPartsAsync()
    }
    if (!rainwaterStyles && !fetchRainwaterStyles) {
      getRainwaterStylesAsync()
    }
    if (!rainwaterUnitTypes && !fetchRainwaterUnitTypes) {
      getRainwaterUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  }

  const [{loading: fetchRainwaterCategories}, getRainwaterCategoriesAsync] =
    useAsyncFn(async () => {
      const result: any = await getRainwaterCategories()
      if (result != null) {
        setRainwaterCategories(result.data)
      }
    }, [setRainwaterCategories])

  const [{loading: fetchRainwaterParts}, getRainwaterPartsAsync] = useAsyncFn(async () => {
    const result: any = await getRainwaterParts()
    if (result != null) {
      setRainwaterParts(result.data)
    }
  }, [setRainwaterParts])

  const [{loading: fetchRainwaterStyles}, getRainwaterStylesAsync] = useAsyncFn(async () => {
    const result: any = await getRainwaterStyles()
    if (result != null) {
      setRainwaterStyles(result.data)
    }
  }, [setRainwaterStyles])

  const [{loading: fetchRainwaterUnitTypes}, getRainwaterUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getRainwaterUnitTypes()
    if (result != null) {
      setRainwaterUnitTypes(result.data)
    }
  }, [setRainwaterStyles])

  const rainwaterProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Product Name', key: 'rainwaterStyleName', type: 'text' },
    { label: 'Category Name', key: 'rainwaterPartName', type: 'text' },
    { label: 'Colour', key: 'rainwaterColourId', type: 'dropdown', options: colours },
  ]
  // #endregion

  // #region Accessory Fetching and Product Fields
  const fetchAccessoryOptions = async () => {
    if (!accessoryCategories && !fetchAccessoryCategories) {
      // getAccessoryCategoriesAsync()
    }
    if (!accessoryParts && !fetchAccessoryParts) {
      // getAccessoryPartsAsync()
    }
    if (!accessoryStyles && !fetchAccessoryStyles) {
      // getAccessoryStylesAsync()
    }
    if (!accessoryUnitTypes && !fetchAccessoryUnitTypes) {
      // getAccessoryUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  }

  const [{loading: fetchAccessoryCategories}, getAccessoryCategoriesAsync] =
    useAsyncFn(async () => {
      const result: any = await getAccessoryCategories()
      if (result != null) {
        setAccessoryCategories(result.data)
      }
    }, [setAccessoryCategories])

  const [{loading: fetchAccessoryParts}, getAccessoryPartsAsync] = useAsyncFn(async () => {
    const result: any = await getAccessoryParts()
    if (result != null) {
      setAccessoryParts(result.data)
    }
  }, [setAccessoryParts])

  const [{loading: fetchAccessoryStyles}, getAccessoryStylesAsync] = useAsyncFn(async () => {
    const result: any = await getAccessoryStyles()
    if (result != null) {
      setAccessoryStyles(result.data)
    }
  }, [setAccessoryStyles])

  const [{loading: fetchAccessoryUnitTypes}, getAccessoryUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getAccessoryUnitTypes()
    if (result != null) {
      setAccessoryUnitTypes(result.data)
    }
  }, [setAccessoryStyles])

  const accessoryProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Accessory Name', key: 'accessoryStyleName', type: 'text' },
    { label: 'Colour', key: 'accessoryColourId', type: 'dropdown', options: colours },
  ]
  // #endregion

  // #region Beams Fetching and Product Fields
  const fetchBeamOptions = async () => {
    if (!fetchBeamTypes && !beamTypes) {
      getBeamTypesAsync()
    }
    if (!fetchBeamFinishes && !beamFinishes) {
      getBeamFinishesAsync()
    }
    if (!fetchBeams && !beams) {
      getBeamsAsync()
    }
    if (!fetchBeamUnitTypes && !beamUnitTypes) {
      fetchBeamUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
    if (!fetchBeamSizes && !beamSizes) {
      getBeamSizesAsync()
    }
    if (!fetchBeamProducts && !beamProducts) {
      getBeamProductsAsync()
    }
  }

  const [{loading: fetchBeamProducts}, getBeamProductsAsync] = useAsyncFn(async () => {
    const result: any = await getBeamProducts()
    if (result != null) {
      setBeamProducts(result.data)
    }
  }, [setBeamProducts])
  
  const [{loading: fetchBeamTypes}, getBeamTypesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamTypes()
    if (result != null) {
      setBeamTypes(result.data)
    }
  }, [setBeamTypes])

  const [{loading: fetchBeamSizes}, getBeamSizesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamSizes()
    if (result != null) {
      setBeamSizes(result.data)
    }
  }, [setBeamSizes])

  const [{loading: fetchBeamFinishes}, getBeamFinishesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamFinishes()
    if (result != null) {
      setBeamFinishes(result.data)
    }
  }, [setBeamFinishes])

  const [{loading: fetchBeams}, getBeamsAsync] = useAsyncFn(async () => {
    const result: any = await getBeams()
    if (result != null) {
      setBeams(result.data)
    }
  }, [setBeams])

  const [{loading: fetchBeamUnitTypes}, fetchBeamUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamUnitTypes()
    if (result != null) {
      setBeamUnitTypes(result.data)
    }
  }, [setBeamUnitTypes])

  const beamProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Beam Product', key: 'beamProductName', type: 'text' },
    { label: 'Type', key: 'beamSizeId', type: 'dropdown', options: beamSizes },
    { label: 'Colour', key: 'colourId', type: 'dropdown', options: colours },
  ]
  // #endregion

  // #region Posts Fetching and Product Fields
  const fetchPostOptions = async () => {
    if (!postTypes && !fetchPostTypes) {
      fetchPostTypesAsync()
    }
    if (!fetchPostProducts && !postProduct) {
      fetchPostProductsAsync()
    }
    if (!fetchPostFinishes && !postFinishes) {
      fetchPostFinishesAsync()
    }
    if (!fetchPostCategories && !postCategories) {
      fetchPostCategoriesAsync()
    }
    if (!fetchPostUnitTypes && !postUnitTypes) {
      fetchPostUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
    if (!fetchBeamSizes && !beamSizes) {
      getBeamSizesAsync()
    }
  }

  const [{loading: fetchPostTypes}, fetchPostTypesAsync] = useAsyncFn(async () => {
    const result: any = await getPostTypes()
    if (result != null) {
      setPostTypes(result.data)
    }
  }, [setPostTypes])

  const [{loading: fetchPostProducts}, fetchPostProductsAsync] = useAsyncFn(async () => {
    const result: any = await getPostProducts()
    if (result != null) {
      setPostProduct(result.data)
    }
  }, [setPostProduct])

  const [{loading: fetchPostFinishes}, fetchPostFinishesAsync] = useAsyncFn(async () => {
    const result: any = await getPostFinishes()
    if (result != null) {
      setPostFinishes(result.data)
    }
  }, [setPostFinishes])

  const [{loading: fetchPostCategories}, fetchPostCategoriesAsync] = useAsyncFn(async () => {
    const result: any = await getPostCategories()
    if (result != null) {
      setPostCategories(result.data)
    }
  }, [setPostCategories])

  const [{loading: fetchPostUnitTypes}, fetchPostUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getPostUnitTypes()
    if (result != null) {
      setPostUnitTypes(result.data)
    }
  }, [setPostUnitTypes])

  const postProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Product Type', key: 'postProductName', type: 'text' },
    { label: 'Post Type', key: 'postTypeName', type: 'text' },
    { label: 'Beam Size', key: 'beamSizeId', type: 'dropdown', options: beamSizes },
    { label: 'Colour', key: 'colourId', type: 'dropdown', options: colours },
  ]
  // #endregion

  // #region Lights Fetching and Product Fields
  const fetchLightOptions = async () => {
    if (!lightProductTypes && !fetchLightProductType) {
      getLightProductTypesAsync()
    }
    if (!lightProduct && !fetchLightProduct) {
      getLightProductsAsync()
    }
    if (!lightPanelTypes && !fetchLightPanel) {
      getLightPanelTypesAsync()
    }
    if (!lightUnitTypes && !fetchLightUnitTypes) {
      fetchLightUnitTypesAsync()
    }
    if (!lightProfiles && !fetchLightProfiles) {
      getLightProfilesAsync()
    }

    if (!fetchLightThicknesses && !lightThickness) {
      await fetchLightThicknessesAsync()
    }
  }

  const [{loading: fetchLightThicknesses}, fetchLightThicknessesAsync] = useAsyncFn(async () => {
    try {
      const result: any = await getAllLightThicknesses()
      if (result != null) {
        setLightThickness(result.data)
      }
    } catch (error) {
      console.error('Error in fetchLightThicknessesAsync:', error)
    }
  }, [setLightThickness])

  const [{loading: fetchLightProfiles}, getLightProfilesAsync] = useAsyncFn(async () => {
    const result: any = await getLightProfiles()
    if (result != null) {
      setLightProfiles(result.data)
    }
  }, [setLightProfiles])

  const [{loading: fetchLightProductType}, getLightProductTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightProductTypes()
    if (result != null) {
      setLightProductTypes(result.data)
    }
  }, [setLightProductTypes])

  const [{loading: fetchLightProduct}, getLightProductsAsync] = useAsyncFn(async () => {
    const result: any = await getLightProducts()
    if (result != null) {
      setLightProduct(result.data)
    }
  }, [setLightProduct])

  const [{loading: fetchLightPanel}, getLightPanelTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightPanelTypes()
    if (result != null) {
      setLightPanelTypes(result.data)
    }
  }, [setLightPanelTypes])

  const [{loading: fetchLightUnitTypes}, fetchLightUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightUnitTypes()
    if (result != null) {
      setLightUnitTypes(result.data)

      const remappedData = (result.data as any[])?.map(({ id, unitTypeName }) => ({
        id: id,
        name: unitTypeName,
      }));
      setRemappedLightUnitTypes(remappedData);
    }
  }, [setLightUnitTypes, setRemappedLightUnitTypes])

  const lightProductFields = [
    { label: 'Item Number', key: 'code', type: 'text' },
    { label: 'Product Name', key: 'lightProductName', type: 'text' },
    { label: 'Panel Type', key: 'lightProfileId', type: 'dropdown', options: lightProfiles },
    { label: 'Thickness', key: 'lightThicknessId', type: 'dropdown', options: lightThickness },
    { label: 'Product Type', key: 'detail', type: 'text' },
    { label: 'Unit Type', key: 'lightUnitTypeId', type: 'dropdown', options: remappedLightUnitTypes },
  ]
  // #endregion

  useEffect(() => {
    fetchProductFields()
  }, [productType, productId])

  const fetchProductFields = async () => {
    await fetchViewedProduct()
    switch (productType) {
      case PRODUCT_TYPE_PANEL:
        await fetchPanelOptions()
        setFields(panelProductFields)
        console.log('fields', panelProductFields)
        break
      case PRODUCT_TYPE_FIXING:
        await fetchFixingOptions()
        setFields(fixingProductFields)
        break
      case PRODUCT_TYPE_FLASHING:
        await fetchFlashingOptions()
        setFields(flashingProductFields)
        break
      case PRODUCT_TYPE_RAINWATER:
        await fetchRainwaterOptions()
        setFields(rainwaterProductFields)
        break
      case PRODUCT_TYPE_BEAM:
        await fetchBeamOptions()
        setFields(beamProductFields)
        break
      case PRODUCT_TYPE_POST:
        await fetchPostOptions()
        setFields(postProductFields)
        break
      case PRODUCT_TYPE_LIGHT:
        await fetchLightOptions()
        setFields(lightProductFields)
        break
      case PRODUCT_TYPE_ACCESSORY:
        await fetchAccessoryOptions()
        setFields(accessoryProductFields)
        break
      default:
        setFields([])
    }
  }

  const fetchViewedProduct = async () => {
    const response = await getProductByIdAndPricebookId(productId, productType)
    setProdData(response)
  }

  const handleEditClick = () => {
    setIsUpdatingProduct(true)
  }

  const handleCloseNewProductModal = () => {
    setIsUpdatingProduct(false)
  }

  const handleUpdateProduct = async (newProduct: any) => {
    newProduct.id = productId
    try {
        const result = await updateProduct(newProduct)
        if ((result as any)?.data) {
            fetchViewedProduct()
        } else {
            console.error('Failed to update product')
        }
    } catch (err) {
        console.error('Error updating product:', err)
    }
  }

  const getProductFields = () => {
    switch (productType) {
      case PRODUCT_TYPE_PANEL:
        return panelProductFields
      case PRODUCT_TYPE_FIXING:
        return fixingProductFields
      case PRODUCT_TYPE_FLASHING:
        return flashingProductFields
      case PRODUCT_TYPE_RAINWATER:
        return rainwaterProductFields
      case PRODUCT_TYPE_BEAM:
        return beamProductFields
      case PRODUCT_TYPE_POST:
        return postProductFields
      case PRODUCT_TYPE_LIGHT:
        return lightProductFields
      case PRODUCT_TYPE_ACCESSORY:
        return accessoryProductFields
      default:
        return []
    }
  }

  const updateProduct = async (payload: any) => {
      switch (productType) {
          case PRODUCT_TYPE_PANEL:
              return await updatePanelProduct(payload)
          case PRODUCT_TYPE_FIXING:
              return await updateFixingProduct(payload)
          case PRODUCT_TYPE_FLASHING:
              return await updateFlashingProduct(payload)
          case PRODUCT_TYPE_RAINWATER:
              return await updateRainwaterProduct(payload)
          case PRODUCT_TYPE_BEAM:
              return await updateBeamProduct(payload)
          case PRODUCT_TYPE_POST:
              return await updatePostProduct(payload)
          case PRODUCT_TYPE_LIGHT:
              return await updateLightProduct(payload)
          case PRODUCT_TYPE_ACCESSORY:
              return await updateAccessoryProduct(payload)
          default:
              throw new Error('Invalid product type')
      }
  }

  const getFieldName = (key: string): string => {
    return key.replace('Id', 'Name');
  };

  const handlePriceBookOnChange = async (e: any) => {
    setPriceBook(e.target.value)
    switch (e.target.value) {
      case 'SEQ Pricebook':
        setPriceBookId(1)
        break
      case 'SYD Pricebook':
        setPriceBookId(2)
        break
      default:
        setPriceBookId(3)
    }
  }

  const updatePricebook = async () => {
    const response = await getProductByIdAndPricebookId(productId, productType, priceBookId)
    setProdData(response)
  }
  
  useEffect(() => {
    updatePricebook()
  }, [priceBookId])

  const priceLevels = [
    'Level 1',
    'Level 2',
    'Level 3',
    'Level 4',
    'Level 5',
    'Level 6',
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCTMASTERLIST'})}</PageTitle>
      <div className="container mt-5">
        <div className="row justify-content-between mb-3">
          <div className="col-auto">
            <h2 className="mt-5">{productType} Details</h2>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={onClose}>
              Return to Product List
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5> </h5>
                <button className="btn btn-primary" onClick={() => handleEditClick()}>
                  EDIT
                </button>
              </div>
              <div className="card-body">
                {fields.map((field, index) => (
                  <div
                    key={field.key}
                    className="col-12"
                    style={{
                      marginBottom: index === 0 ? '0' : '15px',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <label className="form-label" style={{ marginBottom: '5px' }}>{field.label}</label>
                    <div style={{ fontWeight: 'bold', fontSize: '1.3em' }}>{prodData?.[getFieldName(field.key)]}</div>
                    {index === 0 && <hr />}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5>Price Levels</h5>
              </div>
              <div className="card-body">
                <div className='mb-10' style={{width: '100%'}}>
                  <label className='fw-bolder text-dark'>Select Pricebook</label>
                  <select
                    style={{fontSize: '1rem'}}
                    className='form-select'
                    aria-label='Region Selection Pricebook'
                    value={priceBook}
                    onChange={handlePriceBookOnChange}
                  >
                    <option value='SEQ Pricebook'>SEQ Pricebook</option>
                    <option value='SYD Pricebook'>SYD Pricebook</option>
                    <option value='North QLD Pricebook'>North QLD Pricebook</option>
                  </select>
                </div>
                <table className="table table-bordered">
                  <tbody>
                    {priceLevels.map((level, index) => (
                      <tr key={index}>
                        <td className="text-center form-label" style={{ fontWeight: 'bold' }}>{level}</td>
                        <td className="text-center form-label">${prodData[`priceLevel${index + 1}`]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewProductModal
        isOpen={isUpdatingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleUpdateProduct}
        fields={getProductFields()}
        initialValues={prodData}
      />
    </>
  )
}



export default ProductDetailsPage
