import React, {useState, useEffect} from 'react'
import {useAsyncFn} from 'react-use'
import {
  getBeamFinishes,
  getBeamTypes,
  getColours,
  getFixings,
  getLightPanelTypes,
  getLightProductTypes,
  getLightProducts,
  getPanelSetup,
  getPostCategories,
  getPostFinishes,
  getPostProducts,
  getPostTypes,
  checkColumnNull,
  getProductIdByName
} from '../api'
import {IPanelSetup} from '../models/panel-setup'
import {IFixing} from '../models/fixings/fixing'
import {IFixingPricing} from '../models/fixings/fixingPricing'
import {IColour} from '../models/colour'
import {IBeam} from '../models/beams/beams'
import {IBeamType} from '../models/beams/beamType'
import {IBeamFinish} from '../models/beams/beamFinish'
import {IBeamPricing} from '../models/beams/beamPricing'
import {IPostProduct} from '../models/posts/postProduct'
import {IPostType} from '../models/posts/postType'
import {IPostFinish} from '../models/posts/postFinish'
import {IPostCategory} from '../models/posts/postCategory'
import {IPostPricing} from '../models/posts/postPricing'
import {ILightProduct} from '../models/lights/lightProduct'
import {ILightProductType} from '../models/lights/lightProductType'
import {ILightPanelType} from '../models/lights/lightPanelType'
import {ILightPricing} from '../models/lights/lightPricing'
import {IFlashingPricing} from '../models/flashings/flashingPricings'
import {IRainWaterCategory} from '../models/rainwater/rainWaterCategory'
import {getAllProduct as getAllFixingProduct, getFixingPricings} from '../api/fixings-api'
import {getAllProduct as getAllFlashingProduct, getFlashingSetup} from '../api/flashings-api'
import {getAllProduct as getAllRainwaterProduct, getRainWaterCategories} from '../api/rainwater-api'
import {getBeamPricings, getBeamsPM, getBeamColours, getBeamUnitPrice, getBeamTypesProdMasterlist} from '../api/beams-api'
import {getPostPricingList, getPostTypesPM, getAllPostColoursPM, getBeamSizesPM, getPostUnitPrice, getPostExtenderLengths} from '../api/posts-api'
import {getLightPricings, getLightProductsPM, getLightPanelTypesPM, getLightProductTypesPM, getLightUnitPrice, getLightProductMaster,
  getLightThicknesses
} from '../api/light-api'
import { IBeamSize } from '../models/beams/beamSizes'
import {getAllProduct as getAllPanelProduct} from '../api/panels-api'
import { moneyFormatter } from '../../utils'
import { IRainWaterPricing } from '../models/rainwater/rainWaterPricing'
import { roundToDecimalPlaces } from '../shared/service/utils'
import { ProductType } from '../common/constant'
import { BeamUnitPriceFor } from '../common/constant'
import { IAccessoryPricing } from '../models/accessories/accessoryPricing'
import { getAllProduct as getAllAccessoryProduct} from '../api/accessory-api'
import { 
  BeamProducts,
  PRODUCT_IMPORT_TYPE_POST,
  PRODUCT_IMPORT_TYPE_BEAM,
  ORDER_TAB_POSTS,
  ORDER_TAB_POSTS_KIT,
  ORDER_TAB_POSTS_EXTENDA_BRACKET,
  PRODUCT_IMPORT_TYPE_RAINWATER,
  PRODUCT_IMPORT_TYPE_LIGHT,
  ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT,
 } from '../common/constant'

interface IProps {}

export const QuickOrderContext = React.createContext<any>({})

export const QuickOrderContextProvider: React.FC = ({children}) => {
  const dataShipping = JSON.parse(localStorage.getItem('shipping-preference') as any) || {}
  const [panelSetup, setPanelSetup] = useState<IPanelSetup | undefined>()
  const [{loading: fetchingPanelSetup}, getPanelSetupAsync] = useAsyncFn(async () => {
    const result: any = await getPanelSetup(dataShipping.pricebookId)

    if (result != null) {
      setPanelSetup(result.data)
    }
  }, [setPanelSetup])

  const [panelMasterList, setPanelMasterList] = useState<IPanelSetup | undefined>()
  const [{loading: fetchingPanelMasterList}, getPanelMasterListAsync] = useAsyncFn(async () => {
    const result: any = await getAllPanelProduct(dataShipping.pricebookId)
    if (result != null) {
      setPanelMasterList(result)
    }
  }, [setPanelMasterList])

  const [flashingSetup, setFlashingSetup] = useState<IFlashingPricing[] | undefined>()
  const [{loading: fetchingFlashingSetup}, getFlashingSetupAsync] = useAsyncFn(async () => {
    const result: any = await getFlashingSetup(dataShipping.pricebookId)

    if (result != null) {
      setFlashingSetup(result.data)
    }
  }, [setFlashingSetup])

  const [flashingProducts, setFlashingProducts] = useState<IFlashingPricing[] | undefined>()
  const [{loading: fetchingAllFlashingProduct}, getAllFlashingProductAsync] =
    useAsyncFn(async () => {
      const result: any = await getAllFlashingProduct(dataShipping.pricebookId)
      if (result != null) {
        setFlashingProducts(result)
      }
    }, [setFlashingProducts])

  const [isFlashingColourColumnNull, setIsFlashingColourColumnNull] = useState<boolean>(false)
  const [{loading: fetchingFlashingCheckColumnNull}, flashingCheckColumnNullAsync] = useAsyncFn(
    async (flashingTypeId: number) => {
    const isFlashingColNull: any = await checkColumnNull(flashingTypeId, ProductType.Flashings)
    if (isFlashingColNull != null) {
      setIsFlashingColourColumnNull(isFlashingColNull.isColourIdNull)
    }
  }, [setIsFlashingColourColumnNull])

  const [stopEndId, setStopEndId] = useState<number>(0)
  const [{loading: fetchingRainwaterProductIdByName}, getRainwaterProductIdByNameAsync] = useAsyncFn(async (
    productName: string) => {
    const productId: any = await getProductIdByName(productName, PRODUCT_IMPORT_TYPE_RAINWATER)
    if (productId != null) {
      setStopEndId(productId.productId)
    }
  }, [setStopEndId])

  const [rainwaterProductMasters, setRainwaterProductMasters] = useState<IRainWaterPricing[] | undefined>()
  const [{loading: fetchingRainwaterProduct}, getRainwaterProductAsync] = useAsyncFn(async () => {
    const result: any = await getAllRainwaterProduct(dataShipping.pricebookId)
    if (result != null) {
      setRainwaterProductMasters(result)
    }
  }, [setRainwaterProductMasters])

  const [accessoryProductMasters, setAccessoryProductMasters] = useState<IAccessoryPricing[] | undefined>()
  const [{loading: fetchingAccessoryProduct}, getAccessoryProductAsync] = useAsyncFn(async () => {
    const result: any = await getAllAccessoryProduct(dataShipping.pricebookId)
    if (result != null) {
      setAccessoryProductMasters(result)
    }
  }, [setAccessoryProductMasters])

  const [fixings, setFixings] = useState<IFixing[] | undefined>()
  const [fixingPricings, setFixingPricings] = useState<IFixingPricing[] | undefined>()

  const [{loading: fetchingFixings}, getFixingsAsync] = useAsyncFn(async () => {
    const fixings: any = await getFixings(dataShipping.pricebookId)
    if (fixings != null) {
      setFixings(fixings.data)
    }
  }, [setFixings])

  const [{loading: fetchingFixingPricings}, getFixingPricingsAsync] = useAsyncFn(async () => {
    const fixingPricings: any = await getFixingPricings(dataShipping.pricebookId)
    if (fixingPricings != null) {
      setFixingPricings(fixingPricings.data)
    }
  }, [setFixingPricings])

  const [fixingMasterList, setFixinMasterList] = useState<IFixingPricing[] | undefined>()
  const [{loading: fetchingAllFixingProduct}, getAllFixingProductAsync] = useAsyncFn(async () => {
    const fixingPricings: any = await getAllFixingProduct(dataShipping.pricebookId)
    if (fixingPricings != null) {
      setFixinMasterList(fixingPricings)
    }
  }, [setFixinMasterList])

  const [beams, setBeams] = useState<IBeam[] | undefined>()
  const [beamTypes, setBeamTypes] = useState<IBeamType[] | undefined>()
  const [beamColours, setBeamColours] = useState<IColour[] | undefined>()
  const [beamEndCapColours, setBeamEndCapColours] = useState<IColour[] | undefined>()
  const [beamFinishes, setBeamFinishes] = useState<IBeamFinish[] | undefined>()
  const [beamPricings, setBeamPricings] = useState<IBeamPricing[] | undefined>()
  const [beamUnitPrice, setBeamUnitPrice] = useState<number>(0)
  const [beamProductMastersId, setBeamProductMastersId] = useState<number>(0)

  const [steelBeamEndCapId, setSteelBeamEndCapId] = useState<number>(0)
  const [aluminiumBeamEndCapId, setAluminiumBeamEndCapId] = useState<number>(0)
  const [beamSpacerId, setBeamSpacerId] = useState<number>(0)
  const [beamJoinerId, setBeamJoinerId] = useState<number>(0)

  const getBeamProductIdsAsync = async () => {
    const promises = Object.values(BeamProducts).map(async (productName) => {
      const productId: any = await getProductIdByName(productName, PRODUCT_IMPORT_TYPE_BEAM)
      if (productId != null) {
        switch (productName) {
          case BeamProducts.SteelBeamEndCap:
            setSteelBeamEndCapId(productId.productId)
            break
          case BeamProducts.AluminiumBeamEndCap:
            setAluminiumBeamEndCapId(productId.productId)
            break
          case BeamProducts.BeamSpacer:
            setBeamSpacerId(productId.productId)
            getBeamSpacerUnitPriceAsync(productId.productId)
            break
          case BeamProducts.BeamJoiner:
            setBeamJoinerId(productId.productId)
            getBeamJoinerUnitPriceAsync(productId.productId)
            break
          default:
            break
        }
      }
    })
  
    await Promise.all(promises)
  }

  const [beamFasciaHangingSizes, setBeamFasciaHangingSizes] = useState<IBeamType[] | undefined>()
  const [beamFasciaHangingColours, setBeamFasciaHangingColours] = useState<IColour[] | undefined>()

  const [beamEndCapUnitPrice, setBeamEndCapUnitPrice] = useState<number>(0)
  const [beamSpacerUnitPrice, setBeamSpacerUnitPrice] = useState<number>(0)
  const [beamJoinerUnitPrice, setBeamJoinerUnitPrice] = useState<number>(0)
  const [beamFasciaHangingUnitPrice, setBeamFasciaHangingUnitPrice] = useState<number>(0)

  const [beamEndCapProductMastersId, setBeamEndCapProductMastersId] = useState<number>(0)
  const [beamSpacerProductMastersId, setBeamSpacerProductMastersId] = useState<number>(0)
  const [beamJoinerProductMastersId, setBeamJoinerProductMastersId] = useState<number>(0)
  const [beamFasciaHangingProductMastersId, setBeamFasciaHangingProductMastersId] = useState<number>(0)

  const [{loading: fetchingBeamUnitPrice}, getBeamUnitPriceAsync] = useAsyncFn(
    async (beamProductId: number, beamSizeId?: number, colourId?: number) => {
    const beamUnitPrice: any = await getBeamUnitPrice(beamProductId, beamSizeId, colourId)
    if (beamUnitPrice != null) {
      setBeamUnitPrice(beamUnitPrice.priceLevel1)
      setBeamProductMastersId(beamUnitPrice.productMastersId)
    }
  }, [setBeamUnitPrice])

  const [{loading: fetchingBeamEndCapUnitPrice}, getBeamEndCapUnitPriceAsync] = useAsyncFn(
    async (beamEndCapId: number, beamSizeId: number, colourId: number) => {
    const beamUnitPrice: any = await getBeamUnitPrice(beamEndCapId, beamSizeId, colourId)
    if (beamUnitPrice != null) {
      setBeamEndCapUnitPrice(beamUnitPrice.priceLevel1)
      setBeamEndCapProductMastersId(beamUnitPrice.productMastersId)
    }
  }, [setBeamEndCapUnitPrice])

  const [{loading: fetchingBeamSpacerUnitPrice}, getBeamSpacerUnitPriceAsync] = useAsyncFn(
    async (productId: number) => {
    const beamUnitPrice: any = await getBeamUnitPrice(productId, 0, 0)
    if (beamUnitPrice != null) {
      setBeamSpacerUnitPrice(beamUnitPrice.priceLevel1)
      setBeamSpacerProductMastersId(beamUnitPrice.productMastersId)
    }
  }, [setBeamSpacerUnitPrice])

  const [{loading: fetchingBeamJoinerUnitPrice}, getBeamJoinerUnitPriceAsync] = useAsyncFn(
    async (productId: number) => {
    const beamUnitPrice: any = await getBeamUnitPrice(productId, 0, 0)
    if (beamUnitPrice != null) {
      setBeamJoinerUnitPrice(beamUnitPrice.priceLevel1)
      setBeamJoinerProductMastersId(beamUnitPrice.productMastersId)
    }
  }, [setBeamJoinerUnitPrice])

  const [{loading: fetchingBeamFasciaHangingUnitPrice}, getBeamFasciaHangingUnitPriceAsync] = useAsyncFn(
    async (beamSizeId: number, colourId: number) => {
    const beamUnitPrice: any = await getBeamUnitPrice(0, beamSizeId, colourId, true)
    if (beamUnitPrice != null) {
      setBeamFasciaHangingUnitPrice(beamUnitPrice.priceLevel1)
      setBeamFasciaHangingProductMastersId(beamUnitPrice.productMastersId)
    }
  }, [setBeamFasciaHangingUnitPrice])

  const [{loading: fetchingBeams}, getBeamsAsync] = useAsyncFn(async () => {
    const beams: any = await getBeamsPM()
    if (beams != null) {
      setBeams(beams.data)
    }
  }, [setBeams])

  const [{loading: fetchingBeamTypes}, getBeamTypesAsync] = useAsyncFn(async () => {
    const beamTypes: any = await getBeamTypesProdMasterlist()
    if (beamTypes != null) {
      setBeamTypes(beamTypes.data)
    }
  }, [setBeamTypes])

  const [{loading: fetchingBeamFasciaHangingSizes}, getBeamFasciaHangingSizesAsync] = useAsyncFn(async () => {
    const beamTypes: any = await getBeamTypesProdMasterlist(true)
    if (beamTypes != null) {
      setBeamFasciaHangingSizes(beamTypes.data)
    }
  }, [setBeamFasciaHangingSizes])

  const [{loading: fetchingBeamColours}, getBeamColoursAsync] = useAsyncFn(async () => {
    const beamColours: any = await getBeamColours()
    if (beamColours != null) {
      setBeamColours(beamColours.data)
    }
  }, [setBeamColours])

  const [{loading: fetchingBeamEndCapColours}, getBeamEndCapColoursAsync] = useAsyncFn(async () => {
    const beamColours: any = await getBeamColours(true)
    if (beamColours != null) {
      setBeamEndCapColours(beamColours.data)
    }
  }, [setBeamEndCapColours])

  const [{loading: fetchingBeamFasciaHangingColours}, getBeamFasciaHangingColoursAsync] = useAsyncFn(async () => {
    const beamColours: any = await getBeamColours(false, true)
    if (beamColours != null) {
      setBeamFasciaHangingColours(beamColours.data)
    }
  }, [setBeamFasciaHangingColours])

  const [{loading: fetchingBeamFinishes}, getBeamFinishesAsync] = useAsyncFn(async () => {
    const beamFinishes: any = await getBeamFinishes()
    if (beamFinishes != null) {
      setBeamFinishes(beamFinishes.data)
    }
  }, [setBeamFinishes])

  const [{loading: fetchingBeamPricings}, getBeamPricingsAsync] = useAsyncFn(async () => {
    const beamPricings: any = await getBeamPricings(dataShipping.pricebookId)
    if (beamPricings != null) {
      setBeamPricings(beamPricings.data)
    }
  }, [setBeamPricings])

  const [isBeamTabQuantityOnly, setIsBeamTabQuantityOnly] = useState<boolean>(false)
  const [isBeamTabNoColourOnly, setIsBeamTabNoColourOnly] = useState<boolean>(false)
  const [{loading: fetchingBeamCheckColumnNull}, beamCheckColumnNullAsync] = useAsyncFn(
    async (beamProductId: number) => {
    const isBeamColNull: any = await checkColumnNull(beamProductId, ProductType.Beams)
    if (isBeamColNull != null) {
      if (isBeamColNull.isBeamSizeIdNull && isBeamColNull.isColourIdNull) {
        setIsBeamTabQuantityOnly(true)
      }
      else if (!isBeamColNull.isBeamSizeIdNull && isBeamColNull.isColourIdNull) {
        setIsBeamTabNoColourOnly(true)
      }
      else {
        setIsBeamTabQuantityOnly(false)
        setIsBeamTabNoColourOnly(false)
      }
    }
  }, [setIsBeamTabQuantityOnly])

  const [postProducts, setPostProducts] = useState<IPostProduct[] | undefined>()
  const [postTypes, setPostTypes] = useState<IPostType[] | undefined>()
  const [postFinishes, setPostFinishes] = useState<IPostFinish[] | undefined>()
  const [postCategories, setPostCategories] = useState<IPostCategory[] | undefined>()
  const [postPricings, setPostPricings] = useState<IPostPricing[] | undefined>()
  const [postColours, setPostColours] = useState<IColour[] | undefined>()
  const [postUnitPrice, setPostUnitPrice] = useState<number>(0)
  const [postBeamSizes, setPostBeamSizes] = useState<IBeamSize[] | undefined>()
  const [postExtenderLengths, setPostExtenderLengths] = useState<IBeamSize[] | undefined>()
  const [postProductMastersId, setPostProductMastersId] = useState<number>(0)

  const [postProductId, setPostProductId] = useState<number>(0)
  const [postKitProductId, setPostKitProductId] = useState<number>(0)
  const [postExtendaBracketId, setPostExtendaBracketId] = useState<number>(0)

  const [postExtendaBracketProducts, setPostExtendaBracketProducts] = useState<IPostProduct[] | undefined>()
  const [postExtendaBracketColours, setPostExtendaBracketColours] = useState<IColour[] | undefined>()

  const [postExtendaBracketUnitPrice, setPostExtendaBracketUnitPrice] = useState<number>(0)
  const [postExtendaBracketProductMastersId, setPostExtendaBracketProductMastersId] = useState<number>(0)

  const [{loading: fetchingPostProductIdByName}, getPostProductIdByNameAsync] = useAsyncFn(async (
    postProductName: string) => {
    const productId: any = await getProductIdByName(postProductName, PRODUCT_IMPORT_TYPE_POST)
    if (productId != null) {
      if (postProductName === ORDER_TAB_POSTS) {
        setPostProductId(productId.productId)
      }
      else if (postProductName === ORDER_TAB_POSTS_KIT) {
        setPostKitProductId(productId.productId)
      }
      else {
        setPostExtendaBracketId(productId.productId)
      }
    }
  }, [setPostProductId, setPostKitProductId, setPostExtendaBracketId])

  useEffect(() => {
    const getExtendaBracketOptions = async () => {
      if (!postExtendaBracketProducts) await getPostProductsAsync(postExtendaBracketId, true)
      if (!postExtendaBracketColours) await getPostColoursAsync(postExtendaBracketId, true)
    }

    if (postExtendaBracketId) {
      getExtendaBracketOptions()
    }
  }, [postExtendaBracketId])

  const [{loading: fetchingPostProducts}, getPostProductsAsync] = useAsyncFn(async (postProductId: number, isExtendaBracket?: boolean) => {
    const postTypes: any = await getPostTypesPM(postProductId)
    if (postTypes != null) {
      if (isExtendaBracket) {
        setPostExtendaBracketProducts(postTypes.data)
      }
      else{
        setPostProducts(postTypes.data)
      }
    }
  }, [setPostProducts, setPostExtendaBracketProducts])

  const [{loading: fetchingPostBeamSizes}, getPostBeamSizesAsync] = useAsyncFn(async (postProductId: number) => {
    const beamSizes: any = await getBeamSizesPM(postProductId)
    if (beamSizes != null) {
      setPostBeamSizes(beamSizes.data)
    }
  }, [setPostBeamSizes])

  const [{loading: fetchingPostColours}, getPostColoursAsync] = useAsyncFn(async (postProductId: number, isExtendaBracket?: boolean) => {
    const postColours: any = await getAllPostColoursPM(postProductId)
    if (postColours != null) {
      if (isExtendaBracket) {
        setPostExtendaBracketColours(postColours.data)
      }
      else {
        setPostColours(postColours.data)
      }
    }
  }, [setPostColours, setPostExtendaBracketColours])

  const [{loading: fetchingPostExtenderLengths}, getPostExtenderLengthsAsync] = useAsyncFn(async () => {
    const extenderLengths: any = await getPostExtenderLengths()
    if (extenderLengths != null) {
      setPostExtenderLengths(extenderLengths.data)
    }
  }, [setPostExtenderLengths])

  const [{loading: fetchingPostUnitPrice}, getPostUnitPriceAsync] = useAsyncFn(
    async (postProductId: number, postTypeId: number, colourId: number, beamSizeId: number, extenderLengthId: number) => {
      console.log('extenderLengthId0', extenderLengthId)
    const postUnitPrice: any = await getPostUnitPrice(postProductId, postTypeId, colourId, beamSizeId, extenderLengthId)
    if (postUnitPrice != null) {
      if (beamSizeId === 0) {
        setPostExtendaBracketUnitPrice(postUnitPrice.priceLevel1)
        setPostExtendaBracketProductMastersId(postUnitPrice.productMastersId)
      }
      else {
        setPostUnitPrice(postUnitPrice.priceLevel1)
        setPostProductMastersId(postUnitPrice.productMastersId)
      }
    }
  }, [setPostUnitPrice, setPostExtendaBracketUnitPrice])

  const [{loading: fetchingPostFinishes}, getPostFinishesAsync] = useAsyncFn(async () => {
    const beams: any = await getPostFinishes()
    if (beams != null) {
      setPostFinishes(beams.data)
    }
  }, [setPostFinishes])

  const [{loading: fetchingPostCategories}, getPostCategoriesAsync] = useAsyncFn(async () => {
    const beams: any = await getPostCategories()
    if (beams != null) {
      setPostCategories(beams.data)
    }
  }, [setPostCategories])

  const [{loading: fetchingPostPricings}, getPostPricingsAsync] = useAsyncFn(async () => {
    const beams: any = await getPostPricingList(dataShipping.pricebookId)
    if (beams != null) {
      setPostPricings(beams.data)
    }
  }, [setPostPricings])

  const [lightProducts, setLightProducts] = useState<ILightProduct[] | undefined>()
  const [lightProductTypes, setLightProductTypes] = useState<ILightProductType[] | undefined>()
  const [lightPanelTypes, setLightPanelTypes] = useState<ILightPanelType[] | undefined>()
  const [lightPricings, setLightPricings] = useState<ILightPricing[] | undefined>()
  const [lightUnitPricePM, setLightUnitPricePM] = useState<number>(0)
  const [lightPanelTypesPM, setLightPanelTypesPM] = useState<ILightPanelType[] | undefined>()
  const [lightProductTypesPM, setLightProductTypesPM] = useState<ILightProductType[] | undefined>()
  const [lightProductMastersId, setLightProductMastersId] = useState<number>(0)
  const [lightThicknesses, setLightThicknesses] = useState<ILightProduct[] | undefined>()

  const [deltaLowProfileLightId, setDeltaLowProfileLightId] = useState<number>(0)
  const [deltaSkylightId, setDeltaSkylightId] = useState<number>(0)
  const [{loading: fetchingLightProductIdByName}, getLightProductIdByNameAsync] = useAsyncFn(async (
    productName: string) => {
    const productId: any = await getProductIdByName(productName, PRODUCT_IMPORT_TYPE_LIGHT)
    if (productId != null) {
      if (productName === ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT) {
        setDeltaLowProfileLightId(productId.productId)
      }
      else {
        setDeltaSkylightId(productId.productId)
      }
    }
  }, [setDeltaLowProfileLightId, setDeltaSkylightId])

  const [{loading: fetchingLightUnitPrice}, getLightUnitPriceAsync] = useAsyncFn(
    async (lightProductId: number, lightsDetail: string, lightPanelTypeId?: number, lightThicknessId?: number) => {
    const lightUnitPrice: any = await getLightUnitPrice(lightProductId, lightsDetail, lightPanelTypeId, lightThicknessId)
    if (lightUnitPrice != null) {
      setLightUnitPricePM(lightUnitPrice.priceLevel1)
      setLightProductMastersId(lightUnitPrice.productMasterlistId)
    }
  }, [setLightUnitPricePM])

  const [{loading: fetchingLightProducts}, getLightProductsAsync] = useAsyncFn(async () => {
    const lightProducts: any = await getLightProductsPM()
    if (lightProducts != null) {
      setLightProducts(lightProducts.data)
    }
  }, [setLightProducts])

  const [{loading: fetchingLightProductTypes}, getLightProductTypesAsync] = useAsyncFn(async () => {
    const beams: any = await getLightProductTypesPM()
    if (beams != null) {
      setLightProductTypesPM(beams.data)
    }
  }, [setLightProductTypesPM])

  const [{loading: fetchingLightPanelTypes}, getLightPanelTypesAsync] = useAsyncFn(async () => {
    const beams: any = await getLightPanelTypesPM()
    if (beams != null) {
      setLightPanelTypesPM(beams.data)
    }
  }, [setLightPanelTypesPM])

  const [{loading: fetchingLightThicknesses}, getLightThicknessesAsync] = useAsyncFn(async () => {
    const lightThicknesses: any = await getLightThicknesses()
    if (lightThicknesses != null) {
      setLightThicknesses(lightThicknesses.data)
    }
  }, [setLightThicknesses])

  const [{loading: fetchingLightPricings}, getLightPricingsAsync] = useAsyncFn(async () => {
    const beams: any = await getLightProductMaster(dataShipping.pricebookId)
    if (beams != null) {
      setLightPricings(beams.data)
    }
  }, [setLightPricings])

  const [cartItems, setCartItems] = useState<any>(
    JSON.parse(sessionStorage.getItem('cartItems') as any) || []
  )

  const addToCart = (product: any) => {
    const existingItem = cartItems.find((item: any) => item.uniqueID === product.uniqueID)

    if (existingItem) {
      setCartItems((prevItems: any) =>
        prevItems.map((item: any) =>
          item.uniqueID === existingItem.uniqueID
            ? {
                ...item,
                quantity: item.quantity + product.quantity,
                length: item.length + product.length,
              }
            : item
        )
      )
    } else {
      setCartItems((prevItems: any) => [{...product, quantity: product.quantity}, ...prevItems])
    }
  }

  const increaseQuantity = (productId: any) => {
    setCartItems((prevItems: any) =>
      prevItems.map((item: any) =>
        item.customId === productId && item.quantity < 99
          ? {...item, quantity: item.quantity + 1}
          : item
      )
    )
  }

  const decreaseQuantity = (productId: any) => {
    setCartItems((prevItems: any) =>
      prevItems.map((item: any) =>
        item.customId === productId && item.quantity > 0
          ? {...item, quantity: Math.max(item.quantity - 1, 1)}
          : item
      )
    )
  }

  const pricePerLength = {
    default: 1000,
  }

  const whole = 1.0
  const tenth = 0.1

  const roundToTwo = (num: number) => Math.round(num * 100) / 100

  const totalPrice = cartItems.reduce((acc: any, item: any) => {
    let computedPrice = 0.0
    switch (item.type) {
      case 'panel':
        computedPrice = roundToTwo((item.unitPrice * item.quantity * item.length) / pricePerLength.default)
        break
      case 'fixing':
        computedPrice = roundToTwo(item.unitPrice * item.quantity)
        break
      case 'flashing':
        computedPrice = roundToTwo((item.unitPrice * item.quantity * (item.length === 0 ? 1 : item.length)) / pricePerLength.default)
        break
      case 'accessory':
        if (item.length > 1) {
          computedPrice = roundToTwo((item.unitPrice * (item.length / 1000)))
        } else {
          computedPrice = roundToTwo(item.unitPrice * item.quantity)
        }
        break
      case 'gutter':
        computedPrice = roundToTwo((item.unitPrice * item.quantity * item.length) / 1000)
        break
      case 'stop-end-bracket':
        computedPrice = roundToTwo(item.unitPrice * item.quantity)
        break
      case 'beam':
        if (item?.length) {
          computedPrice = roundToTwo((item.unitPrice * item.quantity * item.length) / 1000)
        }
        else {
          computedPrice = roundToTwo(item.unitPrice * item.quantity)
        }
        break
      case 'post-kit':
        computedPrice = roundToTwo(item.unitPrice * (item.length / 1000) * item.quantity)
        break
      case 'post-bracket':
        computedPrice = roundToTwo(item.unitPrice * item.quantity)
        break
      case 'post-item':
        computedPrice = roundToTwo(item.unitPrice * (item.length / 1000) * item.quantity)
        break
      case 'post-extendabracket':
        computedPrice = roundToTwo(item.unitPrice * item.quantity)
        break
      case 'lights':
        if (item?.length){
          computedPrice = roundToTwo(item.unitPrice * (item.length / 1000) * item.quantity)
        }
        else{
          computedPrice = roundToTwo(item.unitPrice * item.quantity)
        }
        
        break
    }
    return acc + (computedPrice || 0)
  }, 0)

  const cartItemsWithGST = cartItems?.map((item: any) => {
    if (item.type === 'fixing') {
      return {
        ...item,
        gst: item.length
          ? (item.unitPrice * item.length * item.quantity * 0.1) / 1000
          : item.fixingName === 'Tek Screws' || item.fixingName === 'Rivets'
          ? ((item.unitPrice * item.quantity) / 100) * 0.1
          : item.unitPrice * item.quantity * 0.1,
      }
    } else if (item.type === 'gutter' || item.type === 'stop-end-bracket') {
      return {
        ...item,
        gst: item.length
          ? (item.unitPrice * item.length * item.quantity * 0.1) / 1000
          : item.unitPrice * item.quantity * 0.1,
      }
    } else if (item.type === 'accessory') {
      return {
        ...item,
        gst:
          item.lengthFactor > 1
            ? ((item.unitPrice * item.length) / item.lengthFactor) * tenth
            : item.unitPrice * item.quantity * tenth,
      }
    } else {
      return {
        ...item,
        gst: item.length
          ? (item.unitPrice * item.length * item.quantity * 0.1) / 1000
          : item.unitPrice * item.quantity * 0.1,
      }
    }
  })

  const [colours, setColours] = useState<IColour[] | undefined>()
  const [{loading: fetchingColour}, getColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])

  const handleDeleteOrderItem = (productId: any) => {
    setCartItems(cartItems.filter((item: any) => item.customId !== productId))
  }


  const totalGST = cartItems.reduce((acc: any, item: any) => {
    let computedPrice = 0.0
    switch (item.type) {
      case 'panel':
        computedPrice = roundToTwo(((item.unitPrice * item.quantity * item.length) / pricePerLength.default) * .10)
        break
      case 'fixing':
        computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        break
      case 'flashing':
        computedPrice = roundToTwo(((item.unitPrice * item.quantity * (item.length === 0 ? 1 : item.length)) / pricePerLength.default) * .10)
        break
      case 'accessory':
        if (item.length > 1) {
          computedPrice = roundToTwo((item.unitPrice * (item.length / 1000)) * .10)
        } else {
          computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        }
        break
      case 'gutter':
        computedPrice = roundToTwo(((item.unitPrice * item.quantity * item.length) / 1000) * .10)
        break
      case 'stop-end-bracket':
        computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        break
      case 'beam':
        if (item?.length) {
          computedPrice = roundToTwo(((item.unitPrice * item.quantity * item.length) / 1000) * .10)
        }
        else {
          computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)  
        }
        break
      case 'post-kit':
        computedPrice = roundToTwo((item.unitPrice * (item.length / 1000) * item.quantity) * .10)
        break
      case 'post-bracket':
        computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        break
      case 'post-item':
        computedPrice = roundToTwo((item.unitPrice * (item.length / 1000) * item.quantity) * .10)
        break
      case 'post-extendabracket':
        computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        break
      case 'lights':
        if (item?.length){
          computedPrice = roundToTwo((item.unitPrice * (item.length / 1000) * item.quantity) * .10)
        }
        else{
          computedPrice = roundToTwo((item.unitPrice * item.quantity) * .10)
        }
        
        break
    }
    return acc + (computedPrice || 0)
  }, 0)



  // const totalGST = roundToDecimalPlaces(totalPrice * tenth, 2);
  const total = totalPrice.toFixed(2)
  const getComputeSummaryTotal = () => {
    
    const miscFee = dataShipping?.deliveryFee && parseFloat(dataShipping.deliveryFee) > 0 ? 
                             dataShipping?.shipping === 'delivery'
                                ? parseFloat(dataShipping.deliveryFee)
                                : 0 : 0
    
    const revTotalGST = miscFee === 0 ? totalGST : totalGST + roundToTwo(miscFee * .1) 

    const total = dataShipping?.deliveryFee !== 'TBC' && dataShipping?.deliveryFee !== '0'
    ? (dataShipping?.shipping === 'delivery'
            ? dataShipping?.deliveryFee
                ? dataShipping?.deliveryFee
                : 0
            : 0) +
        (totalPrice + revTotalGST)
    : totalPrice + revTotalGST

    const creditcardFee =  roundToTwo((total) * 0.0075)
    const creditcardmiscFee = dataShipping?.shipping === 'delivery'
    ? dataShipping?.deliveryFee !== 'TBC'
        ? dataShipping?.deliveryFee ? dataShipping?.deliveryFee : 0
        : 0
    : 0

    const notRoundedTotal = roundToTwo(((creditcardFee + total) * 100) / 100)



    return {
        productTotalFee: totalPrice,
        totalGST: revTotalGST,
        miscFee: miscFee,
        totalProductAmount: total,
        creditcardFee: creditcardFee,
        creditcardmiscFee: creditcardmiscFee,
        totalAmount: notRoundedTotal
        // * 0.75
    }
  }

  useEffect(() => {
    sessionStorage.setItem('cartItems', JSON.stringify(cartItems))
    localStorage.setItem('totalPrice', JSON.stringify(totalPrice))
    localStorage.setItem('cartItemsWithGST', JSON.stringify(cartItemsWithGST))
  }, [cartItems])


  return (
    <QuickOrderContext.Provider
      value={{
        addToCart,
        increaseQuantity,
        decreaseQuantity,
        cartItems,
        setCartItems,
        totalPrice,
        handleDeleteOrderItem,
        getPanelSetupAsync,
        fetchingPanelSetup,
        panelSetup,
        getFixingsAsync,
        getFixingPricingsAsync,
        fixings,
        fixingPricings,
        fetchingFlashingSetup,
        getAllFixingProductAsync,
        fixingMasterList,
        getFlashingSetupAsync,
        flashingSetup,
        flashingProducts,
        getAllFlashingProductAsync,
        steelBeamEndCapId,
        aluminiumBeamEndCapId,
        beamSpacerId,
        beamJoinerId,
        getBeamProductIdsAsync,        
        getBeamsAsync,
        getBeamTypesAsync,
        getBeamFinishesAsync,
        getBeamPricingsAsync,
        getBeamEndCapUnitPriceAsync,
        getBeamSpacerUnitPriceAsync,
        getBeamJoinerUnitPriceAsync,
        setBeamEndCapUnitPrice,
        beamEndCapUnitPrice,
        beamSpacerUnitPrice,
        beamJoinerUnitPrice,
        beamEndCapProductMastersId,
        beamSpacerProductMastersId,
        beamJoinerProductMastersId,
        beams,
        beamTypes,
        beamFinishes,
        beamPricings,
        getBeamFasciaHangingUnitPriceAsync,
        getBeamFasciaHangingColoursAsync,
        getBeamFasciaHangingSizesAsync,
        beamFasciaHangingSizes,
        beamFasciaHangingColours,
        beamFasciaHangingUnitPrice,
        setBeamFasciaHangingUnitPrice,
        beamFasciaHangingProductMastersId,
        getPostProductsAsync,
        getPostBeamSizesAsync,
        getPostFinishesAsync,
        getPostCategoriesAsync,
        getPostPricingsAsync,
        getPostExtenderLengthsAsync,
        postExtenderLengths,
        postProducts,
        postTypes,
        postFinishes,
        postCategories,
        postPricings,
        getPostProductIdByNameAsync,
        postProductId,
        postKitProductId,
        postExtendaBracketId,
        getLightProductsAsync,
        getLightProductTypesAsync,
        getLightPanelTypesAsync,
        getLightPricingsAsync,
        getLightThicknessesAsync,
        deltaLowProfileLightId,
        deltaSkylightId,
        getLightProductIdByNameAsync,
        lightThicknesses,
        lightProducts,
        lightProductTypes,
        lightPanelTypes,
        lightPricings,
        cartItemsWithGST,
        getColoursAsync,
        colours,
        fetchingColour,
        pricePerLength,
        beamColours,
        getBeamColoursAsync,
        beamEndCapColours,
        getBeamEndCapColoursAsync,
        beamUnitPrice, 
        setBeamUnitPrice,
        getBeamUnitPriceAsync,
        isBeamTabQuantityOnly,
        setIsBeamTabQuantityOnly,
        isBeamTabNoColourOnly,
        setIsBeamTabNoColourOnly,
        beamCheckColumnNullAsync,
        fetchingBeamCheckColumnNull,
        postBeamSizes,
        setPostBeamSizes,
        getPostColoursAsync,
        setPostColours,
        postColours,
        getPostUnitPriceAsync,
        setPostUnitPrice,
        postUnitPrice,
        postExtendaBracketUnitPrice,
        setPostExtendaBracketUnitPrice,
        postExtendaBracketProductMastersId,
        postExtendaBracketProducts,
        postExtendaBracketColours,
        panelMasterList,
        fetchingPanelMasterList,
        getPanelMasterListAsync,
        getComputeSummaryTotal,
        beamProductMastersId,
        postProductMastersId,
        lightUnitPricePM,
        setLightUnitPricePM,
        getLightUnitPriceAsync,
        lightPanelTypesPM,
        setLightPanelTypesPM,
        lightProductTypesPM,
        setLightProductTypesPM,
        lightProductMastersId,
        getRainwaterProductAsync,
        rainwaterProductMasters,
        stopEndId,
        getRainwaterProductIdByNameAsync,
        isFlashingColourColumnNull,
        flashingCheckColumnNullAsync,
        accessoryProductMasters,
        getAccessoryProductAsync
      }}
    >
      {children}
    </QuickOrderContext.Provider>
  )
}
