import React, {useState, useEffect, useRef, useContext} from 'react'
import axios from 'axios'
import '../Products.css'
import PostsProduct from './PostsProduct'
import {
  getPostCategories,
  getPostFinishes,
  getPostKitPricingPaginated,
  getPostProducts,
  getPostTypes,
  getPostUnitTypes,
  getPostMasterlist,
  createPostProduct,
  updatePostProduct,
  deletePostProduct,
} from '../../../../../api/posts-api'
import {getColours} from '../../../../../api/index'
import {getBeamSizes} from '../../../../../api/beams-api'
import {useAsyncFn, useEffectOnce} from 'react-use'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {IPostCategory} from '../../../../../models/posts/postCategory'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import {useHistory} from 'react-router-dom'

type Props = {
  product: string
  productId: number
}

const Posts = (props: Props) => {
  const {
    postTypes,
    setPostTypes,
    postProducts,
    setPostProducts,
    postFinishes,
    setPostFinishes,
    postCategories,
    setPostCategories,
    postUnitTypes,
    setPostUnitTypes,
    postProduct,
    setPostProduct,
    colours,
    setColours,
    beamSizes,
    setBeamSizes,
  } = useContext(AdminProductContext)

  const {product, productId} = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isSydAdding, setIsSydAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const baseURL = process.env.REACT_APP_API_URL

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')
  const [Filter4, setFilter4] = useState('')
  const [Filter5, setFilter5] = useState('')

  const history = useHistory()

  /**
   * REFS
   */
  const nameRef = useRef<any>()
  const unitRef = useRef<any>()
  const beamClassRef = useRef<any>()
  const priceRef = useRef<any>()
  const _priceRef = useRef<any>()
  const _nameRef = useRef<any>()
  const _unitRef = useRef<any>()
  const _beamClassRef = useRef<any>()
  const regex = /^[a-zA-Z0-9\s./-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/

  /**
   * INITIAL LOAD
   */
  useEffectOnce(() => {
    if (!postTypes && !fetchPostTypes) {
      fetchPostTypesAsync()
    }
    if (!fetchPostProducts && !postProduct) {
      fetchPostProductsAsync()
    }
    if (!fetchPostFinishes && !postFinishes) {
      fetchPostFinishesAsync()
    }
    if (!fetchPostCategories && !postCategories) {
      fetchPostCategoriesAsync()
    }
    if (!fetchPostUnitTypes && !postUnitTypes) {
      fetchPostUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
    if (!fetchBeamSizes && !beamSizes) {
      getBeamSizesAsync()
    }
  })

  /**
   * FETCH RECORD
   */
  const [{loading: fetchBeamSizes}, getBeamSizesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamSizes()
    if (result != null) {
      setBeamSizes(result.data)
    }
  }, [setBeamSizes])

  const [{loading: fetchPostTypes}, fetchPostTypesAsync] = useAsyncFn(async () => {
    const result: any = await getPostTypes()
    if (result != null) {
      setPostTypes(result.data)
    }
  }, [setPostTypes])

  const [{loading: fetchPostProducts}, fetchPostProductsAsync] = useAsyncFn(async () => {
    const result: any = await getPostProducts()
    if (result != null) {
      setPostProduct(result.data)
    }
  }, [setPostProduct])

  const [{loading: fetchPostFinishes}, fetchPostFinishesAsync] = useAsyncFn(async () => {
    const result: any = await getPostFinishes()
    if (result != null) {
      setPostFinishes(result.data)
    }
  }, [setPostFinishes])

  const [{loading: fetchPostCategories}, fetchPostCategoriesAsync] = useAsyncFn(async () => {
    const result: any = await getPostCategories()
    if (result != null) {
      setPostCategories(result.data)
    }
  }, [setPostCategories])

  const [{loading: fetchPostUnitTypes}, fetchPostUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getPostUnitTypes()
    if (result != null) {
      setPostUnitTypes(result.data)
    }
  }, [setPostUnitTypes])

  const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])

  /**
   * POSTS-SEQ
   */
  const [seqData, setSeqData] = useState<any>()
  const [newSeqProduct, setNewSeqProduct] = useState({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})
  /**
   * PAGINATION
   */

  const handleFilter = (filter1: any, filter2: any, filter3: any, filter4: any, filter5: any) => {
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setFilter4(filter4)
    setFilter5(filter5)
    setSeqCurrentPage(1)
  }

  // useEffect(() => {
  //   setSeqCurrentPage(1)
  // }, [Filter1, Filter2, Filter3, Filter4])

  useEffect(() => {
    setSeqPostsKitCurrentPage(1)
    setSeqCurrentPage(1)
  }, [productId])

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    if (product === 'SEQ Pricebook') {
      setIsSeqAdding(false)
    } else {
      setIsSydAdding(false)
    }
  }
  const handleCancel = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target

    console.log(name, value)
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }

    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({...prevData, [name]: value}))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deletePostProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchPostProduct(seqCurrentPage)
        handleSuccessMsg('Product deleted successfully.')
      } else {
        const errorMessage =
          result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Post:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }

    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
  }
  /**
   * UPDATE
   */
  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg('Product updated sucessfully.')
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewSeqProduct({})
    setIsSeqAdding(false)
  }
  const handleUpdateProduct = (iProductId: any) => {
    history.push(`/admin/product-details/Post/${iProductId}`)
  }
  const handleSave = () => {
    const newData = seqData.map((item: any) => (item.id === editingSeqId ? editedSeqData : item))
    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  /**
   * ADD
   */

  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData([newSeqProduct, ...seqData])
    setIsSeqAdding(false)
  }
  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(nameRef.current.value)) {
      nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(unitRef.current.value)) {
      unitRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(beamClassRef.current.value)) {
      beamClassRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    setNewSeqProduct({...newSeqProduct, [name]: value})
  }

  useEffect(() => {
    fetchPostProduct(seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5)
  }, [seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5])

  const fetchPostProduct = async (
    currentPage: number,
    filter1?: any,
    filter2?: any,
    filter3?: any,
    filter4?: any,
    filter5?: any
  ) => {
    await getPostMasterlist(
      currentPage,
      seqDataPerPage,
      filter1,
      filter2,
      filter3,
      filter4,
      filter5
    ).then((response: any) => {
      setSeqData(response.data)
      setSeqTotalPages(Math.ceil(response.data?.totalCount / 10))
    })
  }

  const productFields = [
    {label: 'Item Number', key: 'code', type: 'text'},
    {label: 'Product Type', key: 'postProductName', type: 'text'},
    {label: 'Post Type', key: 'postTypeName', type: 'text'},
    {label: 'Beam Size', key: 'beamSizeId', type: 'dropdown', options: beamSizes},
    {label: 'Colour', key: 'colourId', type: 'dropdown', options: colours},
  ]

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    console.log('newProduct', newProduct)
    createPostProduct(newProduct)
      .then((result: any) => {
        if (result.data) {
          handleSuccessMsg('Product added successfully.')
          fetchPostProduct(seqCurrentPage)
        } else {
          handleErrorMessage(result?.response?.data)
        }
      })
      .catch(() => {
        handleErrorMessage('Error Encountered While Saving. Please try again later.')
      })

    setIsSeqAdding(false)
  }

  const [isPostsKitModalOpen, setIsPostsKitModalOpen] = useState(false)
  const [isPostsKitAdding, setIsPostsKitAdding] = useState(false)
  const [itemPostsKitToDelete, setItemPostsKitToDelete] = useState(null)

  /**
   * POSTS-KIT-SEQ
   */
  const [seqPostsKitData, setSeqPostsKitData] = useState<any>()
  const [newSeqPostsKitProduct, setNewSeqPostsKitProduct] = useState({})
  const [seqPostsKitCurrentPage, setSeqPostsKitCurrentPage] = useState(1)
  const [seqPostsKitTotalPages, setSeqPostsKitTotalPages] = useState(1)
  const [seqPostsKitDataPerPage] = useState(10)
  const [editingSeqPostsKitId, setEditingSeqPostsKitId] = useState(null)
  const [editedSeqPostsKitData, setEditedSeqPostsKitData] = useState({})

  const fetchPostKitPricings = (currentPage: number) => {
    getPostKitPricingPaginated(currentPage, seqDataPerPage, productId).then((response: any) => {
      setSeqPostsKitData(response.data)
      setSeqPostsKitTotalPages(Math.ceil(response.data.totalCount / 10))
    })
  }

  const handlePostsKitPrevPage = () => {
    setSeqPostsKitCurrentPage((prevPage) => prevPage - 1)
  }

  const handlePostsKitNextPage = () => {
    setSeqPostsKitCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePostsKitChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }

    if (regex.test(value)) {
      setEditedSeqPostsKitData((prevData: any) => ({...prevData, [name]: value}))
    }
  }

  const handlePostsKitCancelClick = () => {
    setErrorMsgPostkits(undefined)
    setIsPostsKitAdding(false)
  }

  const handlePostsKitCancel = () => {
    setErrorMsgPostkits(undefined)
    setEditingSeqPostsKitId(null)
    setEditedSeqPostsKitData({})
  }

  const handleDeletePostsKitProduct = (id: any) => {
    setItemPostsKitToDelete(id)
    setIsPostsKitModalOpen(true)
  }

  const handlePostsKitConfirmDelete = () => {
    // deletePostPricing(itemPostsKitToDelete || 0).then(() => {
    //   const newData = seqPostsKitData.items?.filter((item: any) => item.id !== itemPostsKitToDelete)
    //   setSeqPostsKitData({
    //     ...seqPostsKitData,
    //     items: newData,
    //   })
    // })
    // setIsPostsKitModalOpen(false)
    // setItemPostsKitToDelete(null)
  }

  const handlePostsKitCancelDelete = () => {
    setItemPostsKitToDelete(null)
    setIsPostsKitModalOpen(false)
  }

  /**
   * UPDATE
   */
  const handleUpdatePostsKitSuccess = (newItem: any) => {
    if (!isPostsKitAdding) {
      const newData = {
        ...seqPostsKitData,
        items: seqPostsKitData.items?.map((item: any) =>
          item.id === editingSeqPostsKitId ? newItem : item
        ),
      }
      setSeqPostsKitData(newData)
    } else {
      seqPostsKitData.items.unshift(newItem)
    }

    setErrorMsgPostkits(undefined)
    setEditingSeqPostsKitId(null)
    setEditedSeqPostsKitData({})
    setNewSeqPostsKitProduct({})
    setIsPostsKitAdding(false)
  }
  const handleUpdatePostsKitProduct = (id: any) => {
    setEditingSeqPostsKitId(id)
    const row = seqPostsKitData.items.find((item: any) => item.id === id)
    setEditedSeqPostsKitData({...row})
  }

  const handlePostsKitSave = () => {
    const newData = seqPostsKitData.map((item: any) =>
      item.id === editingSeqPostsKitId ? editedSeqPostsKitData : item
    )
    setSeqPostsKitData(newData)
    setEditingSeqPostsKitId(null)
    setEditedSeqPostsKitData({})
  }

  /**
   * ADD
   */
  const handlePostsKitAddProduct = () => {
    const newProduct = {
      id: Math.random(),
      postProductName: '',
      postTypeName: '',
      postFinishName: '',
      unit: '',
      productId: productId,
      postCategoryId: postCategories.find((x: IPostCategory) => x.name === 'Post Kit')?.id,
    }
    setIsPostsKitAdding(true)
    setNewSeqPostsKitProduct(newProduct)
  }

  const handlePostsKitSaveClick = () => {
    setSeqPostsKitData([newSeqPostsKitProduct, ...seqPostsKitData])
    setIsPostsKitAdding(false)
  }

  const handlePostsKitInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(_priceRef.current.value)) {
      _priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(_nameRef.current.value)) {
      _nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(_unitRef.current.value)) {
      _unitRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(_beamClassRef.current.value)) {
      _beamClassRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    setNewSeqPostsKitProduct({...newSeqPostsKitProduct, [name]: value})
  }

  useEffect(() => {
    fetchPostKitPricings(seqPostsKitCurrentPage)
  }, [seqPostsKitCurrentPage])

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const [errorMsgPostkits, setErrorMsgPostkits] = useState<string | undefined>()
  const handleErrorMessagePostkits = (errorMsg: string) => {
    setErrorMsgPostkits(errorMsg)
  }

  return (
    <>
      <div>
        <>
          <div className='card-body' style={{backgroundColor: '#fff'}}>
            <AddProductButton onClick={handleAddProduct} />
            <div style={{overflowY: 'auto', height: '95%'}}>
              {errorMsg && (
                <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
                  <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
                </div>
              )}
              {successMsg && (
                <div className='alert alert-success w-50 m-auto text-center' role='alert'>
                  <i className='fa fa-check-circle text-success'></i> {successMsg}
                </div>
              )}

              <PostsProduct
                data={seqData}
                currentPage={seqCurrentPage}
                totalPages={seqTotalPages}
                handleUpdateProduct={handleUpdateProduct}
                handleSave={handleSave}
                editingId={editingSeqId}
                editedData={editedSeqData}
                handleSaveClick={handleSaveClick}
                handleInputChange={handleInputChange}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                handleCancelClick={handleCancelClick}
                handleCancel={handleCancel}
                handleDeleteProduct={handleDeleteProduct}
                handleChange={handleChange}
                isAdding={isSeqAdding}
                nameRef={nameRef}
                unitRef={unitRef}
                beamClassRef={beamClassRef}
                priceRef={priceRef}
                newProduct={newSeqProduct}
                handleUpdateSuccess={handleUpdateSuccess}
                handleErrorMessage={handleErrorMessage}
                handleFilter={handleFilter}
              />
            </div>
          </div>
          <DeleteModal
            isModalOpen={isModalOpen}
            handleConfirmDelete={handleConfirmDelete}
            handleCancelDelete={handleCancelDelete}
            isDeleting={isDeleting}
          />
          <NewProductModal
            isOpen={isAddingProduct}
            onClose={handleCloseNewProductModal}
            onSave={handleSaveNewProduct}
            fields={productFields}
          />
        </>
        <></>
      </div>
    </>
  )
}

export default Posts
