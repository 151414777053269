export enum ProductPanelMaster {
  code,
  profile,
  core,
  coreThickness,
  ceilingColour,
  roofColour,
  seqPriceLevel1,
  seqPriceLevel2,
  seqPriceLevel3,
  seqPriceLevel4,
  seqPriceLevel5,
  seqPriceLevel6,
  sydPriceLevel1,
  sydPriceLevel2,
  sydPriceLevel3,
  sydPriceLevel4,
  sydPriceLevel5,
  sydPriceLevel6,
  northQLDPriceLevel1,
  northQLDPriceLevel2,
  northQLDPriceLevel3,
  northQLDPriceLevel4,
  northQLDPriceLevel5,
  northQLDPriceLevel6,
}

export enum ProductMasterFixings {
  code,
  screwName,
  screwType,
  colour,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterFlashings {
  code,
  flashingType,
  panelProfile,
  panelCoreThickness,
  colour,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterRainwaters {
  code,
  product,
  detailStyle,
  colour,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterBeams {
  code,
  product,
  size,
  colour,
  description,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterPosts {
  code,
  product,
  type,
  beamSize,
  extenderLength,
  colour,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterLights {
  code,
  product,
  profile,
  thickness,
  detail,
  quantity,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}

export enum ProductMasterAccessories {
  code,
  product,
  detailStyle,
  colour,
  priceLevel1,
  priceLevel2,
  priceLevel3,
  priceLevel4,
  priceLevel5,
  priceLevel6,
}
