import axios from 'axios'

export const PRODUCTMASTER_URL = `${process.env.REACT_APP_API_URL}/productmaster`
export const saveProductMaster = async (productList: any) => {
  try {
    return await axios.post(`${PRODUCTMASTER_URL}`, productList)
  } catch (err) {
    console.log('err', err)
    return err
  }
}
