import React, {useState} from 'react'
import { getPriceBooks } from '../api/pricebook'
import { useAsyncFn } from 'react-use'

export const AdminContext = React.createContext<any>({})

let progressInterval: any
export const AdminContextProvider: React.FC = ({children}) => {
  const [message, setMessage] = useState<any>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const startProgress = () => {
    if (progressInterval) {
      setProgress(6)
      clearInterval(progressInterval)
    }
    setMessage(null)
    progressInterval = setInterval(() => {
      // Your action or function to be executed at regular intervals
      if (progress > 90) return
      setProgress((prevCounter: any) => prevCounter + 3)
    }, 3000) // Ad
  }

  const stopProgress = () => {
    if (progressInterval) {
      clearInterval(progressInterval)
    }
    setProgress(0)

    // setTimeout(() => {
    //   setProgress(0)
    // }, 1000)
  }

   // ** Unit Type **//
   const [priceBooks, setPriceBooks] = useState<[] | undefined>()
   const [{ loading: fetchPricebook }, getPriceBooksAsync] = useAsyncFn(async () => {
       const result: any = await getPriceBooks()
       if (result != null) {
           setPriceBooks(result.data)
       }
   }, [setPriceBooks])
   

  return (
    <AdminContext.Provider
      value={{
        message,
        setMessage,
        isUploading,
        setIsUploading,
        progress,
        setProgress,
        startProgress,
        stopProgress,
        priceBooks, 
        getPriceBooksAsync
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
