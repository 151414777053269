import React, {Fragment, useContext, useState, useEffect} from 'react'
import {moneyFormatter} from '../../../../../../utils'
import {QuickOrderContext} from '../../../../../context/QuickOrderContext'
import { getUniqueIdWithPrefix } from '../../../../../../_metronic/assets/ts/_utils'
import { 
  ORDER_TAB_RAINWATER_STOPENDBRACKET_DROPPER,
  ORDER_TAB_RAINWATER_STOPENDBRACKET_OVERSTRAP,
} from '../../../../../common/constant'

function NewStopEndBracketItem(props: any) {
  const {items, selectedGutterItem, setSelectedGutterItem, selectedGutterItemForStopEndBracket, pricings, 
    stopEndPrice, setStopEndPrice, stopEndQuantity, setStopEndQuantity, gutterBracketPrice, setGutterBracketPrice,
    gutterBracketQuantity, setGutterBracketQuantity, isGutterBracketChecked, setIsGutterBracketChecked,
    isStopEndChecked, setIsStopEndChecked, stopEndColor, setStopEndColor, setStopEndColourId, setStopEndColorName,
    isGutterDropperChecked, setIsGutterDropperChecked, gutterDropperPrice, setGutterDropperPrice,
    gutterDropperQuantity, setGutterDropperQuantity,
    isGutterOverstrapChecked, setIsGutterOverstrapChecked, gutterOverstrapPrice, setGutterOverstrapPrice,
    gutterOverstrapQuantity, setGutterOverstrapQuantity} = props

  const {addToCart, colours, cartItems, stopEndId} = useContext(QuickOrderContext)

  useEffect(() => {
    loadColourOption(stopEndId)
  }, [])

  useEffect(() => {
    setGutterDropperPrice(
      pricings.find(
        (item: any) =>
          item?.rainwaterPartName === ORDER_TAB_RAINWATER_STOPENDBRACKET_DROPPER
      )
    )
    setGutterOverstrapPrice(
      pricings.find(
        (item: any) =>
          item?.rainwaterPartName === ORDER_TAB_RAINWATER_STOPENDBRACKET_OVERSTRAP
      )
    )
  }, [gutterDropperPrice, gutterDropperPrice])

  useEffect(() => {
    if (Object.keys(selectedGutterItemForStopEndBracket || {}).length !== 0) {
      setStopEndPrice(
        pricings.find(
          (item: any) =>
            item?.rainwaterPartName === 'Gutter Stop End' &&
            item?.rainwaterStyleId === +selectedGutterItemForStopEndBracket?.rainwaterStyleId &&
            item?.rainwaterColourId === stopEndColor
        )
      )
      props.setStopEndPrice(
        pricings.find(
          (item: any) =>
            item?.rainwaterPartName === 'Gutter Stop End' &&
            item?.rainwaterStyleId === +selectedGutterItemForStopEndBracket?.rainwaterStyleId &&
            item?.rainwaterColourId === stopEndColor
        )
      )
      setGutterBracketPrice(
        pricings.find(
          (item: any) =>
            item?.rainwaterPartName === 'Gutter Bracket' &&
            item?.rainwaterStyleId === +selectedGutterItemForStopEndBracket?.rainwaterStyleId 
        )
      )
      props.setGutterBracketPrice(
        pricings.find(
          (item: any) =>
            item?.rainwaterPartName === 'Gutter Bracket' &&
            item?.rainwaterStyleId === +selectedGutterItemForStopEndBracket?.rainwaterStyleId
        )
      )
    } else {
      setStopEndPrice(0)
      setGutterBracketPrice(0)
    }
  }, [selectedGutterItemForStopEndBracket, stopEndColor])

  const handleStopEndCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsStopEndChecked(true)
    } else {
      setIsStopEndChecked(false)
    }
  }

  const handleGutterBracketCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsGutterBracketChecked(true)
    } else {
      setIsGutterBracketChecked(false)
    }
  }

  const handleGutterDropperCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsGutterDropperChecked(true)
    } else {
      setIsGutterDropperChecked(false)
    }
  }

  const handleGutterOverstrapCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsGutterOverstrapChecked(true)
    } else {
      setIsGutterOverstrapChecked(false)
    }
  }

  /**
   * GUTTER OVERSTRAP QUANTITY
   */
  
  const handleGutterOverstrapQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber < 0 || inputNumber > 99) {
      return
    }
    setGutterOverstrapQuantity(inputNumber)
  }

  /**
   * GUTTER DROPPER QUANTITY
   */
  
  const handleGutterDropperQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber < 0 || inputNumber > 99) {
      return
    }
    setGutterDropperQuantity(inputNumber)
  }

  /**
   * GUTTER BRACKET QUANTITY
   */
  
  const handleGutterBracketQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber < 0 || inputNumber > 99) {
      return
    }
    setGutterBracketQuantity(inputNumber)
  }

  /**
   * STOP END QUANTITY
   */
  const handleStopEndQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber < 0 || inputNumber > 99) {
      return
    }
    setStopEndQuantity(inputNumber)
  }

    const [colourOption, setColourOption] = useState<any[]>([])
    const handleColorOnChange = (e: any) => {
        const selectedIndex = e.target.options.selectedIndex
        const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
        setStopEndColourId(+e.target.value)
        setStopEndColor(+e.target.value)
        setStopEndColorName(name)
    }

   const loadColourOption = (rainwaterPartId: number) => {
    const availableColour = items.filter((item: any) => 
            item.rainwaterPartId == rainwaterPartId
        )
        const colourSelection: any = Object.values(
            availableColour.reduce((map: any, obj: any) => {
                const key = `${obj.rainwaterColourId}-${obj.rainwaterColourName}`
                if (!map[key]) {
                map[key] = obj
                }
                return map
            }, {} as Record<string, any>)
        )

        const result = (colourSelection || [])?.map((availPricing: any) => ({
            id: availPricing.rainwaterColourId,
            name: availPricing.rainwaterColourName,
        }))

            // default selection for colour
        const existsDataColour = result.filter((item1: any) =>
            cartItems.some(
                (x: any) =>
                    x.type === 'panel' &&
                    x.topSheetColourName.toLowerCase().includes(item1.name.toLowerCase())
            )
        )
        if (existsDataColour) {
            if (existsDataColour && existsDataColour.length === 1) {
                const defaultColour = existsDataColour[0]
                setStopEndColourId(defaultColour.id)
                setStopEndColor(defaultColour.id)
                setStopEndColorName(defaultColour.name)
            } else {
                setStopEndColor(0)
                setStopEndColourId(0)
            }
        } else {
            setStopEndColor(0)
            setStopEndColourId(0)
        }


        setColourOption(result)
   }

  const disabledStopEnd =
    !isStopEndChecked ||
    stopEndQuantity === '' ||
    isNaN(stopEndQuantity) ||
    !props.isStopEndChecked ||
    props.stopEndQuantity === '' ||
    isNaN(props.stopEndQuantity)
  const disabledGutterBracket =
    !isGutterBracketChecked ||
    gutterBracketQuantity === '' ||
    isNaN(gutterBracketQuantity) ||
    !props.isGutterBracketChecked ||
    props.gutterBracketQuantity === '' ||
    isNaN(props.gutterBracketQuantity)

  return (
    <Fragment>
      <div className='row  mb-4'>
        <div className='col-md-3  d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='stopEnd'
              name='stopEnd'
              onChange={handleStopEndCheckboxChange}
              // checked={isStopEndChecked}
              checked={props.isStopEndChecked}
              style={{marginRight: 10}}
              disabled={
                Object.keys(selectedGutterItemForStopEndBracket || {}).length === 0 ||
                selectedGutterItemForStopEndBracket === undefined
                // ||
                // isGutterBracketChecked
              }
            />
            <label className='fw-bolder text-dark'>Stop End</label>
          </div>
          <span style={{fontSize: 16, fontWeight: 'bold'}}>
            {/* {moneyFormatter.format(
              stopEndPrice?.price !== undefined && selectedGutterItem !== undefined
                ? stopEndPrice?.price
                : 0
            )} */}
            {moneyFormatter.format(
              stopEndPrice?.priceLevel1 !== undefined &&
                props.stopEndPrice &&
                selectedGutterItemForStopEndBracket !== undefined
                ? stopEndPrice?.priceLevel1
                : 0
            )}
          </span>
        </div>
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='stopEndQuantity'
            aria-describedby='stopEndQuantity'
            // value={stopEndQuantity || ''}
            value={props.stopEndQuantity || ''}
            onChange={handleStopEndQuantityChange}
            placeholder='Enter Quantity'
            disabled={
              !isStopEndChecked
            }
          />
        </div>
        {/* Colour */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Colour</label>
          <select
              style={{ fontSize: '1rem' }}
              className='form-select'
              value={stopEndColor}
              onChange={handleColorOnChange}
              disabled={!isStopEndChecked}
          >
              <option value={0}>Select</option>
              {colourOption?.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                      {option.name}
                  </option>
              ))}
          </select>
        </div>
        <div className='col-md-3'>
          {/* <button
            className='btn btn-primary w-100'
            disabled={disabledStopEnd}
            onClick={() => {
              

            }}
          >
            <i className='fa fa-plus'></i>ADD
          </button> */}
        </div>
      </div>
      <div className='row  mb-4'>
        <div className='col-md-3 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='gutterBracket'
              name='gutterBracket'
              // checked={isGutterBracketChecked}
              checked={props.isGutterBracketChecked}
              onChange={handleGutterBracketCheckboxChange}
              style={{marginRight: 10}}
              disabled={
                Object.keys(selectedGutterItemForStopEndBracket || {}).length === 0 ||
                selectedGutterItemForStopEndBracket === undefined
                // ||
                // isStopEndChecked
              }
            />
            <label className='fw-bolder text-dark'>Gutter Bracket</label>
          </div>
          <span style={{fontSize: 16, fontWeight: 'bold'}}>
            {/* {moneyFormatter.format(
              gutterBracketPrice?.price !== undefined && selectedGutterItem !== undefined
                ? gutterBracketPrice?.price
                : 0
            )} */}
            {moneyFormatter.format(
              gutterBracketPrice?.priceLevel1 !== undefined &&
                props.gutterBracketPrice &&
                selectedGutterItemForStopEndBracket !== undefined
                ? gutterBracketPrice?.priceLevel1
                : 0
            )}
          </span>
        </div>
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='gutterBracketQuantity'
            aria-describedby='gutterBracketQuantity'
            // value={gutterBracketQuantity || ''}
            value={props.gutterBracketQuantity || ''}
            onChange={handleGutterBracketQuantityChange}
            placeholder='Enter Quantity'
            disabled={
              !isGutterBracketChecked
            }
          />
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'>
          {/* <button
            className='btn btn-primary w-100'
            disabled={disabledGutterBracket}
            onClick={() => {

            }}
          >
            <i className='fa fa-plus'></i>ADD
          </button> */}
        </div>
      </div>
      <div className='row  mb-4'>
        <div className='col-md-3 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='gutterDropper'
              name='gutterDropper'
              checked={props.isGutterDropperChecked}
              onChange={handleGutterDropperCheckboxChange}
              style={{marginRight: 10}}
              disabled={
                !gutterDropperPrice
              }
            />
            <label className='fw-bolder text-dark'>Gutter Dropper</label>
          </div>
          <span style={{fontSize: 16, fontWeight: 'bold'}}>
            {moneyFormatter.format(
              gutterDropperPrice?.priceLevel1 !== undefined &&
                props.gutterDropperPrice &&
                selectedGutterItemForStopEndBracket !== undefined
                ? gutterDropperPrice?.priceLevel1
                : 0
            )}
          </span>
        </div>
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='gutterDropperQuantity'
            aria-describedby='gutterDropperQuantity'
            value={props.gutterDropperQuantity || ''}
            onChange={handleGutterDropperQuantityChange}
            placeholder='Enter Quantity'
            disabled={
              !isGutterDropperChecked
            }
          />
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'>
        </div>
      </div>
      <div className='row  mb-4'>
        <div className='col-md-3 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='gutterOverstrap'
              name='gutterOverstrap'
              checked={props.isGutterOverstrapChecked}
              onChange={handleGutterOverstrapCheckboxChange}
              style={{marginRight: 10}}
              disabled={
                !gutterOverstrapPrice
              }
            />
            <label className='fw-bolder text-dark'>Overstrap</label>
          </div>
          <span style={{fontSize: 16, fontWeight: 'bold'}}>
            {moneyFormatter.format(
              gutterOverstrapPrice?.priceLevel1 !== undefined &&
                props.gutterDropperPrice &&
                selectedGutterItemForStopEndBracket !== undefined
                ? gutterOverstrapPrice?.priceLevel1
                : 0
            )}
          </span>
        </div>
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='gutterOverstrapQuantity'
            aria-describedby='gutterOverstrapQuantity'
            value={props.gutterOverstrapQuantity || ''}
            onChange={handleGutterOverstrapQuantityChange}
            placeholder='Enter Quantity'
            disabled={
              !isGutterOverstrapChecked
            }
          />
        </div>
        <div className='col-md-3'></div>
        <div className='col-md-3'>
        </div>
      </div>
    </Fragment>
  )
}

export default NewStopEndBracketItem
