import React from 'react'
import {moneyFormatter} from '../../../../../utils'

function FlashingsTable(props: any) {
  const {cartItems } = props

  return (
    <div>
      <table className='table table-hover summary-table' style={{backgroundColor: '#fff'}}>
        <thead className='thead-dark'>
          <tr>
            <th scope='col' style={{width: '20%'}}>
              Description
            </th>
            <th scope='col' style={{width: '15%'}} className='text-center'>
              Colour
            </th>
            <th scope='col' style={{width: '15%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Length
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Quantity
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Unit Price
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              GST
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((item: any) => {
            if (item.type === 'flashing') {
              let computedPrice = 0.00
              let totalAmount = 0.00

              const roundToTwo = (num: number) => Math.round(num * 100) / 100

              computedPrice = (item.unitPrice * (item?.length / 1000) * item.quantity)
              totalAmount = roundToTwo(computedPrice)  + roundToTwo(item.gst)

              return (
                <tr key={item?.uniqueID}>
                  <td className='align-middle'>
                    <p className='fw-bolder text-dark mb-0 text-capitalize'>
                      {item?.flashingTypeName}
                    </p>
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                      <p className='text-dark mb-0 text-capitalize'>Panel Type:</p>
                      <p className='text-dark mb-0 text-capitalize'>{item?.subTypeName}</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                      <p className='text-dark mb-0 text-capitalize'>Thickness:</p>
                      <p className='text-dark mb-0 text-capitalize'>{item?.thicknessName}</p>
                    </div>
                  </td>
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {item?.colorName ? item?.colorName : '-'}
                  </td>
                  <td />
                  <td className='fw-bolder text-dark align-middle text-center'>{item?.length}mm</td>
                  <td className='fw-bolder text-dark align-middle text-center'>{item?.quantity}</td>
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(item?.unitPrice)}
                  </td>
                  {/* Assumption */}
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(item?.gst)}
                  </td>
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(
                      totalAmount
                    )}
                  </td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default FlashingsTable
