import React, { Fragment, useContext, useState, useEffect } from 'react'
import { moneyFormatter } from '../../../../../../utils'
import { QuickOrderContext } from '../../../../../context/QuickOrderContext'
import { 
  ORDER_LIST_ACCESSORIES
} from '../../../../../common/constant'

function NewAccessoriesItem(props: any) {
    const { accessories, selectedColourId } = props
    const { addToCart, cartItems } = useContext(QuickOrderContext)

    const storedShippingPreference = JSON.parse(localStorage.getItem('shipping-preference') || '{}')
    const priceBookId: number | undefined = parseInt(storedShippingPreference.pricebookId)
    const accessoryPricings = props.pricings?.filter((item: any) => item?.pricebookId === priceBookId)

    const [accessoryItem, setAccessoryItem] = useState<any>({})
    const [accessory, setAccessory] = useState<any>(0)

    useEffect(() => {
        if (selectedColourId) {
            setAccessory(0)
            setAccessoryItem({})
            setQuantity('')
            setLength('')
            setQtyErr(false)
            setLengthErr(false)
            setUnitTypeDisplay('')
            setQuantityFactor(0)
            setLengthFactor(0)
            setUnitPrice(0)
            setPricingId(0)
        }
    }, [selectedColourId])

    const handleAccessoryOnChange = (e: any) => {
        let accessoryId = e.target.value
        let pricing = accessoryPricings.find((item: any) => item?.accessoryStyleId === +accessoryId)
        setAccessory(+accessoryId)
        setQuantity('')
        setLength('')
        setQtyErr(false)
        setLengthErr(false)

        if (pricing && !pricing.accessoryColourName) {
            setAccessoryItem(pricing)
            setUnitPrice(pricing?.priceLevel1)
            setPricingId(pricing?.id)
        } else {
            setAccessoryItem({})
            setUnitPrice(0)
            setPricingId(0)
        }
        

        setUnitTypeDisplay('each')
        setQuantityFactor(1)
        setLengthFactor(1)

        getColourOption(+accessoryId)
        
    }

    useEffect(() => {
        if (accessoryPricings) {
            // let pricings = getPricings(0)
            // const pricingsSelection: any = Object.values(
            //     pricings.reduce((map: any, obj: any) => {
            //         const key = `${obj.accessoryPartId}-${obj.accessoryPartName}`
            //         if (!map[key]) {
            //             map[key] = obj
            //         }
            //         return map
            //     }, {} as Record<string, any>)
            // )
            // default selection for accesoryType
            // const existsData = pricingsSelection.filter((item1: any) =>
            //     cartItems.some(
            //         (x: any) =>
            //             x.type === 'panel' &&
            //             x.panelProfileName
            //                 .toLowerCase()
            //                 .includes(getFirstWord(item1.accessoryPartName).toLowerCase().replace('delta', ''))
            //     )
            // )
            // if (existsData) {
            //     if (existsData && existsData.length === 1) {
            //         const defaultPanel = existsData[0]
            //         setAccessoryType(defaultPanel.accessoryPartId)
            //         setAccessoryTypeName(defaultPanel.accessoryPartName)
            //     } else {
            //         setAccessoryType(0)
            //         setAccessoryTypeName('')
            //     }
            // } else {
            //     setAccessoryType(0)
            //     setAccessoryTypeName('')
            // }

            // const result = (pricingsSelection || [])?.map((availPricing: any) => ({
            //     id: availPricing.accessoryPartId,
            //     name: availPricing.accessoryPartName,
            // }))
            // setAccessoryOption(result || [])
        }
    }, [accessory])

     /**
     * COLOUR
     */
    const [colourId, setColourId] = useState<number>(0)
    const [colourName, setColourName] = useState<string>('')
    const [colourOption, setColourOption] = useState<any[]>([])
    const handleColourOnChange = (e: any) => {
        const selectedIndex = e.target.options.selectedIndex
        const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
        setColourId(+e.target.value)
        setColourName(name)
    }

    const getColourOption = (accessoryId: number) => {
        let pricings = accessoryPricings.filter((item: any) => item?.accessoryStyleId === +accessoryId)
        const pricingsSelection: any = Object.values(
            pricings.reduce((map: any, obj: any) => {
                const key = `${obj.accessoryColourId}-${obj.accessoryColourName}`
                if (!map[key]) {
                    map[key] = obj
                }
                return map
            }, {} as Record<string, any>)
        )

        const result = (pricingsSelection || [])?.filter((item: any) => item.accessoryColourName)
        .map((availPricing: any) => ({
            id: availPricing.accessoryColourId,
            name: availPricing.accessoryColourName,
        }))

        setColourOption(result || [])
        setColourId(0)
        setColourName('')
    }

    /**
     * QUANTITY
     */
    const [quantity, setQuantity] = useState<any>('')
    const handleQuantityChange = (e: any) => {
        setQtyErr(false)
        const inputNumber = parseInt(e.target.value, 10)
        if (inputNumber === 0 || inputNumber < 0 || inputNumber > 99) {
            return
        }
        setQuantity(inputNumber)
    }

    /**
     * ERROR
     */
    const [error, setError] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [errorLength, setErrorLength] = useState<boolean>(false)
    const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
    useEffect(() => {
        props.setErrorMsg(errorMsg)
        props.setError(error)
    }, [errorMsg, error])

    /**
     * UNIT PRICE
     */
    const [pricingId, setPricingId] = useState<number>(0)
    const [unitPrice, setUnitPrice] = useState<number>(0)
    const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')
    const [quantityFactor, setQuantityFactor] = useState<number>(0)
    const [lengthFactor, setLengthFactor] = useState<number>(0)

    useEffect(() => {
        if (+accessory > 0 && colourId > 0) {
            let pricing = accessoryPricings.find((item: any) => item?.accessoryStyleId === +accessory && item.accessoryColourId === +colourId)
            if (pricing) {
                setAccessoryItem(pricing)
                setUnitPrice(pricing.priceLevel1)
                setPricingId(pricing.id)
            } else {
                setAccessoryItem({})
                setUnitPrice(0)
                setPricingId(0)
                setUnitTypeDisplay('')
            }
        }
    }, [colourId, accessory, accessoryPricings])

    /**
     * LENGTH
     */

    const [length, setLength] = useState<any>('')
    const handleLengthFocusChange = (e: any) => {
        setLengthErr(false)
        const inputValue = parseInt(e.target.value)
        if (inputValue === 0 || inputValue < 0) {
            setErrorLengthMsg('Value must not be less than 100mm.')
            setErrorLength(true)
            return
        }
        if (inputValue < 100) {
            setErrorLengthMsg('Value must not be less than 100mm.')
            setErrorLength(true)
        } else if (inputValue > 23000) {
            setErrorLengthMsg('Value must not be greater than 23000mm.')
            setErrorLength(true)
        } else {
            setErrorLengthMsg('')
            setErrorLength(false)
        }
        setLength(inputValue)
        setIsLengthInputFocused(true)
    }

    const handleLengthBlur = (e: any) => {
        setErrorLengthMsg('')
        setErrorLength(false)
        setIsLengthInputFocused(false)
    }

    const [isQuantityInputFocused, setIsQuantityInputFocused] = useState(false)
    const [isLengthInputFocused, setIsLengthInputFocused] = useState(false)

    const handleQuantityInputFocus = () => setIsQuantityInputFocused(true)
    const handleQuantityInputBlur = () => setIsQuantityInputFocused(false)

    const disabled = 
        (isNaN(quantity) || quantity <= 0 || quantity > 99 || unitPrice === 0)

    

    const [lengthErr, setLengthErr] = useState(false)
    const [qtyErr, setQtyErr] = useState(false)

    return (
        <Fragment>
            <div className='row' style={{ position: 'relative' }}>
                {/* ACCESSORY NAME */}
                <div className='col-md-3'>
                    <label className='fw-bolder text-dark'>Accessory Name</label>
                    <select
                        style={{ fontSize: '1rem' }}
                        className='form-select my-4'
                        value={accessory}
                        onChange={handleAccessoryOnChange}
                    >
                        <option value=''>Select Accessory</option>
                        {accessories?.map((option: any, index: number) => (
                            <option key={index} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='col-md-3' />
                <div className='col-md-3' />
                <div className='col-md-3' style={{ position: 'absolute', right: 0, top: 10 }}>
                    <div
                        className='d-flex flex-column justify-content-center align-items-end'
                        style={{
                            backgroundColor: '#B5B5C3',
                            paddingTop: 6,
                            paddingBottom: 6,
                            marginBottom: 10,
                            borderRadius: '6.175px',
                        }}
                    >
                        <p
                            style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: 0,
                                marginRight: 24,
                            }}
                        >
                            {moneyFormatter.format(accessory !== '' ? unitPrice : 0)}
                        </p>
                    </div>
                    <button
                        disabled={disabled}
                        className='btn btn-primary w-100'
                        onClick={() => {
                            if (
                                (typeof quantity === 'string' && typeof length === 'string') ||
                                isNaN(length) ||
                                isNaN(quantity)
                            ) {
                                setQtyErr(true)
                                setLengthErr(true)
                                return false
                            }
                            addToCart({
                                ...accessoryItem,
                                pricingId,
                                // accessoryTypeName: accessoryTypeName,
                                unitPrice: unitPrice,
                                // accessoryType,
                                quantity,
                                quantityFactor: quantityFactor,
                                length: 0,
                                lengthFactor: lengthFactor,
                                goodsType: accessoryItem?.accessoryStyleName,
                                customId: Math.random(),
                                type: 'accessory',
                                uniqueID: `accessory-${pricingId}-${Math.random().toString(36).substring(2, 15)}`,
                                colourName: colourName,
                                colourId: colourId,
                                price: unitPrice,
                                productType: ORDER_LIST_ACCESSORIES,
                                productName: accessoryItem?.accessoryStyleName,
                                description: `${colourName ? colourName : '-'}`,
                            })
                            // setAccessoryType(0)
                            setQuantity('')
                            setAccessory('Select One')
                            setLengthErr(false)
                            setQtyErr(false)
                            setLength('')
                            setUnitPrice(0)
                        }}
                    >
                        <i className='fa fa-plus'></i>ADD
                    </button>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-md-9'>
                    <div style={{ borderBottom: '1px solid #B5B5C3' }} />
                </div>
                <div className='col-md-3' />
            </div>
            {/* TYPE */}
            <div className='row  mb-4'>
                {/* <div className='col-md-3'>
                    <label className='fw-bolder text-dark'>Type</label>
                    <select
                        style={{ fontSize: '1rem' }}
                        className='form-select my-4'
                        value={accessoryType}
                        onChange={handleAccessoryTypeOnChange}
                        disabled={accessory === 'Select One' || accessory === ''}
                    >
                        <option value={0}>Select Type</option>
                        {accessoryOption?.map((option: any, index: number) => (
                            <option key={index} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div> */}
                {/* QUANTITY / LENGTH*/}
                <div className='col-md-3'>
                    <label className='fw-bolder text-dark'>Quantity</label>
                    <input
                        style={{
                            fontSize: '1rem',
                            borderColor: qtyErr ? 'red' : '',
                        }}
                        type='number'
                        className='form-control  my-4'
                        placeholder='Enter Quantity'
                        min='1'
                        max='99'
                        value={quantity || ''}
                        onChange={handleQuantityChange}
                        onFocus={handleQuantityInputFocus}
                        onBlur={handleQuantityInputBlur}
                        disabled={accessory === 'Select One' || accessory === ''}
                    />
                </div>
                {
                    colourOption.length  > 0 && (
                        <div className='col-md-3'>
                            <label className='fw-bolder text-dark'>Colour</label>
                            <select
                                style={{ fontSize: '1rem' }}
                                className='form-select my-4'
                                value={colourId}
                                onChange={handleColourOnChange}
                                disabled={colourOption.length  === 0}
                            >
                                <option value={0}>Select Colour</option>
                                {colourOption?.map((option: any, index: number) => (
                                    <option key={index} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )
                }
                <div className='col-md-3'></div>
                <div className='col-md-3'></div>
            </div>
        </Fragment>
    )
}

export default NewAccessoriesItem
