import axios from 'axios'
export const FIXING_PRICING_URL = `${process.env.REACT_APP_API_URL}/FixingPricings`
export const FIXING_URL = `${process.env.REACT_APP_API_URL}/Fixings`
export const FIXINGUNITTYPE_URL = `${process.env.REACT_APP_API_URL}/FixingUnitTypes`

export const FIXINGMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FixingMasterlist`

export const FIXINGADDPROD_URL = `${process.env.REACT_APP_API_URL}/OrderDetails`


interface FixingProduct {
  id: number
  screwName: string
  screwType: string
}

export const getProductFixings = async () => {
  try {
    return await axios.get(`${FIXINGADDPROD_URL}/getProductFixings`)
  } catch (err) {
    return []
  }
}

export const getProductFixingScrewTypes = async (
  fixingId?: number
) => {
  try {
    const response = await axios.get(`${FIXINGADDPROD_URL}/getProductFixingScrewTypes`, {
      params: {
        fixingId
      },
    })
    return response.data
  } catch (err) {
    return []
  }
}

export const getFixingUnitPrice = async (
  fixingId: number,
  screwType: string) => {
  try {
    const response = await axios.get(`${FIXINGADDPROD_URL}/getProductFixingUnitPrice`, {
      params: {
        fixingId,
        screwType
      },
    })
    return response.data
  } catch (ex) {
    return [null, ex]
  }
}

export const getAllProduct = async (priceBookid?: number) => {
  try {
    const response = await axios.get(`${FIXINGMASTERLIST_URL}/getAllFixingProduct`, {
      params: {
        priceBookid,
      },
    })
    return response.data
  } catch (ex) {
    return [null, ex]
  }
}

export const getFixingMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  ScrewName?: string,
  ScrewType?: string,
  ColourName?: string
) => {
  try {
    return await axios.get(`${FIXINGMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        ScrewName,
        ScrewType,
        ColourName,
      },
    })
  } catch (err) {
    return err
  }
}

export const createFixingProduct = async (payload: any) => {
  try {
    return await axios.post(`${FIXINGMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateFixingProduct = async (payload: any) => {
  try {
    return await axios.patch(`${FIXINGMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteFixingProduct = async (id: any) => {
  try {
    return await axios.delete(`${FIXINGMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getFixingPricingPaginated = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${FIXING_PRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getFixingPricings = async (priceBookid: number) => {
  try {
    return await axios.get(`${FIXING_PRICING_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const getAllFixings = async () => {
  try {
    return await axios.get(`${FIXING_URL}`)
  } catch (err) {
    return []
  }
}

export const createFixing = async (payload: any) => {
  try {
    return await axios.post(`${FIXING_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateFixing = async (payload: any) => {
  try {
    return await axios.patch(`${FIXING_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteFixing = async (id: number) => {
  try {
    return await axios.delete(`${FIXING_PRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getFixingUnitTypes = async () => {
  try {
    return await axios.get(`${FIXINGUNITTYPE_URL}`)
  } catch (err) {
    return []
  }
}
