import React, {useContext, useState} from 'react'
import BeamItem from './BeamItem'
import { QuickOrderContext } from '../../../../context/QuickOrderContext'
import { IBeamType } from '../../../../models/beams/beamType'
import NewBeamItem from './NewBeamItem'

const Beams = () => {

  const {beams, beamTypes} = useContext(QuickOrderContext)
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  return (
    <div className='tab-items beams-tab-items'>
      <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
        Beams and Accessories
      </h3>
      {error ? (
        <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
          {errorMsg}
        </div>
      ) : null}
      <div className='my-4' style={{overflowY: 'scroll', overflowX: 'hidden', height: '90vh'}}>
            <NewBeamItem error={error} setError={setError} setErrorMsg={setErrorMsg} />
      </div>
    </div>
  )
}

export default Beams
