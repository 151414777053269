/* eslint-disable react/jsx-no-target-blank */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'

import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import {login} from '../../../app/modules/auth/redux/AuthCRUD'
import {PageDataProvider, useLayout} from '../../../_metronic/layout/core'
import {ScrollTop} from '../../../_metronic/layout/components/ScrollTop'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'administrator@localhost.com',
  password: 'Administrator1!',
}
interface IProps {
  handleShow2fa?: (email: string) => void
  setIsAuthorized?: (value: boolean) => void
}

export function Login({handleShow2fa = () => {}, setIsAuthorized = () => {}}: IProps) {
  const history = useHistory()
  const {config, classes} = useLayout()
  const [loading, setLoading] = useState(false)
  const [showVerificationCode, setShowVerificationCode] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const dispatch = useDispatch()

  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      console.table(values)
      setLoading(true)
      setTimeout(() => {
        setIsAuthorized(true)
        localStorage.setItem('isAuthorized', JSON.stringify(true))
        history.replace('/admin/orders')
      }, 1000)
    },
  })

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div
          id='kt_aside'
          className={clsx('aside', classes.aside.join(' '))}
          data-kt-drawer='false'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction='start'
          data-kt-drawer-toggle='#kt_aside_mobile_toggle'
          style={{backgroundColor: 'rgb(243, 229, 80)', width: '20%'}}
        >
          <div className='container'>
            <div className='mb-10 mt-20 d-flex aligns-items-center justify-content-center'>
              <Link to={'/'}>
                <img
                  src='/delta-panels-logo.png'
                  alt='Delta Panels logo'
                  style={{width: '247px', height: '65px', objectFit: 'cover'}}
                />
              </Link>
            </div>
            <h1 className='text-dark mb-10'>Welcome!!!</h1>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email Address</label>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 d-flex aligns-items-center justify-content-center'>
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Forgot Password ?
                </Link>
              </div>
              <div className='d-flex aligns-items-center justify-content-center'>
                <button
                  type='submit'
                  className='btn btn-lg btn-block'
                  style={{backgroundColor: 'rgb(58, 76, 88)', color: 'white', width: '65%'}}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          style={{marginLeft: '20%', width: '100%'}}
          className='d-flex align-items-center justify-content-center'
        >
          <h1>Display Image here</h1>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}
