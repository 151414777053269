import React from 'react';

type AddProductButtonProps = {
  onClick: () => void;
};

const AddProductButton: React.FC<AddProductButtonProps> = ({ onClick }) => (
  <div className='d-flex justify-content-right'>
    <button className='btn btn-primary btn-sm ml-auto' onClick={onClick}>
      <i className='fa fa-plus text-dark'></i> ADD PRODUCT
    </button>
  </div>
);

export default AddProductButton;
