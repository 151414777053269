import React from 'react'

interface Option {
  id: number
  name: string
}

interface DropdownProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
}

const Dropdown: React.FC<DropdownProps> = ({ options, value, onChange }) => {
  return (
    <select className="w-100 form-select" style={{ border: '1px solid #bbb' }} value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="" key="empty">
      </option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  )
}

export default Dropdown
