import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getPostcodeMatriceByPostcode } from '../../../api'
import { useEffectOnce } from 'react-use'
import { QuickOrderContext } from '../../../context/QuickOrderContext'

const CheckoutContent: React.FC = () => {
    const history = useHistory()

    const [shippingPreference] = useState<any>(() => {
        const lsPreference = localStorage.getItem('shipping-preference')
        return lsPreference ? JSON.parse(lsPreference) : ''
    })

    const { cartItems } = useContext(QuickOrderContext)

    if (cartItems.length === 0) {
        window.location.href = '/quick-order'
    }

    const defaultCustomer = {
        firstName: '',
        lastName: '',
        contact: '',
        email: '',
        address1: '',
        address2: '',
        suburb: '',
        state: shippingPreference.state,
        postCode: shippingPreference.postalCode,
        country: shippingPreference.country,
    }

    const customerInfo = localStorage.getItem('customer-info')
    let initialCustomer = defaultCustomer

    try {
        if (customerInfo) {
            const parsedData = JSON.parse(customerInfo)
            if (typeof parsedData === 'object' && parsedData !== null) {
                if (!parsedData.postCode || parsedData.postCode.trim() === '') {
                    parsedData.postCode = shippingPreference.postalCode
                }

                initialCustomer = parsedData
            }
        }
    } catch (ex) {
        console.error('Error parsing stored customer info:', ex)
    }

    var [customer, setCustomer] = useState(initialCustomer)
    if (!customer.firstName) customer.firstName = ''
    if (!customer.lastName) customer.lastName = ''
    if (!customer.contact) customer.contact = ''
    if (!customer.email) customer.email = ''
    if (!customer.address1) customer.address1 = ''
    if (!customer.address2) customer.address2 = ''
    if (!customer.suburb) customer.suburb = ''
    if (!customer.postCode) customer.postCode = ''
    if (!customer.state) customer.state = ''
    customer.country = 'Australia'

    const [isServiceable, setIsServiceable] = useState(false)

    useEffect(() => {
        if (customer.postCode && customer.postCode.length === 4) {
            const fetchData = async () => {
                try {
                    const postCodeMatrix: any = await getPostcodeMatriceByPostcode(customer.postCode)
                    if (postCodeMatrix.data && postCodeMatrix.data.pricebookId !== null) {
                        setIsServiceable(true)
                    }
                } catch (ex) { }
            }

            // fetchData()
            setIsServiceable(true)
        }
        else {
            setIsServiceable(false)
        }
    }, [customer.postCode])

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    const allowedSpecialCharRegex = /[^a-zA-Z0-9,\.\-\(\)'" ]/
    const nonSpecialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
    const phoneRegex =
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/

    var isValid = false

    const isDelivery = shippingPreference.shipping === 'delivery'
    if (isDelivery) {
        isValid =
            customer.firstName.trim() !== '' &&
            customer.lastName.trim() !== '' &&
            customer.contact.trim() !== '' &&
            customer.email.trim() !== '' &&
            emailRegex.test(customer.email) &&
            !nonSpecialCharRegex.test(customer.firstName) &&
            !nonSpecialCharRegex.test(customer.lastName) &&
            !allowedSpecialCharRegex.test(customer.address1) &&
            !nonSpecialCharRegex.test(customer.suburb) &&
            !nonSpecialCharRegex.test(customer.state) &&
            !nonSpecialCharRegex.test(customer.country) &&
            customer.address1.trim() !== '' &&
            customer.suburb.trim() !== '' &&
            customer.postCode.trim() !== '' &&
            customer.postCode.length === 4 &&
            customer.country.trim() !== ''
    } else {
        isValid =
            customer.firstName.trim() !== '' &&
            customer.lastName.trim() !== '' &&
            customer.contact.trim() !== '' &&
            customer.email.trim() !== '' &&
            emailRegex.test(customer.email) &&
            !nonSpecialCharRegex.test(customer.firstName) &&
            !nonSpecialCharRegex.test(customer.lastName)

        customer.address1 = ''
        customer.address2 = ''
        customer.suburb = ''
        customer.state = ''
        customer.postCode = ''
        customer.country = ''
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        localStorage.setItem('customer-info', JSON.stringify(customer))
        setCustomer(defaultCustomer)

        shippingPreference.postalCode = customer.postCode
        localStorage.setItem('shipping-preference', JSON.stringify(shippingPreference))

        if (isServiceable === true || !isDelivery) {
            history.push('/order-summary')
        }
    }

    const handleInputChange = async (event: any) => {
        const { name, value } = event.target
        if (name === 'postCode') {
            setCustomer((prevCustomer: any) => ({
                ...prevCustomer,
                postCode: value,
            }))

            shippingPreference.postalCode = value
            localStorage.setItem('shipping-preference', JSON.stringify(shippingPreference))

            if (isDelivery === true && value.length === 4) {
                try {
                    let postCodeMatrix: any = await getPostcodeMatriceByPostcode(value)

                    if (postCodeMatrix.data) {
                        shippingPreference.deliveryFee = postCodeMatrix.data.deliveryFee
                    }
                    setIsServiceable(true)
                } catch (ex) {
                    setIsServiceable(false)
                }
            } else {
                setIsServiceable(true)
            }
        } else {
            setCustomer((prevCustomer: any) => ({
                ...prevCustomer,
                [name]: value,
            }))
        }
    }

    return (
        <Fragment>
            <div style={{ backgroundColor: 'rgb(204, 204, 204)' }} className='p-4 mb-5'>
                <h3 className='mb-0'>ORDER CHECKOUT</h3>
            </div>
            <form className='container' onSubmit={handleSubmit}>
                <div className='row mb-4'>
                    <div className='d-flex'>
                        <div className='p-2 flex-grow-1'>
                            <h3>CUSTOMER INFORMATION</h3>
                        </div>
                        <Link to='/quick-order' className='btn btn-secondary me-2'>
                            Back
                        </Link>
                        <button
                            disabled={!isValid || (isDelivery && !isServiceable)}
                            className='btn btn-primary'
                            type='submit'
                        >
                            Proceed to Order Summary
                        </button>
                    </div>
                </div>
                <div className='row mb-6' style={{ height: 80 }}>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='first-name'>
                                First Name
                                {customer.firstName.trim() === '' && <small style={{ color: '#f00' }}>*</small>}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='first-name'
                                className='form-control'
                                name='firstName'
                                value={customer.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            {nonSpecialCharRegex.test(customer.firstName) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='last-name'>
                                Last Name
                                {customer.lastName.trim() === '' && <small style={{ color: '#f00' }}>*</small>}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='last-name'
                                className='form-control'
                                name='lastName'
                                value={customer.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            {nonSpecialCharRegex.test(customer.lastName) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row mb-6' style={{ height: 80 }}>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='contact-number'>
                                Contact Number
                                {customer.contact.trim() === '' && <small style={{ color: '#f00' }}>*</small>}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='contact-number'
                                className='form-control'
                                placeholder='Phone Number 1'
                                name='contact'
                                value={customer.contact}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='email'>
                                Email Address
                                {customer.email.trim() === '' && <small style={{ color: '#f00' }}>*</small>}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='email'
                                id='email'
                                className='form-control'
                                placeholder='Email Address'
                                name='email'
                                value={customer.email}
                                onChange={handleInputChange}
                                required
                            />
                            {!emailRegex.test(customer.email) && customer.email !== '' && (
                                <small style={{ color: '#f00' }}>Invalid Email format.</small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row mb-6' style={{ height: 80 }}>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='address1'>
                                Address{isDelivery && customer.address1.trim() === '' && (
                                    <small style={{ color: '#f00' }}>*</small>
                                )}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='address1'
                                className='form-control'
                                placeholder='Address 1'
                                name='address1'
                                value={customer.address1}
                                onChange={handleInputChange}
                                {...(isDelivery === true ? { required: true } : { disabled: true })}
                            />
                            {allowedSpecialCharRegex.test(customer.address1) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-outline'>
                            <label
                                className='fw-bolder text-dark'
                                htmlFor='address1'
                            >
                                Address 2
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='address2'
                                className='form-control'
                                placeholder='Address 2 (optional)'
                                name='address2'
                                value={customer.address2}
                                onChange={handleInputChange}
                                {...(isDelivery === true ? {} : { disabled: true })}
                            />
                            {allowedSpecialCharRegex.test(customer.address2) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row mb-6' style={{ height: 80 }}>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='suburb'>
                                Suburb
                                {isDelivery && customer.suburb.trim() === '' && (
                                    <small style={{ color: '#f00' }}>*</small>
                                )}
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='suburb'
                                className='form-control'
                                placeholder='Suburb'
                                name='suburb'
                                value={customer.suburb}
                                onChange={handleInputChange}
                                {...(isDelivery === true ? { required: true } : { disabled: true })}
                            />
                            {nonSpecialCharRegex.test(customer.suburb) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='postCode'>
                                Post Code
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='postCode'
                                className='form-control'
                                placeholder='Postcode'
                                name='postCode'
                                value={customer.postCode}
                                onChange={handleInputChange}
                                maxLength={4}
                                readOnly
                                {...(isDelivery === true ? { required: true } : null)}
                            />
                            {isDelivery &&
                                typeof customer.postCode !== 'undefined' &&
                                !/^\d{4}$/.test(customer.postCode) && (
                                    <small style={{ color: '#f00' }}>Invalid Australian Post code format.</small>
                                )}
                            {isDelivery && `${customer.postCode}`.trim() === '' && (
                                <small style={{ color: '#f00' }}>*</small>
                            )}
                            {isDelivery && !isServiceable && (
                                <small style={{ color: '#f00' }}>Area is not serviceable</small>
                            )}
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='state'>
                                State
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='state'
                                className='form-control'
                                placeholder='State'
                                name='state'
                                value={customer.state}
                                onChange={handleInputChange}
                                readOnly
                                {...(isDelivery === true ? { required: true } : null)}
                            />
                            {nonSpecialCharRegex.test(customer.state) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row mb-6' style={{ height: 80 }}>
                    <div className='col-4'>
                        <div className='form-outline'>
                            <label className='fw-bolder text-dark' htmlFor='country'>
                                Country
                            </label>
                            <input
                                style={{ fontSize: '1rem' }}
                                type='text'
                                id='country'
                                className='form-control'
                                placeholder='Country'
                                name='country'
                                value={customer.country}
                                onChange={handleInputChange}
                                readOnly
                                {...(isDelivery === true ? { required: true } : { disabled: true })}
                            />
                            {nonSpecialCharRegex.test(customer.country) && (
                                <small style={{ color: '#f00' }}>Special characters not allowed.</small>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export { CheckoutContent }
