import React, { useState } from 'react'
import { IFixing } from '../models/fixings/fixing'
import { useAsyncFn } from 'react-use'
import { getAllFixings } from '../api/fixings-api'
import { IFlashingType } from '../models/flashings/flashingType'
import { IFlashingThickness } from '../models/flashings/flashingThickness'
import { IFlashingSubtype } from '../models/flashings/flashingSubtype'
import { IRainWaterCategory } from '../models/rainwater/rainWaterCategory'
import { IRainWaterPart } from '../models/rainwater/rainWaterParts'
import { IRainWaterStyle } from '../models/rainwater/rainWaterStyles'
import { IBeamType } from '../models/beams/beamType'
import { IBeam } from '../models/beams/beams'
import { IBeamFinish } from '../models/beams/beamFinish'
import { IPanelBottomColour } from '../models/panel-bottom-color'
import { IPanelCores } from '../models/panel-core'
import { IPanelProfiles } from '../models/panel-profile'
import { IPanelThickness } from '../models/panel-thickness'
import { ILightPanelType } from '../models/lights/lightPanelType'
import { ILightProduct } from '../models/lights/lightProduct'
import { ILightProductType } from '../models/lights/lightProductType'
import { IPostType } from '../models/posts/postType'
import { IPostProduct } from '../models/posts/postProduct'
import { IPostFinish } from '../models/posts/postFinish'
import { IPostCategory } from '../models/posts/postCategory'
import { IUnitType } from '../models/unit-types/unit-type'
import { IFixingUnitType } from '../models/fixings/fixingUnitType'
import { IBeamUnitType } from '../models/beams/beamUnitType'
import { ILightUnitType } from '../models/lights/lightUnitType'
import { IPostUnitType } from '../models/posts/postUnitType'
import { IRainwaterUnitType } from '../models/rainwater/rainwaterUnitType'
import { getPriceBooks } from '../api'

export const AdminPriceBookContext = React.createContext<any>({})

export const AdminPriceBookContextProvider: React.FC = ({ children }) => {
    const [fixings, setFixings] = useState<IFixing[] | undefined>()
    const [{ loading: fetchFixings }, getFixingAsync] = useAsyncFn(async () => {
        const result: any = await getAllFixings()
        if (result != null) {
            setFixings(result.data)
        }
    }, [setFixings])
    // ** Panels **//
    const [panelBottomColour, setPanelBottomColours] = useState<IPanelBottomColour[] | undefined>()
    const [panelCores, setPanelCores] = useState<IPanelCores[] | undefined>()
    const [panelProfiles, setPanelProfiles] = useState<IPanelProfiles[] | undefined>()
    const [panelThickness, setPanelThickness] = useState<IPanelThickness[] | undefined>()
    // ** Flashings **//
    const [flashingTypes, setFlashingTypes] = useState<IFlashingType[] | undefined>()
    const [flashingThickenesses, setFlashingThickenesses] = useState<
        IFlashingThickness[] | undefined
    >()
    const [flashingSubTypes, setFlashingSubTypes] = useState<IFlashingSubtype[] | undefined>()
    // ** Rainwater **//
    const [rainwaterCategories, setRainwaterCategories] = useState<IRainWaterCategory[] | undefined>()
    const [rainwaterParts, setRainwaterParts] = useState<IRainWaterPart[] | undefined>()
    const [rainwaterStyles, setRainwaterStyles] = useState<IRainWaterStyle[] | undefined>()
    const [rainwaterUnitTypes, setRainwaterUnitTypes] = useState<IRainwaterUnitType[] | undefined>()

    // ** Beams **//
    const [beamFinishes, setBeamFinishes] = useState<IBeamFinish[] | undefined>()
    const [beams, setBeams] = useState<IBeam[] | undefined>()
    const [beamTypes, setBeamTypes] = useState<IBeamType[] | undefined>()
    const [beamUnitTypes, setBeamUnitTypes] = useState<IBeamUnitType[] | undefined>()

    // ** Light **//
    const [lightPanelTypes, setLightPanelTypes] = useState<ILightPanelType[] | undefined>()
    const [lightProducts, setLightProducts] = useState<ILightProduct[] | undefined>()
    const [lightProductTypes, setLightProductTypes] = useState<ILightProductType[] | undefined>()
    const [lightUnitTypes, setLightUnitTypes] = useState<ILightUnitType[] | undefined>()

    // ** Post **//
    const [postTypes, setPostTypes] = useState<IPostType[] | undefined>()
    const [postProducts, setPostProducts] = useState<IPostProduct[] | undefined>()
    const [postFinishes, setPostFinishes] = useState<IPostFinish[] | undefined>()
    const [postCategories, setPostCategories] = useState<IPostCategory[] | undefined>()
    const [postUnitTypes, setPostUnitTypes] = useState<IPostUnitType[] | undefined>()
    // ** Fixings **//
    const [fixingUnitTypes, setFixingUnitTypes] = useState<IFixingUnitType[] | undefined>()

    // ** Unit Type **//
    const [unitTypes, setUnitTypes] = useState<IUnitType[] | undefined>()

   
    
    return (
        <AdminPriceBookContext.Provider
            value={{
                fixings,
                getFixingAsync,
                fetchFixings,
                setFlashingTypes,
                flashingTypes,
                setFlashingThickenesses,
                flashingThickenesses,
                setFlashingSubTypes,
                flashingSubTypes,
                rainwaterCategories,
                setRainwaterCategories,
                rainwaterParts,
                setRainwaterParts,
                rainwaterStyles,
                setRainwaterStyles,
                rainwaterUnitTypes,
                setRainwaterUnitTypes,
                beamFinishes,
                setBeamFinishes,
                beams,
                setBeams,
                beamTypes,
                setBeamTypes,
                panelBottomColour,
                setPanelBottomColours,
                panelCores,
                setPanelCores,
                panelProfiles,
                setPanelProfiles,
                panelThickness,
                setPanelThickness,
                lightPanelTypes,
                setLightPanelTypes,
                lightProducts,
                setLightProducts,
                lightProductTypes,
                setLightProductTypes,
                postTypes,
                setPostTypes,
                postProducts,
                setPostProducts,
                postFinishes,
                setPostFinishes,
                postCategories,
                setPostCategories,
                unitTypes,
                setUnitTypes,
                fixingUnitTypes,
                setFixingUnitTypes,
                beamUnitTypes,
                setBeamUnitTypes,
                lightUnitTypes,
                setLightUnitTypes,
                postUnitTypes,
                setPostUnitTypes
            }}
        >
            {children}
        </AdminPriceBookContext.Provider>
    )
}
