import React, {useState, useEffect, useRef, useContext} from 'react'
import axios from 'axios'
import '../Products.css'
import LightsProduct from './LightsProduct'
import {
  getLightPanelTypes,
  getLightProductTypes,
  getLightProducts,
  getLightUnitTypes,
  getLightMasterlist,
  getAllLightThicknesses,
  createLightProduct,
  deleteLightProduct,
  getLightProfiles,
} from '../../../../../api/light-api'
import {useAsyncFn, useEffectOnce} from 'react-use'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import {useHistory} from 'react-router-dom'

type Props = {
  product: string
  productId: number
}

const Lights = (props: Props) => {
  const {productId} = props
  const {
    lightPanelTypes,
    setLightPanelTypes,
    lightProductTypes,
    setLightProductTypes,
    lightUnitTypes,
    setLightUnitTypes,
    lightProduct,
    setLightProduct,
    lightProfiles,
    setLightProfiles,
    lightThickness,
    setLightThickness,
    remappedLightUnitTypes,
    setRemappedLightUnitTypes,
  } = useContext(AdminProductContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')
  const [Filter4, setFilter4] = useState('')
  const [Filter5, setFilter5] = useState('')

  const history = useHistory()

  /**REFS */
  const nameRef = useRef<any>()
  const typeRef = useRef<any>()
  const panelRef = useRef<any>()
  const unitRef = useRef<any>()
  const priceRef = useRef<any>()
  const regex = /^[a-zA-Z0-9\s./-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/

  useEffect(() => {
    setSeqTotalPages(1)
    setSeqCurrentPage(1)
    // Load to page 1
    // fetchLightProducts(1)
  }, [productId])

  /**
   * SEQ
   */
  const [seqData, setSeqData] = useState<any>({})
  const [newSeqProduct, setNewSeqProduct] = useState({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})

  const fetchLightProducts = async (
    currentPage: number,
    filter1?: any,
    filter2?: any,
    filter3?: any,
    filter4?: any,
    filter5?: any,
  ) => {
    await getLightMasterlist(currentPage, seqDataPerPage, filter1, filter2, filter3, filter4, filter5).then(
      (response: any) => {
        setSeqData(response.data)
        setSeqTotalPages(Math.ceil(response.data.totalCount / 10))
      }
    )
  }

  /**
   * INITIAL SETUP
   */
  useEffectOnce(() => {
    if (!lightProductTypes && !fetchLightProductType) {
      getLightProductTypesAsync()
    }
    if (!lightProduct && !fetchLightProduct) {
      getLightProductsAsync()
    }
    if (!lightPanelTypes && !fetchLightPanel) {
      getLightPanelTypesAsync()
    }

    if (!lightUnitTypes && !fetchUnitTypes) {
      fetchUnitTypesAsync()
    }
    if (!lightProfiles && !fetchLightProfiles) {
      getLightProfilesAsync()
    }

    if (!fetchLightThicknesses && !lightThickness) {
      fetchLightThicknessesAsync()
    }
  })

  /**
   * FETCH RECORD
   */
  const [{loading: fetchLightProfiles}, getLightProfilesAsync] = useAsyncFn(async () => {
    const result: any = await getLightProfiles()
    if (result != null) {
      setLightProfiles(result.data)
    }
  }, [setLightProfiles])

  const [{loading: fetchLightProductType}, getLightProductTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightProductTypes()
    if (result != null) {
      setLightProductTypes(result.data)
    }
  }, [setLightProductTypes])

  const [{loading: fetchLightProduct}, getLightProductsAsync] = useAsyncFn(async () => {
    const result: any = await getLightProducts()
    if (result != null) {
      setLightProduct(result.data)
    }
  }, [setLightProduct])

  const [{loading: fetchLightPanel}, getLightPanelTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightPanelTypes()
    if (result != null) {
      setLightPanelTypes(result.data)
    }
  }, [setLightPanelTypes])

  const [{loading: fetchUnitTypes}, fetchUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getLightUnitTypes()
    if (result != null) {
      setLightUnitTypes(result.data)

      const remappedData = (result.data as any[])?.map(({id, unitTypeName}) => ({
        id: id,
        name: unitTypeName,
      }))
      setRemappedLightUnitTypes(remappedData)
    }
  }, [setLightUnitTypes, setRemappedLightUnitTypes])

  const [{loading: fetchLightThicknesses}, fetchLightThicknessesAsync] = useAsyncFn(async () => {
    const result: any = await getAllLightThicknesses()
    if (result != null) {
      setLightThickness(result.data)
    }
  }, [setLightThickness])

  /**
   * PAGINATION
   */

  const handleFilter = (filter1: any, filter2: any, filter3: any, filter4: any, filter5: any) => {
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setFilter4(filter4)
    setFilter5(filter5)
    setSeqCurrentPage(1)
  }

  // useEffect(() => {
  //   // fetchLightsProduct(seqCurrentPage, Filter1, Filter2)
  //   setSeqCurrentPage(1)
  // }, [Filter1, Filter2, Filter3])

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setIsSeqAdding(false)
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
  }
  const handleCancel = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }

    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({
        ...prevData,
        [name]: name === 'lightThickness' ? {...name, name: value} : value,
      }))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deleteLightProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchLightProducts(seqCurrentPage)
        handleSuccessMsg('Product deleted successfully.')
      } else {
        const errorMessage =
          result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Light:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }

    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
  }
  /**
   * UPDATE
   */
  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg('Product updated sucessfully.')
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewSeqProduct({})
    setIsSeqAdding(false)
  }

  const handleUpdateProduct = (iProductId: any) => {
    history.push(`/admin/product-details/Light/${iProductId}`)
  }
  const handleSave = () => {
    const newData = {
      ...seqData,
      items: seqData.items?.map((item: any) => (item.id === editingSeqId ? editedSeqData : item)),
    }
    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  /**
   * ADD
   */

  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData({...seqData, items: [newSeqProduct, ...seqData.items]})
    setIsSeqAdding(false)
  }
  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(nameRef.current.value)) {
      nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(typeRef.current.value)) {
      typeRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    if (!regex.test(panelRef.current.value)) {
      typeRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(unitRef.current.value)) {
      unitRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    setNewSeqProduct({
      ...newSeqProduct,
      [name]: name === 'lightThickness' ? {id: Math.random(), name: value} : value,
    })
  }

  useEffect(() => {
    fetchLightProducts(seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5)
  }, [seqCurrentPage, Filter1, Filter2, Filter3, Filter4, Filter5])

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const productFields = [
    {label: 'Item Number', key: 'Code', type: 'text'},
    {label: 'Product Name', key: 'LightProductName', type: 'text'},
    {label: 'Panel Profile', key: 'LightProfileId', type: 'dropdown', options: lightProfiles},
    {label: 'Thickness', key: 'LightThicknessId', type: 'dropdown', options: lightThickness},
    {label: 'Product Type', key: 'Detail', type: 'text'},
    {label: 'Unit Type', key: 'LightUnitTypeId', type: 'dropdown', options: remappedLightUnitTypes},
  ]

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    console.log('newProduct', newProduct)
    createLightProduct(newProduct)
      .then((result: any) => {
        if (result.data) {
          handleSuccessMsg('Product added successfully.')
          fetchLightProducts(seqCurrentPage)
        } else {
          handleErrorMessage(result?.response?.data)
        }
      })
      .catch(() => {
        handleErrorMessage('Error Encountered While Saving. Please try again later.')
      })

    setIsSeqAdding(false)
  }

  return (
    <>
      <div className='card-body' style={{backgroundColor: '#fff', height: '100vh'}}>
        <AddProductButton onClick={handleAddProduct} />
        <div style={{overflowY: 'auto', height: '95%'}}>
          {errorMsg && (
            <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
              <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className='alert alert-success w-50 m-auto text-center' role='alert'>
              <i className='fa fa-check-circle text-success'></i> {successMsg}
            </div>
          )}
          <LightsProduct
            data={seqData}
            currentPage={seqCurrentPage}
            totalPages={seqTotalPages}
            handleUpdateProduct={handleUpdateProduct}
            handleSave={handleSave}
            editingId={editingSeqId}
            editedData={editedSeqData}
            handleSaveClick={handleSaveClick}
            handleInputChange={handleInputChange}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            handleCancelClick={handleCancelClick}
            handleCancel={handleCancel}
            handleDeleteProduct={handleDeleteProduct}
            handleChange={handleChange}
            handleUpdateSuccess={handleUpdateSuccess}
            isAdding={isSeqAdding}
            nameRef={nameRef}
            typeRef={typeRef}
            unitRef={unitRef}
            priceRef={priceRef}
            panelRef={panelRef}
            newProduct={newSeqProduct}
            handleErrorMessage={handleErrorMessage}
            handleFilter={handleFilter}
          />
        </div>
      </div>
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
        isDeleting={isDeleting}
      />
      <NewProductModal
        isOpen={isAddingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleSaveNewProduct}
        fields={productFields}
      />
    </>
  )
}

export default Lights
