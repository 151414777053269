import React, {useContext, useEffect, useState} from 'react'
import {QuickOrderContext} from '../../../../context/QuickOrderContext' 
import NewGutterItem from './GutterItem/NewGutterItem'
import NewStopEndBracketItem from './GutterItem/NewStopEndBracketItem'
import { getUniqueIdWithPrefix } from '../../../../../_metronic/assets/ts/_utils'
import { ORDER_LIST_RAINWATERGOODS } from '../../../../common/constant'

const RainwaterGoods = () => {
  const {rainWaterSetup, rainwaterProductMasters, addToCart, cartItems} = useContext(QuickOrderContext)

  let rainWaterCategory = ['Gutter']
  
  const getRainWaterStylePM = (category: string) => {
    const availStyle: any = rainwaterProductMasters.filter((x: any) => x.rainwaterPartName.toUpperCase().includes(category.toUpperCase()))

    return availStyle || []
  }

  const getRainWaterStyleBracketStopEnd = (category: string) => {
    const availStyle: any = rainwaterProductMasters.filter((x: any) => 
        x.rainwaterPartName.toLowerCase().includes('gutter bracket') ||
        x.rainwaterPartName.toLowerCase().includes('gutter stop end') ||
        x.rainwaterPartName.toLowerCase().includes('gutter dropper') ||
        x.rainwaterPartName.toLowerCase().includes('gutter overstrap'))
    
    return availStyle || []
  }

  const getRainWaterColourStopEnd = (category: string) => {
    const availStyle: any = rainwaterProductMasters.filter((x: any) => 
        x.rainwaterPartName.toLowerCase().includes('gutter stop end'))
    
    return availStyle || []
  }

  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const [selectedGutterItem, setSelectedGutterItem] = useState<any>({})
  const [selectedGutterItemForStopEndBracket, setSelectedGutterItemForStopEndBracket] = useState<any>({})

  const [gutterDropperQuantity, setGutterDropperQuantity] = useState<any>()
  const [gutterOverstrapQuantity, setGutterOverstrapQuantity] = useState<any>()
  const [gutterBracketQuantity, setGutterBracketQuantity] = useState<any>()
  const [stopEndQuantity, setStopEndQuantity] = useState<any>()
  const [stopEndPrice, setStopEndPrice] = useState<any>()
  const [stopEndColor, setStopEndColor] = useState<number>(0)
  const [stopEndColourId, setStopEndColourId] = useState<number>(0)
  const [stopEndColorName, setStopEndColorName] = useState<number>(0)
  
  const [gutterBracketPrice, setGutterBracketPrice] = useState<any>()
  const [gutterDropperPrice, setGutterDropperPrice] = useState<any>()
  const [gutterOverstrapPrice, setGutterOverstrapPrice] = useState<any>()

  const [isGutterChecked, setIsGutterChecked] = useState(false)
  const [isStopEndChecked, setIsStopEndChecked] = useState(false)
  const [isGutterBracketChecked, setIsGutterBracketChecked] = useState(false)
  const [isGutterDropperChecked, setIsGutterDropperChecked] = useState(false)
  const [isGutterOverstrapChecked, setIsGutterOverstrapChecked] = useState(false)

  const [selectedColourId, setSelectedColourId] = useState<number>(0)

  //New gutter
  const [gutterItem, setGutterItem] = useState<any>({})
  const [newGutterPricingId, setNewGutterPricingId] = useState(0)
  const [color, setColor] = useState<number>(0)
  const [colorName, setColorName] = useState<number>(0)
  const [length, setLength] = useState<any>()
  const [quantity, setQuantity] = useState<any>()
  const [gutter, setGutter] = useState<any>('Select Gutter Name')
  const [price, setPrice] = useState(0)
  const maxQuantity = 1000000

  const resetRainwaterState = () => {
    resetGutter()
    resetStopend()
    resetBracket()
    resetDropper()
    resetOvertstrap()
  }

  const resetGutter = () => {
    //gutter
    setLength('')
    setQuantity(0)
    setStopEndQuantity('')
    setGutterBracketQuantity('')
  }

  const resetStopend = () => {
    //stopend
    setStopEndQuantity('')
  }

  const resetBracket = () => {
    //bracket
    setGutterBracketQuantity('')
  }

  const resetDropper = () => {
    //Dropper
    setGutterDropperQuantity('')
  }
  
  const resetOvertstrap = () => {
    //Overstrap
    setGutterOverstrapQuantity('')
  }

  const GutterAddToCart = () => {
      // ADD TO CART
      addToCart({
        ...gutterItem,
        color,
        colorName,
        gutterName: gutterItem?.rainwaterStyleName,
        length,
        quantity,
        customId: Math.random(),
        type: 'gutter',
        uniqueID: `gutter-${color}-${gutterItem?.rainwaterStyleName}-${gutterItem?.rainwaterStyleName}-${Math.random().toString(36).substring(2, 15)}`,
        pricingId: newGutterPricingId,
        unitPrice: gutterItem.priceLevel1,
        price: gutterItem.priceLevel1,
        productType: ORDER_LIST_RAINWATERGOODS,
        productName: gutterItem?.rainwaterStyleName,
        description: colorName,
    })
    resetGutter()
  }

  const StopEndAddToCart = () => {
    // ADD TO CART
    addToCart({
      ...stopEndPrice,
      pricingId: stopEndPrice.id,
      unitPrice: stopEndPrice.priceLevel1,
      price: stopEndPrice.priceLevel1,
      gutterName: `${stopEndPrice?.rainwaterStyleName} ${stopEndPrice?.rainwaterPartName}`,
      quantity: stopEndQuantity,
      color: stopEndColor,
      colorName: stopEndColorName,
      customId: Math.random(),
      type: 'stop-end-bracket',
      uniqueID: `stop-end-bracket-${stopEndPrice?.rainwaterStyleName}-${stopEndPrice?.rainwaterPartName}-${stopEndQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix('stop-end-bracket')}`.replace(/\s/g, ''),
      productType: ORDER_LIST_RAINWATERGOODS,
      productName: `${stopEndPrice?.rainwaterStyleName} ${stopEndPrice?.rainwaterPartName}`,
      description: stopEndColorName,
    })
    resetStopend()
  }

  const BracketAddToCart = () => {
    // ADD TO CART
    addToCart({
      ...gutterBracketPrice,
      pricingId: gutterBracketPrice.id,
      price: gutterBracketPrice.priceLevel1,
      unitPrice: gutterBracketPrice.priceLevel1,
      gutterName: `${gutterBracketPrice?.rainwaterStyleName} ${gutterBracketPrice?.rainwaterPartName}`,
      quantity: gutterBracketQuantity,
      color: 0,
      colorName: '',
      customId: Math.random(),
      type: 'stop-end-bracket',
      uniqueID: `stop-end-bracket-${gutterBracketPrice?.rainwaterStyleName}-${gutterBracketPrice?.rainwaterPartName}-${gutterBracketQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix('stop-end-bracket')}`.replace(/\s/g, ''),
      productType: ORDER_LIST_RAINWATERGOODS,
      productName: `${gutterBracketPrice?.rainwaterStyleName} ${gutterBracketPrice?.rainwaterPartName}`,
      description: '-',
    })
    resetBracket()
  }

  const DropperAddToCart = () => {
    // ADD TO CART
    addToCart({
      ...gutterDropperPrice,
      pricingId: gutterDropperPrice.id,
      price: gutterDropperPrice.priceLevel1,
      unitPrice: gutterDropperPrice.priceLevel1,
      gutterName: gutterDropperPrice?.rainwaterPartName,
      quantity: gutterDropperQuantity,
      color: 0,
      colorName: '',
      customId: Math.random(),
      type: 'stop-end-bracket',
      uniqueID: `stop-end-bracket-${gutterDropperPrice?.rainwaterStyleName ? gutterDropperPrice?.rainwaterStyleName : ''}-${gutterDropperPrice?.rainwaterPartName}-${gutterDropperPrice}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix('stop-end-bracket')}`.replace(/\s/g, ''),
      productType: ORDER_LIST_RAINWATERGOODS,
      productName: gutterDropperPrice?.rainwaterPartName,
      description: '-',
    })
    resetDropper()
  }

  const OverstrapAddToCart = () => {
    // ADD TO CART
    addToCart({
      ...gutterOverstrapPrice,
      pricingId: gutterOverstrapPrice.id,
      price: gutterOverstrapPrice.priceLevel1,
      unitPrice: gutterOverstrapPrice.priceLevel1,
      gutterName: gutterOverstrapPrice?.rainwaterPartName,
      quantity: gutterOverstrapQuantity,
      color: 0,
      colorName: '',
      customId: Math.random(),
      type: 'stop-end-bracket',
      uniqueID: `stop-end-bracket-${gutterOverstrapPrice?.rainwaterStyleName ? gutterOverstrapPrice?.rainwaterStyleName : ''}-${gutterOverstrapPrice?.rainwaterPartName}-${gutterOverstrapPrice}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix('stop-end-bracket')}`.replace(/\s/g, ''),
      productType: ORDER_LIST_RAINWATERGOODS,
      productName: gutterOverstrapPrice?.rainwaterPartName,
      description: '-',
    })
    resetOvertstrap()
  }

  useEffect(() => {
    if (!isGutterChecked) {
      resetGutter()
      setColor(0)
      setSelectedColourId(0)
      setGutter('Select Gutter Name')
      setPrice(0)
      setGutterItem({})
      setStopEndPrice(0)
      setGutterBracketPrice(0)
      setIsStopEndChecked(false)
      setIsGutterBracketChecked(false)
    }
  },[isGutterChecked])

  useEffect(() => {
    setStopEndColourId(selectedColourId)
    setStopEndColor(color)
    setStopEndColorName(colorName)
  }, [selectedColourId])

  useEffect(() => {
    if (!isStopEndChecked) {
      resetStopend()
      setStopEndPrice(0)
      setGutterBracketPrice(0)
      setGutterDropperPrice(0)
      setGutterOverstrapPrice(0)
      setIsStopEndChecked(false)
    }
  },[isStopEndChecked])

  useEffect(() => {
    if (!isGutterBracketChecked) {
      resetBracket()
    }
  },[isGutterBracketChecked])

  useEffect(() => {
    if (!isGutterDropperChecked) {
      resetDropper()
    }
  },[isGutterDropperChecked])

  useEffect(() => {
    if (!isGutterOverstrapChecked) {
      resetOvertstrap()
    }
  },[isGutterOverstrapChecked])

  const disabledGutter =
      color === 0 ||
      isNaN(quantity) ||
      quantity === 0 ||
      quantity > maxQuantity ||
      quantity < 0 ||
      length === '' ||
      isNaN(length) ||
      length === 0 ||
      length < 0 ||
      length < 100 ||
      length > 23000
  const disabledStopEnd =
    !isStopEndChecked ||
    !stopEndPrice ||
    stopEndQuantity === '' ||
    stopEndColor === 0 ||
    isNaN(stopEndQuantity) ||
    isNaN(stopEndQuantity)
  const disabledGutterBracket =
    !isGutterBracketChecked ||
    !gutterBracketPrice ||
    gutterBracketQuantity === '' ||
    isNaN(gutterBracketQuantity)
  const disabledGutterDropper =
    !isGutterDropperChecked ||
    !gutterDropperPrice ||
    gutterDropperQuantity === '' ||
    isNaN(gutterDropperQuantity)
  const disabledGutterOverstrap =
    !isGutterOverstrapChecked ||
    !gutterOverstrapPrice ||
    gutterOverstrapQuantity === '' ||
    isNaN(gutterOverstrapQuantity)

  const disabled = (
    (!isGutterChecked && !isStopEndChecked && !isGutterBracketChecked && !isGutterDropperChecked && !isGutterOverstrapChecked) ||
    (isGutterChecked && disabledGutter) ||
    (isStopEndChecked && disabledStopEnd) ||
    (isGutterBracketChecked && disabledGutterBracket) ||
    (isGutterDropperChecked && disabledGutterDropper) ||
    (isGutterOverstrapChecked && disabledGutterOverstrap)
  )
    
  const handleGutterStopendBracketAddToCart = () => {
    if (isGutterChecked && !disabledGutter) {
      GutterAddToCart()
    }
  
    if (isStopEndChecked && !disabledStopEnd) {
      StopEndAddToCart()
    }
  
    if (isGutterBracketChecked && !disabledGutterBracket) {
      BracketAddToCart()
    }

    if (isGutterDropperChecked && !disabledGutterDropper) {
      DropperAddToCart()
    }

    if (isGutterOverstrapChecked && !disabledGutterOverstrap) {
      OverstrapAddToCart()
    }
  }

  return (
    <div className='d-flex flex-column justify-content-between' style={{overflow: 'hidden'}}>
      <div style={{overflowY: 'scroll', overflowX: 'hidden', height: '95vh'}}>
        {rainwaterProductMasters && rainWaterCategory.map((waterCategory: string) => (
          <React.Fragment key={waterCategory}>
            <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
              {waterCategory}
            </h3>
            <React.Fragment key={waterCategory}>
              {error ? (
                <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
                  {errorMsg}
                </div>
              ) : null}
              <div className='my-4'>
                <NewGutterItem
                  setError={setError}
                  setErrorMsg={setErrorMsg}
                  items={getRainWaterStylePM(waterCategory)}
                  pricings={getRainWaterStylePM(waterCategory)}
                  categoryName={waterCategory}
                  setSelectedGutterItem={(item: any) => {
                    setSelectedGutterItem({...item})
                  }}
                  setSelectedGutterItemForStopEndBracket={(item: any) => {
                    setSelectedGutterItemForStopEndBracket({...item})
                  }}
                  setGutterBracketQuantity={setGutterBracketQuantity}
                  setStopEndQuantity={setStopEndQuantity}
                  stopEndPrice={stopEndPrice}
                  gutterBracketPrice={gutterBracketPrice}
                  setStopEndPrice={setStopEndPrice}
                  setGutterBracketPrice={setGutterBracketPrice}
                  setIsGutterBracketChecked={setIsGutterBracketChecked}
                  setIsStopEndChecked={setIsStopEndChecked}
                  categoryId={waterCategory}
                  setColourId={(colourId: number) => {
                    setSelectedColourId(colourId)
                  }}
                  gutterItem={gutterItem}
                  setGutterItem={setGutterItem}
                  newGutterPricingId={newGutterPricingId}
                  setNewGutterPricingId={setNewGutterPricingId}
                  color={color}
                  setColor={setColor}
                  colorName={colorName}
                  setColorName={setColorName}
                  length={length}
                  setLength={setLength}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  gutter={gutter}
                  setGutter={setGutter}
                  price={price}
                  setPrice={setPrice}
                  isGutterChecked={isGutterChecked}
                  setIsGutterChecked={setIsGutterChecked}
                  disabled={disabled}
                  handleGutterStopendBracketAddToCart={handleGutterStopendBracketAddToCart}
                />
                <NewStopEndBracketItem
                  items={getRainWaterColourStopEnd(waterCategory)}
                  pricings={getRainWaterStyleBracketStopEnd(waterCategory)}
                  selectedGutterItem={selectedGutterItem}
                  setSelectedGutterItem={setSelectedGutterItem}
                  selectedGutterItemForStopEndBracket={selectedGutterItemForStopEndBracket}
                  setGutterBracketQuantity={setGutterBracketQuantity}
                  setStopEndQuantity={setStopEndQuantity}
                  setStopEndPrice={setStopEndPrice}
                  setGutterBracketPrice={setGutterBracketPrice}
                  gutterBracketQuantity={gutterBracketQuantity}
                  stopEndQuantity={stopEndQuantity}
                  stopEndPrice={stopEndPrice}
                  gutterBracketPrice={gutterBracketPrice}
                  setIsStopEndChecked={setIsStopEndChecked}
                  setIsGutterBracketChecked={setIsGutterBracketChecked}
                  setIsGutterDropperChecked={setIsGutterDropperChecked}
                  setGutterDropperPrice={setGutterDropperPrice}
                  gutterDropperPrice={gutterDropperPrice}
                  isGutterDropperChecked={isGutterDropperChecked}
                  gutterDropperQuantity={gutterDropperQuantity}
                  setGutterDropperQuantity={setGutterDropperQuantity}
                  setIsGutterOverstrapChecked={setIsGutterOverstrapChecked}
                  setGutterOverstrapPrice={setGutterOverstrapPrice}
                  gutterOverstrapPrice={gutterOverstrapPrice}
                  isGutterOverstrapChecked={isGutterOverstrapChecked}
                  gutterOverstrapQuantity={gutterOverstrapQuantity}
                  setGutterOverstrapQuantity={setGutterOverstrapQuantity}
                  isStopEndChecked={isStopEndChecked}
                  isGutterBracketChecked={isGutterBracketChecked}
                  stopEndColor={stopEndColor}
                  setStopEndColor={setStopEndColor}
                  stopEndColourId={stopEndColourId}
                  setStopEndColourId={setStopEndColourId}
                  stopEndColorName={stopEndColorName}
                  setStopEndColorName={setStopEndColorName}
                />
              </div>
            </React.Fragment>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default RainwaterGoods
