import React, { useState } from 'react'
import { IFixing } from '../models/fixings/fixing'
import { useAsyncFn } from 'react-use'
import { getAllFixings } from '../api/fixings-api'
import { IFlashingType } from '../models/flashings/flashingType'
import { IFlashingThickness } from '../models/flashings/flashingThickness'
import { IFlashingSubtype } from '../models/flashings/flashingSubtype'
import { IRainWaterCategory } from '../models/rainwater/rainWaterCategory'
import { IRainWaterPart } from '../models/rainwater/rainWaterParts'
import { IRainWaterStyle } from '../models/rainwater/rainWaterStyles'
import { IBeamType } from '../models/beams/beamType'
import { IBeam } from '../models/beams/beams'
import { IBeamFinish } from '../models/beams/beamFinish'
import { IBeamSize } from '../models/beams/beamSizes'
import { IPanelBottomColour } from '../models/panel-bottom-color'
import { IPanelCores } from '../models/panel-core'
import { IPanelProfiles } from '../models/panel-profile'
import { IPanelThickness } from '../models/panel-thickness'
import { ILightPanelType } from '../models/lights/lightPanelType'
import { ILightProduct } from '../models/lights/lightProduct'
import { ILightProductType } from '../models/lights/lightProductType'
import { ILightProfile } from '../models/lights/lightProfiles'
import { IPostType } from '../models/posts/postType'
import { IPostProduct } from '../models/posts/postProduct'
import { IPostFinish } from '../models/posts/postFinish'
import { IPostCategory } from '../models/posts/postCategory'
import { IUnitType } from '../models/unit-types/unit-type'
import { IFixingUnitType } from '../models/fixings/fixingUnitType'
import { IBeamUnitType } from '../models/beams/beamUnitType'
import { ILightUnitType } from '../models/lights/lightUnitType'
import { IPostUnitType } from '../models/posts/postUnitType'
import { IRainwaterUnitType } from '../models/rainwater/rainwaterUnitType'

import { IFixingsProducts } from '../models/fixings/fixingProducts'
import { IFlashingsProducts } from '../models/flashings/flashingProducts'
import { IRainwaterProducts } from '../models/rainwater/rainWaterProducts'
import { IBeamsProducts } from '../models/beams/beamProducts'
import { IPostsProducts } from '../models/posts/postProducts'
import { ILightsProducts } from '../models/lights/lightProducts'
import { IPanelsProducts } from '../models/panel-products'
import { IColour } from '../models/colour'
import { IFixingUnitTypeProducts } from '../models/fixings/fixingUnitTypeProducts'

export const AdminProductContext = React.createContext<any>({})

export const AdminProductContextProvider: React.FC = ({ children }) => {
    const [fixings, setFixings] = useState<IFixing[] | undefined>()
    const [{ loading: fetchFixings }, getFixingAsync] = useAsyncFn(async () => {
        const result: any = await getAllFixings()
        if (result != null) {
            setFixings(result.data)
        }
    }, [setFixings])

    const [colours, setColours] = useState<IColour[] | undefined>()
    
    // ** Panels **//
    const [panelBottomColour, setPanelBottomColours] = useState<IPanelBottomColour[] | undefined>()
    const [panelCores, setPanelCores] = useState<IPanelCores[] | undefined>()
    const [panelProfiles, setPanelProfiles] = useState<IPanelProfiles[] | undefined>()
    const [panelThickness, setPanelThickness] = useState<IPanelThickness[] | undefined>()
    const [panelProducts, setPanelProducts] = useState<IPanelsProducts[] | undefined>()

    // ** Flashings **//
    const [flashingTypes, setFlashingTypes] = useState<IFlashingType[] | undefined>()
    const [flashingThickenesses, setFlashingThickenesses] = useState<
        IFlashingThickness[] | undefined
    >()
    const [flashingSubTypes, setFlashingSubTypes] = useState<IFlashingSubtype[] | undefined>()
    const [flashingsProducts, setFlashingsProducts] = useState<IFlashingsProducts[] | undefined>()

    // ** Rainwater **//
    const [rainwaterCategories, setRainwaterCategories] = useState<IRainWaterCategory[] | undefined>()
    const [rainwaterParts, setRainwaterParts] = useState<IRainWaterPart[] | undefined>()
    const [rainwaterStyles, setRainwaterStyles] = useState<IRainWaterStyle[] | undefined>()
    const [rainwaterUnitTypes, setRainwaterUnitTypes] = useState<IRainwaterUnitType[] | undefined>()
    const [rainwaterProducts, setRainwaterProducts] = useState<IRainwaterProducts[] | undefined>()


    // ** Beams **//
    const [beamFinishes, setBeamFinishes] = useState<IBeamFinish[] | undefined>()
    const [beams, setBeams] = useState<IBeam[] | undefined>()
    const [beamTypes, setBeamTypes] = useState<IBeamType[] | undefined>()
    const [beamUnitTypes, setBeamUnitTypes] = useState<IBeamUnitType[] | undefined>()
    const [beamsProducts, setBeamsProducts] = useState<IBeamsProducts[] | undefined>()
    const [beamSizes, setBeamSizes] = useState<IBeamSize[] | undefined>()
    const [beamProducts, setBeamProducts] = useState<{ id: number; name: string }[] | undefined>()

    // ** Light **//
    const [lightPanelTypes, setLightPanelTypes] = useState<ILightPanelType[] | undefined>()
    const [lightProduct, setLightProduct] = useState<ILightProduct[] | undefined>()
    const [lightProductTypes, setLightProductTypes] = useState<ILightProductType[] | undefined>()
    const [lightUnitTypes, setLightUnitTypes] = useState<ILightUnitType[] | undefined>()
    const [lightsProducts, setLightsProducts] = useState<ILightsProducts[] | undefined>()
    const [lightProfiles, setLightProfiles] = useState<ILightProfile[] | undefined>()
    const [lightThickness, setLightThickness] = useState<ILightProfile[] | undefined>()
    const [remappedLightUnitTypes, setRemappedLightUnitTypes] = useState<{ id: number; name: string }[] | undefined>();

    // ** Post **//
    const [postTypes, setPostTypes] = useState<IPostType[] | undefined>()
    const [postProduct, setPostProduct] = useState<IPostProduct[] | undefined>()
    const [postFinishes, setPostFinishes] = useState<IPostFinish[] | undefined>()
    const [postCategories, setPostCategories] = useState<IPostCategory[] | undefined>()
    const [postUnitTypes, setPostUnitTypes] = useState<IPostUnitType[] | undefined>()
    const [postsProducts, setPostsProducts] = useState<IPostsProducts[] | undefined>()

    // ** Fixings **//
    const [fixingUnitTypes, setFixingUnitTypes] = useState<IFixingUnitType[] | undefined>()
    const [fixingsProducts, setFixingsProducts] = useState<IFixingsProducts[] | undefined>()
    const [fixingUnitTypesProducts, setFixingUnitTypesProducts] = useState<IFixingUnitTypeProducts[] | undefined>()

    // ** Unit Type **//
    const [unitTypes, setUnitTypes] = useState<IUnitType[] | undefined>()

    return (
        <AdminProductContext.Provider
          value={{
            fixings,
            getFixingAsync,
            fetchFixings,
            setFlashingTypes,
            flashingTypes,
            setFlashingThickenesses,
            flashingThickenesses,
            setFlashingSubTypes,
            flashingSubTypes,
            rainwaterCategories,
            setRainwaterCategories,
            rainwaterParts,
            setRainwaterParts,
            rainwaterStyles,
            setRainwaterStyles,
            rainwaterUnitTypes,
            setRainwaterUnitTypes,
            beamFinishes,
            setBeamFinishes,
            beams,
            setBeams,
            beamTypes,
            setBeamTypes,
            panelBottomColour,
            setPanelBottomColours,
            panelCores,
            setPanelCores,
            panelProfiles,
            setPanelProfiles,
            panelThickness,
            setPanelThickness,
            lightPanelTypes,
            setLightPanelTypes,
            lightProduct,
            setLightProduct,
            lightProductTypes,
            setLightProductTypes,
            postTypes,
            setPostTypes,
            postProduct,
            setPostProduct,
            postFinishes,
            setPostFinishes,
            postCategories,
            setPostCategories,
            unitTypes,
            setUnitTypes,
            fixingUnitTypes,
            setFixingUnitTypes,
            beamUnitTypes,
            setBeamUnitTypes,
            lightUnitTypes,
            setLightUnitTypes,
            postUnitTypes,
            setPostUnitTypes,
            postsProducts,
            setPostsProducts,
            fixingsProducts,
            setFixingsProducts,
            lightsProducts,
            setLightsProducts,
            beamsProducts, 
            setBeamsProducts,
            rainwaterProducts, 
            setRainwaterProducts,
            flashingsProducts, 
            setFlashingsProducts,
            panelProducts, 
            setPanelProducts,
            colours,
            setColours,
            fixingUnitTypesProducts,
            setFixingUnitTypesProducts,
            beamSizes,
            setBeamSizes,
            lightProfiles,
            setLightProfiles,
            lightThickness,
            setLightThickness,
            remappedLightUnitTypes,
            setRemappedLightUnitTypes,
            beamProducts, 
            setBeamProducts
          }}
        >
          {children}
        </AdminProductContext.Provider>
    )
}
