import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {moneyFormatter} from '../../../../../../utils'
import '../LookUpTables.css'
import {UploadLoader} from '../../ProductMaster/Loader/UploadLoader'

function PostCodeMatrix() {
  const intl = useIntl()
  const [data, setData] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [dataPerPage] = useState(10)

  const baseURL = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchPostCodeMatrices()
  }, [currentPage])

  const fetchPostCodeMatrices = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/PostCodeMatrices?PageNumber=${currentPage}&PageSize=${dataPerPage}`
      )
      setData(response.data)
      setTotalPages(Math.ceil(response.data.totalCount / 10))
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  return (
    <>
      <h1>Post Code Matrix</h1>
      <div style={{backgroundColor: '#fff'}}>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.LOOKUPTABLES'})}</PageTitle>
        {/* <UploadLoader></UploadLoader> */}
        {/* TABLE */}
        <div style={{width: '80%'}} className='m-auto mt-4'>
          <table className='table table-hover lookup-table'>
            <thead className='thead-dark'>
              <tr>
                {/* <th scope='col'>ID</th> */}
                <th scope='col' className='align-middle text-center'>
                  Post Code
                </th>
                <th scope='col' className='align-middle text-center'>
                  Price Book
                </th>
                <th scope='col' className='align-middle text-center'>
                  Delivery Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.items?.map((data: any) => (
                <tr key={data?.id}>
                  {/* <td>{data?.id}</td> */}
                  <td className='align-middle text-center'>{data?.postCode}</td>
                  <td className='align-middle text-center'>{data?.pricebook}</td>
                  <td className='align-middle text-center'>
                    {moneyFormatter.format(data?.deliveryFee)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='pagination d-flex align-items-center pb-4'>
            <button className='page-link' onClick={handlePrevPage} disabled={currentPage === 1}>
              <i className='bi bi-chevron-left'></i>
            </button>
            <div className='px-4'>{`Page ${currentPage} of ${totalPages}`}</div>
            <button
              className='page-link'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <i className='bi bi-chevron-right'></i>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostCodeMatrix
