import axios from 'axios'
import React, {useRef, useEffect, useState, useContext} from 'react'
import FlashingsProduct from './FlashingsProduct'
import {
  getFlashingSubTypes,
  getFlashingThicknesses,
  getFlashingTypes,
  getFlashingMasterlist,
  createFlashingProduct,
  updateFlashingProduct,
  deleteFlashingProduct,
} from '../../../../../api/flashings-api'
import {getColours} from '../../../../../api/index'
import {useAsyncFn, useEffectOnce} from 'react-use'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import {useHistory} from 'react-router-dom'

type Props = {
  product: string
  productId: number
}

const Flashings = (props: Props) => {
  const {
    setFlashingTypes,
    flashingTypes,
    setFlashingThickenesses,
    flashingThickenesses,
    setFlashingSubTypes,
    flashingSubTypes,
    colours,
    setColours,
  } = useContext(AdminProductContext)

  const {product, productId} = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')
  const [Filter4, setFilter4] = useState('')

  const history = useHistory()

  useEffect(() => {
    setSeqTotalPages(1)
    setSeqCurrentPage(1)
    // Load to page 1
    // fetchFlashingProduct(1)
  }, [productId])

  const fetchFlashingProduct = async (
    currentPage: number,
    filter1?: any,
    filter2?: any,
    filter3?: any,
    filter4?: any
  ) => {
    await getFlashingMasterlist(
      currentPage,
      seqDataPerPage,
      filter1,
      filter2,
      filter3,
      filter4
    ).then((response: any) => {
      setSeqData(response.data)
      setSeqTotalPages(Math.ceil(response.data?.totalCount / 10))
    })
  }
  /**
   * INITIAL SETUP
   */
  useEffectOnce(() => {
    if (!flashingTypes && !fetchFlashingType) {
      getFlashingTypesAsync()
    }
    if (!flashingThickenesses && !fetchFlashingThicknesses) {
      getFlashingThicknessesAsync()
    }
    if (!flashingSubTypes && !fetchFlashingSubTypes) {
      getFlashingSubTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
  })

  /**
   * FETCH RECORD
   */
  const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])

  const [{loading: fetchFlashingType}, getFlashingTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFlashingTypes()
    if (result != null) {
      setFlashingTypes(result.data)
    }
  }, [setFlashingTypes])

  const [{loading: fetchFlashingThicknesses}, getFlashingThicknessesAsync] =
    useAsyncFn(async () => {
      const result: any = await getFlashingThicknesses()
      if (result != null) {
        setFlashingThickenesses(result.data)
      }
    }, [setFlashingThickenesses])

  const [{loading: fetchFlashingSubTypes}, getFlashingSubTypesAsync] = useAsyncFn(async () => {
    const result: any = await getFlashingSubTypes()
    if (result != null) {
      setFlashingSubTypes(result.data)
    }
  }, [setFlashingSubTypes])
  /**
   * REFS
   */
  const nameRef = useRef<any>()
  const thicknessRef = useRef<any>()
  const subTypeRef = useRef<any>()
  const priceRef = useRef<any>()
  const regex = /^[a-zA-Z0-9\s./-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/
  /**
   * SEQ
   */
  const [seqData, setSeqData] = useState<any>({})
  const [newSeqProduct, setNewSeqProduct] = useState({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})
  /**
   * PAGINATION
   */
  const handleFilter = (filter1: any, filter2: any, filter3: any, filter4: any) => {
    // fetchFlashingProduct(seqCurrentPage, filter1, filter2, filter3, filter4)
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setFilter4(filter4)
    setSeqCurrentPage(1)
  }

  // useEffect(() => {
  //   // fetchFlashingsProduct(seqCurrentPage, Filter1, Filter2)
  //   setSeqCurrentPage(1)
  // }, [Filter1, Filter2, Filter3, Filter4])

  useEffect(() => {
    fetchFlashingProduct(seqCurrentPage, Filter1, Filter2, Filter3, Filter4)
  }, [seqCurrentPage, Filter1, Filter2, Filter3, Filter4])

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setIsSeqAdding(false)
  }
  const handleCancel = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }
    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({
        ...prevData,
        [name]: name === 'panelThickness' ? {...name, name: value} : value,
      }))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deleteFlashingProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchFlashingProduct(seqCurrentPage)
        handleSuccessMsg('Product deleted successfully.')
      } else {
        const errorMessage =
          result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Flashing:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }

    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
  }
  /**
   * UPDATE
   */

  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg('Product updated sucessfully.')
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewSeqProduct({})
    setIsSeqAdding(false)
  }

  const handleUpdateProduct = (iProductId: any) => {
    history.push(`/admin/product-details/Flashing/${iProductId}`)
  }
  const handleSave = () => {
    const newData = {
      ...seqData,
      items: seqData.items?.map((item: any) => (item.id === editingSeqId ? editedSeqData : item)),
    }
    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  /**
   * ADD
   */

  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData({...seqData, items: [newSeqProduct, ...seqData.items]})
    setIsSeqAdding(false)
  }
  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(nameRef.current.value)) {
      nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(thicknessRef.current.value)) {
      thicknessRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(subTypeRef.current.value)) {
      subTypeRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    setNewSeqProduct({...newSeqProduct, [name]: value})
  }

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const productFields = [
    {label: 'Item Number', key: 'Code', type: 'text'},
    {label: 'Flashing Type', key: 'FlashingTypeName', type: 'text'},
    {label: 'Panel Type', key: 'FlashingSubTypeId', type: 'dropdown', options: flashingSubTypes},
    {label: 'Size', key: 'FlashingThicknessId', type: 'dropdown', options: flashingThickenesses},
    {label: 'Colour', key: 'ColourId', type: 'dropdown', options: colours},
  ]

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    createFlashingProduct(newProduct)
      .then((result: any) => {
        if (result.data) {
          handleSuccessMsg('Product added successfully.')
          fetchFlashingProduct(seqCurrentPage)
        } else {
          handleErrorMessage(result?.response?.data)
        }
      })
      .catch(() => {
        handleErrorMessage('Error Encountered While Saving. Please try again later.')
      })

    setIsSeqAdding(false)
  }

  return (
    <>
      <div className='card-body' style={{height: '100vh', backgroundColor: '#fff'}}>
        <AddProductButton onClick={handleAddProduct} />
        <div style={{overflowY: 'auto', height: '95%'}}>
          {errorMsg && (
            <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
              <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className='alert alert-success w-50 m-auto text-center' role='alert'>
              <i className='fa fa-check-circle text-success'></i> {successMsg}
            </div>
          )}

          <FlashingsProduct
            data={seqData}
            currentPage={seqCurrentPage}
            totalPages={seqTotalPages}
            handleUpdateProduct={handleUpdateProduct}
            handleSave={handleSave}
            editingId={editingSeqId}
            editedData={editedSeqData}
            handleSaveClick={handleSaveClick}
            handleInputChange={handleInputChange}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            handleCancelClick={handleCancelClick}
            handleCancel={handleCancel}
            handleDeleteProduct={handleDeleteProduct}
            handleChange={handleChange}
            isAdding={isSeqAdding}
            nameRef={nameRef}
            thicknessRef={thicknessRef}
            subTypeRef={subTypeRef}
            priceRef={priceRef}
            newProduct={newSeqProduct}
            handleUpdateSuccess={handleUpdateSuccess}
            handleErrorMessage={handleErrorMessage}
            handleFilter={handleFilter}
          />
        </div>
      </div>
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
        isDeleting={isDeleting}
      />
      <NewProductModal
        isOpen={isAddingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleSaveNewProduct}
        fields={productFields}
      />
    </>
  )
}

export default Flashings
