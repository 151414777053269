import React, {useContext, useState, useEffect, Fragment} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {ILightPricing} from '../../../../models/lights/lightPricing'
import {ILightProductType} from '../../../../models/lights/lightProductType'
import {ILightPanelType} from '../../../../models/lights/lightPanelType'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { hasUncaughtExceptionCaptureCallback } from 'process'
import { LightProduct } from './enum'
import { 
  ORDER_LIST_LIGHTS,
  ORDER_TAB_LIGHTS_DELTASKYLIGHT,
  ORDER_TAB_LIGHTS_170MM_X_1_LIGHT_PER_BOX
} from '../../../../common/constant'

function LightsItem(props: any) {

  // const getLightProductTypesByProduct = (
  //   pricings: ILightPricing[],
  //   product: string
  // ): ILightProductType[] => {
  //   let resultPricing = pricings
  //     .filter((p) => p.lightProductName === product)
  //     .map((p) => ({id: p.lightProductTypeId, name: p.lightProductTypeName}))
  //     .filter(
  //       (value, index, self) =>
  //         self.findIndex((item) => item.id === value.id && item.name === value.name) === index
  //     )
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))

  //     return resultPricing;
  // }

  // const getLightPanelTypesByProduct = (
  //   pricings: ILightPricing[],
  //   product: string
  // ): ILightPanelType[] => {
  //   return pricings
  //     .filter((p) => p.lightProductName === product)
  //     .map((p) => ({id: p.lightPanelTypeId, name: p.lightPanelTypeName}))
  //     .filter(
  //       (value, index, self) =>
  //         self.findIndex((item) => item.id === value.id && item.name === value.name) === index
  //     )
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  // }

  // const [lightProductTypes, setLightProductTypes] = useState<ILightProductType[]>([])
  // const [lightPanelTypes, setLightPanelTypes] = useState<ILightPanelType[]>([])

  const {addToCart, lightProducts, lightPricings, cartItems, lightUnitPricePM, 
    setLightUnitPricePM, getLightUnitPriceAsync, lightProductMastersId, deltaLowProfileLightId, deltaSkylightId,
    lightThicknesses
  } = useContext(QuickOrderContext)

  const [hasDefaultPanelType, setHasDefaultPanelType] = useState<boolean>(false)

  const [lightProduct, setLightProduct] = useState(0)
  const handleLightProductOnChange = (e: any) => {
    setLightProduct(+e.target.value)
  }
  const [lightName, setLightName] = useState('')

  // useEffect(() => {
  //   if (lightPanelTypes) {
  //     const existsLights = lightPanelTypes.filter((item1) =>
  //       cartItems.some(
  //         (x: any) =>
  //           x.type === 'panel' &&
  //           x.panelProfileName.toLowerCase().includes(item1.name.toLowerCase().replace('delta', ''))
  //       )
  //     )
  //     if (existsLights) {
  //       if (existsLights && existsLights.length > 0) {
  //         const defaultPanel = existsLights[0]
  //         setPanelType(+defaultPanel.id)
  //       }
  //     } else {
  //       setPanelType(0)
  //     }
  //   }
  // }, [productTypeOption])

  /**
   * PRODUCT TYPE
   */
  const [lightsDetail, setLightsDetail] = useState('')
  const [lightsTypeNamePM, setLightsTypeNamePM] = useState<string>()
  const handleLightsTypeOnChange = (e: any) => {
    setLightsDetail(e.target.value)

    // const selectedName = e.target.options[e.target.selectedIndex].innerHTML
    // setLightsTypeNamePM(selectedName)
    // console.log('e.target.value', e.target.value)
    // console.log('selectedName', selectedName)
  }

  const [lightsTypeName, setLightsTypeName] = useState<string>()
  useEffect(() => {
    let selected: any = productTypeOption.find((b: any) => b.name === lightsDetail)
    if (selected) {
      setLightsTypeName(selected.name)
    }
    
    if (lightProduct === deltaLowProfileLightId && lightsDetail.toLowerCase().trim() === ORDER_TAB_LIGHTS_170MM_X_1_LIGHT_PER_BOX.toLowerCase().trim()) {
      setHasNoColour(true)
    }
    else {
      setHasNoColour(false)
    }
  }, [lightsDetail])

  const [isSkylight, setIsSkylight] = useState<boolean>(false)
  const [hasNoColour, setHasNoColour] = useState<boolean>(false)
  const [productTypeOption, setProductTypeOption] = useState([])
  const getLightProductTypesOption = (
    productId: number
  ) => {

    let availableProductType = lightPricings
      .filter((p: any) => p.lightProductId === productId)

    const productTypeSelection: any = Object.values(
      availableProductType.reduce((map: any, obj: any) => {
            const key = `${obj.detail}`
            if (!map[key]) {
              map[key] = obj
            }
            return map
        }, {} as Record<string, any>)
    )
    const result = (productTypeSelection || [])?.map((availPricing: any) => ({
        id: availPricing.detail,
        name: availPricing.detail,
    }))
    setLightsDetail('')
    setProductTypeOption(result)
  }

  /**
   * PANEL TYPE
   */
  const [panelType, setPanelType] = useState(0)
  const [panelTypeOption, setPanelTypeOption] = useState([])
  const handlePanelTypeOnChange = (e: any) => {
    setPanelType(+e.target.value)
    // getLightProductTypesOption(lightProduct, +e.target.value)
  }

  const [panelTypeName, setPanelTypeName] = useState<string>()
  useEffect(() => {
    let selected: any = panelTypeOption.find((b: ILightPanelType) => b.id === panelType)
    if (selected) {
      setPanelTypeName(selected.name)
    }
  }, [panelType])

  /**
   * THICKNESS
   */
  const [lightThickness, setLightThickness] = useState(0)
  const [lightThicknessName, setLightThicknessName] = useState('')
  const handleThicknessOnChange = (e: any) => {
    setLightThickness(+e.target.value)
  }

  useEffect(() => {
    let selected: any = lightThicknesses.find((b: ILightPanelType) => b.id === lightThickness)
    if (selected) {
      setLightThicknessName(selected.name)
    }
    else {
      setLightThickness(0)
    }
  }, [lightThickness])

  useEffect(() => {
    let selected = lightProducts?.find((l: any) => l.id.toString() == lightProduct)
    if (selected) {
      getLightPanelTypesOption(lightProduct)
      getLightProductTypesOption(lightProduct)
      // setLightProductTypes(getLightProductTypesByProduct(lightPricings, selected.name))
      // setLightPanelTypes(getLightPanelTypesByProduct(lightPricings, selected.name))
      setQuantity('')
      setLightsDetail('')
      setLightName(selected.name)
      setLength('')
    } else {
      setQuantity('')
      setLightsDetail('')
      setLength('')
      setProductTypeOption([])
      getLightPanelTypesOption(deltaSkylightId)
      setIsSkylight(false)
      setLightThickness(0)
      setLightName('')
    }

    setErrorLengthMsg('')
    getDefaultThickness()
  }, [lightProduct])

  useEffect(() => {
    if (lightsDetail) {
      if (lightsDetail === '' || lightsDetail === 'Select Type') {
        if (hasDefaultPanelType) {
          getLightPanelTypesOption(deltaSkylightId)
        }
        else {
          setPanelType(0)
        }
      }
    }
  }, [lightsDetail, hasDefaultPanelType])

  const getLightPanelTypesOption = (
    productId: number
  ) => {

    let availableProductType = lightPricings
      .filter((p: any) => p.lightProductId === productId)

    const productTypeSelection: any = Object.values(
      availableProductType.reduce((map: any, obj: any) => {
            const key = `${obj.lightProfileId}-${obj.lightProfileName}`
            if (!map[key]) {
            map[key] = obj
            }
            return map
        }, {} as Record<string, any>)
    )
    const result = (productTypeSelection || [])?.map((availPricing: any) => ({
        id: availPricing.lightProfileId,
        name: availPricing.lightProfileName,
    }))

    // default selection for panel type
    const existsData = result.filter((item1: any) => {
      const panelItems = cartItems.filter((item: any) => item.type === 'panel')
      
      if (panelItems.length > 0) {
        const lastAddedPanelItem = panelItems[0]
    
        return lastAddedPanelItem?.panelProfileName
          .toLowerCase()
          .includes(item1?.name?.toLowerCase().replace('delta', ''))
      }

      return false
    })

    if (existsData) {
      if (existsData && existsData.length === 1) {
        const defaultPanel = existsData[0]
        setPanelType(defaultPanel.id)
        setPanelTypeName(defaultPanel.name)
        setHasDefaultPanelType(true)
      } else {
        setPanelType(0)
        setHasDefaultPanelType(false)
      }
    } else {
      setPanelType(0)
      setHasDefaultPanelType(false)
    }

    setPanelTypeOption(result)
  }

  const getDefaultThickness = () => {
    // default selection for thickness
    const existsData = lightThicknesses.filter((item1: any) => {
      const panelItems = cartItems.filter((item: any) => item.type === 'panel')
      
      if (panelItems.length > 0) {
        const lastAddedPanelItem = panelItems[0]
    
        return lastAddedPanelItem?.panelThicknessName
          .toLowerCase() === item1.name.toLowerCase()
      }

      return false
    })
    
    if (existsData) {
      if (existsData && existsData.length === 1) {
        const defaultPanel = existsData[0]
        setLightThickness(defaultPanel.id)
        setLightThicknessName(defaultPanel.name)
      } else {
        setLightThickness(0)
      }
    } else {
      setLightThickness(0)
    }
  }

  const hasQtyAndLen = lightProduct === deltaSkylightId

  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<any>(0)
  const handleQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > 99 || inputNumber < 0) {
      return
    }
    setQuantity(inputNumber)

    if (!hasQtyAndLen) {
      setLength(0)
    }
  }

  /**
   * LENGTH
   */
  const [length, setLength] = useState<any>('')
  const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
  const handleLengthFocusChange = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue === 0 || inputValue < 0) {
      setErrorLengthMsg('Value must not be less than 100mm')
      return
    }
    if (inputValue < 100) {
      setErrorLengthMsg('Value must not be less than 100mm')
    } else if (inputValue > 8000) {
      setErrorLengthMsg('Length should not be more than 8000mm')
    } else {
      setErrorLengthMsg('')
    }
    setLength(inputValue ? inputValue : 0)

    if (!hasQtyAndLen) {
      setQuantity(0)
    }
  }

  const handleLengthBlur = (e: any) => {
    setErrorMsg('')
    setError(false)
  }

  /**
   * ERROR
   */
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])

  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [pricingId, setPricingId] = useState<number>(0)
  const [lightPricing, setLightPricing] = useState<any>()

  useEffect(() => {
    setUnitPrice(lightUnitPricePM ? lightUnitPricePM : 0)
    if (panelType > 0 && lightsDetail !== '' && lightProduct > 0 && lightsDetail !== 'Select Type' || hasNoColour) {
      // let paramPanel = panelType
      // let defaultPanel = lightPanelTypes.find((x) => x.name.toLowerCase() == 'all')
      // if (defaultPanel && paramPanel == 0) {
      //   paramPanel = defaultPanel.id
      // }
  
      let lightPricing = lightPricings?.find(
        (f: ILightPricing) =>
          f.lightProductId === lightProduct &&
          f.detail === lightsDetail &&
          (hasNoColour === false ? f.lightProfileId === panelType : true)
      )
  
      if (lightPricing) {
        // setUnitTypeDisplay(lightPricing.unitTypeName === '2' ? 'per set' : 'each')
        setUnitTypeDisplay(lightPricing.lightUnitTypeName)
        setUnitPrice(lightUnitPricePM ? lightUnitPricePM : 0)
        setPricingId(lightPricing.id)
        setLightPricing(lightPricing)
        setLightsTypeNamePM(lightPricing.lightUnitTypeName)
        setErrorMsg('')
        setError(false)
        if (hasNoColour) {
          setPanelType(0)
        }
      } else {
        setUnitTypeDisplay('')
        setUnitPrice(lightUnitPricePM ? lightUnitPricePM : 0)
        setLightsTypeNamePM('')
        setErrorMsg('Pricing for this combination is currently not available.')
        setError(true)
      }
    } else {
      setPricingId(0)
      setLightPricing(0)
      setErrorMsg('')
      setError(false)
      setUnitPrice(0)
    }
  
  }, [panelType, lightsDetail, lightProduct, lightUnitPricePM, hasNoColour])

  useEffect(() => {
    const getLightUnitPrice = async () => {
      if (isSkylight) {
        if (lightProduct && panelType && lightsDetail && lightThickness) {
          await getLightUnitPriceAsync(lightProduct, lightsDetail, panelType, lightThickness)
        }        
        else {
          setLightUnitPricePM(0)
        }
      }
      else {
        if (lightProduct && panelType && lightsDetail) {
          await getLightUnitPriceAsync(lightProduct, lightsDetail, panelType)
        }
        else {
          setLightUnitPricePM(0)
        }
      }
    }

    getLightUnitPrice()

  }, [lightProduct, panelType, lightsDetail, lightThickness, isSkylight])

  useEffect(() => {
    const getLightUnitPrice = async () => {
      if (hasNoColour) {
        await getLightUnitPriceAsync(lightProduct, lightsDetail, 0)
      }
    }

    getLightUnitPrice()

  }, [hasNoColour])

  useEffect(() => {
    if (lightName.toLowerCase() === ORDER_TAB_LIGHTS_DELTASKYLIGHT.toLowerCase()) {
      setIsSkylight(true)
    }
    else {
      setIsSkylight(false)
    }
  }, [lightName])

  const description = () => {
    if (lightName.toLowerCase() === ORDER_TAB_LIGHTS_DELTASKYLIGHT.toLowerCase()) {
      return `${panelTypeName ? panelTypeName + ', ' : ''}${lightsTypeName}, ${lightThicknessName}`
    }
    else {
      return `${lightsTypeName}${panelTypeName ? ', ' + panelTypeName : ''}`
    }
  }

  const disabledField = lightProduct === 0
  const isQuantityValid = quantity !== null && !isNaN(quantity) && quantity > 0 && quantity <= 99
  const isLengthValid = length !== null && !isNaN(length) && length >= 100 && length <= 8000

  const disabled = lightProduct === deltaSkylightId ? 
  (
    lightsDetail === '' ||
    unitPrice === 0 || 
    length === null ||
    quantity === null ||
    !isQuantityValid ||
    !isLengthValid
  ) : (
    lightProduct === 0 ||
    lightsDetail === '' ||
    unitPrice === 0 || 
    ((+quantity) + (+length)) === 0 ||
    (!isLengthValid && length > 0)
  )

  const isPanelTypeColourDisabled = lightsDetail === '' || lightsDetail === 'Select Type'

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Product Name</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={lightProduct}
            onChange={handleLightProductOnChange}
          >
            <option value=''>Select Product</option>
            {lightProducts?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' />
        <div className='col-md-3' />
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 0,
                marginRight: 24,
              }}
            >
              {moneyFormatter.format(unitPrice)}
            </p>
            <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
              {unitPrice ? unitTypeDisplay.toLowerCase() : ''}
            </p>
          </div>
          <button
            className='btn btn-primary w-100'
            onClick={() => {
              addToCart({
                ...lightProducts,
                ...lightPricing,
                lightProductMastersId,
                pricingId,
                lightsDetail,
                lightsTypeName,
                lightsTypeNamePM,
                panelTypeName,
                quantity: quantity ? quantity : 1,
                length,
                lightName,
                lightThicknessName: isSkylight ? lightThicknessName : '',
                unitPrice,
                customId: Math.random(),
                type: 'lights',
                uniqueID:
                  `lights-${lightName}-${lightsTypeNamePM}-${lightsDetail}-${panelType}-${quantity}-${length}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
                    'lights'
                  )}`.replace(/\s/g, ''),
                price: unitPrice,
                productType: ORDER_LIST_LIGHTS,
                productName: lightName,
                description: description(),
              })
              
              setLength('')
              setQuantity('')
              setErrorLengthMsg('')
            }}
            disabled={disabled}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>
      <div className='row'>
        {/* PRODUCT TYPE */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Product Type</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={lightsDetail}
            onChange={handleLightsTypeOnChange}
            disabled={disabledField}
          >
            <option value='Select Type'>Select Type</option>
            {productTypeOption?.map((post: any) => (
              <option key={post.id} value={post.id}>
                {post.name}
              </option>
            ))}
          </select>
        </div>
        {/*  PANEL TYPES / COLOUR */}
        {!hasNoColour && (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark'>
              {lightProduct === deltaLowProfileLightId ? 'Colour' : `Panel Type`}
            </label>
            <select
              style={{fontSize: '1rem'}}
              className='form-select my-4'
              value={panelType}
              onChange={handlePanelTypeOnChange}
              disabled={isPanelTypeColourDisabled}
            >
              <option value=''>
                {lightProduct === deltaLowProfileLightId ? 'Select Colour' : `Select Type`}
              </option>
              {panelTypeOption
                ?.filter((option: any) => option.name !== null)
                .map((post: any) => (
                  <option key={post.id} value={post.id}>
                    {post.name}
                  </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className='row'>
        {/*  Thickness */}
        {isSkylight && (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark'>
              Thickness
            </label>
            <select
              style={{fontSize: '1rem'}}
              className='form-select my-4'
              value={lightThickness}
              onChange={handleThicknessOnChange}
              disabled={disabledField}
            >
              <option value=''>
                Select Thickness
              </option>
              {lightThicknesses
                ?.filter((option: any) => option.name !== null)
                .map((light: any) => (
                  <option key={light.id} value={light.id}>
                    {light.name}
                  </option>
              ))}
            </select>
          </div>
        )}
        {/* QUANTITY */}
        {(lightsTypeNamePM?.toLowerCase().trim().includes("per box") || hasQtyAndLen) && (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark'>Quantity</label>
            <input
              style={{fontSize: '1rem'}}
              type='number'
              className='form-control my-4'
              placeholder='Enter Quantity'
              min='1'
              max='99'
              value={quantity || ''}
              onChange={handleQuantityChange}
              disabled={disabledField}
            />
          </div>
        )}
        {/* LENGTH */}
        {lightsTypeNamePM?.toLowerCase().trim().includes("per linear metre") && (
          <div className='col-md-3'>
            <label className='fw-bolder text-dark' htmlFor='length'>
              Length (mm)
            </label>
            <input
              style={{
                fontSize: '1rem',
                borderColor: length < 100 && length !== '' || length > 8000 ? 'red' : '',
              }}
              placeholder='Enter Length'
              min='1'
              step='1'
              max='23000'
              type='number'
              className='form-control my-4'
              id='length'
              aria-describedby='length'
              value={length || ''}
              onFocus={handleLengthFocusChange}
              onBlur={handleLengthBlur}
              onChange={handleLengthFocusChange}
            />
            {errorLengthMsg ? (
              <div className='text-danger m-auto text-left' role='alert'>
                {errorLengthMsg}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default LightsItem
