import axios from 'axios'
export const RAINWATER_PRICING_URL = `${process.env.REACT_APP_API_URL}/rainwaterpricings`
export const RAINWATER_CATEGORIES_URL = `${process.env.REACT_APP_API_URL}/rainwatercategories`
export const RAINWATER_PARTS_URL = `${process.env.REACT_APP_API_URL}/rainwaterparts`
export const RAINWATER_STYLES_URL = `${process.env.REACT_APP_API_URL}/rainwaterstyles`
export const RAINWATER_UNIT_TYPES_URL = `${process.env.REACT_APP_API_URL}/rainwaterunittypes`

export const RAINWATERMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/RainwaterMasterlist`

interface RainwaterProducts {
  id: number
  productName: string
  gutterType: string
  colour: string
}

export const getAllProduct = async (priceBookid?: number) => {
  try {
      const response = await axios.get(`${RAINWATERMASTERLIST_URL}/getAllRainwaterProduct`, {
        params: {
          priceBookid
        }
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getRainwaterMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  RainwaterPartName?: string,
  RainwaterStyleName?: string,
  RainwaterColourName?: string
) => {
  try {
    return await axios.get(`${RAINWATERMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        RainwaterPartName,
        RainwaterStyleName,
        RainwaterColourName
      },
    })
  } catch (err) {
    return err
  }
}

export const createRainwaterProduct = async (payload: any) => {
  try {
    return await axios.post(`${RAINWATERMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateRainwaterProduct = async (payload: any) => {
  try {
    return await axios.patch(`${RAINWATERMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteRainwaterProduct = async (id: any) => {
  try {
    return await axios.delete(`${RAINWATERMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getRainWaterPricings = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${RAINWATER_PRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getRainWaterCategories = async (priceBookid?: number) => {
  try {
    return await axios.get(`${RAINWATER_CATEGORIES_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const createRainWaterPricing = async (payload: any) => {
  try {
    return await axios.post(`${RAINWATER_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateRainWaterPricing = async (payload: any) => {
  try {
    return await axios.patch(`${RAINWATER_PRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteRainWaterPricing = async (id: any) => {
  try {
    return await axios.delete(`${RAINWATER_PRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getRainwaterCategories = async () => {
  try {
    return await axios.get(`${RAINWATER_CATEGORIES_URL}`)
  } catch (err) {
    return []
  }
}

export const getRainwaterParts = async () => {
  try {
    return await axios.get(`${RAINWATER_PARTS_URL}`)
  } catch (err) {
    return []
  }
}

export const getRainwaterStyles = async () => {
  try {
    return await axios.get(`${RAINWATER_STYLES_URL}`)
  } catch (err) {
    return []
  }
}

export const getRainwaterUnitTypes = async () => {
    try {
        return await axios.get(`${RAINWATER_UNIT_TYPES_URL}`)
    } catch (err) {
        return []
    }
}

