import {
  ProductMasterBeams,
  ProductMasterFixings,
  ProductMasterFlashings,
  ProductMasterLights,
  ProductMasterPosts,
  ProductMasterRainwaters,
  ProductPanelMaster,
  ProductMasterAccessories
} from './enum'

export const transformPanelSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductPanelMaster)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (data[ProductPanelMaster.code] && data[ProductPanelMaster.code].toLowerCase() != 'code') {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformFixingsSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterFixings)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (
      data[ProductMasterFixings.code] &&
      data[ProductMasterFixings.code].toLowerCase() != 'code'
    ) {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformRainwatersSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterRainwaters)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (
      data[ProductMasterRainwaters.code] &&
      data[ProductMasterRainwaters.code].toLowerCase() != 'code'
    ) {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformFlashingsSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterFlashings)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (
      data[ProductMasterFlashings.code] &&
      data[ProductMasterFlashings.code].toLowerCase() != 'code'
    ) {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        if (head === 'panelCoreThickness') {
          result[head] = typeof data[index] === 'number' ? data[index].toString() : data[index];
        } else {
          result[head] = data[index];
        }
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformBeamsSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterBeams)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (data[ProductMasterBeams.code] && data[ProductMasterBeams.code].toLowerCase() != 'code') {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        if (head === 'size') {
          result[head] = typeof data[index] === 'number' ? data[index].toString() : data[index];
        } else {
          result[head] = data[index];
        }
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformPostsSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterPosts)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (data[ProductMasterPosts.code] && data[ProductMasterPosts.code].toLowerCase() != 'code') {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformLightsSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterLights)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (data[ProductMasterLights.code] && data[ProductMasterLights.code].toLowerCase() != 'code') {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}

export const transformAccessoriesSaveJson = (objectData: any) => {
  let header: any = []
  Object.entries(ProductMasterAccessories)
    .filter(([key]) => isNaN(Number(key)))
    .forEach(([key]) => {
      header = [...header, key]
    })

  let transformed = objectData.map((data: any) => {
    if (
      data[ProductMasterAccessories.code] &&
      data[ProductMasterAccessories.code].toLowerCase() != 'code'
    ) {
      const result: any = {}
      header.forEach((head: string, index: number) => {
        result[head] = data[index]
      })
      return result
    }
  })
  return transformed.filter((item: any) => item)
}