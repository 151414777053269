import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {moneyFormatter, validLimitDecimal} from '../../../../../../utils'
import {IFixing} from '../../../../../models/fixings/fixing'
import {NumericInput} from '../../../../../shared/components/NumericInput'
import {AdminProductContext} from '../../../../../context/AdminProductContext'

type Product = {
  data?: any
  currentPage?: number
  totalPages?: number
  handleView: (id: any) => void
  handleSave: () => void
  editingId?: any
  editedData?: any
  newProduct?: any
  handleSaveClick: () => void
  handleInputChange: (e: any) => void
  handlePrevPage: () => void
  handleNextPage: () => void
  handleCancelClick: () => void
  handleCancel: () => void
  handleDeleteProduct: (id: any) => void
  handleChange: (e: any) => void
  handleUpdateSuccess: (e: any) => void
  handleErrorMessage: (msg: string) => void
  isAdding: boolean
  nameRef: any
  screwRef: any
  unitRef: any
  priceRef: any
  fixings: IFixing[]
  handleFilter: (filter1: any, filter2: any, filter3: any) => void
}

function FixingsProduct(props: Product) {
  const {
    data,
    currentPage,
    totalPages,
    handleView,
    handleSave,
    editingId,
    editedData,
    handlePrevPage,
    handleNextPage,
    handleCancel,
    handleCancelClick,
    handleDeleteProduct,
    handleUpdateSuccess,
    isAdding,
    fixings,
    newProduct,
    handleErrorMessage,
    handleFilter,
  } = props

  const {fixingUnitTypes} = useContext(AdminProductContext)

  const [screwNameFilter, setScrewNameFilter] = useState('')
  const [screwTypeFilter, setScrewTypeFilter] = useState('')
  const [colourNameFilter, setColourNameFilter] = useState('')

  const numRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/

  const [saveData, setSaveData] = useState(isAdding ? newProduct : editedData)

  useEffect(() => {
    handleFilter(screwNameFilter, screwTypeFilter, colourNameFilter)
  }, [screwNameFilter, screwTypeFilter, colourNameFilter])

  useEffect(() => {
    setSaveData(editedData)
  }, [editedData])

  useEffect(() => {
    setSaveData(newProduct)
  }, [newProduct])

  const invalidRecord = (saveData?.screwName || '') === '' || (saveData?.screwType || '') === ''

  return (
    <div style={{width: '85%'}} className='m-auto mt-4'>
      <table className='table table-hover product-table '>
        <thead className='thead-dark'>
          <tr key={'col' + 0}>
            <th scope='col' className='align-middle text-center'>
              Item Number
            </th>
            <th scope='col' className='align-middle text-center'>
              Screw Name
            </th>
            <th scope='col' className='align-middle text-center'>
              Screw Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Colour
            </th>
            <th scope='col' className='align-middle text-center'></th>
          </tr>
          <tr>
            <td className='align-middle text-center'></td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={screwNameFilter}
                onChange={(e) => setScrewNameFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={screwTypeFilter}
                onChange={(e) => setScrewTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={colourNameFilter}
                onChange={(e) => setColourNameFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'></td>
          </tr>
        </thead>
        <tbody>
          {data?.items?.length === 0 ? (
            <tr>
              <td colSpan={5} className='align-middle text-center'>
                There are no products to display!
              </td>
            </tr>
          ) : (
            data?.items?.map((data: any, index: number) => (
              <tr key={'rw-' + index}>
                <React.Fragment>
                  <td className='align-middle text-center'>{data?.code}</td>
                  <td className='align-middle text-center'>{data?.fixingName}</td>
                  <td className='align-middle text-center'>{data?.screwType}</td>
                  <td className='align-middle text-center'>{data?.colourName}</td>
                </React.Fragment>
                <td className='align-middle text-center' style={{width: '250px'}}>
                  <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => handleView(data?.id)}
                      style={{marginRight: 10}}
                    >
                      View
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleDeleteProduct(data?.id)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className='pagination d-flex align-items-center pb-4'>
        <button className='page-link' onClick={handlePrevPage} disabled={currentPage === 1}>
          <i className='bi bi-chevron-left'></i>
        </button>
        <div className='px-4'>{`Page ${currentPage} of ${totalPages}`}</div>
        <button
          className='page-link'
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <i className='bi bi-chevron-right'></i>
        </button>
      </div>
    </div>
  )
}

export default FixingsProduct
