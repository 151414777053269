import React, { useEffect, useState } from 'react'

function PaymentMethod(props: any) {

  
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    localStorage.getItem('paymentMethod') || 'bank-transfer'
  )

  const handlePaymentMethodChange = (event: any) => {
    setSelectedPaymentMethod(event.target.value)
  }
  
  useEffect(() => {
    localStorage.setItem('paymentMethod', selectedPaymentMethod)
    
    if (props.changePaymentMethodHandler)
        props.changePaymentMethodHandler(selectedPaymentMethod)

  }, [selectedPaymentMethod])

  return (
    <React.Fragment>
        <div className='row mb-6' style={{marginTop: 30}}>
          <h3>PAYMENT METHOD</h3>
        </div>
        <div className='row mb-6'>
          <div
            className='d-flex flex-row align-items-center mb-6'
            style={{backgroundColor: '#e5e5e5', border: '1px solid rgb(204, 204, 204)'}}
          >
            <input
              className='form-check-input'
              type='radio'
              name='paymentMethod'
              id='bank-transfer'
              style={{marginRight: 50, marginLeft: 0}}
              value={'bank-transfer'}
              checked={selectedPaymentMethod === 'bank-transfer'}
              onChange={handlePaymentMethodChange}
            />
            <label
              className='form-check-label py-5 fw-bolder text-dark'
              htmlFor='bank-transfer'
              style={{fontSize: '1.15rem'}}
            >
              BANK TRANSFER
            </label>
          </div>

          <div
            className='d-flex flex-row align-items-center'
            style={{backgroundColor: '#e5e5e5', border: '1px solid rgb(204, 204, 204)'}}
          >
            <input
              className='form-check-input'
              type='radio'
              name='paymentMethod'
              id='credit-card'
              value={'credit-card'}
              checked={selectedPaymentMethod === 'credit-card'}
              style={{marginRight: 50, marginLeft: 0}}
              onChange={handlePaymentMethodChange}
            />
            <label
              className='form-check-label py-5 fw-bolder text-dark'
              htmlFor='credit-card'
              style={{fontSize: '1.15rem'}}
            >
              CREDIT CARD
            </label>
          </div>
        </div>
    </React.Fragment>
  )
}

export default PaymentMethod
