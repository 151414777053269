import React, {Fragment, useContext, useEffect, useState} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import { ORDER_LIST_FLASHINGS } from '../../../../common/constant'

function NewFlashingItem(props: any) {
  const {flashingItemList} = props
  const {addToCart, flashingProducts, cartItems, isFlashingColourColumnNull, flashingCheckColumnNullAsync} = useContext(QuickOrderContext)
  const maxQuantity = 1000000

  // const [isBALSelected, setIsBALSelected] = useState<boolean>(false)
  /**
   * FLASHING
   */
  const [flashingId, setFlashingId] = useState('Select Flashing Type')
  const [flashing, setFlashing] = useState<any>({})

  const handleFlashingOnChange = (e: any) => {
    let flashingId = e.target.value
    setFlashingId(flashingId)
    setFlashing(flashingProducts.find((item: any) => item?.flashingTypeId === +flashingId))
    setThickness(0)
    setLength('')
    setColor(0)
    setSubType(0)
    setUnitPrice(0)
    setQuantity(0)
  }

  useEffect(() => {
    if (flashingId !== 'Select Flashing Type') {
      flashingCheckColumnNullAsync(flashingId)
    }
  }, [flashingId]); 

  const getFlashingSetup = (
    paramSubType: number = 0,
    paramThickness: number = 0,
    paramColour: number = 0,
    isForColour: boolean = false
  ) => {
    // dependency
    // const availPricings = flashingProducts.filter(
    //   (x: any) =>
    //     x.flashingTypeId == flashingId &&
    //     (paramSubType == 0 || x.flashingSubTypeId == paramSubType) &&
    //     (paramThickness == 0 || x.flashingThicknessId == paramThickness) &&
    //     (paramColour == 0 || x.colourId == paramColour)
    // )

    if (isForColour) {
      const flashingIdNum = Number(flashingId)

      const availPricings = flashingProducts.filter((x: any) =>
        x.flashingTypeId === flashingIdNum
      )
    
      return availPricings
    }

    return flashingProducts
  }

  const getFlashingSetupPriceBook = (
    paramSubType: number = 0,
    paramThickness: number = 0,
    paramColour: number = 0
  ) => {
    // dependency
    const availPricings = flashingProducts.filter(
      (x: any) =>
        x.flashingTypeId == +flashingId &&
        (paramSubType == 0 || x.flashingSubTypeId == paramSubType) &&
        (paramThickness == 0 || x.flashingThicknessId == paramThickness) &&
        (paramColour == 0 || x.colourId == paramColour)
    )
    return availPricings
  }

  /**
   * SUB TYPE
   */
  useEffect(() => {
    if (flashingProducts) {
      const subType = getFlashingSetup()
      const subTypeSelection: any = Object.values(
        subType.reduce((map: any, obj: any) => {
          const key = `${obj.flashingSubTypeId}-${obj.flashingSubTypeName}`
          if (!map[key]) {
            map[key] = obj
          }
          return map
        }, {} as Record<string, any>)
      )

      thicknessOptionSetup()
      setColourSelectionOption()

      // default selection for subtype
      const existsData = subTypeSelection.filter((item1: any) => {
        const panelItems = cartItems.filter((item: any) => item.type === 'panel')
        
        if (panelItems.length > 0) {
          const lastAddedPanelItem = panelItems[0]
      
          return lastAddedPanelItem?.panelProfileName
            .toLowerCase()
            .includes(item1?.flashingSubTypeName?.toLowerCase().replace('delta', ''))
        }
  
        return false
      })

      if (existsData) {
        if (existsData && existsData.length === 1) {
          const defaultPanel = existsData[0]
          setSubType(defaultPanel.flashingSubTypeId)
          setSubTypeName(defaultPanel.flashingSubTypeName)
          // dependency
          // thicknessOptionSetup(defaultPanel.flashingSubTypeId)
          // setColourSelectionOption(defaultPanel.flashingSubTypeId)

          // default selection for colour
          // const existsDataColour = colour.filter((item1: any) =>
          //   cartItems.some(
          //     (x: any) =>
          //       x.type === 'panel' &&
          //       x.panelRoofColourName.toLowerCase().includes((item1.name || '').toLowerCase())
          //   )
          // )
          // if (existsDataColour) {
          //   if (existsDataColour && existsDataColour.length === 1) {
          //     const defaultColour = existsDataColour[0]
          //     setColor(+defaultColour.id)
          //     setColorName(defaultColour.name)
          //   } else {
          //     setColor(0)
          //   }
          // } else {
          //   setColor(0)
          // }
        } else {
          setSubType(0)
        }
      } else {
        setSubType(0)
      }

      setSubTypeOption(
        (subTypeSelection || [])?.map((obj: any) => ({
          id: obj.flashingSubTypeId,
          name: obj.flashingSubTypeName,
        }))
      )
    }
  }, [flashingProducts, flashingId])

  const [subType, setSubType] = useState<number>(0)
  const [subTypeName, setSubTypeName] = useState<string>('')
  const [subTypeOption, setSubTypeOption] = useState<any[]>([])

  const handleSubTypeOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    if (selectedIndex > 0) {
      const name = selectedIndex == 0 ? '' : subTypeOption[selectedIndex - 1].name
      setSubType(+e.target.value)
      setSubTypeName(name)
      // dependency
      // thicknessOptionSetup(+e.target.value)
    } else {
      setThicknessOption([])
      setColourOption([])
    }
    setThickness(0)
  }

  const thicknessOptionSetup = (paramSubType?: number) => {
    const thickNessSetup = getFlashingSetup(paramSubType)
    const distinctThickness: any = Object.values(
      thickNessSetup.reduce((map: any, obj: any) => {
        const key = `${obj.flashingThicknessId}-${obj.flashingThicknessName}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )

    const sortedThickness = distinctThickness.sort((a: any, b: any) => {
      // Extract the numeric part of the strings and convert them to numbers
      const numericA = parseFloat(a.flashingThicknessName.replace('mm', ''))
      const numericB = parseFloat(b.flashingThicknessName.replace('mm', ''))

      // Compare the numeric values
      return numericA - numericB
    })

    const newData = (sortedThickness || []).filter((obj: any) => obj.flashingThicknessName !== "0mm").map((obj: any) => ({
      id: obj.flashingThicknessId,
      name: obj.flashingThicknessName,
    }))    

    // default selection for subtype
    const existsData = newData.filter((item1: any) => {
      const panelItems = cartItems.filter((item: any) => item.type === 'panel')
      
      if (panelItems.length > 0) {
        const lastAddedPanelItem = panelItems[0]
    
        return lastAddedPanelItem?.panelThicknessName
          .toLowerCase() === item1.name.toLowerCase()
      }

      return false
    })

    if (existsData) {
      if (existsData && existsData.length === 1) {
        const defaultPanel = existsData[0]
        setThickness(defaultPanel.id)
        setThicknessName(defaultPanel.name)
        // dependency
        // setColourSelectionOption(paramSubType, +defaultPanel.id)
      } else {
        setThickness(0)
      }
    } else {
      setThickness(0)
    }

    setThicknessOption(newData)
    setColourOption([])
  }

  /**
   * THICKNESS
   */
  const [thickness, setThickness] = useState<number>(0)
  const [thicknessName, setThicknessName] = useState<number>(0)
  const [thicknessOption, setThicknessOption] = useState<any[]>([])
  const handleThicknessOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : thicknessOption[selectedIndex - 1].name
    setThickness(+e.target.value)
    setThicknessName(name)
    // dependency
    // setColourSelectionOption(subType, +e.target.value)
  }
  /**
   * COLOR
   */
  // useEffect(() => {
  //   if (colours) {
  //     setColourOption(colours)
  //   }
  // }, [colours])

  const [color, setColor] = useState<number>(0)
  const [colorName, setColorName] = useState<string>('')
  const [colourOption, setColourOption] = useState<any[]>([])
  const handleColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
    setColor(+e.target.value)
    setColorName(name)
  }

  const setColourSelectionOption = (paramSubType?: number, thicknessId?: number) => {
    const colourSetup = getFlashingSetup(paramSubType, thicknessId, 0, true)
    const distinctColour: any = Object.values(
      colourSetup.reduce((map: any, obj: any) => {
        const key = `${obj.colourId}-${obj.colourName}`
        if (!map[key]) {
          map[key] = obj
        }
        return map
      }, {} as Record<string, any>)
    )

    const newData = (distinctColour || [])?.map((obj: any) => ({
      id: obj.colourId,
      name: obj.colourName,
    }))

    setColourOption(newData)

    const existsDataColour = newData.filter((item1: any) => {
      const panelItems = cartItems.filter((item: any) => item.type === 'panel')
      
      if (panelItems.length > 0) {
        const lastAddedPanelItem = panelItems[0]
    
        return lastAddedPanelItem?.topSheetColourName
          .toLowerCase()
          .includes((item1.name || '').toLowerCase())
      }

      return false
    })
    
    if (existsDataColour) {
      if (existsDataColour && existsDataColour.length === 1) {
        const defaultColour = existsDataColour[0]
        setColor(defaultColour.id)
        setColorName(defaultColour.name)
      }
    }

    return newData
  }
  /**
   * LENGTH
   */
  const [length, setLength] = useState<any>()
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [errorLength, setErrorLength] = useState<boolean>(false)
  const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
  const [pricingId, setPricingId] = useState<number>(0)
  /**
   * UNIT PRICE
   */
  const [unitPrice, setUnitPrice] = useState<number>(0)
  useEffect(() => {
    if (isFlashingColourColumnNull) {
      setColorName('')
      if (subType > 0 && thickness > 0) {
        const pricingResult = getFlashingSetupPriceBook(subType, thickness, color)
        if (pricingResult.length > 0) {
          setUnitPrice(pricingResult[0].priceLevel1)
          setPricingId(pricingResult[0].id)
          setUnitTypeDisplay('per metre')
          setErrorMsg('')
          setError(false)
        } else {
          setUnitPrice(0)
          setPricingId(0)
          setError(true)
          setErrorMsg('Pricing for this combination is currently not available.')
        }
      } else {
        setUnitPrice(0)
        setPricingId(0)
      }
    }
    else {
      if (subType > 0 && thickness > 0 && color > 0) {
        const pricingResult = getFlashingSetupPriceBook(subType, thickness, color)
        if (pricingResult.length > 0) {
          setUnitPrice(pricingResult[0].priceLevel1)
          setPricingId(pricingResult[0].id)
          setUnitTypeDisplay('per metre')
          setErrorMsg('')
          setError(false)
        } else {
          setUnitPrice(0)
          setPricingId(0)
          setError(true)
          setErrorMsg('Pricing for this combination is currently not available.')
        }
      } else {
        setUnitPrice(0)
        setPricingId(0)
      }
    }
  }, [subType, thickness, color])

  const handleLengthFocusChange = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue === 0 || inputValue < 0) {
      setErrorLengthMsg('Length must not be less than 100mm.')
      setErrorLength(true)
      return
    }
    if (inputValue < 100) {
      setErrorLengthMsg('Length must not be less than 100mm.')
      setErrorLength(true)
    } else if (inputValue > 8000) {
      setErrorLengthMsg(
        'Length must not be greater than 8000mm. If the length is more than 8000mm, the flashings will be divided into several pieces.'
      )
      setErrorLength(true)
      if (inputValue > 23000) {
        return
      }
    } else {
      setErrorLengthMsg('')
      setErrorLength(false)
    }
    setLength(inputValue)
  }

  const handleLengthBlur = (e: any) => {
    setErrorLengthMsg('')
    setErrorLength(false)
  }

  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<any>()
  const handleQuantityChange = (e: any) => {
    const inputNumber = parseInt(e.target.value, 10)
    if (inputNumber === 0 || inputNumber > maxQuantity || inputNumber < 0) {
      return
    }
    setQuantity(inputNumber)
  }

  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])

    const disabled = isFlashingColourColumnNull
    ? (
        subType === 0 ||
        thickness === 0 ||
        error ||
        isNaN(length) ||
        length === 0 ||
        length < 100 ||
        isNaN(quantity) ||
        quantity === 0 ||
        quantity > maxQuantity ||
        quantity < 0 ||
        flashingId === ''
      )
    : (
        subType === 0 ||
        thickness === 0 ||
        color == 0 ||
        error ||
        isNaN(length) ||
        length === 0 ||
        length < 100 ||
        isNaN(quantity) ||
        quantity === 0 ||
        quantity > maxQuantity ||
        quantity < 0 ||
        flashingId === ''
      )

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        {/* FLASHING NAME */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Flashing Type</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={flashingId}
            onChange={handleFlashingOnChange}
          >
            <option value=''>Select Flashing Type</option>
            {flashingItemList?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' />
        <div className='col-md-3' />
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 0,
                marginRight: 24,
              }}
            >
              {moneyFormatter.format(flashingId !== '' ? unitPrice : 0)}
            </p>
            <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
              {unitPrice ? unitTypeDisplay : ''}
            </p>
          </div>
          <button
            disabled={disabled}
            className='btn btn-primary w-100'
            onClick={() => {
              addToCart({
                ...flashing,
                pricingId,
                subType,
                subTypeName,
                thickness,
                thicknessName,
                unitPrice,
                color,
                colorName,
                length,
                quantity,
                customId: Math.random(),
                type: 'flashing',
                uniqueID: `flashing-${subType}-${thickness}-${color}-${length}-${quantity}-${Math.random().toString(36).substring(2, 15)}`,
                price: unitPrice,
                productType: ORDER_LIST_FLASHINGS,
                productName: flashing?.flashingTypeName,
                description:  `${subTypeName}, ${thicknessName}, ${colorName}`,
              })
              // SET DEFAULT
              setSubType(0)
              setThickness(0)
              setColor(0)
              setLength('')
              setQuantity(0)
              setFlashingId('Select Flashing Type')
            }}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>
      {/* Panel TYPE */}
      <div className='row  mb-4'>
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='sub-type'>
            Panel Type
          </label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={subType}
            onChange={handleSubTypeOnChange}
            id='sub-type'
            disabled={flashingId === 'Select Flashing Type' || flashingId === ''}
          >
            <option value={0}>Select Panel Type</option>
            {subTypeOption?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {/* THICKNESS */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='thickness'>
            Thickness
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='thickness'
            className='form-select  my-4'
            aria-label='Thickness'
            value={thickness}
            onChange={handleThicknessOnChange}
            disabled={flashingId === 'Select Flashing Type' || flashingId === ''}
          >
            <option value={0}>Select</option>
            {thicknessOption?.map((option: any, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {/* LENGTH */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='length'>
            Length (mm)
          </label>
          <input
            style={{
              fontSize: '1rem',
              borderColor: length < 100 && length !== '' ? 'red' : '',
            }}
            min='100'
            step='1'
            max='23000'
            type='number'
            className='form-control  my-4'
            id='length'
            aria-describedby='length'
            value={length || ''}
            onFocus={handleLengthFocusChange}
            onBlur={handleLengthBlur}
            onChange={handleLengthFocusChange}
            placeholder='Enter'
            disabled={flashingId === 'Select Flashing Type' || flashingId === ''}
          />
          {errorLength ? (
            <div className='text-danger m-auto text-left' role='alert'>
              {errorLengthMsg}
            </div>
          ) : null}
        </div>
        <div className='col-md-3'></div>
      </div>
      <div className='row'>
        {!isFlashingColourColumnNull && (
          <>
            {/* COLOUR */}
            <div className='col-md-3'>
              <label className='fw-bolder text-dark' htmlFor='color'>
                Colour
              </label>
              <select
                style={{fontSize: '1rem'}}
                id='color'
                className='form-select  my-4'
                aria-label='Colour'
                value={color}
                onChange={handleColorOnChange}
                disabled={flashingId === 'Select Flashing Type' || flashingId === ''}
              >
                <option value={0}>Select</option>
                {colourOption?.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
        {/* QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            className='form-control  my-4'
            placeholder='Enter'
            min='1'
            max={maxQuantity}
            value={quantity || ''}
            onChange={handleQuantityChange}
            disabled={flashingId === 'Select Flashing Type' || flashingId === ''}
          />
        </div>
        <div className='col-md-3'></div>
      </div>
    </Fragment>
  )
}

export default NewFlashingItem
