import React, {useContext, useEffect, useState} from 'react'
import {QuickOrderContext} from '../../../../context/QuickOrderContext' 
import NewAccessoriesItem from './AccessoriesItem/NewAccessoriesItem'

const Accessories = () => {
  const {accessoryProductMasters, addToCart} = useContext(QuickOrderContext)

  let accessoryCategory = ['Accessories']
  
  const getAccessoryStylePM = (category: string) => {
    const availStyle: any = accessoryProductMasters.filter((x: any) => x.accessoryPartName.toUpperCase().includes(category.toUpperCase()))

    return availStyle || []
  }

  const getAccessoryStyleAccessories = (category: string) => {
    const availStyle = accessoryProductMasters.filter((x: any) => x.accessoryPartName.toUpperCase() === category.toUpperCase())
    if (availStyle && availStyle.length > 0) {
      const accessorySelection: any = Object.values(
        availStyle.reduce((map: any, obj: any) => {
          const key = `${obj.accessoryStyleId}-${obj.accessoryStyleName}`
          if (!map[key]) {
            map[key] = obj
          }
          return map
        }, {} as Record<string, any>)
      )

      const result = (accessorySelection || [])?.map((availPricing: any) => ({
        id: availPricing.accessoryStyleId,
        name: availPricing.accessoryStyleName,
      }))

       // Avoid duplicate Fixings
       const distinctData: any = Object.values(
        result.reduce((map: any, obj: any) => {
          const key = `${obj.id}-${obj.name}`
          if (!map[key]) {
              map[key] = obj
          }
          return map
          }, {} as Record<string, any>)
      )

      return distinctData || []
    } else return []
  }

  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [selectedColourId, setSelectedColourId] = useState<number>(0)


  return (
    <div className='d-flex flex-column justify-content-between' style={{overflow: 'hidden'}}>
      <div style={{overflowY: 'scroll', overflowX: 'hidden', height: '95vh'}}>
        {accessoryProductMasters && accessoryCategory.map((waterCategory: string) => (
          <React.Fragment key={waterCategory}>
            <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
              {waterCategory}
            </h3>
              <div className='my-4'>
                <NewAccessoriesItem
                  setError={setError}
                  setErrorMsg={setErrorMsg}
                  accessories={getAccessoryStyleAccessories(waterCategory)}
                  pricings={getAccessoryStylePM(waterCategory)}
                  selectedColourId={selectedColourId}
                />
              </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Accessories
