import React, { useState, useContext, useEffect } from 'react'
import PostKitItem from './PostKitItem'
import PostBracketItem from './PostBracketItem'
import PostItem from './PostItem'
import NewPostItem from './NewPostItem'
import NewPostBracketItem from './NewPostBracketItem'
import NewPostKitItem from './NewPostKitItem'
import { QuickOrderContext } from '../../../../context/QuickOrderContext'
import { IPostPricing } from '../../../../models/posts/postPricing'
import { IPostProduct } from '../../../../models/posts/postProduct'
import { IPostCategory } from '../../../../models/posts/postCategory'
import { IPostType } from '../../../../models/posts/postType'
import { IPostFinish } from '../../../../models/posts/postFinish'
import NewExtendaBracketItem from './ExtendaBracketItem/NewExtendaBracketItem'
import { 
  ORDER_LIST_POSTS,
  ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE
 } from '../../../../common/constant'

const Posts = () => {
    const { postProducts, postPricings, postCategories, addToCart,
        postExtendaBracketProductMastersId, postExtendaBracketUnitPrice,
        postProductId, postKitProductId } = useContext(QuickOrderContext)

    const [option, setOption] = useState('posts')
    const [optionId, setOptionId] = useState(postProductId)
    const handleOptionOnChange = (e: any) => {
        if (e.target.value === 'post-kits'){
            setOptionId(postKitProductId)
        }
        else{
            setOptionId(postProductId)
        }

        setOption(e.target.value)
    }

    function getPostProductsByCategory(pricings: IPostPricing[] = [], category: string): IPostProduct[] {
        if (!pricings || !pricings.length) {
            return [];
        }

        return pricings
            .filter(p => p.postCategoryName === category)
            .map(p => ({ id: p.postProductId, name: p.postProductName }))
            .filter((value, index, self) =>
                self.findIndex(item => item.id === value.id && item.name === value.name) === index
            )
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    function getPostTypesByProduct(pricings: IPostPricing[], product: string): IPostType[] {
        return pricings
            .filter((p) => p.postProductName === product)
            .map((p) => ({ id: p.postTypeId, name: p.postTypeName }))
            .filter(
                (value, index, self) =>
                    self.findIndex((item) => item.id === value.id && item.name === value.name) === index
            )
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    function getPostFinishesByProduct(pricings: IPostPricing[], product: string): IPostFinish[] {
        return pricings
            .filter((p) => p.postProductName === product)
            .map((p) => ({ id: p.postFinishId, name: p.postFinishName }))
            .filter(
                (value, index, self) =>
                    self.findIndex((item) => item.id === value.id && item.name === value.name) === index
            )
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    useEffect(() => {
        setIsExtendaBracketChecked(false)
    }, [option])

    const [isExtendaBracketChecked, setIsExtendaBracketChecked] = useState(false)

    const [extendaBracketPostProduct, setExtendaBracketPostProduct] = useState('')
    const [extendaBracketProductName, setExtendaBracketProductName] = useState('')
    
    const [extenderLength, setExtenderLength] = useState('')
    const [extenderLengthName, setExtenderLengthName] = useState('')
    const [isExtenderLengthsHidden, setExtenderLengthsHidden] = useState<boolean>(false)

    const [extendaBracketColor, setExtendaBracketColor] = useState('Select Colour')
    const [extendaBracketColorName, setExtendaBracketColorName] = useState()

    const [extendaBracketQuantity, setExtendaBracketQuantity] = useState<any>()
    const [extendaBracketUnitPrice, setExtendaBracketUnitPrice] = useState<number>(0)

    const disabledExtendaBracket =
        isNaN(extendaBracketQuantity) ||
        extendaBracketQuantity === 0 ||
        extendaBracketQuantity > 99 ||
        extendaBracketQuantity < 0 ||
        extendaBracketUnitPrice === 0 ||
        (!isExtenderLengthsHidden && extenderLength === '') ||
        extendaBracketQuantity === ''

    const {beams, beamTypes} = useContext(QuickOrderContext)
    const [error, setError] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>('')

    const addNewExtendaItem = () => {
        if (isExtendaBracketChecked) {
            addToCart({
                postProductMastersId: postExtendaBracketProductMastersId,
                productName: extendaBracketProductName,
                length: 0,
                lengthName: '',
                unitPrice: postExtendaBracketUnitPrice,
                colorId: parseInt(extendaBracketColor),
                colorName: extendaBracketColorName,
                extenderLength,
                extenderLengthName,
                quantity: extendaBracketQuantity,
                customId: Math.random(),
                type: ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE,
                uniqueID: `${ORDER_TAB_POSTS_EXTENDA_BRACKET_TYPE}-${postExtendaBracketUnitPrice}-${extendaBracketColor}-${extendaBracketProductName}-${extendaBracketQuantity}-${Math.random().toString(36).substring(2, 15)}`,
                price: postExtendaBracketUnitPrice,
                productType: ORDER_LIST_POSTS,
                description: `${extendaBracketProductName}, ${extendaBracketColorName}, ${extenderLengthName}`,
            })
            // SET DEFAULT
            setIsExtendaBracketChecked(false)
        }
    }

    return (
        <div className='d-flex flex-column justify-content-between' style={{ overflow: 'hidden' }}>
            <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: '95vh' }}>
                <h3 style={{ backgroundColor: '#e5e5e5', borderRadius: '0.475rem' }} className='p-2'>
                    Posts
                </h3>
                {error ? (
                    <div className='alert alert-danger w-25 m-auto text-center' role='alert'>
                    {errorMsg}
                    </div>
                ) : null}
                <div className='my-4'>
                    <div className='row mt-3'>
                        <div className='col-md-3 d-flex'>
                            <div className='form-check me-10'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='option'
                                    id='posts'
                                    value={'posts'}
                                    onChange={handleOptionOnChange}
                                    checked={option === 'posts'}
                                />
                                <label className='form-check-label fw-bolder' htmlFor='posts'>
                                    Posts
                                </label>
                            </div>

                            <div className='form-check'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='option'
                                    id='post-kits'
                                    value={'post-kits'}
                                    onChange={handleOptionOnChange}
                                    checked={option === 'post-kits'}
                                />
                                <label className='form-check-label fw-bolder' htmlFor='post-kits'>
                                    Post Kits
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* {getPostProductsByCategory(postPricings, 'Post')?.map((post: any) => {
            if (post.name.includes('Post Brackets')) {
              return (
                <PostBracketItem
                  postBracket={post}
                  key={post.id}
                  name={post.name}
                  types={getPostTypesByProduct(postPricings, post.name)}
                  finishes={getPostFinishesByProduct(postPricings, post.name)}
                />
              )
            } else {
              return (
                <PostItem
                  postItem={post}
                  key={post.id}
                  name={post.name}
                  types={getPostTypesByProduct(postPricings, post.name)}
                  finishes={getPostFinishesByProduct(postPricings, post.name)}
                />
              )
            }
          })} */}

                    {option == 'posts' ? (
                        <>
                            <NewPostItem 
                                posts={getPostProductsByCategory(postPricings, 'Post')} 
                                error={error} 
                                setError={setError} 
                                setErrorMsg={setErrorMsg} 
                                isExtendaBracketChecked={isExtendaBracketChecked}
                                extendaBracketUnitPrice={extendaBracketUnitPrice}
                                disabledExtendaBracket={disabledExtendaBracket}
                                addNewExtendaItem={addNewExtendaItem}
                            />
                        </>
                    ) : (
                        <NewPostKitItem 
                            posts={getPostProductsByCategory(postPricings, 'Post Kit')} 
                            error={error} 
                            setError={setError} 
                            setErrorMsg={setErrorMsg} 
                            isExtendaBracketChecked={isExtendaBracketChecked}
                            extendaBracketUnitPrice={extendaBracketUnitPrice}
                            disabledExtendaBracket={disabledExtendaBracket}
                            addNewExtendaItem={addNewExtendaItem}
                        />
                    )}
                    <NewExtendaBracketItem 
                        posts={getPostProductsByCategory(postPricings, 'Post')} 
                        error={error} 
                        setError={setError} 
                        setErrorMsg={setErrorMsg}
                        isExtendaBracketChecked={isExtendaBracketChecked}
                        setIsExtendaBracketChecked={setIsExtendaBracketChecked}
                        extendaBracketPostProduct={extendaBracketPostProduct}
                        setExtendaBracketPostProduct={setExtendaBracketPostProduct} 
                        extendaBracketProductName={extendaBracketProductName} 
                        setExtendaBracketProductName={setExtendaBracketProductName}
                        extendaBracketColor={extendaBracketColor}
                        setExtendaBracketColor={setExtendaBracketColor} 
                        extendaBracketColorName={extendaBracketColorName} 
                        setExtendaBracketColorName={setExtendaBracketColorName}
                        extendaBracketQuantity={extendaBracketQuantity}
                        setExtendaBracketQuantity={setExtendaBracketQuantity} 
                        extendaBracketUnitPrice={extendaBracketUnitPrice} 
                        setExtendaBracketUnitPrice={setExtendaBracketUnitPrice}
                        extenderLength={extenderLength}
                        setExtenderLength={setExtenderLength}
                        extenderLengthName={extenderLengthName}
                        setExtenderLengthName={setExtenderLengthName}
                        isExtenderLengthsHidden={isExtenderLengthsHidden}
                        setExtenderLengthsHidden={setExtenderLengthsHidden}
                    />
                </div>

                {/* <h3 style={{backgroundColor: '#e5e5e5', borderRadius: '0.475rem'}} className='p-2'>
          Post Kits
        </h3>
        <div className='my-4'>
          {getPostProductsByCategory(postPricings, "Post Kit")?.map((postKit: any) => (
            <PostKitItem
              postKit={postKit}
              key={postKit.id}
              postKitName={postKit.name}
              types={getPostTypesByProduct(postPricings, postKit.name)}
              finishes={getPostFinishesByProduct(postPricings, postKit.name)}
            />
          ))}
        </div> */}
            </div>
        </div>
    )
}

export default Posts
