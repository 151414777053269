import React, {Fragment, useState, useEffect, useContext} from 'react'
import './OrderSummary.css'
import CustomerInfo from './CustomerInfo'
import TotalPriceInfo from './TotalPriceInfo'
import PanelTable from './tables/PanelTable'
import FixingsTable from './tables/FixingsTable'
import FlashingsTable from './tables/FlashingsTable'
import BeamsTable from './tables/BeamsTable'
import PostsTable from './tables/PostsTable'
import RainwaterTable from './tables/RainwaterTable'
import LightsTable from './tables/LightsTable'
import {moneyFormatter} from '../../../../utils'
import MiscTable from './tables/MiscTable'
import {QuickOrderContext} from '../../../context/QuickOrderContext'
import {Link, useHistory} from 'react-router-dom'
import PaymentMethod from './PaymentMethod'
import {ICustomerOrder} from '../../../models/quick-order/customerOrder'
import axios from 'axios'
import {getPostcodeMatriceByPostcode} from '../../../api'
import AccessoriesTable from './tables/AccessoriesTable'
import { 
  ORDER_SUMMARY_SECTION_PANELS,
  ORDER_SUMMARY_SECTION_FIXINGS,
  ORDER_SUMMARY_SECTION_FLASHINGS,
  ORDER_SUMMARY_SECTION_RAINWATERGOODS,
  ORDER_SUMMARY_SECTION_BEAMS,
  ORDER_SUMMARY_SECTION_POSTS,
  ORDER_SUMMARY_SECTION_LIGHTS,
  ORDER_SUMMARY_SECTION_ACCESSORIES
} from '../../../common/constant'

function OrderSummaryContent() {
  const history = useHistory()
  const baseURL = process.env.REACT_APP_API_URL
  const {cartItemsWithGST, cartItems, getComputeSummaryTotal} = useContext(QuickOrderContext)

  if (cartItems.length === 0) {
    window.location.href = '/quick-order'
  }

  const [customer] = useState(JSON.parse(localStorage.getItem('customer-info') as any))
  const [summaryTotalPrice] = useState(JSON.parse(localStorage.getItem('summaryTotalPrice') as any) || {})

  const [shippingPreference] = useState(
    JSON.parse(localStorage.getItem('shipping-preference') as any)
  )
  const [selectedPaymentMethod] = useState(localStorage.getItem('paymentMethod') || '')
  const [totalPrice] = useState(JSON.parse(localStorage.getItem('totalPrice') as any))
  const [customerOrder, setCustomerOrder] = useState<ICustomerOrder>()
  const isDelivery = shippingPreference.shipping === 'delivery'
  const [isServiceable, setIsServiceable] = useState(false)

  const tenth = 0.1
  const totalGST = totalPrice * tenth

  useEffect(() => {
    if (isDelivery) {
      if (customer.postCode && customer.postCode.length === 4) {
        const fetchData = async () => {
          try {
            const postCodeMatrix: any = await getPostcodeMatriceByPostcode(customer.postCode)
            if (postCodeMatrix.data && postCodeMatrix.data.pricebookId !== null) {
              setIsServiceable(true)
            }
          } catch (ex) {}
        }

        // fetchData()
        setIsServiceable(true)
      }
    } else {
      setIsServiceable(true)
    }
  }, [customer.postCode])

  const saveCustomerOrder = async () => {
    try {
      const response = await axios.post(`${baseURL}/Customer/Order`, customerOrder)
      let orderNumber = parseInt(response.data)
      if (!isNaN(orderNumber) && orderNumber > 0) {
        localStorage.setItem('orderNumber', orderNumber.toString())
        localStorage.setItem('customerOrder', JSON.stringify(cartItemsWithGST))
        window.location.href = '/confirm-order'
      } else {
      }
    } catch (ex) {
      console.error('Error fetching data:', ex)
    }
  }

  useEffect(() => {
    const {
      productTotalFee,
      totalGST,
      miscFee,
      totalProductAmount,
      creditcardFee,
      creditcardmiscFee,
      totalAmount,
    } = getComputeSummaryTotal()

    const formatDeliveryFee = (fee: any) => {
      if (typeof fee === 'number') {
        return fee.toFixed(2)
      }

      return fee
    }

    const formatMiscFee = (fee: any) => {
      if (typeof fee === 'string') {
        return parseFloat(fee.replace("$", "").trim())
      } else if (typeof fee === 'number') {
        return fee
      }

      return 0
    }

    console.log('cartItems', cartItems)
    setCustomerOrder({
      customer: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        contactNumber: customer.contact,
        emailAddress: customer.email,
        address: customer.address1,
        address2: customer.address2,
        suburb: customer.suburb,
        state: customer.state,
        postcode: customer.postCode,
        country: customer.country,
      },
      orderSummary: {
        customerId: 0,
        deliveryPreference: shippingPreference.shipping,
        deliveryFee: formatDeliveryFee(shippingPreference.deliveryFee),
        productTotalFee: summaryTotalPrice ? 0 : productTotalFee,
        totalGST,
        miscFee: formatMiscFee(miscFee),
        totalProductAmount: totalProductAmount,
        creditcardFee,
        creditcardmiscFee,
        totalAmount,
        paymentMethod: selectedPaymentMethod,
        pricebookId: shippingPreference.pricebookId,
      },
      panelOrders: cartItems
        .filter((item: any) => item.type == 'panel')
        .map((item: any) => ({
          panelPricingId: item.pricingId,
          panelDirectionalLayId: parseInt(item.directionalLayId),
          panelCutbackId: parseInt(item.cutback),
          length: item.length,
          transactedUnitPrice: item.unitPrice,
          quantity: item.quantity,
          colourId: item.topSheetColor,
          type: item.type
        })),
      fixingOrders: cartItems
        .filter((item: any) => item.type == 'fixing')
        .map((item: any) => ({
          fixingPricingId: item.pricingId,
          quantity: item.quantity,
          transactedUnitPrice: item.unitPrice,
          type: item.type
        })),
      flashingOrders: cartItems
        .filter((item: any) => item.type == 'flashing')
        .map((item: any) => ({
          flashingPricingId: item.pricingId,
          length: item.length,
          quantity: item.quantity,
          colourId: item.color,
          transactedUnitPrice: item.unitPrice,
          type: item.type,
        })),
      rainwaterOrders: cartItems.map((item: any) => {
        const length = item.type === 'gutter' ?
          (item?.rainwaterPricing?.rainwaterPart?.name.toLowerCase().includes("stop end") || item?.rainwaterPricing?.rainwaterPart?.name.toLowerCase().includes("bracket") ? 0 : item.length) :
          item.type === 'stop-end-bracket' ? 0 :
          item.length

        const colourId = item.type === 'gutter' ? item.color : item.rainwaterPartName === 'Gutter Stop End' ? item.stopEndColour : undefined

        return {
          rainwaterPricingId: item.pricingId,
          length: length,
          unitQuantity: item.quantity,
          transactedUnitPrice: item.unitPrice,
          colourId: colourId,
          type: item.type,
        }
      }).filter((item: any) => ['gutter', 'stop-end-bracket'].includes(item.type)),
      beamOrders: cartItems
        .filter((item: any) => item.type == 'beam')
        .map((item: any) => ({
          beamPricingId: item.beamProductMastersId,
          colourId: item.colorId,
          length: item.length ? item.length : 0,
          quantity: item.quantity,
          transactedUnitPrice: item.unitPrice,
          type: item.type,
        })),
      postOrders: cartItems
        .filter((item: any) => item.type === 'post-item' || item.type === 'post-kit' || item.type === 'post-extendabracket')
        .map((item: any) => ({
          postPricingId: item.postProductMastersId,
          colourId: item.colorId,
          length: item.length,
          quantity: item.quantity,
          transactedUnitPrice: item.unitPrice,
          type: item.type,
        })),
      lightOrders: cartItems
        .filter((item: any) => item.type == 'lights')
        .map((item: any) => ({
          lightPricingId: item.pricingId,
          quantity: item.quantity,
          length: item.length,
          transactedUnitPrice: item.unitPrice,
          type: item.type,
        })),
        accessoryOrders: cartItems
        .filter((item: any) => item.type == 'accessory')
        .map((item: any) => ({
          accessoryPricingId: item.pricingId,
          unitQuantity: item.quantity,
          length: item.length,
          transactedUnitPrice: item.unitPrice,
          colourId: item.color,
          type: item.type,
        })),
    })
  }, [])

  const whole = 1.0

  // PANEL SUBTOTAL PRICE
  const panelSubTotalPrice = cartItemsWithGST
    ?.filter((item: any) => item.type === 'panel')
    ?.reduce((acc: any, item: any) => {
      return acc + (item.unitPrice * item.quantity * item.length) / 1000 + item.gst
    }, 0)

  // FIXING SUBTOTAL PRICE
  const fixingSubTotalPrice = cartItemsWithGST
    ?.filter((item: any) => item.type === 'fixing')
    ?.reduce((acc: any, item: any) => {
      return (
        acc +
        (item?.fixingName === 'Tek Screws' || item?.fixingName === 'Rivets'
          ? (item.price * item.quantity) / 100
          : item.price * item.quantity) +
        item.gst
      )
    }, 0)

  // FLASHING SUBTOTAL PRICE
  const flashingSubTotalPrice = cartItemsWithGST
    ?.filter((item: any) => item.type === 'flashing')
    ?.reduce((acc: any, item: any) => {
      return acc + (item.unitPrice * item.quantity * item.length) / 1000 + item.gst
    }, 0)

  // BEAM SUBTOTAL PRICE
  const beamSubTotalPrice = cartItemsWithGST
    ?.filter((item: any) => item.type === 'beam')
    ?.reduce((acc: any, item: any) => {
      return acc + (item.unitPrice * item.quantity * item.length) / 1000 + item.gst
    }, 0)

  // POST SUBTOTAL PRICE
  const postSubTotalPrice = cartItemsWithGST
    ?.filter(
      (item: any) =>
        item.type === 'post-item' || item.type === 'post-extendabracket' || item.type === 'post-kit'
    )
    ?.reduce((acc: any, item: any) => {
      let computedPrice
      switch (item.type) {
        case 'post-kit':
          computedPrice = item.unitPrice * item.quantity + item.gst
          break
        case 'post-extendabracket':
          computedPrice = item.unitPrice * item.quantity + item.gst
          break
        case 'post-item':
          computedPrice = item.unitPrice * item.quantity + item.gst
          break
      }
      return acc + computedPrice
    }, 0)

  // RAINWATER SUBTOTAL PRICE
  const rainwaterSubTotalPrice = cartItemsWithGST
    ?.filter(
      (item: any) => item.type === 'gutter' || item.type === 'stop-end-bracket')
    ?.reduce((acc: any, item: any) => {
      let computedPrice = 0.0
      switch (item.type.toLowerCase()) {
        case 'gutter':
          computedPrice = (item.price * item.quantity * item.length) / 1000 + item.gst
          break
        case 'stop-end-bracket':
          computedPrice = item.price * item.quantity + item.gst
          break
      }
      return acc + computedPrice
    }, 0)

  // LIGHTS SUBTOTAL PRICE
  const lightsSubTotalPrice = cartItemsWithGST
    ?.filter((item: any) => item.type === 'lights')
    ?.reduce((acc: any, item: any) => {
      let computedPrice = item.unitPrice * (item.quantity === 0 ? 1 : item.quantity) + item.gst
      return acc + computedPrice
    }, 0)

  // ACESSORY SUBTOTAL PRICE
  const accessorySubTotalPrice = cartItemsWithGST
  ?.filter((item: any) => item.type === 'accessory')
  ?.reduce((acc: any, item: any) => {
    let computedPrice = 0.0
    if (item.lengthFactor > 1) {
      computedPrice = (item.unitPrice * item.length * whole) / item.lengthFactor + item.gst
    } else {
      computedPrice = item.unitPrice * item.quantity + item.gst
    }
    return acc + computedPrice
  }, 0)

  return (
    <>
      {cartItems.length === 0 ? (
        (window.location.href = '/quick-order')
      ) : (
        <Fragment>
          <div style={{backgroundColor: 'rgb(204, 204, 204)'}} className='p-4 mb-5'>
            <h3 className='mb-0'>ORDER CHECKOUT</h3>
          </div>
          <div className='container'>
            <div className='row mb-4'>
              <div className='d-flex'>
                <div className='p-2 flex-grow-1'>
                  <h3>ORDER SUMMARY</h3>
                </div>
                <Link to='/checkout' className='btn btn-secondary me-2'>
                  Back
                </Link>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    saveCustomerOrder()
                  }}
                  disabled={isServiceable !== true}
                >
                  Confirm Order
                </button>
              </div>
            </div>
            {/* SUMMARY */}
            <div className='row mb-4'>
              <CustomerInfo customer={customer} />
              <div className='col-md-2' />
              <TotalPriceInfo shippingPreference={shippingPreference} cartItems={cartItems} />
            </div>
            {/* PAYMENT METOD */}
            <div className='row mb-4' style={{ display: 'none' }}>
              <PaymentMethod />
            </div>
            {/* TABLES */}
            {panelSubTotalPrice > 0 && (
              <div className='row mb-4 d-flex flex-col'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_PANELS}
                </p>
                <PanelTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(panelSubTotalPrice)}
                </p>
              </div>
            )}
            {fixingSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_FIXINGS}
                </p>
                <FixingsTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(fixingSubTotalPrice)}
                </p>
              </div>
            )}
            {flashingSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_FLASHINGS}
                </p>
                <FlashingsTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(flashingSubTotalPrice)}
                </p>
              </div>
            )}
            {rainwaterSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_RAINWATERGOODS}
                </p>
                <RainwaterTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(rainwaterSubTotalPrice)}
                </p>
              </div>
            )}
            {beamSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_BEAMS}
                </p>
                <BeamsTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(beamSubTotalPrice)}
                </p>
              </div>
            )}
            {postSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_POSTS}
                </p>
                <PostsTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(postSubTotalPrice)}
                </p>
              </div>
            )}
            {accessorySubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_ACCESSORIES}
                </p>
                <AccessoriesTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(accessorySubTotalPrice)}
                </p>
              </div>
            )}
            {lightsSubTotalPrice > 0 && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  {ORDER_SUMMARY_SECTION_LIGHTS}
                </p>
                <LightsTable cartItems={cartItemsWithGST} />
                <p className='d-none fw-bolder text-dark text-end' style={{fontSize: '1.05rem'}}>
                  SUBTOTAL: {moneyFormatter.format(lightsSubTotalPrice)}
                </p>
              </div>
            )}
            {shippingPreference?.shipping === 'delivery' && (
              <div className='row mb-4'>
                <p className='fw-bolder text-dark' style={{fontSize: '1.05rem'}}>
                  Miscellaneous
                </p>
                <MiscTable shippingPreference={shippingPreference} />
              </div>
            )}
          </div>
        </Fragment>
      )}
    </>
  )
}

export default OrderSummaryContent
