import React, {FC} from 'react'
import {Switch, Route} from 'react-router-dom'
import {UserLayout} from '../../../_metronic/layout/UserLayout'
import ConfirmOrderContent from './ConfirmOrderContent'

type ConfirmOrderPage = {
  isAuthorized?: boolean
  isBanner?: boolean
}

const ConfirmOrderPage = (props: ConfirmOrderPage) => {
  const {isAuthorized} = props
  return (
    <UserLayout isAuthorized={isAuthorized} isBanner={false}>
      <Switch>
        <Route exact path='/confirm-order'>
          <ConfirmOrderContent />
        </Route>
      </Switch>
    </UserLayout>
  )
}

export default ConfirmOrderPage
