import React from 'react'
import { moneyFormatter } from '../../../../../utils'

function PostsTable(props: any) {
    const { cartItems } = props

    return (
        <div>
            <table className='table table-hover summary-table' style={{ backgroundColor: '#fff' }}>
                <thead className='thead-dark'>
                    <tr>
                        <th scope='col' style={{ width: '20%' }}>
                            Description
                        </th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'>
                            Colour
                        </th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'></th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Length
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Quantity
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Unit Price
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            GST
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems?.map((item: any) => {
                        if (
                            item.type === 'post-item' ||
                            item.type === 'post-extendabracket' ||
                            item.type === 'post-kit'
                        ) {
                            let computedPrice = 0.00
                            let totalAmount = 0.00

                            const roundToTwo = (num: number) => Math.round(num * 100) / 100
                            if (item.type === 'post-extendabracket') {
                                computedPrice = (item.unitPrice * item?.quantity)
                            }
                            else {
                                computedPrice = (item.unitPrice * (item.length / 1000) * item?.quantity)
                            }
                            
                            totalAmount = roundToTwo(computedPrice)  + roundToTwo(item.gst)
                            return (
                                <tr key={item?.uniqueID}>
                                    <td className='align-middle'>
                                        <p className='fw-bolder text-dark mb-0'>
                                            {item?.type === 'post-item' ? 'Post' : item?.type === 'post-kit' ? 'Post Kit' : 'Extenda Bracket'}
                                        </p>
                                        {item.type === 'post-bracket' && (
                                            <>
                                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        Bracket Type:
                                                    </p>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        {item?.bracketTypeName}
                                                    </p>
                                                </div>
                                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        Item Type:
                                                    </p>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        {item?.itemTypeName}
                                                    </p>
                                                </div>
                                                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        Item Type:
                                                    </p>
                                                    <p className='text-dark mb-0 text-capitalize'>
                                                        {item?.itemTypeName}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                            <p className='text-dark mb-0 text-capitalize'>
                                                Type:
                                            </p>
                                            <p className='text-dark mb-0 text-capitalize'>
                                                {item?.productName}
                                            </p>
                                        </div>
                                        { item.type !== 'post-extendabracket' ? (
                                            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    Beam Size:
                                                </p>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    {item?.lengthName ? item?.lengthName : ''}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    Extender Length:
                                                </p>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    {item?.extenderLengthName}
                                                </p>
                                            </div>
                                        )}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {item?.colorName ? item.colorName : '-'}
                                    </td>
                                    <td />
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {item?.length ? `${item.length}mm` : '-'}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>{item?.quantity}</td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(item?.unitPrice)}
                                    </td>
                                    {/* Assumption */}
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(item?.gst)}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(totalAmount)}
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default PostsTable
