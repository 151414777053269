import React from 'react'

const LoadingQuickOrderData: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <div className="loader"></div>
      <p>Quick Order</p>
      <style>
        {`
          .loader {
            border: 6px solid #f3f3f3;
            border-top: 6px solid #ecf902;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 2s linear infinite;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  )
}

export default LoadingQuickOrderData