import React, {FC} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {UserLayout} from '../../../_metronic/layout/UserLayout'
import {CheckoutContextProvider} from './context/CheckoutContext'
import {CheckoutContent} from './components/CheckoutContent'

type CheckoutPage = {
  isAuthorized?: boolean
  isBanner?: boolean
}

const CheckoutPage = (props: CheckoutPage) => {
  const {isAuthorized} = props
  return (
    <UserLayout isAuthorized={isAuthorized} isBanner={false}>
      <Switch>
        <CheckoutContextProvider>
          <Route exact path='/checkout'>
            <CheckoutContent />
          </Route>
        </CheckoutContextProvider>
      </Switch>
    </UserLayout>
  )
}

export default CheckoutPage
