import {SortDescriptor} from '@progress/kendo-data-query'
import axios from 'axios'
import { 
  PRODUCT_TYPE_ACCESSORY,
  PRODUCT_TYPE_BEAM,
  PRODUCT_TYPE_FIXING,
  PRODUCT_TYPE_FLASHING,
  PRODUCT_TYPE_LIGHT,
  PRODUCT_TYPE_PANEL,
  PRODUCT_TYPE_POST,
  PRODUCT_TYPE_RAINWATER 
} from '../common/constant'
export const PANEL_URL = `${process.env.REACT_APP_API_URL}/panels`
export const SHIPPING_PREF_URL = `${process.env.REACT_APP_API_URL}/shippingpreferences`
export const FIXING_URL = `${process.env.REACT_APP_API_URL}/fixings`
export const FIXING_PRICING_URL = `${process.env.REACT_APP_API_URL}/fixingpricings`
export const FLASHING_URL = `${process.env.REACT_APP_API_URL}/flashingpricings`
export const BEAM_URL = `${process.env.REACT_APP_API_URL}/beams`
export const BEAMTYPE_URL = `${process.env.REACT_APP_API_URL}/beamtypes`
export const BEAMFINISH_URL = `${process.env.REACT_APP_API_URL}/beamfinishes`
export const BEAMPRICING_URL = `${process.env.REACT_APP_API_URL}/beampricings`

export const POSTPRODUCT_URL = `${process.env.REACT_APP_API_URL}/postproducts`
export const POSTTYPE_URL = `${process.env.REACT_APP_API_URL}/posttypes`
export const POSTFINISH_URL = `${process.env.REACT_APP_API_URL}/postfinishes`
export const POSTCATEGORY_URL = `${process.env.REACT_APP_API_URL}/postcategories`
export const POSTPRICING_URL = `${process.env.REACT_APP_API_URL}/postpricings`

export const LIGHTPRODUCT_URL = `${process.env.REACT_APP_API_URL}/lightproducts`
export const LIGHTPRODUCTTYPE_URL = `${process.env.REACT_APP_API_URL}/lightproducttypes`
export const LIGHTPANELTYPE_URL = `${process.env.REACT_APP_API_URL}/lightpaneltypes`
export const LIGHTPRICING_URL = `${process.env.REACT_APP_API_URL}/lightpricings`

export const COLOURS_URL = `${process.env.REACT_APP_API_URL}/colours`
export const RAINWATER_CATEGORY_URL = `${process.env.REACT_APP_API_URL}/rainwatercategories`

export const BEAMPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/BeamMasterlist/getAllBeamProduct`
export const PANELPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PanelMasterlist/getAllPanelProduct`
export const FIXINGPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FixingMasterlist/getAllFixingProduct`
export const FLASHINGPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FlashingMasterlist/getAllFlashingProduct`
export const LIGHTPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/LightMasterlist/getAllLightProduct`
export const RAINWATERPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/RainwaterMasterlist/getAllRainwaterProduct`
export const POSTPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PostMasterlist/getAllPostProduct`

export const EXPORTPANELPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PanelMasterlist/getAllPanelProductsForExport`
export const EXPORTPOSTPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/PostMasterlist/getAllPostProductsForExport`
export const EXPORTBEAMPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/BeamMasterlist/getAllBeamProductsForExport`
export const EXPORTRAINWATERPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/RainwaterMasterlist/getAllRainwaterProductsForExport`
export const EXPORTLIGHTPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/LightMasterlist/getAllLightProductsForExport`
export const EXPORTFLASHINGPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FlashingMasterlist/getAllFlashingProductsForExport`
export const EXPORTFIXINGPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/FixingMasterlist/getAllFixingProductsForExport`
export const EXPORTACCESSORIESPRODUCTMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/AccessoryMasterlist/getAllAccessoryProductsForExport`

export const COLUMNNULLCHECKER_URL = `${process.env.REACT_APP_API_URL}/ColumnNullChecker`

export const COMMON_URL = `${process.env.REACT_APP_API_URL}/Common`

export const BASEURL_URL = `${process.env.REACT_APP_API_URL}`

export const getProductIdByName = async (productName: string, productType: string) => {
  try {
      const response = await axios.get(`${COMMON_URL}/getProductIdByNameQuery`, {
        params: {
          productName,
          productType
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const checkColumnNull = async (productId: number, productType: number) => {
  try {
      const response = await axios.get(`${COLUMNNULLCHECKER_URL}/checkcolumnnull`, {
        params: {
          productId,
          productType
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllBeamProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTBEAMPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllRainwaterProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTRAINWATERPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllAccessoryProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTACCESSORIESPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllLightProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTLIGHTPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllFlashingProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTFLASHINGPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllFixingProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTFIXINGPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllPostProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTPOSTPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllPanelProductForExport = async () => {
  try {
      const response = await axios.get(`${EXPORTPANELPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllBeamProduct = async () => {
  try {
      const response = await axios.get(`${BEAMPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllPanelProduct = async () => {
  try {
      const response = await axios.get(`${PANELPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllFixingProduct = async () => {
  try {
      const response = await axios.get(`${FIXINGPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllFlashingProduct = async () => {
  try {
      const response = await axios.get(`${FLASHINGPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllLightProduct = async () => {
  try {
      const response = await axios.get(`${LIGHTPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllRainwaterProduct = async () => {
  try {
      const response = await axios.get(`${RAINWATERPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getAllPostProduct = async () => {
  try {
      const response = await axios.get(`${POSTPRODUCTMASTERLIST_URL}`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

const getFullProductUpdateURL = (productType: string) => {
  switch (productType) {
    case PRODUCT_TYPE_PANEL:
      return BASEURL_URL + '/PanelMasterlist/getPanelProductByIdAndpricebookId'
    case PRODUCT_TYPE_FIXING:
      return BASEURL_URL + '/FixingMasterlist/getFixingProductByIdAndpricebookId'
    case PRODUCT_TYPE_FLASHING:
      return BASEURL_URL + '/FlashingMasterlist/getFlashingProductByIdAndpricebookId'
    case PRODUCT_TYPE_RAINWATER:
      return BASEURL_URL + '/RainwaterMasterlist/getRainwaterProductByIdAndpricebookId'
    case PRODUCT_TYPE_BEAM:
      return BASEURL_URL + '/BeamMasterlist/getBeamProductByIdAndpricebookId'
    case PRODUCT_TYPE_POST:
      return BASEURL_URL + '/PostMasterlist/getPostProductByIdAndpricebookId'
    case PRODUCT_TYPE_LIGHT:
      return BASEURL_URL + '/LightMasterlist/getLightProductByIdAndpricebookId'
    case PRODUCT_TYPE_ACCESSORY:
      return BASEURL_URL + '/AccessoryMasterlist/getAccessoryProductByIdAndpricebookId'
    default:
      return ''
  }
}

export const getProductByIdAndPricebookId = async (productId: any, productType: string, pricebookId?: any) => {
  try {
      let updateProductBaseUrl = getFullProductUpdateURL(productType)
      const response = await axios.get(`${updateProductBaseUrl}?id=${productId}&pricebookId=${pricebookId}`)
      console.log('data', response.data)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getProductById = async (productId: any, productType: string) => {
  try {
    let updateProductBaseUrl = getFullProductUpdateURL(productType)

    const response = await fetch(`${updateProductBaseUrl}/${productId}`);

    if (response.ok) {
      const panelData = await response.json();
      console.log('Panel retrieved successfully in mock data:', panelData);
      return panelData;
    } else {
      console.error('Failed to retrieve panel in mock data:', response.statusText);
      return null;
    }
  } catch (err) {
    console.error('Error retrieving panel in mock data:', err);
    return null;
  }
};

export const updatePanelInMockData = async (productId: number, payload: any, productType: string) => {
  try {    
    let updateProductBaseUrl = getFullProductUpdateURL(productType)

    const response = await fetch(`${updateProductBaseUrl}/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const updatedPanel = await response.json()
      console.log('Product updated successfully in mock data:', updatedPanel);
      return updatedPanel;
    } else {
      console.error('Failed to update product in mock data:', response.statusText);
      return null;
    }
  } catch (err) {
    console.error('Error updating product in mock data:', err);
    return null;
  }
}

export const getFixings = async (priceBookid?: number) => {
  try {
    return await axios.get(`${FIXING_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const getPanelSetup = async (priceBookid?: number) => {
  try {
    return await axios.get(`${PANEL_URL}/getpanelsetup`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return []
  }
}

export const getPanel = async () => {
  try {
    return await axios.get(`${PANEL_URL}/getpanelsetup`)
  } catch (err) {
    return []
  }
}
export const getPostcodeMatriceByPostcode = async (postCode: string) => {
  try {
    return await axios.get(`${SHIPPING_PREF_URL}/getpostcodematricebypostcode`, {
      params: {
        postCode,
      },
    })
  } catch (err) {
    return []
  }
}

export const getBeamTypes = async () => {
  try {
    return await axios.get(`${BEAMTYPE_URL}`)
  } catch (err) {
    return []
  }
}

export const getBeamFinishes = async () => {
  try {
    return await axios.get(`${BEAMFINISH_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostProducts = async () => {
  try {
    return await axios.get(`${POSTPRODUCT_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostTypes = async () => {
  try {
    return await axios.get(`${POSTTYPE_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostFinishes = async () => {
  try {
    return await axios.get(`${POSTFINISH_URL}`)
  } catch (err) {
    return []
  }
}

export const getPostCategories = async () => {
  try {
    return await axios.get(`${POSTCATEGORY_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightProducts = async () => {
  try {
    return await axios.get(`${LIGHTPRODUCT_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightProductTypes = async () => {
  try {
    return await axios.get(`${LIGHTPRODUCTTYPE_URL}`)
  } catch (err) {
    return []
  }
}

export const getLightPanelTypes = async () => {
  try {
    return await axios.get(`${LIGHTPANELTYPE_URL}`)
  } catch (err) {
    return []
  }
}

export const getColours = async () => {
  try {
    return await axios.get(`${COLOURS_URL}`)
  } catch (err) {
    return []
  }
}

export const getPriceBooks = async () => {
  try {
    return await axios.get(`${COLOURS_URL}`)
  } catch (err) {
    return []
  }
}
