import React, {useState, useEffect, useRef, useContext} from 'react'
import '../Products.css'
import BeamsProduct from './BeamsProduct'
import {
  getBeamFinishes,
  getBeamTypes,
  getBeamUnitTypes,
  getBeams,
  getBeamMasterlist,
  createBeamProduct,
  updateBeamProduct,
  deleteBeamProduct,
  getBeamSizes,
  getBeamProducts
} from '../../../../../api/beams-api'
import { getColours } from '../../../../../api/index'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {useAsyncFn, useEffectOnce} from 'react-use'
import {getUnitTypes} from '../../../../../api/unittype-api'
import AddProductButton from '../../../../../../_metronic/partials/content/button/AddProductButton'
import NewProductModal from '../../../../../../_metronic/partials/modals/NewProductModal'
import DeleteModal from '../../../../../../_metronic/partials/modals/DeleteModal'
import { useHistory } from 'react-router-dom'

type Props = {
  product: string
  productId: number
}

const Beams = (props: Props) => {
  const {productId} = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isSeqAdding, setIsSeqAdding] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const {
    beamFinishes,
    setBeamFinishes,
    beams,
    setBeams,
    beamTypes,
    setBeamTypes,
    setBeamUnitTypes,
    beamUnitTypes,
    colours,
    setColours,
    beamSizes,
    setBeamSizes,
    beamProducts,
    setBeamProducts
  } = useContext(AdminProductContext)

  /**
   * Pricebook Event
   */
  useEffect(() => {
    setSeqTotalPages(1)
    setSeqCurrentPage(1)
    // Load to page 1
    // fetchBeamProduct(1)
  }, [productId])

  /**
   * FETCH RECORD
   */
  useEffectOnce(() => {
    if (!fetchBeamTypes && !beamTypes) {
      getBeamTypesAsync()
    }
    if (!fetchBeamFinishes && !beamFinishes) {
      getBeamFinishesAsync()
    }
    if (!fetchBeams && !beams) {
      getBeamsAsync()
    }
    if (!fetchUnitTypes && !beamUnitTypes) {
      fetchUnitTypesAsync()
    }
    if (!fetchColours && !colours) {
      fetchColoursAsync()
    }
    if (!fetchBeamSizes && !beamSizes) {
      getBeamSizesAsync()
    }
    if (!fetchBeamProducts && !beamProducts) {
      getBeamProductsAsync()
    }
  })

  /**
   * FETCH RECORD
   */
  const [{loading: fetchBeamProducts}, getBeamProductsAsync] = useAsyncFn(async () => {
    const result: any = await getBeamProducts()
    if (result != null) {
      setBeamProducts(result.data)
    }
  }, [setBeamProducts])

  const [{loading: fetchBeamSizes}, getBeamSizesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamSizes()
    if (result != null) {
      setBeamSizes(result.data)
    }
  }, [setBeamSizes])

  const [{loading: fetchBeamTypes}, getBeamTypesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamTypes()
    if (result != null) {
      setBeamTypes(result.data)
    }
  }, [setBeamTypes])

  const [{loading: fetchBeamFinishes}, getBeamFinishesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamFinishes()
    if (result != null) {
      setBeamFinishes(result.data)
    }
  }, [setBeamFinishes])

  const [{loading: fetchBeams}, getBeamsAsync] = useAsyncFn(async () => {
    const result: any = await getBeams()
    if (result != null) {
      setBeams(result.data)
    }
  }, [setBeams])

  const [{loading: fetchUnitTypes}, fetchUnitTypesAsync] = useAsyncFn(async () => {
    const result: any = await getBeamUnitTypes()
    if (result != null) {
      setBeamUnitTypes(result.data)
    }
  }, [setBeamUnitTypes])

  const [{loading: fetchColours}, fetchColoursAsync] = useAsyncFn(async () => {
    const result: any = await getColours()
    if (result != null) {
      setColours(result.data)
    }
  }, [setColours])
  /**

  /**
   * REFS
   */
  const nameRef = useRef<any>()
  const typeRef = useRef<any>()
  const finishRef = useRef<any>()
  const priceRef = useRef<any>()
  const regex = /^[a-zA-Z0-9 ./()-]*$/
  const numRegex = /^[A-Za-z0-9\s.]*$/
  /**
   * SEQ
   */
  const [seqData, setSeqData] = useState<any>({})
  const [newProduct, setNewProduct] = useState({})
  const [seqCurrentPage, setSeqCurrentPage] = useState(1)
  const [seqTotalPages, setSeqTotalPages] = useState(1)
  const [seqDataPerPage] = useState(10)
  const [editingSeqId, setEditingSeqId] = useState(null)
  const [editedSeqData, setEditedSeqData] = useState({})

  const [Filter1, setFilter1] = useState('')
  const [Filter2, setFilter2] = useState('')
  const [Filter3, setFilter3] = useState('')

  const history = useHistory();

  const fetchBeamProduct = async (currentPage: number, filter1?: any, filter2?: any, filter3?: any) => {
    await getBeamMasterlist(currentPage, seqDataPerPage, filter1, filter2, filter3).then((response: any) => {
      setSeqData(response.data)
      setSeqTotalPages(Math.ceil(response.data?.totalCount / 10))
    })
  }

  /**
   * PAGINATION
   */
  const handleFilter = (filter1: any, filter2: any, filter3: any) => {
    setFilter1(filter1)
    setFilter2(filter2)
    setFilter3(filter3)
    setSeqCurrentPage(1)
  }

  // useEffect(() => {
  //   // fetchBeamsProduct(seqCurrentPage, Filter1, Filter2)
  //   setSeqCurrentPage(1)
  // }, [Filter1, Filter2, Filter3])

  const handlePrevPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage - 1)
  }
  const handleNextPage = () => {
    setSeqCurrentPage((prevPage: number) => prevPage + 1)
  }

  const handleCancelClick = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setIsSeqAdding(false)
  }
  const handleCancel = () => {
    setErrorMsg(undefined)
    setSuccessMsg(undefined)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  const handleChange = (e: any) => {
    const {name, value} = e.target
    if (parseInt(value) < 0 || parseFloat(value) < 0) {
      return
    }

    if (regex.test(value)) {
      setEditedSeqData((prevData: any) => ({...prevData, [name]: value}))
    }
  }
  /**
   * DELETE
   */
  const handleDeleteProduct = (id: any) => {
    setItemToDelete(id)
    setIsModalOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true)
      const result: any = await deleteBeamProduct(itemToDelete || 0)
      if (result.data == '' || result.data) {
        await fetchBeamProduct(seqCurrentPage)
        handleSuccessMsg("Product deleted successfully.")
      } else {
        const errorMessage = result.error?.message || 'Error Encountered While Deleting. Please try again later.'
        handleErrorMessage(errorMessage)
      }
    } catch (error) {
      console.error('Error deleting Beam:', error)
      handleErrorMessage('Error Encountered While Deleting. Please try again later.')
    }
  
    setIsModalOpen(false)
    setItemToDelete(null)
    setIsDeleting(false)
  }
  const handleCancelDelete = () => {
    setItemToDelete(null)
    setIsModalOpen(false)
  }
  /**
   * UPDATE
   */
  const handleUpdateSuccess = (newItem: any) => {
    if (!isSeqAdding) {
      const newData = {
        ...seqData,
        items: seqData.items?.map((item: any) => (item.id === editingSeqId ? newItem : item)),
      }
      setSeqData(newData)
    } else {
      seqData.items.unshift(newItem)
      setSeqData(seqData)
    }
    handleSuccessMsg("Product updated sucessfully.")
    setEditingSeqId(null)
    setEditedSeqData({})
    setNewProduct({})
    setIsSeqAdding(false)
  }

  const handleView = (iProductId: any) => {
    history.push(`/admin/product-details/Beam/${iProductId}`)
  }
  const handleSave = () => {
    const newData = seqData?.items?.map((item: any) =>
      item.id === editingSeqId ? editedSeqData : item
    )
    setSeqData(newData)
    setEditingSeqId(null)
    setEditedSeqData({})
  }
  /**
   * ADD
   */

  const handleAddProduct = () => {
    setIsAddingProduct(true)
  }
  const handleSaveClick = () => {
    setSeqData([newProduct, ...seqData])
    setIsSeqAdding(false)
  }
  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    if (!numRegex.test(priceRef.current.value)) {
      priceRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(nameRef.current.value)) {
      nameRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(typeRef.current.value)) {
      typeRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }
    if (!regex.test(finishRef.current.value)) {
      finishRef.current.value = value.replace(/[^a-zA-Z]/g, '')
      return
    }

    setNewProduct({...newProduct, [name]: value})
  }
  useEffect(() => {
    fetchBeamProduct(seqCurrentPage, Filter1, Filter2, Filter3)
  }, [seqCurrentPage, Filter1, Filter2, Filter3])

  /**
   * ALERT MESSAGE
   */
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const handleErrorMessage = (errorMsg: string) => {
    setErrorMsg(errorMsg)
    setSuccessMsg(undefined)
  }

  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const handleSuccessMsg = (successMsg: string) => {
    setSuccessMsg(successMsg)
    setErrorMsg(undefined)
  }

  const productFields = [
    { label: 'Item Number', key: 'Code', type: 'text' },
    { label: 'Beam Product', key: 'BeamProductName', type: 'text' },
    { label: 'Type', key: 'BeamSizeId', type: 'dropdown', options: beamSizes },
    { label: 'Colour', key: 'ColourId', type: 'dropdown', options: colours },
  ]

  const handleCloseNewProductModal = () => {
    setIsAddingProduct(false)
  }

  const handleSaveNewProduct = async (newProduct: any) => {
    console.log('newProduct', newProduct)
    createBeamProduct(newProduct)
    .then((result: any) => {
      if (result.data) {
        handleSuccessMsg("Product added successfully.")
        fetchBeamProduct(seqCurrentPage)
      } else {
        handleErrorMessage(result?.response?.data)
      }
    })
    .catch(() => {
      handleErrorMessage('Error Encountered While Saving. Please try again later.')
    })

    setIsSeqAdding(false)
  }


  return (
    <>
      <div className='card-body' style={{backgroundColor: '#fff', height: '100vh'}}>
        <AddProductButton onClick={handleAddProduct} />
        <div style={{overflowY: 'auto', height: '95%'}}>
        {errorMsg && (
            <div className='alert alert-danger w-50 m-auto text-center' role='alert'>
              <i className='fa fa-exclamation-circle text-danger'></i> {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className='alert alert-success w-50 m-auto text-center' role='alert'>
              <i className='fa fa-check-circle text-success'></i> {successMsg}
            </div>
          )}

          <BeamsProduct
            data={seqData}
            currentPage={seqCurrentPage}
            totalPages={seqTotalPages}
            handleView={handleView}
            handleSave={handleSave}
            editingId={editingSeqId}
            editedData={editedSeqData}
            handleSaveClick={handleSaveClick}
            handleInputChange={handleInputChange}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            handleCancelClick={handleCancelClick}
            handleCancel={handleCancel}
            handleDeleteProduct={handleDeleteProduct}
            handleChange={handleChange}
            isAdding={isSeqAdding}
            nameRef={nameRef}
            typeRef={typeRef}
            finishRef={finishRef}
            priceRef={priceRef}
            newProduct={newProduct}
            handleUpdateSuccess={handleUpdateSuccess}
            handleErrorMessage={handleErrorMessage}
            handleFilter={handleFilter}
          />
        </div>
      </div>
      <DeleteModal
        isModalOpen={isModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
        isDeleting={isDeleting}
      />
      <NewProductModal
        isOpen={isAddingProduct}
        onClose={handleCloseNewProductModal}
        onSave={handleSaveNewProduct}
        fields={productFields}
      />
    </>
  )
}

export default Beams
