/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AdminMenuItem} from './AdminMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AdminMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/* <div className='my-10 d-flex aligns-items-center justify-content-center'>
        <img
          src='/delta-panels-logo.webp'
          alt='Delta Panels logo'
          style={{width: '120px', height: '25px', objectFit: 'cover'}}
        />
      </div> */}
      <AdminMenuItem
        to='/admin/orders'
        title={intl.formatMessage({id: 'MENU.ORDERS'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      />
      {/* <AdminMenuItem
        to='/admin/price-books'
        title={intl.formatMessage({id: 'MENU.PRICEBOOKS'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      /> */}
      <AdminMenuItem
        to='/admin/order-number-manager'
        title={intl.formatMessage({id: 'MENU.ORDERNUMBERMANAGER'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      />
      <AsideMenuItemWithSub
        to='#'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.LOOKUPTABLES'})}
        fontIcon='fa-cog'
      >
        <AdminMenuItem
          to='/admin/lookup-tables/post-code-matrix'
          title='Post Code Matrix'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
        <AdminMenuItem
          to='/admin/lookup-tables/regions'
          title='Regions'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
        <AdminMenuItem
          to='/admin/lookup-tables/topographical-classification-matrix'
          title='Topographical Classification Matrix'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />

        <AdminMenuItem
          to='/admin/lookup-tables/wind-classification'
          title='Wind Classification'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
        <AdminMenuItem
          to='/admin/lookup-tables/load-capacity-matrix'
          title='Load Capacity Matrix'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
        <AdminMenuItem
          to='/admin/lookup-tables/span-matrix'
          title='Span Matrix'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
        <AdminMenuItem
          to='/admin/lookup-tables/beam-uplift-loads-matrix'
          title='Beam Uplift Loads Matrix'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        />
      </AsideMenuItemWithSub>
      <AdminMenuItem
        to='/admin/products'
        title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      />
      <AdminMenuItem
        to='/admin/colours'
        title={intl.formatMessage({id: 'MENU.COLOURS'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
