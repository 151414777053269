import React, {useState} from 'react'
import {moneyFormatter} from '../../../../utils'

function CustomerInfo(props: any) {
  const {customer} = props
  const [paymentMethod, setPayMethod] = useState(
    (localStorage.getItem('paymentMethod') as any) || ''
  )

  const [selectedShipping, setSelectedShipping] = useState(
    JSON.parse(localStorage.getItem('shipping-preference') as any)?.shipping
  )

  return (
    <div className='col-md-6'>
      <div className='row mb-4'>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='first-name'>
              First Name
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='first-name'
              className='form-control'
              name='firstName'
              value={customer?.firstName}
              readOnly
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='last-name'>
              Last Name
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='last-name'
              className='form-control'
              name='lastName'
              value={customer?.lastName}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='contact-number'>
              Contact Number
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='contact-number'
              className='form-control'
              placeholder='Phone Number 1'
              name='contact'
              value={customer?.contact}
              readOnly
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='email'>
              Email Address
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='email'
              id='email'
              className='form-control'
              placeholder='Email Address'
              name='email'
              value={customer?.email}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='address1'>
              Address
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='address1'
              className='form-control'
              placeholder='Address 1'
              name='address1'
              value={customer?.address1 + ' ' + customer?.address2}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-4'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='suburb'>
              Suburb
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='suburb'
              className='form-control'
              placeholder='Suburb'
              name='suburb'
              value={customer?.suburb}
              readOnly
            />
          </div>
        </div>
        <div className='col-4'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='state'>
              State
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='state'
              className='form-control'
              placeholder='State'
              name='state'
              value={customer?.state}
              readOnly
            />
          </div>
        </div>
        <div className='col-4'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='postCode'>
              Post Code
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='postCode'
              className='form-control'
              placeholder='Postcode'
              pattern='[0-9]*'
              name='postCode'
              value={customer?.postCode}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col'>
          <div className='form-outline'>
            <label className='fw-bolder text-dark' htmlFor='delivery'>
              Delivery Preferences
            </label>
            <input
              style={{fontSize: '1rem'}}
              type='text'
              id='delivery'
              className='form-control text-capitalize'
              placeholder='Delivery Preferences'
              name='delivery'
              value={selectedShipping.split('-').join(' ')}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerInfo
