import React, {useContext, useState, useEffect, Fragment} from 'react'
import {moneyFormatter} from '../../../../../utils'
import {QuickOrderContext} from '../../../../context/QuickOrderContext'
import {IBeamPricing} from '../../../../models/beams/beamPricing'
import {IBeamFinish} from '../../../../models/beams/beamFinish'
import {IBeamType} from '../../../../models/beams/beamType'
import {IBeam} from '../../../../models/beams/beams'
import {getUniqueIdWithPrefix} from '../../../../../_metronic/assets/ts/_utils'
import { BeamUnitPriceFor } from '../../../../common/constant'
import NewBeamFasciaHangingItem from './NewFasciaHangingItem'
import { 
  ORDER_LIST_BEAMS,
  ORDER_TAB_STEEL_BEAM,
  ORDER_TAB_BEAM_END_CAP,
  ORDER_TAB_STEEL_BEAM_END_CAP,
  ORDER_TAB_ALUMINIUM_BEAM_END_CAP,
  ORDER_TAB_BEAM_JOINER,
  ORDER_TAB_BEAM_SPACER,
  ORDER_TAB_BEAM_FASCIA_HANGING
} from '../../../../common/constant'

function NewBeamItem(props: any) {
  const getBeamFinishByType = (pricings: IBeamPricing[], type: string): IBeamFinish[] => {
    return pricings
      .filter((p) => p.beamTypeName === type)
      .map((p) => ({id: p.beamFinishId, name: p.beamFinishName}))
      .filter(
        (value, index, self) =>
          self.findIndex((item) => item.id === value.id && item.name === value.name) === index
      )
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  const {beam, beamName} = props

  const {
    addToCart,
    beamFinishes,
    beamPricings,
    steelBeamEndCapId,
    aluminiumBeamEndCapId,
    beamSpacerId,
    beamJoinerId,
    beams,
    beamTypes,
    beamColours,
    beamEndCapColours,
    setBeamEndCapUnitPrice,
    beamUnitPrice,
    getBeamUnitPriceAsync,
    getBeamEndCapUnitPriceAsync,
    beamProductMastersId,
    setBeamUnitPrice,
    isBeamColourColumnNull,
    isBeamBeamSizeColumnNull,
    beamCheckColumnNullAsync,
    fetchingBeamCheckColumnNull,
    setIsBeamColourColumnNull,
    isBeamTabQuantityOnly,
    setIsBeamTabQuantityOnly,
    isBeamTabNoColourOnly,
    setIsBeamTabNoColourOnly,
    beamEndCapUnitPrice,
    beamSpacerUnitPrice,
    beamJoinerUnitPrice,
    beamEndCapProductMastersId,
    beamSpacerProductMastersId,
    beamJoinerProductMastersId,
    beamFasciaHangingProductMastersId,
    setBeamFasciaHangingUnitPrice,
  } = useContext(QuickOrderContext)

  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [unitPriceToDisplay, setUnitPriceToDisplay] = useState<number>(0)
  const [prevUnitPriceToDisplay, setPrevUnitPriceToDisplay] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)

  const storedShippingPreference = JSON.parse(localStorage.getItem('shipping-preference') || '{}')
  const priceBookId: number | undefined = parseInt(storedShippingPreference.pricebookId)
  const pricingsByBook = beamPricings?.filter(
    (beamPricing: {priceBookId: number}) => beamPricing.priceBookId === priceBookId
  )

  const [isBeamChecked, setIsBeamChecked] = useState(false)
  const [isBeamEndCapChecked, setIsBeamEndCapChecked] = useState(false)
  const [isBeamSpacerChecked, setIsBeamSpacerChecked] = useState(false)
  const [isBeamJoinerChecked, setIsBeamJoinerChecked] = useState(false)
  const [isBeamFasciaHangingChecked, setIsBeamFasciaHangingChecked] = useState(false)

  const [endCapId, setEndCapId] = useState<number>(0)
  const [isJoinerAndFasciaHidden, setIsJoinerAndFasciaHidden] = useState(false)

  const [endCapUnitPrice, setEndCapUnitPrice] = useState<number>(0)
  const [spacerUnitPrice, setSpacerUnitPrice] = useState<number>(0)
  const [joinerUnitPrice, setJoinerUnitPrice] = useState<number>(0)
  const [fasciaHangingUnitPrice, setFasciaHangingUnitPrice] = useState<number>(0)

  const [beamEndCapQuantity, setBeamEndCapQuantity] = useState<number | null>(null)
  const [beamSpacerQuantity, setBeamSpacerQuantity] = useState<number | null>(null)
  const [beamJoinerQuantity, setBeamJoinerQuantity] = useState<number | null>(null)
  const [fasciaHangingQuantity, setFasciaHangingQuantity] = useState<number | null>(null)

  const [fasicaHangingSize, setFasicaHangingSize] = useState('')
  const [fasciaHangingSizeName, setFasciaHangingSizeName] = useState('')
  const [fasciaHangingColorId, setFasciaHangingColorId] = useState<number>(0)
  const [fasciaHangingColorName, setFasciaHangingColorName] = useState<string>('')

  /**
   * PRODUCT
   */
  const [beamEndCapProductName, setBeamEndCapProductName] = useState('')
  const [beamEndCapDescriptionProductName, setBeamEndCapDescriptionProductName] = useState('')
  const [beamProductName, setBeamProductName] = useState('')
  const [beamProduct, setBeamProduct] = useState('')
  const handleBeamProductOnChange = (e: any) => {
    setIsBeamTabQuantityOnly(false)
    setIsBeamTabNoColourOnly(false)
    setBeamProduct(e.target.value)
    setIsLengthAvailable(false)
    resetBeamState()
    setColorId(0)
    setBeamType('')
        
    if (e.target.value) {
      beamCheckColumnNullAsync(e.target.value)
    }
  }

  const resetBeamState = () => {
    setQuantity(null)
    setBeamEndCapQuantity(null)
    setLength('')
  }
  
  const resetFasciaHangingState = () => {
    setFasciaHangingQuantity(null)
  }

  useEffect(() => {
    if (beams && beams.length > 0) {
      let selected = beams.find((b: IBeam) => b.id.toString() === beamProduct)
      if (selected) {
        setBeamProductName(selected.name)
        resetBeamState()

        if (selected.name.toLowerCase().includes('steel')) {
          setEndCapId(steelBeamEndCapId)
        }
        else{
          setEndCapId(aluminiumBeamEndCapId)
        }
      } else {
        resetBeamState()
      }
    }
  }, [beamProduct])

  /**
   * TYPE
   */

  const [finishes, setFinishes] = useState<IBeamFinish[]>([])

  const [beamType, setBeamType] = useState('')
  const handleBeamTypeOnChange = (e: any) => {
    setBeamType(e.target.value)
    const selectedName = e.target.options[e.target.selectedIndex].innerHTML
    setFinishes(getBeamFinishByType(pricingsByBook, selectedName))
    setFinish('')
  }
  const [beamTypeName, setBeamTypeName] = useState('')
  useEffect(() => {
    if (beamTypes && beamTypes.length > 0) {
      let selected = beamTypes.find((b: IBeamType) => b.id.toString() == beamType)
      if (selected) {
        setBeamTypeName(selected.name)
      }
    }
  }, [beamType])
  /**
   * FINISH
   */
  const [finishName, setFinishName] = useState('')
  const [finish, setFinish] = useState('Select Finish')
  const handleFinishOnChange = (e: any) => {
    setFinish(e.target.value)
  }

  useEffect(() => {
    if (beamFinishes && beamFinishes.length > 0) {
      let selected = beamFinishes.find((b: IBeamFinish) => b.id.toString() == finish)
      if (selected) {
        setFinishName(selected.name)
      }
    }
  }, [finish])

  /**
   * COLOR
   */
  useEffect(() => {
    if (beamColours) {
      setColourOption(beamColours)
    }
  }, [beamColours])

  const [colorId, setColorId] = useState<number>(0)
  const [colorName, setColorName] = useState<string>('')
  const [colourOption, setColourOption] = useState<any[]>([])
  const handleColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
    const parsedValue = parseFloat(e.target.value)
    if (!isNaN(parsedValue)) {
      setColorId(parsedValue)
    } else {
      setColorId(0)
    }
    setColorName(name)
  }

  const [endCapColorId, setEndCapColorId] = useState<number>(0)
  const [endCapColorName, setEndCapColorName] = useState<string>('')
  const handleEndCapColorOnChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex
    const name = selectedIndex == 0 ? '' : colourOption[selectedIndex - 1].name
    const parsedValue = parseFloat(e.target.value)
    if (!isNaN(parsedValue)) {
      setEndCapColorId(parsedValue)
    } else {
      setEndCapColorId(0)
    }
    setEndCapColorName(name)
  }

  const [pricingId, setPricingId] = useState<number>(0)
  const [beamPricing, setBeamPricing] = useState<IBeamPricing>()
  const [isLengthAvailable, setIsLengthAvailable] = useState<boolean>(false)
  useEffect(() => {
    setUnitPrice(beamUnitPrice ? beamUnitPrice : 0)
    if (pricingsByBook && pricingsByBook.length > 0) {
      let beamPricing = pricingsByBook.find(
        (f: IBeamPricing) =>
          f.beamId == parseInt(beamProduct) &&
          f.beamTypeId.toString() == beamType &&
          f.beamFinishId.toString() == finish
      )

      if (beamPricing) {
        setIsLengthAvailable(beamPricing.unitTypeName == 'L/M')
        setUnitPrice(beamUnitPrice ? beamUnitPrice : 0)
        setPricingId(beamPricing.id)
        setBeamPricing(beamPricing)
        setUnitTypeDisplay(beamPricing.unitTypeName == 'L/M' ? 'per metre' : 'each')
      } else {
        setBeamPricing(undefined)
        setUnitPrice(beamUnitPrice ? beamUnitPrice : 0)
        setPricingId(0)
      }
    }
  }, [beamUnitPrice])

  /**
   * ERROR
   */
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [errorLength, setErrorLength] = useState<boolean>(false)
  const [errorLengthMsg, setErrorLengthMsg] = useState<string>('')
  useEffect(() => {
    props.setErrorMsg(errorMsg)
    props.setError(error)
  }, [errorMsg, error])
  /**
   * LENGTH
   */
  const [length, setLength] = useState<any>('')
  const handleLengthFocusChange = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue === 0 || inputValue < 0) {
      setErrorLengthMsg('Value must not be less than 100mm.')
      setErrorLength(true)
      return
    }
    if (inputValue < 100) {
      setErrorLengthMsg('Value must not be less than 100mm.')
      setErrorLength(true)
    } else if (inputValue > 23000) {
      setErrorLengthMsg('Value must not be greater than 23000mm.')
      setErrorLength(true)
    } else {
      setErrorLengthMsg('')
      setErrorLength(false)
    }
    setLength(inputValue)
  }

  const handleLengthBlur = (e: any) => {
    setErrorLengthMsg('')
    setErrorLength(false)
  }

  /**
   * QUANTITY
   */
  const [quantity, setQuantity] = useState<number | null>(null)
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim()
    let inputNumber
    if (inputValue === '') {
      setQuantity(null)
    } else {
      inputNumber = parseInt(inputValue, 10)
      if (!isNaN(inputNumber) && inputNumber >= 1 && inputNumber <= 99) {
        setQuantity(inputNumber)
      }
    }
  }

  const getBeamUnitPrice = async () => {
    if (beamProduct && beamType && colorId) {
      await getBeamUnitPriceAsync(beamProduct, beamType, colorId)
    }
    else {
      setBeamUnitPrice(0)
    }
  }

  useEffect(() => {
    setEndCapColorId(colorId)
    setEndCapColorName(colorName)
    setFasciaHangingColorId(colorId)
    setFasciaHangingColorName(colorName)
  }, [colorId])

  useEffect(() => {
    getBeamUnitPrice()
  }, [beamProduct, beamType, colorId])

  useEffect(() => {
    if (endCapId && beamType && colorId) {
      getBeamEndCapUnitPriceAsync(endCapId, beamType, colorId)
    }
    else {
      setBeamEndCapUnitPrice(0)
    }
    
  }, [endCapId, beamType, colorId])

  const disabledField = beamProduct === ''
  const isQuantityValid = quantity !== null && !isNaN(quantity) && quantity > 0 && quantity <= 99
  const isLengthValid = length !== null && !isNaN(length) && length >= 100 && length <= 23000
  const disabledBeamCheckBox = unitPrice === 0
  const disabledEndCapCheckBox = endCapUnitPrice === 0
  const disabledJoinerCheckBox = beamJoinerUnitPrice === 0
  const disabledSpacerCheckBox = beamSpacerUnitPrice === 0
  const disabledFasciaCheckBox = fasciaHangingUnitPrice === 0

  const disabledBeam = isBeamTabQuantityOnly
  ? (
      quantity === null ||
      unitPrice === 0 ||
      !isQuantityValid
    )
  : isBeamTabNoColourOnly
    ? (
        quantity === null ||
        unitPrice === 0 ||
        beamType === '' ||
        length === null ||
        !isQuantityValid
      )
    : (
        beamType === '' ||
        colorName === '' ||
        length === null ||
        quantity === null ||
        unitPrice === 0 ||
        !isQuantityValid ||
        !isLengthValid
      )

    const disabledBeamEndCap =
      !isBeamEndCapChecked ||
      endCapColorName === '' ||
      beamEndCapQuantity === null ||
      endCapUnitPrice === 0 ||
      isNaN(beamEndCapQuantity)

    const disabledBeamSpacer =
      !isBeamSpacerChecked ||
      beamSpacerQuantity === null ||
      isNaN(beamSpacerQuantity)

    const disabledBeamJoiner =
      !isBeamJoinerChecked ||
      beamJoinerQuantity === null ||
      isNaN(beamJoinerQuantity)

    const disabledBeamFasciaHanging =
      !isBeamFasciaHangingChecked ||
      fasciaHangingUnitPrice === 0 ||
      fasciaHangingQuantity === null ||
      isNaN(fasciaHangingQuantity)

    const disabled = (
      (!isBeamChecked && !isBeamEndCapChecked && !isBeamSpacerChecked && !isBeamJoinerChecked && !isBeamFasciaHangingChecked) ||
      (isBeamChecked && disabledBeam) ||
      (isBeamEndCapChecked && disabledBeamEndCap) ||
      (isBeamSpacerChecked && disabledBeamSpacer) ||
      (isBeamJoinerChecked && disabledBeamJoiner) ||
      (isBeamFasciaHangingChecked && disabledBeamFasciaHanging)
    )

  useEffect(() => {
    const lengthValue = length === '' || Number.isNaN(length) ? 0 : length
    const quantityValue = quantity === null || Number.isNaN(quantity) ? 0 : quantity

    if (!disabled) {
      setPrice(quantityValue * lengthValue * unitPrice)
    } else {
      setPrice(0)
    }
  }, [quantity, length, unitPrice, isQuantityValid, isLengthValid])

  /**
   * UNIT TYPE DISPLAY
   */
  const [unitTypeDisplay, setUnitTypeDisplay] = useState<string>('')

  const handleBeamCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsBeamChecked(true)
    } else {
      setIsBeamChecked(false)
    }
  }

  const handleBeamEndCapCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsBeamEndCapChecked(true)
    } else {
      setIsBeamEndCapChecked(false)
    }
  }

  const handleBeamSpacerCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsBeamSpacerChecked(true)
    } else {
      setIsBeamSpacerChecked(false)
    }
  }

  const handleBeamJoinerCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setIsBeamJoinerChecked(true)
    } else {
      setIsBeamJoinerChecked(false)
    }
  }

  const handleBeamEndCapQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim()
    let inputNumber
    if (inputValue === '') {
      setBeamEndCapQuantity(null)
    } else {
      inputNumber = parseInt(inputValue, 10)
      if (!isNaN(inputNumber) && inputNumber >= 1 && inputNumber <= 99) {
        setBeamEndCapQuantity(inputNumber)
      }
    }
  }

  const handleBeamSpacerQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim()
    let inputNumber
    if (inputValue === '') {
      setBeamSpacerQuantity(null)
    } else {
      inputNumber = parseInt(inputValue, 10)
      if (!isNaN(inputNumber) && inputNumber >= 1 && inputNumber <= 99) {
        setBeamSpacerQuantity(inputNumber)
      }
    }
  }

  const handleBeamJoinerQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim()
    let inputNumber
    if (inputValue === '') {
      setBeamJoinerQuantity(null)
    } else {
      inputNumber = parseInt(inputValue, 10)
      if (!isNaN(inputNumber) && inputNumber >= 1 && inputNumber <= 99) {
        setBeamJoinerQuantity(inputNumber)
      }
    }
  }

  useEffect(() => {
    if (!isBeamChecked) {
      setQuantity(null)
      setLength('')
    }
  }, [isBeamChecked])

  useEffect(() => {
    if (isBeamEndCapChecked) {
      setEndCapUnitPrice(beamEndCapUnitPrice ? beamEndCapUnitPrice : 0)
    }
    else {
      setBeamEndCapQuantity(null)
    }
  }, [isBeamEndCapChecked])

  useEffect(() => {
    setEndCapUnitPrice(beamEndCapUnitPrice ? beamEndCapUnitPrice : 0)
  }, [beamEndCapUnitPrice])

  useEffect(() => {
    if (isBeamSpacerChecked) {
      setSpacerUnitPrice(beamSpacerUnitPrice)
    }
    else {
      setSpacerUnitPrice(0)
      setBeamSpacerQuantity(null)
    }
  }, [isBeamSpacerChecked])

  useEffect(() => {
    if (isBeamJoinerChecked) {
      setJoinerUnitPrice(beamJoinerUnitPrice)
    }
    else {
      setJoinerUnitPrice(0)
      setBeamJoinerQuantity(null)
    }
  }, [isBeamJoinerChecked])

  useEffect(() => {
    if (unitPrice === 0) {
      setIsBeamChecked(false)
    }
  }, [unitPrice])

  useEffect(() => {
    if (endCapUnitPrice === 0) {
      setIsBeamEndCapChecked(false)
    }
  }, [endCapUnitPrice])

  useEffect(() => {
    if (spacerUnitPrice === 0) {
      setIsBeamSpacerChecked(false)
    }
  }, [spacerUnitPrice])

  useEffect(() => {
    if (joinerUnitPrice === 0) {
      setIsBeamJoinerChecked(false)
    }
  }, [joinerUnitPrice])

  useEffect(() => {
    if (fasciaHangingUnitPrice === 0) {
      setIsBeamFasciaHangingChecked(false)
    }
  }, [fasciaHangingUnitPrice])

  useEffect(() => {
    const combinedPrice = (isBeamChecked ? unitPrice : 0) + 
      (isBeamEndCapChecked ? endCapUnitPrice : 0) + 
      spacerUnitPrice + 
      joinerUnitPrice + 
      (isBeamFasciaHangingChecked ? fasciaHangingUnitPrice : 0)
    setUnitPriceToDisplay(combinedPrice)
  }, [unitPrice, endCapUnitPrice, spacerUnitPrice, joinerUnitPrice, fasciaHangingUnitPrice, isBeamChecked, isBeamEndCapChecked, isBeamFasciaHangingChecked])

  useEffect(() => {
    if (beamProduct !== '') {
      if (beamProductName.toLowerCase().includes('steel')) {
        setBeamEndCapProductName(ORDER_TAB_STEEL_BEAM_END_CAP.replace('Beam', ''))
        setBeamEndCapDescriptionProductName(ORDER_TAB_STEEL_BEAM_END_CAP)
        setIsJoinerAndFasciaHidden(false)
      }
      else if (beamProductName.toLowerCase().includes('aluminium')) {
        setBeamEndCapProductName(ORDER_TAB_ALUMINIUM_BEAM_END_CAP.replace('Beam', ''))
        setBeamEndCapDescriptionProductName(ORDER_TAB_ALUMINIUM_BEAM_END_CAP)
        setIsBeamFasciaHangingChecked(false)
        setIsBeamJoinerChecked(false)
        setIsJoinerAndFasciaHidden(true)
      }
      else {
        setBeamEndCapProductName(ORDER_TAB_BEAM_END_CAP)
        setIsJoinerAndFasciaHidden(false)
        setIsBeamChecked(false)
        setIsBeamEndCapChecked(false)
        setIsBeamFasciaHangingChecked(false)
      }
    }
    else {
      setBeamEndCapProductName(ORDER_TAB_BEAM_END_CAP)
      setIsJoinerAndFasciaHidden(false)
      setIsBeamChecked(false)
      setIsBeamEndCapChecked(false)
      setIsBeamFasciaHangingChecked(false)
    }
    setColorId(0)
    setBeamType('')
  }, [beamProductName, beamProduct])

  const getCheckboxMessage = () => {
    if (isJoinerAndFasciaHidden) {
      return "Only applicable for steel beam"
    }

    return ""
  }
  
  const BeamAddToCart = () => {
    addToCart({
      ...beam,
      ...beamPricing,
      pricingId,
      beamProductMastersId,
      beamProductName,
      beamTypeName,
      beamName,
      beamProduct,
      colorId,
      colorName,
      length,
      quantity,
      unitPrice,
      customId: Math.random(),
      type: 'beam',
      uniqueID:
        `beam-${beamName}-${beamType}-${colorId}-${quantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
          'beam'
        )}`.replace(/\s/g, ''),
      price: unitPrice,
      productType: ORDER_LIST_BEAMS,
      productName: beamProductName,
      description:  `${beamTypeName}, ${colorName}`,
    })
    resetBeamState()
    setBeamEndCapQuantity(null)
    setBeamSpacerQuantity(null)
    setBeamJoinerQuantity(null)
  }

  const BeamEndCapAddToCart = () => {
    addToCart({
      ...beam,
      pricingId: 0,
      beamProductMastersId: beamEndCapProductMastersId,
      beamProductName: beamEndCapProductName,
      beamTypeName: beamTypeName,
      beamName,
      beamProduct: endCapId,
      colorId: colorId,
      colorName: colorName,
      length: 0,
      quantity: beamEndCapQuantity,
      unitPrice: beamEndCapUnitPrice,
      customId: Math.random(),
      type: 'beam',
      uniqueID:
        `beam-${beamName}-${beamEndCapQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
          'beam'
        )}`.replace(/\s/g, ''),
      price: beamEndCapUnitPrice,
      productType: ORDER_LIST_BEAMS,
      productName: beamEndCapDescriptionProductName,
      description:  `${beamTypeName}, ${colorName}`,
    })
    resetBeamState()
  }

  const BeamSpacerAddToCart = () => {
    addToCart({
      ...beam,
      pricingId: 0,
      beamProductMastersId: beamSpacerProductMastersId,
      beamProductName: ORDER_TAB_BEAM_SPACER,
      beamTypeName: '',
      beamName,
      beamProduct: beamSpacerId,
      colorId: 0,
      colorName: '',
      length: 0,
      quantity: beamSpacerQuantity,
      unitPrice: beamSpacerUnitPrice,
      customId: Math.random(),
      type: 'beam',
      uniqueID:
        `beam-${beamName}-${beamSpacerQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
          'beam'
        )}`.replace(/\s/g, ''),
      price: beamSpacerUnitPrice,
      productType: ORDER_LIST_BEAMS,
      productName: ORDER_TAB_BEAM_SPACER,
      description: '-',
    })
    resetBeamState()
    setBeamSpacerQuantity(null)
  }

  const BeamJoinerAddToCart = () => {
    addToCart({
      ...beam,
      pricingId: 0,
      beamProductMastersId: beamJoinerProductMastersId,
      beamProductName: ORDER_TAB_BEAM_JOINER,
      beamName,
      beamProduct: beamJoinerId,
      colorId: 0,
      colorName: '',
      length: 0,
      quantity: beamJoinerQuantity,
      unitPrice: beamJoinerUnitPrice,
      customId: Math.random(),
      type: 'beam',
      uniqueID:
        `beam-${beamName}-${beamJoinerQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
          'beam'
        )}`.replace(/\s/g, ''),
      price: beamJoinerUnitPrice,
      productType: ORDER_LIST_BEAMS,
      productName: ORDER_TAB_BEAM_JOINER,
      description: '-',
    })
    resetBeamState()
    setBeamJoinerQuantity(null)
  }

  const BeamFasciaHangingAddToCart = () => {
    addToCart({
      pricingId: 0,
      beamProductMastersId: beamFasciaHangingProductMastersId,
      beamProductName: ORDER_TAB_BEAM_FASCIA_HANGING,
      beamTypeName: beamTypeName,
      beamName,
      beamProduct: beamFasciaHangingProductMastersId,
      colorId: colorId,
      colorName: colorName,
      length: 0,
      quantity: fasciaHangingQuantity,
      unitPrice: fasciaHangingUnitPrice,
      customId: Math.random(),
      type: 'beam',
      uniqueID:
        `beam-${beamName}-${fasciaHangingQuantity}-${Math.random().toString(36).substring(2, 15)}-${getUniqueIdWithPrefix(
          'beam'
        )}`.replace(/\s/g, ''),
      price: fasciaHangingUnitPrice,
      productType: ORDER_LIST_BEAMS,
      productName: ORDER_TAB_BEAM_FASCIA_HANGING,
      description:  `${beamTypeName}, ${colorName}`,
    })
    resetFasciaHangingState()
  }
  
  const handleAddToCart = () => {
    if (isBeamChecked && !disabledBeam) {
      BeamAddToCart()
    }

    if (isBeamEndCapChecked && !disabledBeamEndCap) {
      BeamEndCapAddToCart()
    }

    if (isBeamJoinerChecked && !disabledBeamJoiner) {
      BeamJoinerAddToCart()
    }
  
    if (isBeamSpacerChecked && !disabledBeamSpacer) {
      BeamSpacerAddToCart()
    }
  
    if (isBeamFasciaHangingChecked && !disabledBeamFasciaHanging) {
      BeamFasciaHangingAddToCart()
    }
  }

  return (
    <Fragment>
      <div className='row' style={{position: 'relative'}}>
        <div className='col-md-4'>
          <label className='fw-bolder text-dark'>Product</label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={beamProduct}
            onChange={handleBeamProductOnChange}
          >
            <option value=''>Select Product</option>
            {beams?.filter((beam: any) => !beam.name.toLowerCase().includes('end cap') && beam.name !== 'Beam Spacer' 
              && beam.name !== 'Beam Joiner' && !beam.name.toLowerCase().includes('fascia'))
              .map((beam: { id: number; name: string }) => (
                <option key={beam.id} value={beam.id}>
                  {beam.name}
                </option>
              ))
            }
          </select>
        </div>
        {/*  COLOR */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='colorId'>
            Colour
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='colorId'
            className='form-select my-4'
            aria-label='Colour'
            value={colorId}
            onChange={handleColorOnChange}
            disabled={disabledField}
          >
            <option value='Select Colour'>Select Colour</option>
            {beamColours?.map((beamColour: any) => (
              <option value={beamColour.id} key={beamColour.id}>
                {beamColour.name}
              </option>
            ))}
          </select>
        </div>
        {/* TYPE / SIZE */}
        <div className='col-md-2'>
          <label className='fw-bolder text-dark' htmlFor='sub-type'>
            Size
          </label>
          <select
            style={{fontSize: '1rem'}}
            className='form-select my-4'
            value={beamType}
            onChange={handleBeamTypeOnChange}
            id='beam-type'
            disabled={disabledField}
          >
            <option value=''>Select Size</option>
            {beamTypes?.map((beamType: any) => (
              <option value={beamType.id} key={beamType.id}>
                {beamType.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-3' style={{position: 'absolute', right: 0, top: 10}}>
          <div
            className='d-flex flex-column justify-content-center align-items-end'
            style={{
              backgroundColor: '#B5B5C3',
              paddingTop: 6,
              paddingBottom: 6,
              marginBottom: 10,
              borderRadius: '6.175px',
            }}
          >
            <>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 0,
                  marginRight: 24,
                }}
              >
                {moneyFormatter.format(unitPriceToDisplay)}
              </p>
              <p style={{fontWeight: '700', marginBottom: 0, marginRight: 24}}>
                {unitPrice ? unitTypeDisplay : ''}
              </p>
            </>
          </div>
          <button
            className='btn btn-primary w-100'
            onClick={handleAddToCart}
            disabled={disabled}
          >
            <i className='fa fa-plus'></i>ADD
          </button>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-9'>
          <div style={{borderBottom: '1px solid #B5B5C3'}} />
        </div>
        <div className='col-md-3' />
      </div>
      {/*  BEAM */}
      <div className='row'>
        <div className='col-md-4 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='beamCheckBox'
              name='beamCheckBox'
              checked={isBeamChecked}
              onChange={handleBeamCheckboxChange}
              style={{marginRight: 10}}
              disabled={disabledBeamCheckBox}
            />
            <label className='fw-bolder text-dark' style={{fontSize: 12}}>Beam</label>
          </div>
          <span style={{fontSize: 12, fontWeight: 'bold'}}>
            ({moneyFormatter.format(
              unitPrice
            )})
          </span>
        </div>
        {/*  QUANTITY */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            className='form-control my-4'
            min='1'
            max='99'
            value={quantity === null ? '' : quantity}
            onChange={handleQuantityChange}
            disabled={!isBeamChecked}
            placeholder='Enter Quantity'
          />
        </div>
        {/* LENGTH */}
        <div className='col-md-2'>
          <label
            className='fw-bolder text-dark'
            htmlFor='length'
            // hidden={disabledField || !isLengthAvailable}
          >
            Length (mm)
          </label>
          <input
            style={{
              fontSize: '1rem',
              borderColor: length < 100 && length !== '' ? 'red' : '',
            }}
            placeholder='Enter Length'
            min='1'
            step='1'
            max='23000'
            type='number'
            className='form-control my-4'
            id='length'
            aria-describedby='length'
            value={length || ''}
            onFocus={handleLengthFocusChange}
            onBlur={handleLengthBlur}
            onChange={handleLengthFocusChange}
            disabled={!isBeamChecked}
            // hidden={disabledField || !isLengthAvailable}
          />
        </div>
      </div>
      {/*  Beam Length Error  */}
      {errorLength ? (
          <>
            <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-3'></div>
              <div className='col-md-4'>
                <div className='text-danger m-auto text-left' role='alert'>
                  {errorLengthMsg}
                </div>
              </div>
            </div>
          </>
        ) : null}
      {/*  Beam End Cap  */}
      <div className='row mb-4'>
        <div className='col-md-4 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='beamEndCap'
              name='beamEndCap'
              checked={isBeamEndCapChecked}
              onChange={handleBeamEndCapCheckboxChange}
              style={{marginRight: 10}}
              disabled={disabledEndCapCheckBox}
            />
            <label className='fw-bolder text-dark' style={{fontSize: 12}}>{beamEndCapProductName}</label>
          </div>
          <span style={{fontSize: 12, fontWeight: 'bold'}}>
            ({moneyFormatter.format(
              beamEndCapUnitPrice ? beamEndCapUnitPrice : 0
            )})
          </span>
        </div>
        {/* BEAM END CAP COLOR */}
        {/* <div className='col-md-3'>
          <label className='fw-bolder text-dark' htmlFor='colorId'>
            Colour
          </label>
          <select
            style={{fontSize: '1rem'}}
            id='endCapcolorId'
            className='form-select'
            aria-label='endCapColour'
            value={endCapColorId}
            onChange={handleEndCapColorOnChange}
            disabled={!isBeamEndCapChecked}
          >
            <option value='Select Colour'>Select Colour</option>
            {beamEndCapColours?.map((beamColour: any) => (
              <option value={beamColour.id} key={beamColour.id}>
                {beamColour.name}
              </option>
            ))}
          </select>
        </div> */}
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='beamEndCapQuantity'
            aria-describedby='beamEndCapQuantity'
            value={beamEndCapQuantity === null ? '' : beamEndCapQuantity}
            onChange={handleBeamEndCapQuantityChange}
            disabled={!isBeamEndCapChecked}
            placeholder='Enter Quantity'
          />
        </div>
      </div>
      {!isJoinerAndFasciaHidden ? (
        <>
          {/*  Beam Joiner  */}
          <div className='row  mb-4'>
            <div className='col-md-4 d-flex align-items-center justify-content-between' title={getCheckboxMessage()}>
              <div>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='beamJoiner'
                  name='beamJoiner'
                  checked={isBeamJoinerChecked}
                  onChange={handleBeamJoinerCheckboxChange}
                  style={{marginRight: 10}}
                  disabled={isJoinerAndFasciaHidden || disabledJoinerCheckBox}
                />
                <label className='fw-bolder text-dark' style={{fontSize: 12}}>Beam Joiner</label>
              </div>
              <span style={{fontSize: 12, fontWeight: 'bold'}}>
                ({moneyFormatter.format(
                  beamJoinerUnitPrice
                )})
              </span>
            </div>
            {/* Quantity */}
            <div className='col-md-3'>
              <label className='fw-bolder text-dark'>Quantity</label>
              <input
                style={{fontSize: '1rem'}}
                type='number'
                min='1'
                step='1'
                max='99'
                className='form-control'
                id='beamJoinerQuantity'
                aria-describedby='beamJoinerQuantity'
                value={beamJoinerQuantity === null ? '' : beamJoinerQuantity}
                onChange={handleBeamJoinerQuantityChange}
                disabled={!isBeamJoinerChecked}
                placeholder='Enter Quantity'
              />
            </div>
          </div>
        </>
      ) : null}
      {/*  Beam Spacer  */}
      <div className='row  mb-4'>
        <div className='col-md-4 d-flex align-items-center justify-content-between'>
          <div>
            <input
              className='form-check-input'
              type='checkbox'
              id='beamSpacer'
              name='beamSpacer'
              checked={isBeamSpacerChecked}
              onChange={handleBeamSpacerCheckboxChange}
              style={{marginRight: 10}}
              disabled={disabledSpacerCheckBox}
            />
            <label className='fw-bolder text-dark' style={{fontSize: 12}}>Beam Spacer</label>
          </div>
          <span style={{fontSize: 12, fontWeight: 'bold'}}>
            ({moneyFormatter.format(
              beamSpacerUnitPrice
            )})

          </span>
        </div>
        {/* Quantity */}
        <div className='col-md-3'>
          <label className='fw-bolder text-dark'>Quantity</label>
          <input
            style={{fontSize: '1rem'}}
            type='number'
            min='1'
            step='1'
            max='99'
            className='form-control'
            id='beamSpacerQuantity'
            aria-describedby='beamSpacerQuantity'
            value={beamSpacerQuantity === null ? '' : beamSpacerQuantity}
            onChange={handleBeamSpacerQuantityChange}
            disabled={!isBeamSpacerChecked}
            placeholder='Enter Quantity'
          />
        </div>
      </div>

      <div className='row'>
      </div>
      <NewBeamFasciaHangingItem 
        isBeamFasciaHangingChecked={isBeamFasciaHangingChecked} 
        setIsBeamFasciaHangingChecked={setIsBeamFasciaHangingChecked} 
        fasciaHangingUnitPrice={fasciaHangingUnitPrice} 
        setFasciaHangingUnitPrice={setFasciaHangingUnitPrice} 
        fasciaHangingQuantity={fasciaHangingQuantity} 
        setFasciaHangingQuantity={setFasciaHangingQuantity} 
        fasicaHangingSize={fasicaHangingSize} 
        setFasicaHangingSize={setFasicaHangingSize} 
        fasciaHangingSizeName={fasciaHangingSizeName} 
        setFasciaHangingSizeName={setFasciaHangingSizeName} 
        fasciaHangingColorId={fasciaHangingColorId} 
        setFasciaHangingColorId={setFasciaHangingColorId} 
        fasciaHangingColorName={fasciaHangingColorName} 
        setFasciaHangingColorName={setFasciaHangingColorName} 
        resetFasciaHangingState={resetFasciaHangingState}
        beamType={beamType}
        colorId={colorId}
        isJoinerAndFasciaHidden={isJoinerAndFasciaHidden}
        length={length}
        isLengthValid={isLengthValid}
        disabledFasciaCheckBox={disabledFasciaCheckBox}
      />
    </Fragment>
    
  )
}

export default NewBeamItem
