import React from 'react'
import { moneyFormatter } from '../../../../../utils'
import { 
    ORDER_TAB_LIGHTS_170MM_X_1_LIGHT_PER_BOX,
    ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT
 } from '../../../../common/constant'

function LightsTable(props: any) {
    const { cartItems } = props
    return (
        <div>
            <table className='table table-hover summary-table' style={{ backgroundColor: '#fff' }}>
                <thead className='thead-dark'>
                    <tr>
                        <th scope='col' style={{ width: '20%' }}>
                            Description
                        </th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'>Colour</th>
                        <th scope='col' style={{ width: '15%' }} className='text-center'></th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Length
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Quantity
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Unit Price
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            GST
                        </th>
                        <th scope='col' style={{ width: '10%' }} className='text-center'>
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems?.map((item: any) => {
                        if (item.type === 'lights') {
                            const length = item?.length ? item?.length / 1000 : 1
                            
                            let computedPrice = 0.00
                            let gst = 0.00
                            let totalAmount = 0.00

                            const roundToTwo = (num: number) => Math.round(num * 100) / 100

                            if (item?.length) {
                                computedPrice = (item.unitPrice * (item?.length / 1000) * item?.quantity)
                            }
                            else{
                                computedPrice = (item.unitPrice * item?.quantity)
                            }

                            gst = roundToTwo(computedPrice * .1)

                            totalAmount = roundToTwo(computedPrice)  + gst

                            return (
                                <tr key={item?.uniqueID}>
                                    <td className='align-middle'>
                                        <p className='fw-bolder text-dark mb-0 text-capitalize'>{item?.lightName}</p>
                                        {item.lightsTypeName.toLowerCase().trim() !== ORDER_TAB_LIGHTS_170MM_X_1_LIGHT_PER_BOX.toLowerCase().trim() ? (
                                            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    {item?.lightName.toLowerCase().trim() === ORDER_TAB_LIGHTS_DELTALOWPROFILE_LIGHT.toLowerCase().trim() ? 'Colour:' : `Panel Type:`}
                                                </p>
                                                <p className='text-dark mb-0 text-capitalize'>{item?.panelTypeName}</p>
                                            </div>
                                        ) : null}
                                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                            <p className='text-dark mb-0 text-capitalize'>Product Type:</p>
                                            <p className='text-dark mb-0 text-capitalize'>{item?.lightsTypeName}</p>
                                        </div>
                                        {item?.lightThicknessName ? (
                                            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                                <p className='text-dark mb-0 text-capitalize'>
                                                    Thickness:
                                                </p>
                                                <p className='text-dark mb-0 text-capitalize'>{item?.lightThicknessName}</p>
                                            </div>
                                        ) : null}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>-</td>    
                                    <td />
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {item.length === 0 ? '-' : `${item.length}mm`}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {(item.quantity) > 0 ? (item?.quantity) : '-'}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(item?.unitPrice)}
                                    </td>
                                    {/* Assumption */}
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(gst)}
                                    </td>
                                    <td className='fw-bolder text-dark align-middle text-center'>
                                        {moneyFormatter.format(totalAmount)}
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default LightsTable
