export enum SHIPPING_TYPES {
  BNE = 'customer-collect-bne',
  SYD = 'customer-collect-syd',
  DELIVERY = 'delivery',
}

/** CURRENCY FORMATTER */
export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const roundToTwo = (num: number) => Math.round(num * 100) / 100

export const validLimitDecimal = (amount: string): boolean => {
  const inputValue = amount
  // Use a regular expression to limit to a maximum of 2 decimal places and allow only valid numeric input
  const validValue = inputValue.match(/^\d+(\.\d{0,2})?$/) || ''
  if (!validValue[0]) {
    return false
  }
  return true
}

export const getFirstWord = (word: string): string => {
  const arrayWord = word.split(' ')
  return arrayWord.length > 0 ? arrayWord[0] : ''
}


export const dateFormat = (dateString: string): string => {
  const dateObject = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
  };

 return dateObject.toLocaleDateString('en-US', options);

}

