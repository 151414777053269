import React from 'react'

interface IProps {}

export const HomeContext = React.createContext<IProps>({})

export const HomeContextProvider: React.FC = ({children}) => {
  const value = ''

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}
