import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { QuickOrderContext } from '../../context/QuickOrderContext'
function ConfirmOrderContent() {
    const history = useHistory();
    const { cartItems, setCartItems } = useContext(QuickOrderContext);
    const orderNumber = localStorage.getItem('orderNumber');
    const orderNumberLength = 5;

    useEffect(() => {
        if (cartItems?.length > 0) {
            setCartItems([]);
        }

        if (orderNumber === null) {
            history.push('/');
        }
    }, [cartItems, setCartItems, orderNumber, history]);

    return (
        <div className='container'>
            <div
                className='d-flex align-center justify-content-center'
                style={{ marginTop: 30, marginBottom: 30 }}
            >
                <img
                    src='/delta-panels-logo.png'
                    alt='Delta Panels logo'
                    style={{ width: '247px', height: '65px', objectFit: 'cover' }}
                />
            </div>

            <div className='d-flex align-center justify-content-center'>
                <i
                    className='bi bi-check-circle'
                    style={{ fontSize: '96px', marginBottom: 30, color: '#1DB954' }}
                ></i>
            </div>

            <h4 className='text-center fw-bold' style={{ marginBottom: 30, fontSize: 32 }}>
                Thank you for your order!
            </h4>
            <div style={{ marginBottom: 30 }}>
                <h3 className='text-center'>ORDER CONFIRMATION</h3>
                <p className='text-center fw-bold'>Order Number: {String(orderNumber).padStart(orderNumberLength, '0')}</p>
            </div>
            <p className='text-center' style={{ marginBottom: 30, fontSize: 14 }}>
                We’ve received your order and will contact you as soon as we receive your payment.
            </p>
            <div className='d-flex align-center justify-content-center'>
                <Link to={'/'} className='btn btn-primary'>
                    Back to Home
                </Link>
            </div>
        </div>
    )
}

export default ConfirmOrderContent
