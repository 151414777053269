import React from 'react'
import {moneyFormatter} from '../../../../../utils'
import { 
  FIXINGS_CYCLONE_PLATES,
 } from '../../../../common/constant'

function FixingsTable(props: any) {
  const {cartItems} = props

  return (
    <div>
      <table className='table table-hover summary-table' style={{backgroundColor: '#fff'}}>
        <thead className='thead-dark'>
          <tr>
            <th scope='col' style={{width: '20%'}}>
              Description
            </th>
            <th scope='col' style={{width: '15%'}} className='text-center'>Colour</th>
            <th scope='col' style={{width: '15%'}} className='text-center'></th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Length
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Quantity
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Unit Price
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              GST
            </th>
            <th scope='col' style={{width: '10%'}} className='text-center'>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((item: any, index: number) => {
            if (item.type === 'fixing') {
              let computedPrice = 0.00
              let gst = 0.00
              let totalAmount = 0.00

              const roundToTwo = (num: number) => Math.round(num * 100) / 100

              computedPrice = (item.unitPrice * item?.quantity)
              gst = roundToTwo(computedPrice * .1)
              totalAmount = roundToTwo(computedPrice)  + gst

              return (
                <tr key={index}>
                  <td className='align-middle'>
                    <p className='fw-bolder text-dark mb-0'>{item?.fixingName}</p>
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                      <p className='text-dark mb-0'>Type:</p>
                      <p className='text-dark mb-0'>{item?.fixingName === FIXINGS_CYCLONE_PLATES ? FIXINGS_CYCLONE_PLATES : item?.screwType}</p>
                    </div>
                  </td>
                  <td className='fw-bolder text-dark align-middle text-center'>{item?.colorName ? item?.colorName : '-'}</td>
                  <td />
                  <td className='fw-bolder text-dark align-middle text-center'>-</td>
                  <td className='fw-bolder text-dark align-middle text-center'>{item?.quantity}</td>
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(item?.price)}
                    {/* {item?.fixingName === 'Tek Screws' || item?.fixingName === 'Rivets'
                      ? moneyFormatter.format((item?.unitPrice * item?.quantity) / 100)
                      : moneyFormatter.format(item?.unitPrice * item?.quantity)} */}
                  </td>
                  {/* Assumption */}
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(gst)}
                  </td>
                  <td className='fw-bolder text-dark align-middle text-center'>
                    {moneyFormatter.format(totalAmount)}
                    {/* {item?.fixingName === 'Tek Screws' || item?.fixingName === 'Rivets'
                      ? moneyFormatter.format((item?.price * item?.quantity) / 100 + item?.gst)
                      : moneyFormatter.format(item?.price * item?.quantity + item?.gst)} */}
                  </td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default FixingsTable
