import React, {useContext, useEffect, useState} from 'react'
import {moneyFormatter, validLimitDecimal} from '../../../../../../utils'
import {AdminProductContext} from '../../../../../context/AdminProductContext'
import {NumericInput} from '../../../../../shared/components/NumericInput'

type Product = {
  data?: any
  currentPage?: number
  totalPages?: number
  handleUpdateProduct: (id: any) => void
  handleSave: () => void
  editingId?: any
  editedData?: any
  handleSaveClick: () => void
  handleInputChange: (e: any) => void
  handlePrevPage: () => void
  handleNextPage: () => void
  handleCancelClick: () => void
  handleCancel: () => void
  handleDeleteProduct: (id: any) => void
  handleChange: (e: any) => void
  handleUpdateSuccess: (e: any) => void
  handleErrorMessage: (msg: string) => void
  isAdding: boolean
  nameRef: any
  unitRef: any
  beamClassRef: any
  priceRef: any
  newProduct: any
  handleFilter: (filter1: any, filter2: any, filter3: any, filter4: any, filter5: any) => void
}

function PostsProduct(props: Product) {
  const {
    data,
    currentPage,
    totalPages,
    handleUpdateProduct,
    handleSave,
    editingId,
    editedData,
    handleSaveClick,
    handleInputChange,
    handlePrevPage,
    handleNextPage,
    handleCancelClick,
    handleCancel,
    handleDeleteProduct,
    handleChange,
    handleUpdateSuccess,
    handleErrorMessage,
    isAdding,
    nameRef,
    unitRef,
    beamClassRef,
    priceRef,
    newProduct,
    handleFilter,
  } = props
  const {postTypes, postProducts, postFinishes, postUnitTypes} = useContext(AdminProductContext)
  const [saveData, setSaveData] = useState(isAdding ? newProduct : editedData)

  const [productTypeFilter, setproductTypeFilter] = useState('')
  const [postTypeFilter, setpostTypeFilter] = useState('')
  const [beamSizeFilter, setbeamSizeFilter] = useState('')
  const [extenderLengthFilter, setExtenderLengthFilter] = useState('')
  const [colourFilter, setcolourFilter] = useState('')

  useEffect(() => {
    handleFilter(
      productTypeFilter,
      postTypeFilter,
      beamSizeFilter,
      extenderLengthFilter,
      colourFilter
    )
  }, [productTypeFilter, postTypeFilter, beamSizeFilter, extenderLengthFilter, colourFilter])

  const numRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/

  /**
   * Options
   */
  const [postProductOptions, setPostProductOptions] = useState<any[]>([])
  useEffect(() => {
    if (postProducts) {
      const newPosProduct = postProducts.filter(
        (item: any) => item.name.toLowerCase().indexOf('post kit') === -1
      )
      setPostProductOptions(newPosProduct)
    }
  }, [postProducts])

  useEffect(() => {
    setSaveData(editedData)
  }, [editedData])

  useEffect(() => {
    setSaveData(newProduct)
  }, [newProduct])

  const invalidRecord =
    (saveData?.productType || '') === '' ||
    (saveData?.postType || '') === '' ||
    (saveData?.beamSize || '') === '' ||
    (saveData?.colour || '') === ''

  return (
    <div style={{width: '85%'}} className='m-auto mt-4'>
      <table className='table table-hover product-table '>
        <thead className='thead-dark'>
          <tr key={Math.random()}>
            <th scope='col' className='align-middle text-center'>
              Item Number
            </th>
            <th scope='col' className='align-middle text-center'>
              Product Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Post Type
            </th>
            <th scope='col' className='align-middle text-center'>
              Beam Size
            </th>
            <th scope='col' className='align-middle text-center'>
              Extender Length
            </th>
            <th scope='col' className='align-middle text-center'>
              Colour
            </th>
            <th scope='col' className='align-middle text-center'></th>
          </tr>
          <tr>
            <td className='align-middle text-center'></td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={productTypeFilter}
                onChange={(e) => setproductTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={postTypeFilter}
                onChange={(e) => setpostTypeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={beamSizeFilter}
                onChange={(e) => setbeamSizeFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={extenderLengthFilter}
                onChange={(e) => setExtenderLengthFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'>
              <input
                type='text'
                value={colourFilter}
                onChange={(e) => setcolourFilter(e.target.value)}
                className='filter-input'
              />
            </td>
            <td className='align-middle text-center'></td>
          </tr>
        </thead>
        <tbody>
          {data?.items?.length === 0 ? (
            <tr>
              <td colSpan={5} className='align-middle text-center'>
                There are no products to display!
              </td>
            </tr>
          ) : (
            data?.items?.map((data: any, index: number) => (
              <tr key={index + 1}>
                <td className='align-middle text-center'>{data?.code}</td>
                <td className='align-middle text-center'>{data?.postProductName}</td>
                <td className='align-middle text-center'>{data?.postTypeName}</td>
                <td className='align-middle text-center'>{data?.beamSizeName}</td>
                <td className='align-middle text-center'>{data?.extenderLengthName}</td>
                <td className='align-middle text-center'>{data?.colourName}</td>
                <td className='align-middle text-center' style={{width: '250px'}}>
                  <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => handleUpdateProduct(data?.id)}
                      style={{marginRight: 10}}
                    >
                      View
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleDeleteProduct(data?.id)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className='pagination d-flex align-items-center pb-4'>
        <button className='page-link' onClick={handlePrevPage} disabled={currentPage === 1}>
          <i className='bi bi-chevron-left'></i>
        </button>
        <div className='px-4'>{`Page ${currentPage} of ${totalPages}`}</div>
        <button
          className='page-link'
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <i className='bi bi-chevron-right'></i>
        </button>
      </div>
    </div>
  )
}

export default PostsProduct
