import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UserPage = lazy<React.FC>(() => import('../modules/users/UserPage'))
  const RolePage = lazy<React.FC>(() => import('../modules/roles/RolePage'))

  return (
    <MasterLayout>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route exact path='/dashboard' component={DashboardWrapper} />
          <Route path='/user' render={() => <UserPage />} />
          <Route path='/role' render={() => <RolePage />} />
          <Route path='/builder' component={BuilderPageWrapper} />
          <Route path='/crafted/pages/profile' component={ProfilePage} />
          <Route path='/crafted/pages/wizards' component={WizardsPage} />
          <Route path='/crafted/widgets' component={WidgetsPage} />
          <Route path='/crafted/account' component={AccountPage} />
          <Route path='/apps/chat' component={ChatPage} />
          <Route path='/menu-test' component={MenuTestPage} />
          <Redirect to='/' />
        </Switch>
      </Suspense>
    </MasterLayout>
  )
}
